import validator from 'validator';

const validateName = (value) => {
    const errors = [];

    if (!value.trim().length) {
      errors.push({ field: 'name', message: 'Dit veld is verplicht. Vul alstublieft uw naam in voordat u verdergaat' });
    }

    return errors;
  },
  validateEmail = (value) => {
    const errors = [];

    if (!validator.isEmail(value)) {
      errors.push({ field: 'email', message: 'Dit veld is verplicht. Vul alstublieft een geldig e-mailadres in voordat u verdergaat' });
    }

    return errors;
  },
  validateSubjectType = (value) => {
    const errors = [];

    if (!value) {
      errors.push({ field: 'subjectType', message: 'Dit veld is verplicht. Kies alstublieft een onderwerp voordat u verdergaat' });
    }

    return errors;
  },
  validateMessage = (value) => {
    const errors = [];

    if (!value.trim().length) {
      errors.push({ field: 'message', message: 'Dit veld is verplicht. Vul alstublieft een bericht in voordat u verdergaat' });
    }

    return errors;
  };

export default {
  name: validateName,
  email: validateEmail,
  subjectType: validateSubjectType,
  message: validateMessage,
};
