export const subject = `Account aanvragen Debat Direct Kamerbeelden`;

export const body = `Beste Tweede Kamer,
      
Namens de redactie van ...vraag ik een account aan voor de website Debat Direct Kamerbeelden om beelden van Tweede Kamer vergaderingen te mogen gebruiken voor journalistieke doeleinden.

Hieronder vindt u mijn gegevens:

Volledige naam:
Redactie:
E-mailadres:
Mobiele telefoonnummer*:

*Het mobiele telefoonnummer is nodig voor een tweestapsverificatie bij het inloggen.

`;
