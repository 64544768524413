import { type FC } from 'react';
import { AppProvider, type AppContextType } from '@debatdirect/core-ts/lib/AppProvider';
import RouterView from '@debatdirect/core/components/RouterView/RouterView';
import { createRootComponent } from '@debatdirect/core/bootstrap';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { type Config } from '@debatdirect/core-ts/types/app';

type Props = {
  getCursor: (keyPath?: string[]) => unknown;
  queryClient: QueryClient;
  routes: object;
  config: Config;
} & AppContextType;

const RootComponent: FC<Props> = ({ getCursor, getService, routes, queryClient, config }) => {
  const RootComponent = createRootComponent(RouterView, getCursor, getService);
  return (
    <AppProvider getService={getService} config={config}>
      <QueryClientProvider client={queryClient}>
        <RootComponent cursor={getCursor()} routes={routes} />
      </QueryClientProvider>
    </AppProvider>
  );
};

export default RootComponent;
