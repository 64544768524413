const /**
   * CancelablePromise is a promise implementation
   * whose result chain can be cancelled.
   * It does not cancel the operation itself, instead it
   * rejects upon completion when the cancel method is called,
   * thereby disrupting the promise chain.
   *
   * @param {Function} executor Function object with two arguments resolve and reject.
   *                            The first argument fulfills the promise, the second
   *                            argument rejects it.
   *                            We can call these functions once our operation is completed.
   */
  CancelablePromise = function (executor) {
    this.isCancelled = false;

    this.promise = new Promise((resolve, reject) => {
      const onResolve = (...args) => {
        this.isCancelled ? reject(new Error('Operation has been cancelled')) : resolve(...args);
      };

      executor(onResolve, reject);
    });
  };

CancelablePromise.prototype = {
  /**
   * The cancel() method sets a flag which causes the promise to reject on fulfilment.
   */
  cancel: function () {
    this.isCancelled = true;
  },

  /**
   * The catch() method returns a Promise and deals with rejected cases only.
   * It behaves the same as calling Promise.prototype.then(undefined, onRejected).
   *
   * @param  {Function} onRejected A Function called when the Promise is rejected.
   *                               This function has one argument, the rejection reason.
   * @return {Promise}             A new promise resolving to the return value of the
   *                               callback if it is called, or to its original
   *                               fulfillment value if the promise is instead fulfilled.
   */
  catch: function (onRejected) {
    return this.promise.catch(onRejected);
  },

  /**
   * The then() method returns a Promise. It takes two arguments:
   * callback functions for the success and failure cases of the Promise.
   *
   * @param  {Function} onResolved A Function called when the Promise is fulfilled.
   *                               This function has one argument, the fulfillment value.
   * @param  {Function} onRejected A Function called when the Promise is rejected.
   *                               This function has one argument, the rejection reason.
   * @return {Promise}             A new promise resolving to the return value of the called
   *                               handler, or to its original settled value if the promise
   *                               was not handled (i.e. if the relevant handler onFulfilled
   *                               or onRejected is undefined).
   */
  then: function (onResolved, onRejected) {
    return this.promise.then(onResolved, onRejected);
  },
};

export default CancelablePromise;
