import React from 'react';
import { withDefaults } from 'omniscient';

import DebateDocumentsListItem from './DebateDocumentsListItem';

const component = withDefaults({ cursorField: 'cursor' }),
  /**
   * Creates a list item for the passed document
   * @param  {Cursor}        cursor Document cursor
   * @return {React.Element}        List item element
   */
  createListItem = (cursor) => <DebateDocumentsListItem key={cursor.get('id')} cursor={cursor} />,
  /**
   * Renders the DebateDocumentList
   * @param  {Cursor}        cursor The document cursor
   * @return {React.Element}        Debate Documents list
   */
  render = function ({ documents }) {
    if (0 === documents.count()) {
      return null;
    }

    return <ul className="List">{documents.map(createListItem)}</ul>;
  };

export default component('DebateDocumentsList', render);
