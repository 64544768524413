import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import component from 'omniscient';

import Prompt from '../../Prompt/Prompt';
import OnClickOutside from '../../OnClickOutside/OnClickOutside';
import Button from '../../../components/Button/Button';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';

const definition = {
    promptRef: createRef(),

    contextTypes: {
      getService: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
    },

    mixins: [OnClickOutside],

    /**
     * Close prompt with given status
     * @param {string} status
     */
    closePrompt: function (status) {
      const { getService } = this.context;

      getService('prompt').dismiss(status, 'FormSubmitError');
    },

    /**
     * Close button handler
     */
    closePromptHandler: function () {
      this.closePrompt('retry');
    },

    /**
     * Click outside handler
     */
    handleClickOutside: function () {
      this.closePrompt('cancel');
    },

    getClickOutsideElement: function () {
      return this.promptRef.current;
    },
  },
  render = function () {
    return (
      <Prompt
        alignment="centerCenter"
        visible={true}
        role="alertdialog"
        aria-labelledby="prompt-title"
        aria-describedby="prompt-description"
        aria-live="assertive"
        forwardRef={this.promptRef}
      >
        <div>
          <h2 className="Heading Prompt-heading" id="prompt-title">
            Foutmelding
          </h2>
          <p className="Text Prompt-description" id="prompt-description">
            Uw supportaanvraag kan niet worden verzonden. Probeer het nog een keer of verstuur uw vraag naar{' '}
            <ExternalLink href="mailto:support@debat.direct">support@debat.direct</ExternalLink>.
          </p>
        </div>
        <div className="Prompt-actions">
          <Button className="Prompt-action Prompt-action--primary" onClick={this.closePromptHandler}>
            <div className="Button-label">Opnieuw verzenden</div>
          </Button>
        </div>
      </Prompt>
    );
  };

export default component.withDefaults({ cursorField: 'cursor' })('FormSubmitError', definition, render);
