import immutable from 'immutable';

import { service, structure } from '../../core';

/**
 * Fetch data.
 * @returns {Promise}
 */
const fetchData = () => {
  const dateService = service.ServiceContainer.get('date'),
    date = dateService.getDateFromPath();

  // If path contains a valid date, we first fetch the agenda to check if the date is valid within the allowed time window.
  // If not -> immediately fetch the components of today.
  return date ? fetchAgendaOverview(date) : fetchTodaysComponents();
};

/**
 * Fetch agenda overview.
 * @param {String} date
 * @returns {Promise}
 */
const fetchAgendaOverview = (date) => {
  const api = service.ServiceContainer.get('api');

  return api.getAgendaOverview().then((overview) => {
    const dateService = service.ServiceContainer.get('date'),
      isAllowed = dateService.isWithinAllowedTimeWindow(date);

    if (!isAllowed) {
      // date is not allowed, navigate to today
      if (window.location.href.indexOf('/agenda/') !== -1) {
        service.ServiceContainer.get('router').navigate('/');
      }

      return fetchTodaysComponents();
    }

    if (date === overview.currentDate) {
      return fetchTodaysComponents(); // The today route is more efficient, so if possible we choose it.
    }

    return fetchDatedComponents(date, overview);
  });
};

/**
 * Fetch dated components.
 * @param {String} date
 * @param {Object} overview
 * @returns {Promise}
 */
const fetchDatedComponents = (date, overview) => {
  const api = service.ServiceContainer.get('api'),
    promise = Promise.all([
      api.getApp(),
      api.getDatedActors(date),
      api.getDatedAgenda(date),
      api.getActors(), // We need today's actors for rendering cabinet and parliament pages (possibly change to JIT loading).
      overview,
      api.getVotingsOverview(),
    ]);

  return promise.then(handleDatedComponents);
};

/**
 * Handle dated components.
 * @param {Array} result
 */
const handleDatedComponents = (result) => {
  // De-structure promise result.
  const [app, datedActors, datedAgenda, actors, agendaOverview, votingsOverview] = result;

  // Determine data to add to structure.
  const data = {
    ...app,
    ...datedAgenda,
    actors: datedActors,
    votings: votingsOverview,
    persistent: {
      actors,
      agendaOverview,
    },
  };

  initData(data);
};

/**
 * Fetch today's data components.
 */
const fetchTodaysComponents = () => {
  const api = service.ServiceContainer.get('api'),
    promise = Promise.all([api.getApp(), api.getActors(), api.getAgenda(), api.getVotingsOverview()]);

  return promise.then(handleTodaysComponents);
};

/**
 * Handle today's components.
 * @param {Array} result
 */
const handleTodaysComponents = (result) => {
  // De-structure promise result.
  const [app, actors, agenda, votingsOverview] = result;

  // Determine data to add to structure.
  const data = {
    ...app,
    ...agenda,
    actors,
    votings: votingsOverview,
    persistent: {
      actors,
      agendaOverview: agenda.overview,
    },
  };

  initData(data);
};

/**
 * Store data in structure.
 */
const initData = (data) => {
  Object.entries(data).reduce((cursor, [key, data]) => cursor.update(key, () => immutable.fromJS(data)), structure.cursor(['data']));
};

/**
 * Public exports.
 */
export default fetchData;
