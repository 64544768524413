import { type FC, useRef } from 'react';
import classNames from 'classnames';

import styles from './Switch.module.scss';

type Props = {
  name: string;
  label?: string;
  checked: boolean;
  labelPosition?: 'left' | 'right';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Switch: FC<Props> = ({ checked, name, label, onChange, labelPosition = 'right' }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.target === event.currentTarget && event.key === ' ') {
      handleClick();
    }
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <label className={styles.switch} htmlFor={name} role="switch" aria-checked={checked} tabIndex={0} onKeyDown={handleKeyDown}>
      {label && labelPosition === 'left' && <span className={classNames(styles.label, styles.labelLeft)}>{label}</span>}
      <input
        className={styles.input}
        checked={checked}
        id={name}
        name={name}
        type="checkbox"
        value={label}
        onChange={onChange}
        ref={inputRef}
        aria-label={label}
        tabIndex={-1}
      />
      {label && labelPosition === 'right' && <span className={classNames(styles.label, styles.labelRight)}>{label}</span>}
    </label>
  );
};

export default Switch;
