import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import { ExpandLess, ExpandMore } from '../../icons';

const eventsDict = {
  chairman_change: 'is tot voorzitter benoemd',
  chairman: 'spreekt',
  chairman_selection: 'is tot voorzitter benoemd',
  interrupter: 'interrumpeert',
  speaker: 'spreekt',
  speaker_motion_change: 'dient een motiewijziging in',
  speaker_motion_present: 'dient een motie in',
  speaker_motion_withdraw: 'trekt een motie terug',
};

class SearchResultSpeechMoments extends Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
  }

  static contextTypes = {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    pathTo: PropTypes.func.isRequired,
  };

  onClickSpeakMoment = (debate, source) => {
    const path = this.context.pathTo(`debate-markers`, {
      date: moment(debate.debateDate).format('YYYY-MM-DD'),
      category: debate.categoryIds[0],
      location: debate.locationId,
      debate: debate.slug,
    });

    this.context.getCursor().setIn(['ui', 'seekEvent'], {
      disableTime: Date.now() + 10000,
      event: source.eventType + source.eventStart,
    });

    this.context.getService('router').navigate(path);
  };

  renderSpeechMoment = (event) => {
    const source = event._source;
    const name = source?.politician?.name;
    const time = moment(source.eventStart).format('HH:mm');
    const eventName = source.eventType && eventsDict[source.eventType];

    let transcription = event?.highlight?.transcription?.[0];
    let prefix = '';
    let suffix;

    if (!transcription) {
      transcription = event._source.transcription?.slice(0, 50);
      suffix = '...';
    } else {
      prefix = '...';
      suffix = '...';
    }

    // do this first, so we can filter out empty transcriptions in the next block
    if (transcription) {
      transcription = transcription.replace(/\\[nt]/g, '').trim();
    }

    if (transcription) {
      if (prefix) {
        transcription = prefix + transcription;
      }

      if (suffix) {
        transcription = transcription + suffix;
      }
    }

    return (
      <li className="List-item DebateEventItem is-playable" key={event._id}>
        <div
          className="List-item-align u-flex u-row"
          role="button"
          tabIndex={0}
          onClick={(event) => this.onClickSpeakMoment(this.props.debate, source, event)}
          onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && this.onClickSpeakMoment(this.props.debate, source, event)}
        >
          <div className="List-bullet u-flex u-center">
            <div className="List-bullet-icon u-centerSelf" />
          </div>
          <time className="u-centerSelf u-flex-child">{time}</time>
          <span className="description u-flex-child u-shrink u-centerSelf">
            <div className="description-header">
              {name} <Icon name="arrow" className="u-inline" width="8.57" height="12" aria-hidden="true" /> {eventName || ''}
            </div>
            {transcription ? (
              <div className="description-content">
                <span dangerouslySetInnerHTML={{ __html: transcription }} />
              </div>
            ) : null}
          </span>
        </div>
      </li>
    );
  };

  render() {
    const { events } = this.props;
    const { isExpanded } = this.state;

    return (
      <div className="SpeakResult">
        <div className="SpeakResult-heading">
          <h4>{`${events.length} ${events.length === 1 ? 'spreekmoment' : 'spreekmomenten'} gevonden in debat`}</h4>
          <Button className="SpeakResult-toggleExpand" onClick={() => this.setState({ isExpanded: !isExpanded })}>
            {isExpanded ? (
              <span>
                Verberg <ExpandLess />
              </span>
            ) : (
              <span>
                Toon <ExpandMore />
              </span>
            )}
          </Button>
        </div>
        {isExpanded && (
          <div className="SpeakResult-events">
            <ul className="DebateEvents-list List">{this.props.events.map(this.renderSpeechMoment)}</ul>
          </div>
        )}
      </div>
    );
  }
}

SearchResultSpeechMoments.defaultProps = {
  debate: {},
  searchPhrase: 'Werkzaamheden',
  events: [],
};

export default SearchResultSpeechMoments;
