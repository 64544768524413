import React from 'react';
import component from 'omniscient';

const style = { display: 'none' };

/**
 * Module definition.
 */
const definition = {
  shouldComponentUpdate: () => false, // Never update!
};

/**
 * Module render method.
 * @returns {React.Component}
 */
const render = () => {
  return (
    <svg style={style}>
      <symbol id="icon-enable-video" viewBox="0 0 20 14">
        <path d="M10,3.75 C5.83333333,3.75 2.275,6.34166667 0.833333333,10 C2.275,13.6583333 5.83333333,16.25 10,16.25 C14.1666667,16.25 17.725,13.6583333 19.1666667,10 C17.725,6.34166667 14.1666667,3.75 10,3.75 L10,3.75 Z M10,14.1666667 C7.7,14.1666667 5.83333333,12.3 5.83333333,10 C5.83333333,7.7 7.7,5.83333333 10,5.83333333 C12.3,5.83333333 14.1666667,7.7 14.1666667,10 C14.1666667,12.3 12.3,14.1666667 10,14.1666667 L10,14.1666667 Z" />
        <path d="M10,7.5 C8.61666667,7.5 7.5,8.61666667 7.5,10 C7.5,11.3833333 8.61666667,12.5 10,12.5 C11.3833333,12.5 12.5,11.3833333 12.5,10 C12.5,8.61666667 11.3833333,7.5 10,7.5 L10,7.5 Z" />
      </symbol>
      <symbol id="icon-arrow" viewBox="0 0 10 14">
        <path d="M0,0.845538721 C1.24557543,2.56411336 1.98316273,4.69318182 1.98316273,7 C1.98316273,9.30681818 1.24557543,11.4358866 0,13.1544613 L0.824643643,14 L10,7 L0.824643643,0 L0,0.845538721 Z" />
      </symbol>
      <symbol id="icon-bell" viewBox="0 0 41 41">
        <ellipse strokeWidth="1px" stroke="white" fill="none" cx="20.5" cy="20.5" rx="20" ry="20" />
        <path
          d="M11.2,26.1c1-1.6,1.5-3.6,1.5-5.5V16c0-1.5,0.5-6.3,6.6-6.3
                c0.2,0,0.4-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.7,0.2-1c0,0,0.2-0.2,0.6-0.2c0.4,0,0.5,0.1,0.6,0.2
                c0.2,0.3,0.2,0.8,0.2,1c0,0.2,0,0.4,0.2,0.6c0.1,0.2,0.4,0.3,0.6,0.3c6.3,0,6.6,5.2,6.6,6.3v4.6c0,2,0.5,3.9,1.5,5.5l1.3,2.2h-22
                L11.2,26.1z M34.1,29.1c0-0.2-0.1-0.4-0.2-0.5l-2-3.2c-0.9-1.4-1.3-3-1.3-4.7V16c0-0.8-0.2-7.4-7.4-7.8c-0.1-0.4-0.2-0.9-0.6-1.3
                c-0.3-0.3-0.8-0.7-1.7-0.7c-0.9,0-1.5,0.4-1.8,0.7c-0.4,0.4-0.5,0.9-0.6,1.3c-7.2,0.4-7.4,7-7.4,7.8v4.6c0,1.7-0.5,3.3-1.3,4.7
                l-2.1,3.3c-0.1,0.2-0.2,0.5,0,0.8c0.1,0.2,0.4,0.4,0.7,0.4h24.8h0C33.8,29.9,34.1,29.5,34.1,29.1z M25.5,31.1c-0.7,1.8-2.5,3-4.6,3
                c-2.1,0-3.9-1.3-4.6-3h1.7c0.6,0.9,1.6,1.5,2.8,1.5c1.2,0,2.2-0.6,2.8-1.5H25.5z"
        />
      </symbol>
      <symbol id="icon-bell-on" viewBox="-29 71 41 41">
        <ellipse strokeWidth="1px" stroke="white" fill="none" cx="-8.5" cy="91.5" rx="20" ry="20" />
        <path
          d="M5.1,100.1c0-0.2-0.1-0.4-0.2-0.5l-2-3.2c-0.9-1.4-1.3-3-1.3-4.7V87c0-0.8-0.2-7.4-7.4-7.8c-0.1-0.4-0.2-0.9-0.6-1.3
                c-0.3-0.3-0.8-0.7-1.7-0.7s-1.5,0.4-1.8,0.7c-0.4,0.4-0.5,0.9-0.6,1.3c-7.2,0.4-7.4,7-7.4,7.8v4.6c0,1.7-0.5,3.3-1.3,4.7l-2.1,3.3
                c-0.1,0.2-0.2,0.5,0,0.8c0.1,0.2,0.4,0.4,0.7,0.4H4.2l0,0C4.8,100.9,5.1,100.5,5.1,100.1z M-3.5,102.1c-0.7,1.8-2.5,3-4.6,3
                s-3.9-1.3-4.6-3h1.7c0.6,0.9,1.6,1.5,2.8,1.5s2.2-0.6,2.8-1.5C-5.4,102.1-3.5,102.1-3.5,102.1z"
        />
      </symbol>
      <symbol id="icon-bell-loading" viewBox="0 0 40 40">
        <path fill="none" stroke="white" strokeWidth="3" strokeMiterlimit="10" d="M20,9.3c5.9,0,10.7,4.8,10.7,10.7S25.9,30.7,20,30.7 S9.3,25.9,9.3,20" />
      </symbol>
      <symbol id="icon-bell-loading-alt" viewBox="0 0 40 40">
        <path fill="none" stroke="#262564" strokeWidth="3" strokeMiterlimit="10" d="M20,9.3c5.9,0,10.7,4.8,10.7,10.7S25.9,30.7,20,30.7 S9.3,25.9,9.3,20" />
      </symbol>
      <symbol id="icon-bell-error" viewBox="0 0 40 40">
        <ellipse fill="none" stroke="white" cx="20" cy="20" rx="19.5" ry="19.5" />
        <path
          fill="white"
          d="M16.8,27.7c0-1.9,1.3-3.3,3.2-3.3c1.9,0,3.2,1.4,3.2,3.3c0,1.9-1.3,3.3-3.2,3.3C18.1,31,16.8,29.6,16.8,27.7z M17.9,22.6L17.1,6.3h5.7l-0.8,16.3H17.9z"
        />
      </symbol>
      <symbol id="icon-bell-error-alt" viewBox="0 0 40 40">
        <ellipse fill="none" stroke="#262564" cx="20" cy="20" rx="19.5" ry="19.5" />
        <path
          fill="#262564"
          d="M16.8,27.7c0-1.9,1.3-3.3,3.2-3.3c1.9,0,3.2,1.4,3.2,3.3c0,1.9-1.3,3.3-3.2,3.3C18.1,31,16.8,29.6,16.8,27.7z M17.9,22.6L17.1,6.3h5.7l-0.8,16.3H17.9z"
        />
      </symbol>
      <symbol id="icon-bell-alt" viewBox="0 0 41 41">
        <ellipse strokeWidth="1px" stroke="#121469" fill="none" cx="20.5" cy="20.5" rx="20" ry="20" />
        <path
          d="M11.2,26.1c1-1.6,1.5-3.6,1.5-5.5V16c0-1.5,0.5-6.3,6.6-6.3
                c0.2,0,0.4-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.7,0.2-1c0,0,0.2-0.2,0.6-0.2c0.4,0,0.5,0.1,0.6,0.2
                c0.2,0.3,0.2,0.8,0.2,1c0,0.2,0,0.4,0.2,0.6c0.1,0.2,0.4,0.3,0.6,0.3c6.3,0,6.6,5.2,6.6,6.3v4.6c0,2,0.5,3.9,1.5,5.5l1.3,2.2h-22
                L11.2,26.1z M34.1,29.1c0-0.2-0.1-0.4-0.2-0.5l-2-3.2c-0.9-1.4-1.3-3-1.3-4.7V16c0-0.8-0.2-7.4-7.4-7.8c-0.1-0.4-0.2-0.9-0.6-1.3
                c-0.3-0.3-0.8-0.7-1.7-0.7c-0.9,0-1.5,0.4-1.8,0.7c-0.4,0.4-0.5,0.9-0.6,1.3c-7.2,0.4-7.4,7-7.4,7.8v4.6c0,1.7-0.5,3.3-1.3,4.7
                l-2.1,3.3c-0.1,0.2-0.2,0.5,0,0.8c0.1,0.2,0.4,0.4,0.7,0.4h24.8h0C33.8,29.9,34.1,29.5,34.1,29.1z M25.5,31.1c-0.7,1.8-2.5,3-4.6,3
                c-2.1,0-3.9-1.3-4.6-3h1.7c0.6,0.9,1.6,1.5,2.8,1.5c1.2,0,2.2-0.6,2.8-1.5H25.5z"
        />
      </symbol>
      <symbol id="icon-bell-on-alt" viewBox="-29 71 41 41">
        <ellipse strokeWidth="1px" stroke="#121469" fill="none" cx="-8.5" cy="91.5" rx="20" ry="20" />
        <path
          d="M5.1,100.1c0-0.2-0.1-0.4-0.2-0.5l-2-3.2c-0.9-1.4-1.3-3-1.3-4.7V87c0-0.8-0.2-7.4-7.4-7.8c-0.1-0.4-0.2-0.9-0.6-1.3
                c-0.3-0.3-0.8-0.7-1.7-0.7s-1.5,0.4-1.8,0.7c-0.4,0.4-0.5,0.9-0.6,1.3c-7.2,0.4-7.4,7-7.4,7.8v4.6c0,1.7-0.5,3.3-1.3,4.7l-2.1,3.3
                c-0.1,0.2-0.2,0.5,0,0.8c0.1,0.2,0.4,0.4,0.7,0.4H4.2l0,0C4.8,100.9,5.1,100.5,5.1,100.1z M-3.5,102.1c-0.7,1.8-2.5,3-4.6,3
                s-3.9-1.3-4.6-3h1.7c0.6,0.9,1.6,1.5,2.8,1.5s2.2-0.6,2.8-1.5C-5.4,102.1-3.5,102.1-3.5,102.1z"
        />
      </symbol>
      <symbol id="icon-close" viewBox="0 0 40 40">
        <path d="M7.6,32.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1L20,20.7l11.7,11.7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1 c0.2-0.2,0.2-0.5,0-0.7L20.7,20L32.4,8.3c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L20,19.3L8.3,7.6c-0.2-0.2-0.5-0.2-0.7,0 c-0.2,0.2-0.2,0.5,0,0.7L19.3,20L7.6,31.7C7.5,31.9,7.5,32.2,7.6,32.4z" />
      </symbol>
      <symbol id="icon-collapse" viewBox="0 0 16 16">
        <circle className="u-fillWhite" transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) " cx="8" cy="8" r="8" />
        <polygon className="u-fillBlue" points="8 4.44444444 13.3333333 9.77777778 2.66666667 9.77777778 " />
      </symbol>
      <symbol id="icon-collapse-alt" viewBox="0 0 16 16">
        <circle fill="#E0E7EA" transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) " cx="8" cy="8" r="8" />
        <polygon className="u-fillBlue" points="8 4.44444444 13.3333333 9.77777778 2.66666667 9.77777778 " />
      </symbol>
      <symbol id="icon-download" viewBox="0 0 40 40">
        <path d="M12.4,26.3v2.2h15.2v-2.2H12.4z M27.6,16.6h-4.3v-6.5h-6.5v6.5 h-4.3l7.6,7.6L27.6,16.6z" />
        <rect x="12.4" y="26.3" width="15.2" height="2.2" />
        <polygon points="27.6,16.6 23.2,16.6 23.2,10.1 16.8,10.1 16.8,16.6 12.4,16.6 20,24.2 " />
      </symbol>
      <symbol id="icon-expand" viewBox="0 0 16 16">
        <circle className="u-fillWhite" cx="8" cy="8" r="8" />
        <polygon
          className="u-fillBlue"
          transform="translate(8.000000, 8.888889) scale(1, -1) translate(-8.000000, -8.888889) "
          points="8 6.22222222 13.3333333 11.5555556 2.66666667 11.5555556 "
        />
      </symbol>
      <symbol id="icon-expand-alt" viewBox="0 0 16 16">
        <circle fill="#E0E7EA" cx="8" cy="8" r="8" />
        <polygon
          className="u-fillBlue"
          transform="translate(8.000000, 8.888889) scale(1, -1) translate(-8.000000, -8.888889) "
          points="8 6.22222222 13.3333333 11.5555556 2.66666667 11.5555556 "
        />
      </symbol>
      <symbol id="icon-menu" viewBox="0 0 40 40">
        <rect width="25" height="2" x="8" y="11" />
        <rect width="25" height="2" x="8" y="19" />
        <rect width="25" height="2" x="8" y="27" />
      </symbol>
      <symbol id="icon-search" viewBox="0 -8 40 40">
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </symbol>
      <symbol id="icon-microphone" viewBox="0 0 44 44">
        <path d="M22,42c11,0,20-9,20-20c0-11-9-20-20-20C11,2,2,11,2,22C2,33,11,42,22,42L22,42L22,42L22,42z" />
        <path
          className="u-fillWhite"
          d="M22,27.4c3.8,0,4.6-3.8,4.6-3.8v-5.4V12c0,0-0.8-3.8-4.6-3.8c-3.8,0-4.6,3.8-4.6,3.8v6.2v5.4 C17.4,23.5,18.2,27.4,22,27.4z"
        />
        <path
          className="u-fillWhite"
          d="M28.2,18.2v5.4c0,0.4-0.1,1.1-0.2,1.5c0,0-0.8,3.9-5.9,3.9S16.1,25,16.1,25c-0.1-0.4-0.2-1.1-0.2-1.5v-5.4 h-1.5v5.4c0,0.4,0.1,1.1,0.2,1.5c0,0,1,5,6.7,5.4v3.9h-4.6v1.5h10.8v-1.5h-4.6v-3.9c5.7-0.4,6.7-5.4,6.7-5.4 c0.1-0.4,0.2-1.1,0.2-1.5v-5.4H28.2z"
        />
      </symbol>
      <symbol id="icon-pause" viewBox="0 0 400 400">
        <rect x="127.7" y="97.5" width="47" height="210.5" />
        <rect x="225.3" y="97.5" width="47" height="210.5" />
      </symbol>
      <symbol id="icon-play" viewBox="0 0 38 38">
        <path
          d="M0,19 C0,8.50658943 8.50658943,0 19,0 C29.4934106,0 38,8.50658943 38,19 C38,29.4934106 29.4934106,38 19,38 C8.50658943,38 0,29.4934106 0,19 L0,19 Z"
          id="stop"
          fill="#FFFFFF"
        />
        <polygon
          className="u-fillBlue"
          transform="translate(21.760870, 19.260870) rotate(90.000000) translate(-21.760870, -19.260870) "
          points="21.7608696 11 31.7608696 27.5217391 11.7608696 27.5217391"
        />
      </symbol>
      <symbol id="icon-playArrow" viewBox="0 0 38 38">
        <polygon
          transform="translate(21.760870, 19.260870) rotate(90.000000) translate(-21.760870, -19.260870) "
          points="21.7608696 11 31.7608696 27.5217391 11.7608696 27.5217391"
        />
      </symbol>
      <symbol id="icon-fullscreen" viewBox="0 0 40 40">
        <path
          d="M13.3,23h-3v7.5h7.5v-3h-4.5V23z M10.3,17h3v-4.5h4.5v-3h-7.5V17z M28.3,27.5h-4.5v3h7.5V23h-3V27.5z M23.8,9.5v3h4.5V17h3
		V9.5H23.8z"
        />
      </symbol>
      <symbol id="icon-settings" viewBox="0 0 400 400">
        <g>
          <path
            d="M278.2,210.3c0.4-3.4,0.7-6.7,0.7-10.3c0-3.6-0.3-6.9-0.7-10.3l22.2-17.4c2-1.6,2.5-4.4,1.3-6.7l-21.1-36.4
		c-1.3-2.3-4.1-3.2-6.4-2.3L248,137.4c-5.5-4.2-11.4-7.7-17.8-10.3l-4-27.9c-0.3-2.5-2.5-4.4-5.2-4.4H179c-2.6,0-4.8,1.9-5.2,4.4
		l-4,27.9c-6.4,2.6-12.3,6.2-17.8,10.3l-26.2-10.5c-2.4-0.9-5.2,0-6.4,2.3l-21.1,36.4c-1.4,2.3-0.7,5.2,1.3,6.7l22.2,17.4
		c-0.4,3.4-0.7,6.8-0.7,10.3c0,3.5,0.3,6.9,0.7,10.3l-22.2,17.4c-2,1.6-2.5,4.4-1.3,6.7l21.1,36.4c1.3,2.3,4.1,3.2,6.4,2.3
		l26.2-10.5c5.5,4.2,11.4,7.7,17.8,10.3l4,27.9c0.3,2.5,2.5,4.4,5.2,4.4h42.1c2.6,0,4.8-1.9,5.2-4.4l4-27.9
		c6.4-2.6,12.3-6.2,17.8-10.3l26.2,10.5c2.4,0.9,5.2,0,6.4-2.3l21.1-36.4c1.3-2.3,0.7-5.2-1.3-6.7L278.2,210.3z M200,236.8
		c-20.3,0-36.8-16.5-36.8-36.8s16.5-36.8,36.8-36.8s36.8,16.5,36.8,36.8S220.3,236.8,200,236.8z"
          />
        </g>
      </symbol>
      <symbol id="icon-caretPullDown" viewBox="-9 -9 18 18">
        <polygon
          className="u-fillWhite"
          transform="scale(1, -1) rotate(45.000000) translate(-570.461535, -188.461535) "
          points="564.461535 194.427953 564.461535 182.495117 576.461535 182.495117 576.461535 183.773635 565.724693 183.773635 565.724693 194.427953"
        />
      </symbol>
      <symbol id="icon-caretLeft" viewBox="0 0 40 40">
        <polygon points="25.1,31.3 13.7,20 25.1,8.7 26.3,9.9 16.1,20 26.2,30.1 " />
      </symbol>
      <symbol id="icon-toStart" viewBox="0 0 32 32">
        <circle className="u-fillWhite" cx="16" cy="16" r="16" />
        <polygon
          className="u-fillBlue"
          transform="translate(17.808696, 16.139130) scale(-1, 1) rotate(90.000000) translate(-17.808696, -16.139130) "
          points="17.8086957 10.1913043 25.0086957 22.0869565 10.6086957 22.0869565"
        />
        <rect className="u-fillBlue" x="8.8" y="8.8" width="1.6" height="14.4" />
      </symbol>
      <symbol id="icon-sync-off" viewBox="0 0 40 40">
        <rect x="10.6" y="30.3" width="18.8" height="1.2" />
        <path d="M3,8v21.1h34V8H3z M35.8,27.9H4.2V9.2h31.7V27.9z" />
      </symbol>
      <symbol id="icon-sync-on" viewBox="0 100 40 40">
        <rect x="10.6" y="130.3" width="18.8" height="1.2" />
        <path d="M3,108v21.1h34V108H3z M35.8,127.9H4.2v-18.7h31.7v18.7H35.8z" />
        <path fill="none" d="M10.6,130.3h18.8v1.2H10.6V130.3z M4.2,109.2h31.7V128H4.2V109.2z M3,108h34v21.1H3V108z" />
        <g>
          <ellipse cx="23.9" cy="118.5" rx="1.3" ry="1.3" />
          <ellipse cx="20" cy="118.5" rx="1.3" ry="1.3" />
          <ellipse cx="16.2" cy="118.5" rx="1.3" ry="1.3" />
          <polygon points="27.1,123.5 32.2,118.5 27.1,113.4 26.4,114 30.7,118.5 26.4,122.8" />
          <polygon points="13.5,122.8 9.2,118.5 13.5,114 12.8,113.4 7.8,118.5 12.8,123.5" />
        </g>
      </symbol>
      <symbol id="icon-sync-busy" viewBox="0 100 40 40">
        <rect x="10.6" y="130.3" width="18.8" height="1.2" />
        <path d="M3,108v21.1h34V108H3z M35.8,127.9H4.2v-18.7h31.7v18.7H35.8z" />
        <path fill="none" d="M10.6,130.3h18.8v1.2H10.6V130.3z M4.2,109.2h31.7V128H4.2V109.2z M3,108h34v21.1H3V108z" />
        <g className="u-fadeInOut">
          <ellipse cx="23.9" cy="118.5" rx="1.3" ry="1.3" />
          <ellipse cx="20" cy="118.5" rx="1.3" ry="1.3" />
          <ellipse cx="16.2" cy="118.5" rx="1.3" ry="1.3" />
          <polygon points="27.1,123.5 32.2,118.5 27.1,113.4 26.4,114 30.7,118.5 26.4,122.8" />
          <polygon points="13.5,122.8 9.2,118.5 13.5,114 12.8,113.4 7.8,118.5 12.8,123.5" />
        </g>
      </symbol>
      <symbol id="icon-external-link" viewBox="0 0 40 40">
        <path d="M29.1,25.1V40H0V5.8h18.3v3.9H4v26.4h21v-11H29.1 L29.1,25.1z M24.6,0v3.9h8.5L17.8,18.7l2.9,2.7L36,6.6v8.2h4V0H24.6L24.6,0z" />
      </symbol>
      <symbol id="icon-trash" viewBox="0 0 24 24">
        <path d="M18.8,22.8H5.2V8h1.5v13.2h10.5V8h1.5V22.8z M14.8,19h-1.5V9h1.5V19z M10.8,19H9.2V9h1.5V19z M20,6.5H4V5h16 V6.5z M15,3.5H9V2h6V3.5z" />
      </symbol>
      <symbol id="icon-information" viewBox="0 0 20 20">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Melding" transform="translate(-624.000000, -11.000000)">
            <g id="ic_info_white_24px" transform="translate(622.000000, 9.000000)">
              <path
                d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,11 L13,11 L13,17 Z M13,9 L11,9 L11,7 L13,7 L13,9 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </symbol>
      <symbol id="icon-warning" viewBox="0 0 22 19">
        <g id="Tablet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-1, -1)">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path d="M1,21 L23,21 L12,2 L1,21 Z M13,18 L11,18 L11,16 L13,16 L13,18 Z M13,14 L11,14 L11,10 L13,10 L13,14 Z" fill="#293088" fillRule="nonzero" />
        </g>
      </symbol>
    </svg>
  );
};

/**
 * Public exports.
 */
export default component('Icons', definition, render);
