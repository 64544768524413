import moment from 'moment';

import ServiceContainer from './lib/service/ServiceContainer';
import Router from './lib/service/factory/Router';
import appendCSSLink from './lib/util/appendCSSLink';
import createRenderLoop from './lib/util/createRenderLoop';
import getIn from './lib/util/getIn';
import provide from './lib/util/provide';
import getOption from './lib/util/getOption';
import 'moment/dist/locale/nl';

moment.locale('nl');

export const service = {
  ServiceContainer,
  factory: {
    Router,
  },
};

export const util = {
  appendCSSLink,
  createRenderLoop,
  getIn,
  provide,
  getOption,
};

export { default as structure } from './lib/structure/structure';
