const setItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    console.log(error);
  }
};

const removeItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};

const getItem = (key) => {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    console.log(error);
  }
};

export default {
  setItem,
  removeItem,
  getItem,
};
