/**
 * Returns the shorthand of a party for a given politician
 * @param  {Cursor} politician Politician cursor
 * @param  {Cursor} parties    The list of parties
 * @return {String}            The shorthand
 */
const getPoliticianPartyShorthand = (politician, parties) => {
  const partyId = politician.get('partyId'),
    party = parties.find((party) => party.get('id') === partyId);

  return party ? party.get('shorthand') : null;
};

/**
 * Parses a politician title
 *
 * @param  {Cursor} politician The politician whose title needs to be parsed
 * @param  {Cursor} parties    A list of all parties
 *
 * @return {String}            The parsed title
 */
const parsePoliticianTitle = (politician, parties) => {
  const partyShorthand = getPoliticianPartyShorthand(politician, parties),
    title = politician.get('title');

  if ('Tweede Kamerlid' !== title || !partyShorthand) {
    return title;
  }

  return `${title} (${partyShorthand})`;
};

/**
 * Parses a politician short title
 *
 * @param  {Cursor} politician The politician whose title needs to be parsed
 * @param  {Cursor} parties    A list of all parties
 *
 * @return {String}            The parsed short title
 */
const parsePoliticianShortTitle = (politician, parties) => {
  const partyShorthand = getPoliticianPartyShorthand(politician, parties),
    title = politician.get('title');

  if ('Tweede Kamerlid' !== title || !partyShorthand) {
    return title;
  }

  return partyShorthand;
};

export default {
  parsePoliticianTitle,
  parsePoliticianShortTitle,
};
