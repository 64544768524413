import React from 'react';

import RadioIcon from '../../icons/Radio';
import RadioSelectedIcon from '../../icons/RadioSelected';

const Radio = ({ checked, onChange, ...rest }) => {
  const Icon = checked ? RadioSelectedIcon : RadioIcon;

  return (
    <div className="Radio">
      <Icon className="Radio-icon" />
      <input className="Radio-input" type="radio" onChange={onChange} checked={checked} {...rest} />
      <div className="Radio-focus" />
    </div>
  );
};

export default Radio;
