import throttle from '../../utils/throttle';

// hold all throttled trackEvents grouped by category
const throttleMap = {};

/**
 * Track event
 *
 * @param category
 * @param action
 * @param name
 * @param value
 */
const trackEvent = function (category, action, name, value) {
  if (window._paq) {
    window._paq.push(['trackEvent', category, action, name, value]);
  }
};

/**
 * Track event throttled by category.
 *
 * @param category
 * @param action
 * @param name
 * @param value
 */
export const trackEventThrottled = function (category, action, name, value) {
  // create throttled trackEvent when it doesn't exist
  if (!throttleMap[category]) throttleMap[category] = throttle(trackEvent, 60 * 1000);

  // call throttled trackEvent
  throttleMap[category](category, action, name, value);
};

/**
 *
 * @param debateTitle - Debate date and name
 */
export const trackDebateName = function (debateTitle) {
  if (window._paq) {
    window._paq.push(['setCustomDimension', 2, debateTitle]);
  }
};

export default trackEvent;
