import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import classNames from 'classnames';

import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';

const /**
   * The component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
    },
  },
  render = function ({ className }) {
    const votingExplanationClickHandler = () => {
      const { getService } = this.context;
      const infoPanelService = getService('info-panel');

      infoPanelService.show('VotingExplanationPanel', {});
    };

    return (
      <Button className={classNames('u-verticalCenter u-inlineBlock', className)} onClick={votingExplanationClickHandler}>
        <span className="srOnly">Uitleg</span>
        <Icon name="information" className="u-inline" width={18} height={18} />
      </Button>
    );
  };

export default component('VotingExplanationButton', definition, render);
