import moment from 'moment';

/**
 * Create params for encoder service.
 * @param {Object} config - Fragment config.
 * @param {Object} props - Contains debate and event cursor.
 * @param {String} name - Name for fragment.
 * @param {Function} getService
 */
const getFragmentParam = (config, props, name, getService) => {
  const debate = props.debate,
    event = props.event,
    votingRound = props.votingRound,
    sources = getService('video').getSources();

  // Check on current source.
  if (!sources?.video) {
    return null;
  }

  if (!votingRound) {
    // Check on validity event.
    if (!event?.eventStart) {
      return null;
    }

    // Check on validity events property.
    if (!debate.events || !(debate.events instanceof Array)) {
      return null;
    }

    const nextEvent = findNextEvent(debate.events, event);

    if (!nextEvent?.eventStart) {
      return null;
    }

    return {
      startsAt: getFragmentBoundary(event.eventStart, config.eventSubtractFromStartSeconds, 'subtract'),
      endsAt: getFragmentBoundary(nextEvent.eventStart, config.eventAddToEndSeconds, 'add'),
      location: sources.video,
      name: createFullName(name, debate, event),
    };
  } else {
    if (!votingRound.startedAt || !votingRound.endedAt) {
      return null;
    }

    return {
      startsAt: getFragmentBoundary(votingRound.startedAt, config.eventSubtractFromStartSeconds, 'subtract'),
      endsAt: getFragmentBoundary(votingRound.endedAt, config.eventAddToEndSeconds, 'add'),
      location: sources.video,
      name: createFullName(name, debate, { eventStart: votingRound.startedAt }),
    };
  }
};

/**
 * Create full name for event by concatenating debate name, event name and event start.
 * Replacing illegal file name characters will be done on server.
 * @param {String} eventName
 * @param {Object} debate
 * @param {Object} event
 * @returns {String}
 */
const createFullName = (eventName, debate, event) => {
  const maxFileNameLength = 250, // A little safety margin of 5 characters.
    startLabel = moment(event.eventStart).format('D-M-YYYY HH.mm'), // Do not use HH:mm because colon is illegal in file names.
    eventAndTime = (eventName ? eventName + '-' : '') + startLabel;

  if (eventAndTime.length === maxFileNameLength) {
    return eventAndTime;
  }

  if (eventAndTime.length > maxFileNameLength) {
    return eventAndTime.slice(0, maxFileNameLength - 2) + '..';
  }

  const debateName = debate.name || '',
    maxDebateNameLength = maxFileNameLength - eventAndTime.length - 1; // Extra -1 because of needed '-' in between.

  if (!debateName) {
    return eventAndTime;
  }

  if (debateName.length === maxDebateNameLength) {
    return debateName + '-' + eventAndTime;
  }

  if (debateName.length > maxDebateNameLength) {
    return debateName.slice(0, maxDebateNameLength - 2) + '..-' + eventAndTime;
  }

  return debateName + '-' + eventAndTime;
};

/**
 * Get boundary for fragment, taking adjust values into account.
 * @param {String} dateString
 * @param {Number} adjustSeconds
 * @param {String} action
 * @returns {String|null}
 */
const getFragmentBoundary = (dateString, adjustSeconds, action) => {
  const date = moment(dateString);

  if (!date.isValid()) {
    return null;
  }

  if (isNaN(adjustSeconds) || adjustSeconds === 0) {
    return date.format();
  }

  return date[action](adjustSeconds, 's').format();
};

/**
 * Find next event.
 * @param {Array} events
 * @param {Object} event
 * @returns {Object|null}
 */
const findNextEvent = (events, event) => {
  for (let i = 0; i < events.length; i++) {
    let current = events[i];

    if (current && current.eventStart === event.eventStart && current.eventType === event.eventType) {
      return i === 0 ? null : events[i - 1];
    }
  }

  return null;
};

/**
 * Public exports.
 */
export default {
  findNextEvent,
  getFragmentParam,
  createFullName,
};
