import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { ChevronLeft } from '../../icons';

export default class MenuListHeader extends React.Component {
  static propTypes = {
    label: PropTypes.node,
    onClick: PropTypes.func,
    titleId: PropTypes.string,
  };

  render() {
    const { className, label, titleId, ...rest } = this.props;

    return (
      <div className={classNames('MenuListHeader', className)} {...rest}>
        <span
          className="MenuListHeader-icon"
          aria-label="Sluiten"
          role="button"
          tabIndex={0}
          onClick={this.props.onClick}
          onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && this.props.onClick()}
        >
          <ChevronLeft />
        </span>
        <h1 className="MenuListHeader-title" id={titleId}>
          {label}
        </h1>
      </div>
    );
  }
}
