import React from 'react';
import { hashHistory, browserHistory, Router } from 'react-router';

const RouterView = ({ routes }) => {
  const history = window.cordova ? hashHistory : browserHistory;

  return <Router history={history} routes={routes} />;
};

export default RouterView;
