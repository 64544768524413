import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import OnClickOutsideCreator from '../OnClickOutside/OnClickOutside';
import SwipeDetector from '../../utils/lib/SwipeDetector';
import { PlenairSimple as InfoGraphic } from '../../infographics';
import { byId } from '../../predicates';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import Button from '../../components/Button/Button';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import Icon from '../../components/Icon/Icon';
import PoliticianImage from '../../components/PoliticianImage/PoliticianImage';
import PoliticianNotificationButton from '../../components/PoliticianNotificationButton/PoliticianNotificationButton';

const OnClickOutside = OnClickOutsideCreator.withConfig({ ignoreClass: 'Link--infoPanel' });

/**
 * Capitalize first letter.
 * @param {String} input
 */
const ucfirst = ([c, ...cs]) => [c.toUpperCase(), ...cs].join('');

/**
 * Create heading tag
 * @param {Number} level
 * @param {Object} props
 * @param {React.ReactNode} children
 * @returns {React.Component}
 */
const createHeadingTag = (level, props, children) => {
  const tag = `h${level}`;
  return React.createElement(tag, props, children);
};

/**
 * Create document list item
 * @param {Object} document
 * @param {Number} headingLevel
 * @returns {React.Component}
 */
const createDocumentListItem = (document, headingLevel) => {
  const id = document.id || '',
    type = document.type || '',
    description = document.description || '',
    title = document.name || '',
    url = document.url || '',
    linkClassName = classNames({
      'u-hidden': !url,
    });

  let label = '';

  if (!title && !description) {
    return null;
  }

  if (title && description) {
    label = ucfirst(title) + ' - ' + ucfirst(description);
  } else if (title) {
    label = ucfirst(title);
  } else {
    label = ucfirst(description);
  }

  return (
    <li className="List-item InfoPanel-listItem u-pl20 u-pr20" key={id}>
      {createHeadingTag(headingLevel, { className: 'List-itemMetadata PoliticianPanel-header' }, ucfirst(type))}
      <span className="List-itemTitle">{label}</span>
      <ExternalLink className={linkClassName} href={url} aria-label={`Download document ${label}`}>
        Download
        <Icon name="download" width="30" height="30" className="u-inline u-verticalCenter" aria-hidden="true" />
      </ExternalLink>
    </li>
  );
};

/**
 * Create document list
 * @param {Object} politician
 * @param {Number} headingLevel
 * @returns {React.Component} A document list instance
 */
const createDocumentList = (politician, headingLevel) => {
  if (!(politician.documents instanceof Array) || politician.documents.length === 0) {
    return null;
  }

  const documents = politician.documents.slice(0, 5);

  return <ul className="List InfoPanel-list">{documents.map((item) => createDocumentListItem(item, headingLevel))}</ul>;
};

/**
 * Create politician recent documents
 * @param {Object} politician
 * @param {Number} headingLevel
 * @returns {React.Component|null}
 */
const createPoliticianRecentDocumentList = (politician, headingLevel) => {
  return (
    <section className="Section Politician-documents">
      {createHeadingTag(headingLevel, { className: 'InfoPanel-sectionHeading Section-heading' }, 'Recent behandelde stukken')}
      {createDocumentList(politician, headingLevel + 1)}
    </section>
  );
};

/**
 * Create InfoGraphic
 * @param {Cursor} politician
 * @param {Cursor} politicians
 * @returns {React.Component} InfoGraphic component instance
 */
const createInfoGraphic = (politician, politicians) => {
  // No infographic for cabinet members and external speakers.
  if (typeof politician.get('seat') === 'undefined') {
    return null;
  }

  const seats = [politician.get('seat')],
    partySeats = politicians
      .filter((item) => item.get('partyId') === politician.get('partyId') && item.get('seat') !== politician.get('seat'))
      .map((item) => item.get('seat'))
      .toJS();
  return (
    <InfoGraphic
      className="InfoPanel-infographic u-mt20 u-fill"
      highlight="fractie"
      lowlight={partySeats}
      seats={seats}
      role="img"
      aria-labelledby="simple-infographic-title"
      politicians={[politician.get('firstName') + ' ' + politician.get('lastName')]}
      variant="light"
    />
  );
};

/**
 * Create link to profile.
 * @param {Object} politician
 * @returns {*}
 */
const createExternalLink = function (politician) {
  if (!/^https?:\/\/.+/.test(politician.profileUrl || '')) {
    return null;
  }

  if (!politician.title) {
    return null;
  }

  return (
    <ExternalLink href={politician.profileUrl}>
      Volledig profiel
      <Icon name="external-link" width="13" height="13" className="u-inline u-ml2" />
    </ExternalLink>
  );
};

/**
 * The PoliticianPanel definition.
 */
const definition = {
  asideRef: createRef(),

  contextTypes: {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  },

  mixins: [OnClickOutside],

  propTypes: {
    infoPanel: PropTypes.object.isRequired,
  },

  getInitialState: () => ({
    politician: null,
    fetchingId: null,
  }),

  componentDidMount: function () {
    const politicianId = this.props.infoPanel.getIn(['props', 'politicianId']);

    if (politicianId) {
      this._determinePoliticianData(politicianId);
    }

    this._setSwipeDetector();
  },

  componentDidUpdate: function (prevProps) {
    const currentId = this.props.infoPanel.getIn(['props', 'politicianId']),
      prevId = prevProps.infoPanel.getIn(['props', 'politicianId']);

    if (currentId !== prevId) {
      this._determinePoliticianData(currentId);
    }
  },

  componentWillUnmount: function () {
    if (this._swipeDetector) {
      this._swipeDetector.dispose();
      this._swipeDetector = null;
    }

    this.setState({ fetchingId: null });
  },

  /**
   * Handle click outside
   */
  handleClickOutside: function () {
    this._closePanel();
  },

  getClickOutsideElement: function () {
    return this.asideRef.current;
  },

  /**
   * Set swipe detector.
   * @private
   */
  _setSwipeDetector: function () {
    const container = this.asideRef.current;

    if (this._swipeDetector) {
      return;
    }

    this._swipeDetector = new SwipeDetector(container);
    this._swipeDetector.onSwipe = this._handleSwipe;
    this._swipeDetector.init();
  },

  /**
   * Handle swipe event.
   * @param {Object} event
   * @private
   */
  _handleSwipe: function (event) {
    if (event.direction === SwipeDetector.RIGHT()) {
      this._closePanel();
    }
  },

  /**
   * Try to determine politician data with data coming from today's actor's feed.
   * Also fetch supplementary data from API.
   * @param {String} politicianId
   * @private
   */
  _determinePoliticianData: function (politicianId) {
    const { getService, getCursor } = this.context,
      politicians = getCursor(['data', 'actors', 'politicians']),
      politician = politicians.find(byId(politicianId));

    // If politician is not in today's actors list we have nothing to show already.
    if (!politician) {
      this.setState({ politician: null, fetchingId: politicianId });
      this._fetchPoliticianData(politicianId);

      return;
    }

    const parties = getCursor(['data', 'actors', 'parties']),
      partyId = politician.get('partyId'),
      party = partyId ? parties.find(byId(partyId)) : null;

    const politicianData = {
      id: politicianId,
      date: getService('date').getTodayDate(),
      party: party,
      title: politician.get('title'),
      firstName: politician.get('firstName'),
      lastName: politician.get('lastName'),
      seat: politician.get('seat'),
      profileUrl: politician.get('profileUrl') || '',
      slogan: politician.get('slogan'),
      infographic: createInfoGraphic(politician, politicians),
    };

    // If politician is available in today's list, we gather all the information available.
    // The list of recent documents still has to be fetched.
    this.setState({ politician: politicianData, fetchingId: politicianId });
    this._fetchPoliticianData(politicianId);
  },

  /**
   * Fetch politician data from server.
   * @param {String} politicianId
   * @private
   */
  _fetchPoliticianData: function (politicianId) {
    const createCallback = (callback) => (response) => {
      // User has apparently clicked on another politician or has closed the overlay.
      if (this.state.fetchingId !== politicianId) {
        return;
      }

      callback(response);
    };

    // Fetch data.
    this.context
      .getService('api')
      .getPoliticianData(politicianId)
      .then(createCallback(this._handlePoliticianData), createCallback(this._handlePoliticianError));
  },

  /**
   * Handle politician data.
   * @param {Object} response - politician data.
   * @private
   */
  _handlePoliticianData: function (response) {
    const statePolitician = this.state.politician || {};

    // Merge the data from the response with the data we could determine from the actors feed.
    const politician = {
      ...response,
      ...statePolitician,
    };

    this.setState({ politician: politician, fetchingId: null });
  },

  /**
   * Handle politician data error.
   * @param {Object} response - object containing error details.
   * @private
   */
  _handlePoliticianError: function (response) {
    if (import.meta.env.DEV) {
      console.error(response);
    }

    // In most cases we have already determined the politician data from the actor's feed. We don't lose that.
    this.setState({ politician: this.state.politician, fetchingId: null });
  },

  /**
   * Closes the panel
   */
  _closePanel: function () {
    this.context.getService('info-panel').hide();
  },

  /**
   * Render politician content.
   * @param {Object} politician
   * @returns {React.Component}
   * @private
   */
  _renderPoliticianContent: function (politician) {
    if (!politician) {
      return null;
    }
    const fullName = politician.firstName + ' ' + politician.lastName,
      partyShortHand = politician.party ? politician.party.shorthand || '' : '',
      fullTitle = politician.title === 'Tweede Kamerlid' ? politician.title + ' ' + partyShortHand : politician.title;

    const initialHeadingLevel = this.props.infoPanel.getIn(['props', 'initialHeadingLevel']) || 3;

    return (
      <div className="InfoPanel-content Politician">
        <div className="u-pt35 u-pl20 u-pb20 u-pr20 u-bgWhite">
          <PoliticianImage className="Politician-photo" politicianId={politician.id} size={100} />
          <header className="InfoPanel-header Header">
            {createHeadingTag(initialHeadingLevel, { className: 'InfoPanel-heading Heading' }, fullName)}
            <div className="InfoPanel-metadata Politician-title">{fullTitle}</div>
            {createExternalLink(politician)}
          </header>
          <blockquote className="InfoPanel-quote Politician-quote">{politician.slogan}</blockquote>
          {window.cordova && politician.title ? (
            <PoliticianNotificationButton politicianId={politician.id} altIcon={true} className="InfoPanel-notifyButton" />
          ) : null}
          {politician.infographic}
        </div>
        {createPoliticianRecentDocumentList(politician, initialHeadingLevel + 1)}
      </div>
    );
  },

  /**
   * Render error content.
   * @returns {React.Component}
   * @private
   */
  _renderErrorContent: function () {
    const initialHeadingLevel = this.props.infoPanel.getIn(['props', 'initialHeadingLevel']) || 3;

    return (
      <div className="InfoPanel-content Politician">
        <div className="u-pt35 u-pl20 u-pb20 u-pr20 u-bgWhite">
          <header className="InfoPanel-header Header">
            {createHeadingTag(initialHeadingLevel, { className: 'InfoPanel-heading error Heading' }, 'Geen informatie beschikbaar')}
          </header>
          <p className="InfoPanel-text Text">Debat Direct heeft geen informatie over deze politicus.</p>
        </div>
      </div>
    );
  },
};

/**
 * The PoliticianPanel render method.
 */
const render = function () {
  const { politician, fetchingId } = this.state,
    error = !politician && !fetchingId; // We don't have a politician and we are not fetching data any more.

  return (
    <aside className="InfoPanel u-bgOffWhite" data-control="true" ref={this.asideRef}>
      <Button className="InfoPanel-closeButton Button Button--close" onClick={this._closePanel} aria-label="Sluit paneel">
        <Icon name="close" />
      </Button>
      <ScrollView>{error ? this._renderErrorContent() : this._renderPoliticianContent(politician)}</ScrollView>
    </aside>
  );
};

/**
 * Public exports.
 */
export default component('PoliticianPanel', definition, render);
