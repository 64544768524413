import React from 'react';
import component from 'omniscient';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const className = (props.className || 'u-fill').concat(' Infographic');

    /* eslint-disable no-extra-parens */
    return (
      <svg x="0px" y="0px" viewBox="0 0 256 256" className={className}>
        <path className="u-noFill u-strokeWhite" d="M200.6,256H55.4V0h145.2V256z M56.3,255.1h143.3V0.9H56.3L56.3,255.1z" />
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicOude', render);
