import React from 'react';
import component from 'omniscient';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const className = (props.className || 'u-fill').concat(' Infographic');

    /* eslint-disable no-extra-parens */
    return (
      <svg x="0px" y="0px" viewBox="0 0 256 256" className={className}>
        <g className="u-noFill u-strokeWhite">
          <rect x="30.5" y="56.3" width="190.4" height="20.9" />
          <rect x="0.5" y="101.7" width="20.9" height="98.1" />
          <rect x="230" y="104.6" width="25.6" height="41.4" />
          <rect x="230" y="158.4" width="25.6" height="41.4" />
          <rect x="101.5" y="147.7" width="48.3" height="20.9" />
        </g>
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicEnquete', render);
