import moment from 'moment';
import immutable from 'immutable';

import { bySlug, byId } from '../../predicates';

const NotificationsServiceFactory = function (reference) {
  let /**
     * List of all notification ids seen by the user
     * @type {Array}
     */
    seenNotifications = [];

  const /**
     * Shortcut to getService
     */
    getService = this.getService,
    /**
     * Get value from localStorage
     * @param key
     * @returns {string}
     */
    restore = (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(key));
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * Save to localStorage
     * @param key
     * @param value
     */
    save = (key, value) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * Get the current route params
     * @returns {{}}
     */
    getRouteParams = () => {
      return getService('router').getParams();
    },
    /**
     * Get the current route information
     * @returns {{}}
     */
    getRoute = () => {
      return getService('router').getRoute();
    },
    /**
     * Get the current debate if we are on a debate page
     * @returns {undefined|Object}
     */
    getCurrentDebate = () => {
      const params = getRouteParams();

      if (!params?.debate) {
        return undefined;
      }

      return reference.cursor().getIn(['data', 'debates']).find(bySlug(params.debate));
    },
    /**
     * Find existing notification in the UI cursor.
     * @param {string} id
     * @returns {Object}
     */
    findExistingNotification = (id) => {
      return reference
        .cursor()
        .getIn(['ui', 'notifications'])
        .find((datum) => datum.get('id') === id);
    },
    /**
     * Returns true if the given notification should be visible for the current user
     * @param notification
     * @param debate
     * @param debates
     * @param locationId
     * @returns {boolean}
     */
    notificationShouldBeVisible = (notification, debate, debates, locationId) => {
      const startsAt = notification.get('startsAt');
      const endsAt = notification.get('endsAt');

      if (startsAt && !moment().isAfter(moment(startsAt))) {
        return false;
      }

      if (endsAt && !moment().isBefore(moment(endsAt))) {
        return false;
      }

      /**
       * The user has already seen this notification
       */
      if (seenNotifications.indexOf(notification.get('id')) > -1) {
        return false;
      }

      const debateId = notification.get('debateId');

      // For embedded: we check if the locationId matches the notification debate locationId
      if (debateId && locationId) {
        const targetDebate = debates.find(byId(debateId));

        if (!targetDebate || targetDebate.get('locationId') !== locationId) {
          return false;
        }
      }

      const meetingId = notification.get('meetingId');

      // For embedded: if no debateId is given, check if the notification is for a whole meeting
      if (!debateId && meetingId && locationId) {
        const targetDebate = debates.find((datum) => datum.get('meetingId') === meetingId);

        if (!targetDebate || targetDebate.get('locationId') !== locationId) {
          return false;
        }
      }

      // If not on the detail screen: filter non-plenary Schorsing notifications
      if (!debate && debateId && !locationId && notification.get('type') === 'Schorsing') {
        const targetDebate = debates.find((datum) => datum.get('id') === debateId);

        if (targetDebate?.get('locationId') !== 'plenaire-zaal') {
          return false;
        }
      }

      // we got a debate, so we should be on a debate details screen...
      // we only show notifications if the debate id matches or the meeting id
      if (debate) {
        const meetingId = notification.get('meetingId');
        const debateId = notification.get('debateId');

        if (!debateId && meetingId) {
          return meetingId === debate.get('meetingId');
        }

        return !debateId || debateId === debate.get('id');
      }

      return true;
    },
    /**
     * The service definition
     * @type {Object}
     */
    NotificationsService = {
      /**
       * Init the notification service
       */
      init: function () {
        seenNotifications = restore('debatdirect-sn') || [];

        reference.reference(['data', 'liveNotifications']).observe('swap', function () {
          NotificationsService.check();
        });

        // listen for route updates
        getService('router').history.listen(() => setTimeout(() => NotificationsService.check(), 50));

        // initial check
        setTimeout(() => NotificationsService.check(), 50); // Wait for the intial route to be set
      },

      /**
       * Check if we should display notifications
       */
      check: function () {
        const debate = getCurrentDebate();
        const debates = reference.cursor(['data', 'debates']);
        const notifications = reference.cursor(['data', 'liveNotifications']);
        const route = getRoute();
        const params = getRouteParams();

        // we probably are still loading the page
        if (!route.name || (params?.debate && !debate)) {
          return;
        }

        let locationId;

        if (route.name === 'location-embedded' && params.location) {
          locationId = params.location;
        }

        // loop through all notifications and check if we need to make one or more visible.
        notifications.forEach((datum) => {
          const type = datum.get('type');

          // only allow certain notification types
          if (!['Schorsing', 'Incident', 'Melding'].includes(type)) {
            return;
          }

          const visibleNotification = findExistingNotification(datum.get('id'));

          if (visibleNotification && visibleNotification.get('message') !== datum.get('message')) {
            return visibleNotification.set('message', datum.get('message'));
          }

          if (!visibleNotification && notificationShouldBeVisible(datum, debate, debates, locationId)) {
            reference.cursor().updateIn(['ui', 'notifications'], (current) => {
              return current.concat(immutable.fromJS([immutable.Map(datum.set('visible', true))]));
            });
          }
        });
      },

      /**
       * Mark notification as seen
       * @param notificationId
       */
      markAsSeen: (notificationId) => {
        // add to seen notifications
        if (seenNotifications.indexOf(notificationId) === -1) {
          seenNotifications.unshift(notificationId);
        }

        // save up to 20 notifications in the localStorage
        save('debatdirect-sn', seenNotifications.slice(0, 20));
      },
    };

  return NotificationsService;
};

export default NotificationsServiceFactory;
