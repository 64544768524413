import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import classNames from 'classnames';

import Link from '../Link/Link';
import Icon from '../Icon/Icon';

const definition = {
  contextTypes: {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  },
};

const render = function ({ className, icon }) {
  const liveDataClickHandler = (event) => {
    event.preventDefault();

    const { getService, getCursor } = this.context;

    const infoPanelService = getService('info-panel');
    const terminology = getCursor(['data', 'terminology']).find((cur) => cur.get('name') === 'Live registratie');

    if (!terminology) {
      return;
    }

    infoPanelService.show('TerminologyPanel', {
      title: terminology.get('name'),
      description: terminology.get('description'),
      imageUrl: null,
    });
  };

  return (
    <div className={classNames('LiveDataDisclaimer u-flex u-pl10 u-pr10', className)}>
      <div className="u-grow u-pt10 u-pb10">
        {'Let op: vanwege '}
        <Link onClick={liveDataClickHandler} href="#">
          {'live-registratie'}
          <Icon name="arrow" className="u-inline" width="8.57" height="12" aria-hidden="true" />
        </Link>
        {' bestaat de kans op onjuistheden.'}
      </div>
      {icon ? <div className="u-shrink u-pt10">{icon}</div> : null}
    </div>
  );
};

export default component('LiveDataDisclaimer', definition, render);
