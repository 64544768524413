import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';

import { DOWNLOAD_STATUS } from '../../services/DownloadService/DownloadService';
import { trackEvent } from '../../common';
import { ChevronLeft, Close, Download } from '../../icons';
import addSourceParameters from '../../utils/addSourceParameters';
import DownloadOptionsSelectComponent from '../DownloadOptionsSelect/DownloadOptionsSelect';
import FragmentSelect from '../FragmentSelect/FragmentSelect';
import DownloadAgreement from '../DownloadAgreement/DownloadAgreement';
import Button from '../Button/Button';
import MenuItem from '../MenuList/MenuItem';
import MenuList from '../MenuList/MenuList';
import Modal from '../Modal/Modal';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import MenuListHeader from '../MenuList/MenuListHeader';
import { byId } from '../../predicates';

import DownloadModalBody from './DownloadModalBody';

export default class DownloadModal extends React.Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  };

  swipeableViewsRef = createRef();

  state = {
    tabIndex: 1,
    quality: null,

    fragmentDownloadId: null,
    debateDownloadId: null,
    agreementAccepted: false,
    previewFragmentOpen: false,
  };

  componentDidUpdate(prevProps) {
    const debateDate = this.props.debate.get('debateDate');
    const downloadFragmentDebateFromDate = this.context.getCursor(['config', 'dateLimit']).get('downloadFragmentDebateFromDate');

    if (!this.props.open && prevProps.open) {
      this.setState({
        quality: null,
      });
    } else if (!prevProps.open && this.props.open) {
      const requestingFragment = this.props.marker || this.props.fromDate;
      const canDownloadFragment = moment(debateDate).toDate() > downloadFragmentDebateFromDate;

      this.setState({
        canDownloadFragment,
        tabIndex: requestingFragment && canDownloadFragment ? 1 : 0,
      });

      this.fetchDebateDownloadOptions();
    }

    setTimeout(() => {
      if (this.swipeableViewsRef.current) {
        this.swipeableViewsRef.current.updateHeight();
      }
    }, 15);
  }

  fetchDebateDownloadOptions() {
    const { getCursor } = this.context;
    const debateId = this.props.debate.get('id');
    const downloadOptions = getCursor(['data', 'debates']).find(byId(debateId))?.get('downloadOptions');

    if (downloadOptions) {
      this.setState({ downloadOptions });
      return;
    }

    this.context
      .getService('api')
      .getDebateDownloadQualities(debateId)
      .then(({ qualities }) => {
        getCursor(['data', 'debates'])
          .find(byId(debateId))
          .update('downloadOptions', () => qualities);
        this.setState({ downloadOptions: qualities });
      });
  }

  handleTabChange = (index) => {
    if (this.state.tabIndex !== index) {
      this.setState({ tabIndex: index });
    }
  };

  handleSubtitleDownloadClick = () => {
    const { debate } = this.props;
    const baseUrl = this.context.getCursor(['ui', 'settings', 'api']).get('cdnBaseUrl');

    window.open(`${baseUrl}/debates/${debate.get('id')}/subtitle/download`, '_blank');
  };

  handleDownloadDebateClick = () => {
    const quality = this.state.quality;

    if (!quality) {
      return false;
    }

    const { debateDownloadId } = this.state;
    const { downloads, debate } = this.props;

    const startedAt = debate.get('startedAt');
    const endedAt = debate.get('endedAt', moment().format());
    const downloadService = this.context.getService('download');

    const item = downloads.find((item) => item.get('id') === debateDownloadId);

    if (item) {
      return downloadService.open(item.get('id'));
    }

    // track download event
    const name = debate.get('name');
    const date = moment(debate.get('startsAt')).format('YYYY-MM-DD');
    const title = `${date} / ${name}`;
    const location = debate.get('locationId').replace(/-/g, ' ');

    trackEvent('debate', 'download', title, 1);

    let videoBaseUrl = debate.getIn(['video', 'catchup', 'url']);

    // use vodUrl if debate has ended
    if (debate.get('endedAt')) {
      videoBaseUrl = debate.getIn(['video', 'catchup', 'vodUrl']);
    }

    Promise.resolve(videoBaseUrl)
      .then((videoBaseUrl) => {
        const videoUrl = addSourceParameters(videoBaseUrl, startedAt, endedAt);
        // start generating a dynamic video fragment
        return downloadService.generateFragment(name, videoUrl, location, startedAt, endedAt, quality);
      })
      .then((item) => {
        this.setState({
          debateDownloadId: item.get('id'),
        });
      });
  };

  handleFragmentSelectConfirm = (data) => {
    // no quality
    if (!data.quality) {
      return;
    }

    const { fragmentDownloadId } = this.state;
    const { downloads, debate } = this.props;

    const downloadService = this.context.getService('download');

    const item = downloads.find((item) => item.get('id') === fragmentDownloadId);

    if (item) {
      return downloadService.open(item.get('id'));
    }

    // track download event
    const name = debate.get('name');
    const date = moment(debate.get('startsAt')).format('YYYY-MM-DD');
    const title = `${date} / ${name}`;
    const location = debate.get('locationId').replace(/-/g, ' ');
    const startedAt = debate.get('startedAt');
    const endedAt = debate.get('endedAt');

    trackEvent('debate', 'downloadFragments', title, 1);

    let videoBaseUrl = debate.getIn(['video', 'catchup', 'url']);

    // use vodUrl if debate has ended
    if (debate.get('endedAt')) {
      videoBaseUrl = debate.getIn(['video', 'catchup', 'vodUrl']);
    }

    const videoUrl = addSourceParameters(videoBaseUrl, startedAt, endedAt);

    // start generating video fragment
    downloadService.generateFragment(name, videoUrl, location, data.start, data.end, data.quality).then((item) => {
      this.setState({
        fragmentDownloadId: item.get('id'),
      });
    });
  };

  handleDownloadFragmentClick = () => {
    const { fragmentDownloadId } = this.state;
    const { downloads } = this.props;
    const downloadService = this.context.getService('download');

    const item = downloads.find((item) => item.get('id') === fragmentDownloadId);

    if (item) {
      downloadService.open(item.get('id'));
    }
  };

  handleCreateFragmentClick = () => {
    this.setState({
      fragmentDownloadId: null,
    });
  };

  handleQualityChange = (event, value) => {
    this.setState({
      quality: value,
    });
  };

  handleResetDebateClick = () => {
    this.setState({
      debateDownloadId: null,
    });
  };

  close() {
    this.props.onClose();
  }

  renderDownloadDebate() {
    const { downloads } = this.props;
    const { debateDownloadId } = this.state;

    const item = downloads.find((item) => item.get('id') === debateDownloadId);

    if (item) {
      const status = item.get('status');
      const url = item.get('url');
      const filename = item.get('filename');

      // progress
      if (status === DOWNLOAD_STATUS.GENERATING || status === DOWNLOAD_STATUS.DOWNLOADING) {
        const action = status === DOWNLOAD_STATUS.DOWNLOADING ? 'downloaden' : 'genereren';

        return (
          <DownloadModalBody
            title={`Debat aan het ${action}.`}
            description={`Bij lange debatten of een hoge videokwaliteit kan het ${action} van de video meer tijd kosten. Ondertussen kunt u Debat Direct blijven gebruiken. Sluit de browser niet. U kunt het videobestand terugvinden bij Downloads in het menu.`}
            progress
          />
        );
      }

      // error
      if (status === DOWNLOAD_STATUS.ERROR) {
        return (
          <DownloadModalBody
            title="Er is helaas iets misgegaan!"
            description="Het is helaas mislukt om het debat te downloaden. Probeer het later opnieuw."
            actions={
              <Button className="Button--light Button--medium Button--block Button--uppercase" onClick={this.handleResetDebateClick}>
                <ChevronLeft className="Button-icon" /> <span className="Button-label">Probeer opnieuw</span>
              </Button>
            }
          />
        );
      }

      // ready!
      if ((status === DOWNLOAD_STATUS.GENERATED && url) || (status === DOWNLOAD_STATUS.DOWNLOADED && filename)) {
        const text = window.cordova
          ? 'Je bestand is gedownload en wordt automatisch geopend.'
          : 'Je bestand wordt automatisch gedownload naar je download map.';

        return (
          <DownloadModalBody
            title="Klaar!"
            description={`${text} Indien dit niet gebeurt, kunt u op de onderstaande knop drukken.`}
            actions={
              <React.Fragment>
                <Button className="Button--primary Button--medium Button--block Button--uppercase" onClick={this.handleDownloadDebateClick}>
                  <Download className="Button-icon" /> <span className="Button-label">Download debat</span>
                </Button>
                <Button className="Button--light Button--medium Button--block Button--uppercase" onClick={this.handleResetDebateClick}>
                  <ChevronLeft className="Button-icon" />
                  <span className="Button-label">Ga terug</span>
                </Button>
              </React.Fragment>
            }
          />
        );
      }
    }

    const debate = this.props.debate;
    const quality = this.state.quality;
    const totalSeconds = moment(debate.get('endedAt')).diff(debate.get('startedAt'), 's');
    const sizeExceeded = window.cordova && (quality ? quality.bandwidth : 1) * totalSeconds >= 1e9;
    const isAppOrMobileWeb = !!(window.cordova || this.context.getService('platform').isMobileWeb());
    const player = this.context.getService('video').getPlayer();
    const hasSubtitles = !!player?.videoTextTracks?.[0];
    const isLive = !debate.has('endedAt');
    const showSubtitleDownloadButton = hasSubtitles && !isAppOrMobileWeb && !isLive;

    return (
      <DownloadModalBody title="Gehele debat downloaden" description="Bij lange debatten kan het genereren van de video lang duren.">
        <form>
          <div className="u-mt25">
            <DownloadAgreement
              id="debate-agreement"
              checked={this.state.agreementAccepted}
              onChange={(event) => this.setState({ agreementAccepted: event.currentTarget.checked })}
            />
          </div>
          <div className="Modal-body-actions">
            <DownloadOptionsSelectComponent options={this.state.downloadOptions} seconds={totalSeconds} onChange={this.handleQualityChange} prefix="download" />
          </div>
          <div className="DownloadModal-actions">
            {showSubtitleDownloadButton && (
              <Button
                className="Button--primary Button--medium Button--uppercase"
                onClick={this.handleSubtitleDownloadClick}
                disabled={!this.state.agreementAccepted}
              >
                <Download className="Button-icon" /> <span className="Button-label">Download ondertiteling</span>
              </Button>
            )}
            <Button
              className="Button--primary Button--medium Button--block Button--uppercase"
              disabled={sizeExceeded || !this.state.agreementAccepted}
              onClick={this.handleDownloadDebateClick}
            >
              <Download className="Button-icon" /> <span className="Button-label">Download debat</span>
            </Button>
          </div>
        </form>
      </DownloadModalBody>
    );
  }

  renderDownloadFragment() {
    const { downloads } = this.props;
    const { fragmentDownloadId } = this.state;

    const item = downloads.find((item) => item.get('id') === fragmentDownloadId);

    if (item) {
      const status = item.get('status');
      const url = item.get('url');
      const filename = item.get('filename');

      if (status === DOWNLOAD_STATUS.GENERATING || status === DOWNLOAD_STATUS.DOWNLOADING) {
        const action = status === DOWNLOAD_STATUS.GENERATING ? 'genereren' : 'downloaden';

        return (
          <DownloadModalBody
            title={`Videofragment aan het ${action}.`}
            description={`Bij lange videofragmenten of een hoge videokwaliteit kan het ${action} van de video meer tijd kosten. Ondertussen kunt u Debat Direct blijven gebruiken. Sluit de browser niet. U kunt het videobestand terugvinden bij Downloads in het menu.`}
            progress
          />
        );
      }

      if (status === DOWNLOAD_STATUS.ERROR) {
        return (
          <DownloadModalBody
            title="Er is helaas iets misgegaan!"
            description="Het is helaas mislukt om het videofragment te downloaden. Probeer het later opnieuw."
            actions={
              <Button className="Button--light Button--medium Button--block Button--uppercase" onClick={this.handleCreateFragmentClick}>
                <ChevronLeft className="Button-icon" /> <span className="Button-label">Probeer opnieuw</span>
              </Button>
            }
          />
        );
      }

      if ((status === DOWNLOAD_STATUS.GENERATED && url) || (status === DOWNLOAD_STATUS.DOWNLOADED && filename)) {
        const text = window.cordova
          ? 'Je bestand is gedownload en wordt automatisch geopend.'
          : 'Je bestand wordt automatisch gedownload naar je download map.';

        return (
          <DownloadModalBody
            title="Klaar!"
            description={`${text} Indien dit niet gebeurt, kunt u op de onderstaande knop drukken.`}
            actions={
              <React.Fragment>
                <Button className="Button--primary Button--medium Button--block Button--uppercase" onClick={this.handleDownloadFragmentClick}>
                  <Download className="Button-icon" /> <span className="Button-label">Download videofragment</span>
                </Button>
                <Button className="Button--light Button--medium Button--block Button--uppercase" onClick={this.handleCreateFragmentClick}>
                  <ChevronLeft className="Button-icon" />
                  <span className="Button-label">Maak nog een videofragment</span>
                </Button>
              </React.Fragment>
            }
          />
        );
      }
    }

    return (
      <FragmentSelect
        fromDate={this.props.fromDate}
        toDate={this.props.toDate}
        marker={this.props.marker}
        debate={this.props.debate}
        events={this.props.debate?.get('events')}
        politicians={this.props.politicians}
        onConfirm={this.handleFragmentSelectConfirm}
        downloadOptions={this.state.downloadOptions}
        previewFragmentOpen={this.state.previewFragmentOpen}
        onPreviewFragmentOpen={() => this.setState({ previewFragmentOpen: true })}
        onPreviewFragmentClose={() => this.setState({ previewFragmentOpen: false })}
      />
    );
  }

  render() {
    const startedAt = this.props.debate?.has('startedAt');
    const { tabIndex, canDownloadFragment } = this.state;

    return (
      <Modal
        type="auto"
        className="DownloadModal"
        open={this.props.open}
        onClose={() => this.close()}
        inert={this.state.previewFragmentOpen}
        initiator={this.props.initiator}
        aria-labelledby="modal-title"
      >
        <MenuListHeader onClick={() => this.close()} label="Debat downloaden" titleId="modal-title" />
        <Tabs index={tabIndex} onChange={this.handleTabChange}>
          <Tab role="tab" aria-selected={tabIndex === 0}>
            GEHELE DEBAT
          </Tab>
          <Tab role="tab" aria-selected={tabIndex === 0} disabled={!canDownloadFragment}>
            MAAK VIDEOFRAGMENT
          </Tab>
        </Tabs>
        <SwipeableViews
          ref={this.swipeableViewsRef}
          index={tabIndex}
          onChangeIndex={this.handleTabChange}
          disabled={this.state.debateCreating || this.state.fragmentCreating}
          animateHeight
        >
          <div className="Modal-body" role="tabpanel" inert={tabIndex === 0 ? null : ''}>
            {startedAt ? (
              this.renderDownloadDebate()
            ) : (
              <div>
                <div className="Modal-body-title">Debat nog niet begonnen.</div>
                <p className="Modal-body-paragraph">Momenteel is het niet mogelijk om het debat te downloaden.</p>
              </div>
            )}
          </div>
          <div role="tabpanel" inert={tabIndex === 1 ? null : ''}>
            <div className="Modal-body">
              {startedAt ? (
                this.renderDownloadFragment()
              ) : (
                <div>
                  <div className="Modal-body-title">Debat nog niet begonnen.</div>
                  <p className="Modal-body-paragraph">Momenteel is het niet mogelijk om een fragment te maken.</p>
                </div>
              )}
            </div>
          </div>
        </SwipeableViews>
        <hr className="u-m0" />
        <MenuList>
          <MenuItem className="MenuItem--footer" icon={<Close />} label="Sluiten" onClick={() => this.close()} />
        </MenuList>
      </Modal>
    );
  }
}
