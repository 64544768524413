import React, { useEffect, useState } from 'react';

type Props = {
  id: string; // Needed for caching
  image: string | null;
  fallbackImage: string | undefined;
  alt?: string;
  className?: string;
  onError?: OnErrorEventHandler;
};

const loadedImages = new Set();

const DDImage = ({ id, image, fallbackImage, alt = '', className, onError }: Props) => {
  const [imageSrc, setImageSrc] = useState(image ? undefined : fallbackImage); // don't use the fallbackImage when we have an image

  useEffect(() => {
    const handleImageLoad = () => {
      if (!image) return;

      loadedImages.add(image);
      setImageSrc(image);
    };
    const handleImageError: OnErrorEventHandler = (event) => {
      onError?.(event);
      setImageSrc((image) => image || fallbackImage);
    };
    const loadImage = () => {
      if (!image && fallbackImage) return setImageSrc(fallbackImage);
      if (!image) return;
      if (loadedImages.has(image)) return setImageSrc(image); // if this image was already successfully loaded, we can skip the preloading

      const img = new Image();
      img.onload = handleImageLoad;
      img.onerror = handleImageError;

      img.src = image;
    };

    loadImage();
  }, [image, id, fallbackImage, onError]);

  if (!imageSrc) return null;

  return <img src={imageSrc} className={className} alt={alt} />;
};

export default React.memo(DDImage);
