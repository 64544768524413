import React from 'react';
import component from 'omniscient';

import { eq, getBackgroundClass, getSeatClass, toggleFill, toggleStroke } from './logic';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const { className, highlight, role, politicians, lowlight, seats, variant } = props;
    const fillColor = variant === 'dark' ? 'White' : '';
    const rootClassName = (className || 'u-fill').concat(' Infographic');

    const lowlightSet = lowlight ? (Array.isArray(lowlight) ? lowlight : lowlight.toJS()) : [];
    const seatData = seats ? (Array.isArray(seats) ? seats : seats.toJS()) : [];

    const politiciansList = politicians && politicians.join(', ');
    const title = `Visualisatie van de Plenaire zaal ${politiciansList ? ' en de plaats van ' + politiciansList : ''}`;

    /* eslint-disable no-extra-parens */
    return (
      <svg viewBox="100 160 300 220" className={rootClassName} role={role} aria-labelledby="infographic-title">
        <title id="infographic-title">{title}</title>
        <g className={getBackgroundClass(variant === 'light' && !!highlight, !!lowlight)}>
          <path d="M389.7,279.6c1.2-3.4,2.3-6.9,3.3-10.5c0-0.1,0-0.2,0.1-0.2 M393,268.8c1-3.6,1.8-7.3,2.5-11l0,0c0.3-1.7,0.7-3.4,0.9-5.2 c0.3-2,0.6-3.9,0.7-6l0,0c0.4-3.7,0.7-7.3,0.8-11.1h-90.2c-0.2,1.7-0.4,3.3-0.7,5.1c-0.3,1.6-0.7,3.3-1.1,4.9l0,0l0,0 c-0.4,1.6-1,3.2-1.5,4.7c-0.6,1.5-1.2,3-1.9,4.5l78.3,44.9c1.6-3.1,3.2-6.4,4.6-9.6l0,0c0.7-1.7,1.5-3.5,2.2-5.2 c0.7-1.7,1.3-3.4,2-5.2" />
          <path d="M110.3,279.6c-1.2-3.4-2.3-6.9-3.3-10.5c0-0.1,0-0.2-0.1-0.2 M107,268.8c-1-3.6-1.8-7.3-2.5-11l0,0 c-0.3-1.7-0.7-3.4-0.9-5.2c-0.3-2-0.6-3.9-0.7-6l0,0c-0.4-3.7-0.7-7.3-0.8-11.1h90.2c0.2,1.7,0.4,3.3,0.7,5.1 c0.3,1.6,0.7,3.3,1.1,4.9l0,0l0,0c0.4,1.6,1,3.2,1.5,4.7c0.6,1.5,1.2,3,1.9,4.5l-78.3,44.9c-1.6-3.1-3.2-6.4-4.6-9.6l0,0 c-0.7-1.7-1.5-3.5-2.2-5.2c-0.7-1.7-1.3-3.4-2-5.2" />
          <path d="M290.8,271.2c-1.2,1.1-2.4,2.3-3.7,3.3c-1.3,1.1-2.6,2-3.9,2.9l45.4,78.1c3-1.9,6-3.8,8.9-6c1.5-1.1,3.1-2.3,4.6-3.4 c1.5-1.1,2.9-2.4,4.3-3.5l0,0c2.8-2.4,5.5-4.8,8.1-7.4c0.1-0.1,0.1-0.1,0.2-0.2l0,0c2.6-2.6,5.2-5.4,7.7-8.2l0,0 c1.1-1.3,2.3-2.7,3.4-4.1c1.2-1.5,2.4-3.1,3.7-4.7c2.2-2.9,4.3-6,6.3-9.1l-78.1-45.3c-1,1.4-2,2.7-3.1,3.9 c-1.1,1.2-2.2,2.4-3.3,3.6L290.8,271.2z" />
          <path d="M319.2,360.9l-44.9-78.3c-1.5,0.7-3.1,1.3-4.7,1.9c-1.5,0.6-3.2,1.1-4.7,1.5l0,0l0,0c-1.6,0.4-3.3,0.7-4.8,1 c-1.6,0.2-3.3,0.5-4.9,0.6v90.3c3.6-0.1,7.1-0.4,10.7-0.7l0,0c1.9-0.2,3.7-0.4,5.6-0.7c1.8-0.2,5.5-0.9,5.5-0.9 c3.6-0.7,10.9-2.5,10.9-2.5c3.6-0.9,10.8-3.3,10.8-3.3c1.6-0.6,3.3-1.1,5-1.8c1.9-0.7,3.7-1.5,5.5-2.3l0,0 C312.5,364.3,315.8,362.6,319.2,360.9z" />
          <path d="M209.1,271.2c-1.1-1.1-2.3-2.4-3.4-3.7c-1.1-1.3-2.1-2.6-3.1-4l-78.1,45.2c2,3.2,4.1,6.2,6.3,9.2c1.2,1.6,2.4,3.2,3.7,4.7 c1.1,1.4,2.2,2.7,3.3,4.1c2.4,2.9,5,5.6,7.7,8.2l0,0c0.1,0.1,0.1,0.2,0.2,0.2c2.6,2.6,5.3,5.1,8.1,7.4l0,0c1.4,1.2,2.9,2.4,4.3,3.5 c1.5,1.2,3,2.3,4.6,3.4c2.9,2.1,5.8,4.1,8.8,6l45.4-78.1c-1.3-0.9-2.6-1.9-3.9-2.9C211.6,273.4,210.3,272.3,209.1,271.2 C209.2,271.2,209.2,271.2,209.1,271.2" />
          <path d="M235.1,286c-1.6-0.4-3.2-0.9-4.8-1.5c-1.5-0.6-3.2-1.1-4.7-1.9L180.8,361c3.3,1.7,6.7,3.3,10,4.8c1.9,0.8,3.7,1.5,5.5,2.3 c1.6,0.7,3.3,1.2,5,1.8c3.6,1.2,7.2,2.4,10.8,3.3l0,0c0.1,0,0.2,0.1,0.2,0.1c3.6,0.9,7.2,1.7,10.8,2.4l0,0c1.8,0.3,3.7,0.7,5.5,0.9 c1.9,0.2,3.7,0.5,5.6,0.7c3.6,0.4,7.1,0.7,10.7,0.7l-0.1-90.3c-1.6-0.2-3.3-0.3-4.9-0.6C238.4,286.7,236.8,286.4,235.1,286 L235.1,286" />
          <polygon points="250.4,250.2 218.3,237.5 214.7,246.1 250.2,260.2 285.7,246.3 282,237.8" />
          <rect x="255.3" y="207.9" transform="matrix(0.708 -0.7062 0.7062 0.708 -76.1044 246.5145)" width="9.6" height="14.8" />
          <polygon points="302.8,216.3 263.5,179.3 270.1,172.7 315.9,216.3" />
          <polygon points="333.9,216.1 285.3,169.3 292.5,162 347.5,216.1" />
          <rect x="233.1" y="166.3" transform="matrix(0.7081 -0.7061 0.7061 0.7081 -51.3483 217.8773)" width="9.6" height="9.4" />
          <polygon points="199.8,215.9 228,187.8 221.4,181.2 194.2,208.2 177.6,208.2 183.7,202.1 177.1,195.5 164.3,208.2 160.3,208.2 160.3,215.9" />
          <rect x="181.1" y="176.5" transform="matrix(0.7081 -0.7061 0.7061 0.7081 -70.2542 192.5107)" width="33.3" height="9.4" />
        </g>
        <g className={toggleFill(eq(highlight, 'fracties'), fillColor).concat(' ', toggleStroke(eq(highlight, 'fracties')))}>
          {[
            // 1
            'M382.8,235.8c0-0.1,0-0.1,0-0.2H398c-0.2,3.7-0.4,7.4-0.8,11.1l-14.9-1.3C382.6,242.2,382.8,239,382.8,235.8',
            // 2
            'M382.3,245.4l14.8,1.3c-0.2,2-0.5,4-0.7,6c-0.2,1.7-0.6,3.5-0.9,5.2l-14.5-2.6C381.6,252.1,382,248.7,382.3,245.4',
            // 3
            'M381,255.3l14.5,2.5c-0.7,3.7-1.5,7.3-2.5,11l-14.1-3.7C379.8,261.9,380.5,258.6,381,255.3',
            // 4
            'M378.9,265l14.1,3.7c0,0.1-0.1,0.2-0.1,0.2c-1,3.6-2,7-3.3,10.5l-13.8-5C377,271.5,378.1,268.3,378.9,265',
            // 5
            'M375.9,274.6l13.8,5c-0.7,1.7-1.2,3.5-2,5.2c-0.7,1.8-1.5,3.5-2.2,5.2l-13.4-6.3C373.5,280.8,374.8,277.7,375.9,274.6',
            // 6
            'M385.5,290c-1.5,3.3-3,6.4-4.6,9.6l-13-7.5c1.5-2.7,3-5.5,4.3-8.4L385.5,290z',
            // 7
            'M367.4,235.8c0-0.1,0-0.1,0-0.2h8h7.3c0,0.1,0,0.1,0,0.2c0,3.9-0.2,7.8-0.6,11.7l-15.2-1.6 C367.3,242.5,367.4,239.1,367.4,235.8',
            // 8
            'M366.9,245.8l15.2,1.6c-0.4,4-1.1,7.9-1.9,11.8l-14.9-3.2C366,252.7,366.6,249.3,366.9,245.8',
            // 9
            'M365.4,256.1l14.9,3.2c-0.8,3.9-1.9,7.8-3.1,11.6l-14.5-4.7C363.8,262.8,364.7,259.4,365.4,256.1',
            // 10
            'M362.7,266.1l14.5,4.7c-1.2,3.9-2.7,7.6-4.2,11.2l-14-6.2C360.4,272.6,361.6,269.4,362.7,266.1',
            // 11
            'M373,282c-1.5,3.5-3.3,6.9-5.1,10.2l-6.5-3.7l-6.8-3.8c1.6-2.9,3.1-5.8,4.5-8.8L373,282z',
            // 12
            'M351.9,235.8c0-0.1,0-0.1,0-0.2h1.1h14.5c0,0.1,0,0.1,0,0.2c0,3.4-0.2,6.8-0.5,10l-15.5-1.6 C351.7,241.4,351.9,238.6,351.9,235.8',
            // 13
            'M351.5,244.2l15.5,1.6c-0.3,3.5-0.9,6.8-1.6,10.3l-15.2-3.3C350.7,250,351.1,247.1,351.5,244.2',
            // 14
            'M350.1,252.8l15.2,3.3c-0.7,3.4-1.6,6.8-2.7,10l-14.8-4.8C348.8,258.5,349.5,255.7,350.1,252.8',
            // 15
            'M347.9,261.2l14.8,4.8c-1.1,3.3-2.3,6.5-3.7,9.7l-14.3-6.4C345.9,266.8,347,264.1,347.9,261.2',
            // 16
            'M359,275.7c-1.3,3-2.9,6-4.5,8.8l-12.9-7.3l-0.6-0.4c1.4-2.4,2.6-4.9,3.7-7.4L359,275.7z',
            // 17
            'M336.5,235.8c0-0.1,0-0.1,0-0.2h15.4c0,0.1,0,0.1,0,0.2c0,3.6-0.2,7.1-0.7,10.6l-15.3-2 C336.2,241.6,336.5,238.6,336.5,235.8',
            // 18
            'M335.9,244.3l15.3,2c-0.5,3.7-1.1,7.3-2.1,10.7l-14.9-4C334.9,250.2,335.5,247.3,335.9,244.3',
            // 19
            'M334.2,253.1l14.9,4c-1,3.5-2.1,7-3.5,10.4l-14.3-6C332.5,258.8,333.4,255.9,334.2,253.1',
            // 20
            'M345.7,267.4c-1.3,3.3-2.9,6.4-4.6,9.4l-13.4-7.7c1.4-2.4,2.6-5,3.7-7.7L345.7,267.4z',
            // 21
            'M321.9,235.6h8.5h6c0,0.1,0,0.1,0,0.2c0,3.9-0.3,7.7-1,11.5l-14.4-2.6C321.6,241.8,321.9,238.7,321.9,235.6',
            // 22
            'M321,244.7l14.3,2.5c-0.7,3.9-1.7,7.7-3,11.4l-13.8-5C319.7,250.9,320.6,247.8,321,244.7',
            // 23
            'M332.5,258.7c-1.3,3.6-2.9,7.1-4.8,10.4l-5.5-3.2l-7.2-4.2c1.5-2.6,2.7-5.3,3.7-8.2L332.5,258.7z',
            // 24
            'M307.1,240.7c0.3-1.6,0.6-3.3,0.7-5.1h14c0,4.7-0.7,9.3-1.8,13.6l-14-3.7C306.5,243.9,306.8,242.3,307.1,240.7',
            // 25
            'M306,245.6C306,245.5,306,245.5,306,245.6l14.1,3.7c-1.2,4.4-2.9,8.6-5.1,12.6l-12.4-7.1c0.7-1.5,1.3-3,1.9-4.5 C305,248.7,305.6,247.1,306,245.6',
            // 26
            'M362.2,301.2L362.2,301.2l13.3,7.6c-2,3.2-4.1,6.2-6.3,9.2l-12.2-8.6C358.8,306.6,360.6,303.9,362.2,301.2',
            // 27
            'M357,309.3l12.2,8.6c-1.2,1.6-2.4,3.2-3.7,4.7c-1.1,1.4-2.2,2.7-3.3,4.1l-11.2-9.5C353.1,314.7,355,312,357,309.3',
            // 28
            'M351,317.2l11.2,9.5c-2.4,2.9-5,5.6-7.7,8.2l-10.4-10.4C346.6,322.3,348.8,319.7,351,317.2',
            // 29
            'M344.3,324.6l10.4,10.4c-0.1,0.1-0.1,0.2-0.2,0.2c-2.6,2.6-5.3,5.1-8.1,7.4l-9.5-11.2C339.5,329.2,341.9,326.9,344.3,324.6 ',
            // 30
            'M336.9,331.3l9.4,11.2c-1.4,1.2-2.9,2.4-4.3,3.5c-1.5,1.2-3,2.3-4.6,3.4l-8.5-12.1C331.7,335.5,334.3,333.4,336.9,331.3',
            // 31
            'M337.5,349.5c-2.9,2.1-5.8,4.1-8.8,6l-7.6-13c2.7-1.5,5.4-3.3,7.9-5.1L337.5,349.5z',
            // 32
            'M348.9,293.5L348.9,293.5l7,3.9l6.3,3.7l-0.1,0.1c-2,3.4-4.1,6.7-6.4,9.8l-12.4-9C345.4,299.2,347.2,296.4,348.9,293.5',
            // 33
            'M343.5,301.9l12.4,9c-2.4,3.3-4.9,6.4-7.5,9.3L337,310C339.3,307.4,341.4,304.7,343.5,301.9',
            // 34
            'M337,310l11.3,10.2c-2.7,3-5.5,5.8-8.5,8.5l-10.2-11.3C332.2,315,334.7,312.6,337,310',
            // 35
            'M329.7,317.3l10.2,11.3c-3,2.7-6.1,5.2-9.3,7.6l-9-12.4C324.4,321.9,327.1,319.7,329.7,317.3',
            // 36
            'M330.6,336.2c-3.1,2.3-6.3,4.3-9.5,6.3l-3.7-6.5l-3.9-6.7c2.9-1.6,5.5-3.4,8.2-5.4L330.6,336.2z',
            // 37
            'M335.5,285.7L335.5,285.7l1,0.5l12.5,7.2l-0.1,0.1c-1.7,2.9-3.5,5.8-5.5,8.5l-12.6-9.1C332.5,290.5,334,288.1,335.5,285.7',
            // 38
            'M330.9,292.8l12.6,9.1c-2,2.9-4.2,5.5-6.5,8.1l-11.6-10.4C327.3,297.4,329.1,295.1,330.9,292.8',
            // 39
            'M325.4,299.5l11.5,10.5c-2.3,2.6-4.7,5.1-7.3,7.3l-10.4-11.6C321.4,303.8,323.4,301.7,325.4,299.5',
            // 40
            'M319.3,305.7l10.4,11.6c-2.6,2.4-5.3,4.5-8.1,6.5l-9.1-12.6C314.8,309.6,317.1,307.7,319.3,305.7',
            // 41
            'M321.6,323.9c-2.7,2-5.4,3.7-8.2,5.4l-7.4-12.8l-0.4-0.7c2.4-1.4,4.6-2.9,6.9-4.6L321.6,323.9z',
            // 42
            'M322.1,277.9L322.1,277.9l13.5,7.7l-0.1,0.1c-1.8,3.1-3.7,6-5.9,8.8l-12.2-9.4C319,282.8,320.6,280.4,322.1,277.9',
            // 43
            'M317.3,285.1l12.2,9.4c-2.2,2.9-4.6,5.7-7.2,8.2l-10.9-10.9C313.5,289.7,315.5,287.5,317.3,285.1',
            // 44
            'M311.5,291.8l10.9,10.9c-2.6,2.6-5.3,5-8.2,7.3l-9.4-12.2C307.2,295.9,309.4,293.9,311.5,291.8',
            // 45
            'M314.2,310c-2.8,2.1-5.7,4.1-8.6,5.9l-7.7-13.4c2.4-1.5,4.8-3,7-4.7L314.2,310z',
            // 46
            'M309.6,270.6l7.3,4.2l5.2,3l-0.1,0.1c-2,3.4-4.2,6.5-6.6,9.5l-11.1-9.4C306.2,275.7,308,273.3,309.6,270.6',
            // 47
            'M304.3,278l11.2,9.4c-2.5,3.1-5.4,5.9-8.3,8.4l-9.5-11.2C300.1,282.6,302.3,280.4,304.3,278',
            // 48
            'M307.1,295.9c-2.9,2.4-6,4.7-9.4,6.6l-3.2-5.5l-4.2-7.2c2.5-1.5,5-3.3,7.3-5.1L307.1,295.9z',
            // 49
            'M294.2,267.5c1.1-1.3,2.1-2.6,3.1-4l12.1,7c-2.4,4.1-5.2,7.7-8.4,10.9l-10.2-10.3C292,270,293.2,268.8,294.2,267.5',
            // 50
            'M290.8,271.2L290.8,271.2l10.4,10.3c-3.3,3.3-6.8,6-10.8,8.3l-7.2-12.3c1.3-0.9,2.6-1.9,3.9-2.9 C288.4,273.4,289.7,272.4,290.8,271.2',
            // 51
            'M311.4,347.7L311.4,347.7l7.7,13.2c-3.3,1.7-6.7,3.3-10,4.8l-6.3-13.5C305.7,350.8,308.6,349.3,311.4,347.7',
            // 52
            'M302.9,352.2l6.3,13.5c-1.9,0.8-3.7,1.5-5.5,2.3c-1.6,0.7-3.3,1.2-5,1.8l-5-13.9C296.8,354.9,299.8,353.6,302.9,352.2',
            // 53
            'M293.7,356l5,13.9c-3.6,1.2-7.2,2.4-10.8,3.3l-3.8-14.2C287.3,358.1,290.5,357.2,293.7,356',
            // 54
            'M284.1,359l3.7,14.1c-0.1,0-0.2,0.1-0.2,0.1c-3.6,0.9-7.2,1.7-10.8,2.4l-2.5-14.4C277.7,360.6,280.9,359.8,284.1,359',
            // 55
            'M274.4,361.2l2.5,14.4c-1.8,0.3-3.7,0.7-5.5,0.9c-1.9,0.2-3.7,0.5-5.6,0.7l-1.2-14.8C267.8,362.1,271.2,361.7,274.4,361.2',
            // 56
            'M265.8,377.2c-3.6,0.4-7.1,0.7-10.7,0.7v-15c3.1,0,6.3-0.2,9.4-0.4L265.8,377.2z',
            // 57
            'M303.8,334.5C303.9,334.5,303.9,334.4,303.8,334.5l4.1,6.8l3.6,6.3l-0.1,0.1c-3.4,2-6.8,3.7-10.4,5.3l-6.2-13.9 C297.9,337.7,300.9,336.2,303.8,334.5',
            // 58
            'M294.9,339.1l6.2,13.9c-3.7,1.6-7.4,3.1-11.2,4.3l-4.7-14.5C288.4,341.7,291.7,340.4,294.9,339.1',
            // 59
            'M285.2,342.7l4.7,14.5c-3.8,1.2-7.7,2.3-11.6,3.1l-3.2-14.9C278.6,344.8,281.9,343.9,285.2,342.7',
            // 60
            'M275.1,345.4l3.2,14.9c-3.9,0.8-7.8,1.5-11.8,1.9L265,347C268.4,346.7,271.8,346.2,275.1,345.4',
            // 61
            'M266.5,362.3c-3.8,0.4-7.6,0.6-11.4,0.7v-7.5v-7.7c3.3,0,6.6-0.2,9.9-0.6L266.5,362.3z',
            // 62
            'M296,321L296,321l0.6,0.8l7.3,12.6l-0.1,0.1c-2.9,1.7-6,3.2-9,4.6l-6.3-14.3C291,323.7,293.6,322.4,296,321',
            // 63
            'M288.5,324.8l6.3,14.3c-3.2,1.4-6.4,2.7-9.7,3.7l-4.8-14.8C283.1,327,285.8,326,288.5,324.8',
            // 64
            'M280.4,327.9l4.8,14.8c-3.3,1.1-6.7,2-10,2.7l-3.2-15.2C274.9,329.6,277.7,328.9,280.4,327.9',
            // 65
            'M272.1,330.2l3.2,15.2c-3.4,0.7-6.8,1.2-10.3,1.6l-1.6-15.5C266.3,331.2,269.2,330.8,272.1,330.2',
            // 66
            'M265,347.1c-3.3,0.3-6.6,0.5-9.9,0.6v-14.9V332c2.8,0,5.5-0.2,8.2-0.5L265,347.1z',
            // 67
            'M288.4,307.6C288.5,307.6,288.5,307.5,288.4,307.6l7.7,13.3L296,321c-3.1,1.8-6.3,3.3-9.5,4.6l-5.9-14.3 C283.4,310.3,285.9,309,288.4,307.6',
            // 68
            'M280.8,311.3l5.9,14.3c-3.4,1.4-6.8,2.6-10.4,3.5l-4-14.9C275.1,313.5,278,312.5,280.8,311.3',
            // 69
            'M272.3,314.3l4,14.9c-3.5,1-7.1,1.6-10.7,2.1l-2-15.3C266.5,315.6,269.4,315,272.3,314.3',
            // 70
            'M265.6,331.3c-3.5,0.5-6.9,0.7-10.4,0.7v-15.4c2.9,0,5.6-0.2,8.5-0.6L265.6,331.3z',
            // 71
            'M281.3,294.9l4.2,7.3l3,5.2l-0.1,0.1c-3.4,2-6.9,3.6-10.4,4.9l-5-13.7C275.9,297.7,278.7,296.4,281.3,294.9',
            // 72
            'M273,298.7l5,13.7c-3.7,1.4-7.6,2.4-11.4,3.1l-2.5-14.4C267.1,300.6,270.1,299.8,273,298.7',
            // 73
            'M266.5,315.5c-3.8,0.7-7.6,1-11.4,1.1v-6.4v-8.3c2.9,0,6-0.2,8.9-0.8L266.5,315.5z',
            // 74
            'M269.6,284.6c1.5-0.6,3.2-1.1,4.7-1.9l7,12.1c-4.1,2.4-8.4,4.1-12.7,5.2l-3.7-14C266.4,285.6,268.1,285.2,269.6,284.6',
            // 75
            'M264.8,286.1C264.8,286,264.8,286,264.8,286.1l3.7,14.1c-4.5,1.2-9,1.8-13.5,1.8v-14.3c1.6-0.2,3.3-0.3,4.9-0.6 C261.6,286.8,263.2,286.5,264.8,286.1',
            // 76
            'M234.2,377.2c3.5,0.4,7.1,0.7,10.7,0.7v-15c-3.1,0-6.3-0.2-9.4-0.4L234.2,377.2z',
            // 77
            'M225.6,361.2l-2.5,14.4c1.8,0.3,3.7,0.7,5.5,0.9c1.9,0.2,3.7,0.5,5.6,0.7l1.2-14.8C232.2,362.1,228.9,361.7,225.6,361.2',
            // 78
            'M215.9,359l-3.7,14.1c0.1,0,0.2,0.1,0.2,0.1c3.6,0.9,7.2,1.7,10.8,2.4l2.5-14.4C222.3,360.6,219.1,359.8,215.9,359',
            // 79
            'M206.3,356l-5,13.9c3.6,1.2,7.2,2.4,10.8,3.3l3.7-14.1C212.7,358.1,209.5,357.2,206.3,356',
            // 80
            'M197.2,352.2l-6.3,13.5c1.9,0.8,3.7,1.5,5.5,2.3c1.6,0.7,3.3,1.2,5,1.8l5-13.9C203.2,354.9,200.2,353.6,197.2,352.2',
            // 81
            'M188.6,347.7L188.6,347.7l-7.7,13.2c3.3,1.7,6.7,3.3,10,4.8l6.3-13.5C194.3,350.8,191.4,349.3,188.6,347.7',
            // 82
            'M233.5,362.3c3.8,0.4,7.6,0.6,11.4,0.7v-7.5v-7.7c-3.3,0-6.6-0.2-9.9-0.6L233.5,362.3z',
            // 83
            'M224.9,345.4l-3.2,14.9c3.9,0.8,7.8,1.5,11.8,1.9L235,347C231.7,346.7,228.3,346.2,224.9,345.4',
            // 84
            'M214.8,342.7l-4.7,14.5c3.8,1.2,7.7,2.3,11.6,3.1l3.2-14.9C221.5,344.8,218.2,343.9,214.8,342.7',
            // 85
            'M205.2,339.1L199,353c3.7,1.6,7.4,3.1,11.2,4.3l4.7-14.5C211.6,341.7,208.4,340.4,205.2,339.1',
            // 86
            'M196.2,334.5C196.2,334.5,196.2,334.4,196.2,334.5l-4.1,6.8l-3.6,6.3l0.1,0.1c3.4,2,6.8,3.7,10.4,5.3l6.2-13.9 C202.1,337.7,199.2,336.2,196.2,334.5',
            // 87
            'M235.1,347.1c3.3,0.3,6.6,0.5,9.9,0.6v-14.9V332c-2.8,0-5.5-0.2-8.2-0.5L235.1,347.1z',
            // 88
            'M228,330.2l-3.2,15.2c3.4,0.7,6.8,1.2,10.3,1.6l1.6-15.5C233.8,331.2,230.9,330.8,228,330.2',
            // 89
            'M219.6,327.9l-4.8,14.8c3.3,1.1,6.7,2,10,2.7l3.2-15.2C225.3,329.6,222.4,328.9,219.6,327.9',
            // 90
            'M211.5,324.8l-6.3,14.3c3.2,1.4,6.4,2.7,9.7,3.7l4.8-14.8C216.9,327,214.2,326,211.5,324.8',
            // 91
            'M204,321L204,321l-0.6,0.8l-7.3,12.6l0.1,0.1c2.9,1.7,6,3.2,9,4.6l6.3-14.3C209,323.7,206.4,322.4,204,321',
            // 92
            'M234.6,331.3c3.5,0.5,6.9,0.7,10.4,0.7v-15.4c-2.9,0-5.6-0.2-8.5-0.6L234.6,331.3z',
            // 93
            'M227.8,314.3l-4,14.9c3.5,1,7.1,1.6,10.7,2.1l2-15.3C233.6,315.6,230.6,315,227.8,314.3',
            // 94
            'M219.4,311.3l-5.9,14.3c3.4,1.4,6.8,2.6,10.4,3.5l4-14.9C224.9,313.5,222.2,312.5,219.4,311.3',
            // 95
            'M211.6,307.6C211.6,307.6,211.6,307.5,211.6,307.6l-7.7,13.3l0.1,0.1c3.1,1.8,6.3,3.3,9.5,4.6l5.9-14.3 C216.8,310.3,214.2,309,211.6,307.6',
            // 96
            'M233.6,315.5c3.8,0.7,7.6,1,11.4,1.1v-6.4v-8.3c-2.9,0-6-0.2-8.9-0.8L233.6,315.5z',
            // 97
            'M227.1,298.7l-5,13.7c3.7,1.4,7.6,2.4,11.4,3.1l2.5-14.4C233.1,300.6,230.1,299.8,227.1,298.7',
            // 98
            'M218.8,294.9l-4.2,7.3l-3,5.2l0.1,0.1c3.4,2,6.9,3.6,10.4,4.9l5-13.7C224.3,297.7,221.5,296.4,218.8,294.9',
            // 99
            'M235.3,286.1C235.3,286,235.3,286,235.3,286.1l-3.7,14.1c4.5,1.2,9,1.8,13.5,1.8v-14.3c-1.6-0.2-3.3-0.3-4.9-0.6 C238.5,286.8,236.9,286.5,235.3,286.1',
            // 100
            'M230.5,284.6c-1.5-0.6-3.2-1.1-4.7-1.9l-7,12.1c4.1,2.4,8.4,4.1,12.7,5.2l3.7-14C233.7,285.6,232.1,285.2,230.5,284.6',
            // 101
            'M162.6,349.5c2.9,2.1,5.8,4.1,8.8,6l7.6-13c-2.7-1.5-5.4-3.3-7.9-5.1L162.6,349.5z',
            // 102
            'M163.1,331.3l-9.4,11.2c1.4,1.2,2.9,2.4,4.3,3.5c1.5,1.2,3,2.3,4.6,3.4l8.5-12.1C168.4,335.5,165.7,333.4,163.1,331.3',
            // 103
            'M155.8,324.6L145.4,335c0.1,0.1,0.1,0.2,0.2,0.2c2.6,2.6,5.3,5.1,8.1,7.4l9.4-11.2C160.6,329.2,158.2,326.9,155.8,324.6',
            // 104
            'M149,317.2l-11.2,9.5c2.4,2.9,5,5.6,7.7,8.2l10.4-10.4C153.4,322.3,151.2,319.7,149,317.2',
            // 105
            'M143,309.3l-12.2,8.6c1.2,1.6,2.4,3.2,3.7,4.7c1.1,1.4,2.2,2.7,3.3,4.1l11.2-9.5C147,314.7,145,312,143,309.3',
            // 106
            'M137.9,301.2L137.9,301.2l-13.4,7.5c2,3.2,4.1,6.2,6.3,9.2l12.2-8.6C141.2,306.6,139.5,303.9,137.9,301.2',
            // 107
            'M169.4,336.2c3.1,2.3,6.3,4.3,9.5,6.3l3.7-6.5l3.9-6.7c-2.9-1.6-5.5-3.4-8.2-5.4L169.4,336.2z',
            // 108
            'M170.3,317.3l-10.2,11.3c3,2.7,6.1,5.2,9.3,7.6l9-12.4C175.6,321.9,172.9,319.7,170.3,317.3',
            // 109
            'M163.1,310l-11.3,10.2c2.7,3,5.5,5.8,8.5,8.5l10.2-11.3C167.8,315,165.3,312.6,163.1,310',
            // 110
            'M156.5,301.9l-12.4,9c2.4,3.3,4.9,6.4,7.5,9.3L163,310C160.8,307.4,158.6,304.7,156.5,301.9',
            // 111
            'M151.1,293.5L151.1,293.5l-7,3.9l-6.3,3.7l0.1,0.1c2,3.4,4.1,6.7,6.4,9.8l12.4-9C154.6,299.2,152.8,296.4,151.1,293.5',
            // 112
            'M178.4,323.9c2.7,2,5.4,3.7,8.2,5.4l7.4-12.8l0.4-0.7c-2.4-1.4-4.6-2.9-6.9-4.6L178.4,323.9z',
            // 113
            'M180.7,305.7l-10.4,11.6c2.6,2.4,5.3,4.5,8.1,6.5l9.1-12.6C185.2,309.6,182.9,307.7,180.7,305.7',
            // 114
            'M174.6,299.5L163.1,310c2.3,2.6,4.7,5.1,7.3,7.3l10.4-11.6C178.6,303.8,176.6,301.7,174.6,299.5',
            // 115
            'M169.2,292.8l-12.6,9.1c2,2.9,4.2,5.5,6.5,8.1l11.6-10.4C172.7,297.4,170.9,295.1,169.2,292.8',
            // 116
            'M164.6,285.7L164.6,285.7l-1,0.5l-12.6,7.1l0.1,0.1c1.7,2.9,3.5,5.8,5.5,8.5l12.6-9.1C167.5,290.5,166,288.1,164.6,285.7',
            // 117
            'M185.8,310c2.8,2.1,5.7,4.1,8.6,5.9l7.7-13.4c-2.4-1.5-4.8-3-7-4.7L185.8,310z',
            // 118
            'M188.6,291.8l-10.9,10.9c2.6,2.6,5.3,5,8.2,7.3l9.4-12.2C192.9,295.9,190.6,293.9,188.6,291.8',
            // 119
            'M182.7,285.1l-12.2,9.4c2.2,2.9,4.6,5.7,7.2,8.2l10.9-10.9C186.5,289.7,184.5,287.5,182.7,285.1',
            // 120
            'M178,277.9L178,277.9l-13.5,7.7l0.1,0.1c1.8,3.1,3.7,6,5.9,8.8l12.2-9.4C181,282.8,179.4,280.4,178,277.9',
            // 121
            'M192.9,295.9c2.9,2.4,6,4.7,9.4,6.6l3.2-5.5l4.2-7.2c-2.5-1.5-5-3.3-7.3-5.1L192.9,295.9z',
            // 122
            'M195.7,278l-11.2,9.5c2.5,3.1,5.4,5.9,8.3,8.4l9.4-11.2C200,282.6,197.8,280.4,195.7,278',
            // 123
            'M190.4,270.6l-7.3,4.2l-5.2,3l0.1,0.1c2,3.4,4.2,6.5,6.6,9.5l11.1-9.4C193.8,275.7,192,273.3,190.4,270.6',
            // 124
            'M209.2,271.2L209.2,271.2l-10.4,10.2c3.3,3.3,6.8,6,10.8,8.3l7.2-12.3c-1.3-0.9-2.7-1.9-3.9-2.9 C211.6,273.4,210.3,272.4,209.2,271.2',
            // 125
            'M205.8,267.5c-1.1-1.3-2.1-2.6-3.1-4l-12.1,7c2.4,4.1,5.2,7.7,8.4,10.9l10.3-10.4C208,270,206.8,268.8,205.8,267.5',
            // 126
            'M114.5,290c1.5,3.3,3,6.4,4.6,9.6l13-7.5c-1.5-2.7-3-5.5-4.3-8.4L114.5,290z',
            // 127
            'M124.1,274.6l-13.8,5c0.7,1.7,1.2,3.5,2,5.2c0.7,1.8,1.5,3.5,2.2,5.2l13.4-6.3C126.5,280.8,125.2,277.7,124.1,274.6',
            // 128
            'M121.1,265l-14.1,3.8c0,0.1,0.1,0.2,0.1,0.2c1,3.6,2,7,3.3,10.5l13.8-5C123,271.5,122,268.3,121.1,265',
            // 129
            'M119,255.3l-14.5,2.5c0.7,3.7,1.5,7.3,2.5,11l14.1-3.7C120.3,261.9,119.5,258.6,119,255.3',
            // 130
            'M117.7,245.4l-14.9,1.3c0.2,2,0.5,4,0.7,6c0.2,1.7,0.6,3.5,0.9,5.2l14.5-2.5C118.4,252.1,118,248.7,117.7,245.4',
            // 131
            'M117.2,235.8c0-0.1,0-0.1,0-0.2H102c0.2,3.7,0.4,7.4,0.8,11.1l14.9-1.3C117.4,242.2,117.2,239,117.2,235.8z',
            // 132
            'M127,282c1.5,3.5,3.3,6.9,5.1,10.2l6.5-3.7l6.8-3.8c-1.6-2.9-3.1-5.8-4.5-8.8L127,282z',
            // 133
            'M137.3,266.1l-14.5,4.7c1.2,3.8,2.7,7.6,4.2,11.2l14-6.2C139.6,272.6,138.4,269.4,137.3,266.1',
            // 134
            'M134.7,256.1l-14.9,3.2c0.8,3.9,1.9,7.8,3.1,11.6l14.5-4.7C136.2,262.8,135.3,259.4,134.7,256.1',
            // 135
            'M133.1,245.8l-15.2,1.6c0.4,4,1.1,7.9,1.9,11.8l14.9-3.2C134,252.7,133.5,249.3,133.1,245.8',
            // 136
            'M132.6,235.8c0-0.1,0-0.1,0-0.2h-8h-7.3c0,0.1,0,0.1,0,0.2c0,3.9,0.2,7.8,0.6,11.7l15.2-1.6 C132.7,242.5,132.6,239.1,132.6,235.8',
            // 137
            'M141,275.7c1.3,3,2.9,6,4.5,8.8l12.9-7.3l0.7-0.4c-1.4-2.4-2.6-4.9-3.7-7.4L141,275.7z',
            // 138
            'M152.1,261.2l-14.8,4.8c1.1,3.3,2.3,6.5,3.7,9.7l14.3-6.4C154.1,266.8,153,264.1,152.1,261.2',
            // 139
            'M149.9,252.8l-15.2,3.3c0.7,3.4,1.6,6.8,2.7,10l14.8-4.8C151.2,258.5,150.5,255.7,149.9,252.8',
            // 140
            'M148.5,244.2l-15.5,1.6c0.3,3.5,0.9,6.8,1.6,10.3l15.2-3.3C149.4,250,148.9,247.1,148.5,244.2',
            // 141
            'M148.1,235.8c0-0.1,0-0.1,0-0.2H147h-14.5c0,0.1,0,0.1,0,0.2c0,3.4,0.2,6.8,0.5,10l15.5-1.6 C148.3,241.4,148.1,238.6,148.1,235.8',
            // 142
            'M154.4,267.4c1.3,3.3,2.9,6.4,4.6,9.4l13.4-7.7c-1.4-2.4-2.6-5-3.7-7.7L154.4,267.4z',
            // 143
            'M165.8,253.1l-14.9,4c1,3.5,2.1,7,3.5,10.4l14.3-6C167.5,258.8,166.6,255.9,165.8,253.1',
            // 144
            'M164.1,244.3l-15.3,2c0.5,3.7,1.1,7.3,2.1,10.7l14.9-4C165.1,250.2,164.5,247.3,164.1,244.3',
            // 145
            'M163.6,235.8c0-0.1,0-0.1,0-0.2h-15.5c0,0.1,0,0.1,0,0.2c0,3.6,0.2,7.1,0.7,10.6l15.3-2 C163.8,241.6,163.6,238.6,163.6,235.8',
            // 146
            'M167.6,258.7c1.3,3.6,2.9,7.1,4.8,10.4l5.5-3.2l7.2-4.2c-1.5-2.6-2.7-5.3-3.7-8.2L167.6,258.7z',
            // 147
            'M179,244.7l-14.3,2.5c0.7,3.9,1.7,7.7,3,11.4l13.8-5C180.3,250.9,179.5,247.8,179,244.7',
            // 148
            'M178.1,235.6h-8.5h-6c0,0.1,0,0.1,0,0.2c0,3.9,0.3,7.7,1,11.5l14.3-2.5C178.5,241.8,178.1,238.7,178.1,235.6',
            // 149
            'M194,245.6C194,245.5,194,245.5,194,245.6l-14.1,3.7c1.2,4.4,2.9,8.6,5.1,12.6l12.4-7.1c-0.7-1.5-1.3-3-1.9-4.5 C195,248.7,194.5,247.1,194,245.6',
            // 150
            'M192.9,240.7c-0.3-1.6-0.6-3.3-0.7-5.1h-14c0,4.7,0.7,9.3,1.8,13.6l14.1-3.7C193.6,243.9,193.2,242.3,192.9,240.7',
          ].map((d, index) => (
            <path key={index} className={getSeatClass(seatData, lowlightSet, index + 1)} d={d} />
          ))}
        </g>
        <polygon
          className={toggleFill(eq(highlight, 'interruptiemicrofoons'), fillColor)}
          points="250.4,250.3 218.3,237.5 214.7,246.1 250.2,260.2 285.7,246.4 282.1,237.9 "
        />
        <path
          className={toggleFill(eq(highlight, 'kabinet'), fillColor)}
          d="M302.8,216.4l-39.3-37.1l6.6-6.6l45.8,43.7C315.9,216.4,302.8,216.4,302.8,216.4z M347.5,216.1l-54.9-54l-7.2,7.2l48.6,46.8 H347.5z"
        />
        <rect
          className={toggleFill(eq(highlight, 'verslagleggers'), fillColor)}
          x="255.2"
          y="207.9"
          transform="matrix(0.708 -0.7062 0.7062 0.708 -76.1379 246.5253)"
          width="9.6"
          height="14.8"
        />
        <rect
          className={toggleFill(eq(highlight, 'sprekersstoel'), fillColor)}
          x="233.1"
          y="166.4"
          transform="matrix(0.7081 -0.7061 0.7061 0.7081 -51.3818 217.8881)"
          width="9.6"
          height="9.4"
        />
        <rect
          className={toggleFill(eq(highlight, 'griffier-medewerkers-griffie'), fillColor)}
          x="197.3"
          y="202.5"
          transform="matrix(0.7081 -0.7061 0.7061 0.7081 -87.3511 203.1772)"
          width="9.6"
          height="9.4"
        />
        <rect
          className={toggleFill(eq(highlight, 'voorzitter'), fillColor)}
          x="205.9"
          y="193.9"
          transform="matrix(0.7081 -0.7061 0.7061 0.7081 -78.6887 206.7642)"
          width="9.6"
          height="9.4"
        />
        <rect
          className={toggleFill(eq(highlight, 'griffier-medewerkers-griffie'), fillColor)}
          x="214.6"
          y="185.3"
          transform="matrix(0.7081 -0.7061 0.7061 0.7081 -70.092 210.3654)"
          width="9.6"
          height="9.4"
        />
        <rect
          className={toggleFill(eq(highlight, 'griffier-medewerkers-griffie'), fillColor)}
          x="181.1"
          y="176.5"
          transform="matrix(0.7081 -0.7061 0.7061 0.7081 -70.2542 192.5107)"
          width="33.3"
          height="9.4"
        />
        <polygon className={toggleFill(false, fillColor)} points="183.8,201.9 177.2,208.5 164,208.5 177.2,195.2 " />
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicPlenair', render);
