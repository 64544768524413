/**
 * Get the deeplink from the given notification
 * @param notification
 * @returns {string}
 */
export const getDeeplinkFromNotification = (notification) => {
  if (!notification) {
    return '';
  }

  if (cordova.platformId.toLowerCase() === 'ios') {
    return notification.data?.deeplink || notification.data?.data?.pinpoint.deeplink || notification.data?.url || '';
  }

  return notification.data?.['pinpoint.deeplink'] || notification.data?.url || '';
};

/**
 * Return the path parameters from the given deeplink
 * @param deeplink
 * @return {{date: *, debateSlug: *, locationSlug: *, page: *, categorySlug: *}}
 */
export const getDeeplinkParameters = (deeplink) => {
  const [, date, categorySlug, locationSlug, debateSlug, page] =
    (deeplink || '').match(/debatdirect:\/\/(\d{4}-\d{2}-\d{2})\/([\w-]+)\/([\w-]+)\/([\w-]+)\/?([\w-]+)/) || [];

  return {
    date,
    categorySlug,
    locationSlug,
    debateSlug,
    page,
  };
};

/**
 * Return the pathname from the given deeplink
 * @param {string} deeplink
 * @returns {string}
 */
export const getPathnameFromDeeplink = (deeplink) => {
  return deeplink ? deeplink.replace('debatdirect://', '/') : '';
};
