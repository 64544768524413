import React from 'react';

import createIcon from './createIcon';

export default createIcon(
  '0 0 24 24',
  <g>
    <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
  </g>,
);
