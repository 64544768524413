import { structure } from '../../core';

const VideoServiceFactory = function () {
  const events = [
    'initialized',
    'playing',
    'pause',
    'timeupdate',
    'durationchange',
    'ended',
    'loadeddata',
    'waiting',
    'seeked',
    'seeking',
    'ratechange',
    'representationchange',
    'segmentnotfound',
    'activequalitychanged',
    'targetqualitychanged',
    'texttrackadd',
    'texttrackremove',
    'texttrackchange',
    'videotrack',
    'readystatechange',
    'volumechange',
    'chromecaststatechange',
    'airplaystatechange',
    'canplay',
  ];

  let player = null,
    eventListeners = [];

  function onPlayerEvent(event) {
    structure
      .cursor(['ui', 'video'])
      .update('isPlaying', () => !player.paused)
      .update('isSeeking', (seeking) => (seeking ? event.type !== 'seeked' : event.type === 'seeking' && !player.paused));
    dispatchEvent(event);
  }

  function dispatchEvent(event) {
    eventListeners.filter((listener) => listener.type === event.type).forEach((listener) => listener.callback.call(this, event));
  }

  function bindAllListeners() {
    if (player) {
      events.forEach((type) => player.addEventListener(type, onPlayerEvent));
    }
  }

  function unbindAllListeners() {
    if (player) {
      events.forEach((type) => player.removeEventListener(type, onPlayerEvent));
    }
  }

  function on(evt, fn) {
    if (eventListeners.findIndex((event) => event.type === evt && event.callback === fn) !== -1) {
      if (import.meta.env.DEV) {
        console.log('prevent adding duplicate event listener to video service', evt, fn);
      }

      return;
    }

    eventListeners.push({
      type: evt,
      callback: fn,
    });
  }

  function off(evt, fn) {
    const index = eventListeners.findIndex((event) => event.type === evt && event.callback === fn);

    if (index >= 0) {
      eventListeners.splice(index, 1);
    }
  }

  function setPlayer(_player) {
    player = _player;
    bindAllListeners();

    eventListeners.filter((listener) => listener.type === 'player').forEach((listener) => listener.callback.call(this, { type: 'player' }));
  }

  function getPlayer() {
    return player;
  }

  function unsetPlayer() {
    unbindAllListeners();

    structure.cursor(['ui', 'video']).update('isPlaying', () => false);

    player = null;
  }

  function updatePlayRequested(value) {
    structure.cursor(['ui', 'video']).update('playRequested', () => value);
  }

  function play() {
    updatePlayRequested(true);

    if (player) {
      // when loading a live stream it sometimes won't start, setting current time fixes this.
      if (player.currentTime === Infinity) {
        player.currentTime = player.seekable.end(0);
      }

      player.prepareWithUserAction();
      player.play();
    }
  }

  function playFromStart() {
    updatePlayRequested(true);

    if (!player) {
      return;
    }

    player.currentTime = 0;

    if (player.paused) {
      player.play();
    }
  }

  function playLive() {
    updatePlayRequested(true);

    if (!player) {
      return;
    }

    if (player.seekable.length > 0) {
      player.currentTime = player.seekable.end(0);
    }

    if (player.paused) {
      player.play();
    }
  }

  function pause() {
    if (!player) {
      return;
    }

    player.pause();
  }

  function seek(position) {
    if (!player) {
      return;
    }

    player.currentTime = position;
  }

  function getDuration() {
    if (!player) {
      return 0;
    }

    return player.duration;
  }

  function getCurrentProgramDateTime() {
    if (!player) {
      return null;
    }

    return player.currentProgramDateTime;
  }

  function getSources() {
    if (!player) {
      return null;
    }

    return player.getSources();
  }

  function getPosition() {
    if (!player) {
      return 0;
    }

    return player.currentTime;
  }

  function getMuted() {
    if (!player) {
      return false;
    }

    return player.muted;
  }

  function setMuted(to) {
    if (!player) {
      return false;
    }

    player.muted = to;
  }

  function getVolume() {
    if (!player) {
      return;
    }

    return player.volume;
  }

  function setVolume(volume) {
    if (!player) {
      return;
    }

    player.volume = volume;
  }

  function canSwitchPlayers() {
    if (!player) {
      return false;
    }

    // If player is still seeking after a player switch, we don't want the user to switch back.
    // This throttling helps stability.
    if (player.isSeekingAfterPlayerSwitch) {
      return false;
    }

    return true;
  }

  function keyboardSeek(direction) {
    if (!player) {
      return;
    }

    const isPlaying = structure.cursor(['ui', 'video']).get('isPlaying');

    if (!isPlaying) {
      return;
    }

    const newPosition = getPosition() + direction * 30;

    seek(newPosition);
  }

  function resumeCastSession() {
    if (!player) return;

    player.resumeCastSession();
  }

  return {
    setPlayer,
    getPlayer,
    unsetPlayer,
    play,
    playFromStart,
    playLive,
    updatePlayRequested,
    canSwitchPlayers,
    pause,
    seek,
    keyboardSeek,
    getDuration,
    getCurrentProgramDateTime,
    getSources,
    getPosition,
    getMuted,
    setMuted,
    getVolume,
    setVolume,
    resumeCastSession,
    on,
    off,
  };
};

export default VideoServiceFactory;
