import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

/**
 * Renders a notification component
 * @param  {object} props
 * @return {React.Component}
 */
export default component(({ children, className, onDismiss, ...rest }) => {
  const isIos = window.cordova && window.cordova.platformId.toLowerCase() === 'ios';

  const handleCloseButtonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onDismiss();
  };

  const props = {
    ...rest,
    className: classNames('Notification', className),
    role: 'alert',
    'aria-live': 'polite',
  };

  return (
    <div {...props}>
      {isIos ? (
        <Button className="Button--close" onClick={handleCloseButtonClicked} title="Notificatie sluiten" aria-label="Sluit notificatie">
          <Icon name="close" className="u-inline" width={32} height={32} />
        </Button>
      ) : null}
      <div className="Notification-content u-flex u-row">{children}</div>
    </div>
  );
});
