class ModalManager {
  modals = [];
  containers = [];
  visibleModals = [];

  constructor() {
    this.rootElement = document.createElement('div');
    this.rootElement.className = 'Modals';

    document.body.appendChild(this.rootElement);

    document.addEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp = (event) => {
    if (event.which !== 27 || !this.visibleModals.length) {
      return;
    }

    // close top most modal
    this.visibleModals[0].props.onClose();
  };

  handleBackButtonPress = () => {
    // close top most modal
    this.visibleModals[0].props.onClose();
  };

  add(modal) {
    let modalIndex = this.modals.indexOf(modal);

    if (modalIndex === -1) {
      modalIndex = this.modals.length;

      this.modals.push(modal);
      this.containers[modalIndex] = document.createElement('div');

      this.rootElement.appendChild(this.containers[modalIndex]);
    }

    return this.containers[modalIndex];
  }

  remove(modal) {
    let modalIndex = this.modals.indexOf(modal);

    if (modalIndex === -1) {
      return;
    }

    this.rootElement.removeChild(this.containers[modalIndex]);

    this.containers.splice(modalIndex, 1);
    this.modals.splice(modalIndex, 1);
  }

  addVisibleModal(modal) {
    this.visibleModals.unshift(modal);

    if (window.cordova) {
      document.addEventListener('backButton', this.handleBackButtonPress);
    }
  }

  removeVisibleModal(modal) {
    this.visibleModals.splice(
      this.visibleModals.findIndex((value) => value === modal),
      1,
    );

    if (window.cordova && !this.visibleModals.length) {
      document.removeEventListener('backButton', this.handleBackButtonPress);
    }
  }
}

export default new ModalManager();
