/**
 * Swipe detector.
 */
class SwipeDetector {
  static LEFT() {
    return 'left';
  }

  static RIGHT() {
    return 'right';
  }

  /**
   * Constructor.
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._element = element;

    this._downHandler = this._handleDown.bind(this);
    this._moveHandler = this._handleMove.bind(this);
    this._upHandler = this._handleUp.bind(this);

    this._swipeState = null;
    this.onSwipe = null;
  }

  /**
   * Initialize.
   */
  init() {
    this._setListeners(true);
  }

  /**
   * Dispose.
   */
  dispose() {
    this._swipeState = null;
    this.onSwipe = null;

    this._setListeners(false);
  }

  /**
   * Update element.
   * @param {HTMLElement} element
   */
  updateElement(element) {
    if (element === this._element) {
      return;
    }

    this._setListeners(false);
    this._element = element;
    this._setListeners(true);
  }

  /**
   * Handle touch start.
   * @param {TouchEvent} event
   * @private
   */
  _handleDown(event) {
    if (this._swipeState || event.touches.length > 1) {
      this._swipeState = null;

      return;
    }

    const touch = event.touches[0];

    this._swipeState = {
      start: {
        clientX: touch.clientX,
        clientY: touch.clientY,
      },
    };
  }

  /**
   * Handle touch move.
   * @param {TouchEvent} event
   * @private
   */
  _handleMove(event) {
    if (!this._swipeState) {
      return;
    }

    if (event.touches.length > 1) {
      this._swipeState = null;
    }
  }

  /**
   * Handle touch end.
   * @param {TouchEvent} event
   * @private
   */
  _handleUp(event) {
    const state = this._swipeState;

    this._swipeState = null;

    if (!state) {
      return;
    }

    if (event.type !== 'touchend') {
      return;
    }

    const start = state.start,
      touch = event.changedTouches[0],
      xDiff = touch.clientX - start.clientX,
      yDiff = touch.clientY - start.clientY,
      xDiffAbs = Math.abs(xDiff),
      yDiffAbs = Math.abs(yDiff) || 1;

    // Horizontal move has to be at least 100 pixels.
    // Horizontal move has to be at least twice as high as the vertical move.
    if (xDiffAbs < 100 || xDiffAbs / yDiffAbs < 2) {
      return;
    }

    // Prevent triggering of click event after touch end.
    event.preventDefault();

    // Dispatch swipe event.
    if (typeof this.onSwipe === 'function') {
      this.onSwipe({
        target: this,
        direction: xDiff < 0 ? SwipeDetector.LEFT() : SwipeDetector.RIGHT(),
      });
    }
  }

  /**
   * Add or remove listeners.
   * @param {Boolean} activate
   * @private
   */
  _setListeners(activate) {
    if (!this._element) {
      return;
    }

    const method = activate ? 'addEventListener' : 'removeEventListener';

    this._element[method]('touchstart', this._downHandler);
    this._element[method]('touchmove', this._moveHandler);
    this._element[method]('touchend', this._upHandler);
    this._element[method]('touchcancel', this._upHandler);
  }
}

/**
 * Public exports.
 * @type {SwipeDetector}
 */
export default SwipeDetector;
