import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import Notification from '../Notification/Notification';
import InternalLink from '../InternalLink/InternalLink';

/**
 * Renders the notification toggle
 * @param  {object} props
 * @return {React.Component}
 */
export default component(({ title, description, href, image, className, onDismiss, ...rest }) => {
  const props = {
    ...rest,
    onDismiss,
    className: classNames('Notification--debate', className),
  };

  return (
    <Notification {...props}>
      <InternalLink className="Notification-message u-flex u-grow u-col" href={href} onClick={onDismiss}>
        {title ? <strong className="u-bold">{title}</strong> : null}
        {description}
      </InternalLink>
    </Notification>
  );
});
