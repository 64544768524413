import React from 'react';
import { CYCLE_MODE_STOP, TileSlider, type RenderPagination } from '@videodock/tile-slider';
import classNames from 'classnames';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import FeaturedCard from '@debatdirect/core-ts/components/FeaturedCard/FeaturedCard';
import type { AppLocationsDict } from '@debatdirect/core-ts/types/app';
import { useResponsiveSize } from '@debatdirect/core-ts/hooks/useResponsiveSize';
import { ChevronLeft, ChevronRight } from '@debatdirect/core/icons';
import ChevronButton from '@debatdirect/core-ts/components/ChevronButton/ChevronButton';
import { debateUrl } from '@debatdirect/core-ts/utils/routing';

import styles from './FeaturedShelf.module.scss';

type Props = {
  debateLeft: AppAgendaDebate[];
  debatesRight: AppAgendaDebate[];
  locations?: AppLocationsDict;
};

const FeaturedShelf: React.FC<Props> = ({ debateLeft, debatesRight, locations }) => {
  const size = useResponsiveSize();
  const smallDevice = size === 'phone' || size === 'small';

  if (!debateLeft.length) return null;

  const renderPagination: RenderPagination = ({ page, pages, slideToPage }) => {
    const items = Array.from({ length: pages }, (_, pageIndex) => pageIndex);
    return (
      <>
        <div aria-live="polite" className="srOnly">
          {`Pagina ${page + 1} van ${pages}`}
        </div>
        <nav className={styles.dots}>
          <ul>
            {items.map((current) => (
              <li key={current}>
                <button
                  onClick={() => slideToPage(current)}
                  className={classNames(styles.dot, { [styles.active]: page === current })}
                  aria-current={page === current || undefined}
                >
                  {current + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </>
    );
  };

  return (
    <section aria-labelledby="title-featured" className={styles.shelf}>
      <h2 className="srOnly" id="title-featured">
        Uitgelicht
      </h2>
      {smallDevice ? (
        <TileSlider<AppAgendaDebate>
          className={styles.slider}
          items={[...debateLeft, ...debatesRight].slice(0, 3)}
          tilesToShow={1}
          spacing={16}
          pageStep={'tile'}
          showControls={true}
          cycleMode={CYCLE_MODE_STOP}
          renderLeftControl={({ onClick, disabled }) => (
            <ChevronButton icon={<ChevronLeft aria-hidden={true} />} title={'Vorige slide'} onClick={!disabled ? onClick : undefined} disabled={disabled} />
          )}
          renderRightControl={({ onClick, disabled }) => (
            <ChevronButton icon={<ChevronRight aria-hidden={true} />} title={'Volgende slide'} onClick={!disabled ? onClick : undefined} disabled={disabled} />
          )}
          renderTile={({ item, isVisible }) => {
            const location = locations?.[item.locationId];
            const cardLink = debateUrl(item, location);
            return <FeaturedCard debate={item} location={location} link={cardLink} tabIndex={isVisible ? undefined : -1} />;
          }}
          renderPagination={renderPagination}
        />
      ) : (
        <>
          <FeaturedCard
            debate={debateLeft[0]}
            location={locations?.[debateLeft[0].locationId]}
            className={styles.leftCard}
            link={debateUrl(debateLeft[0], locations?.[debateLeft[0].locationId])}
          />
          {debatesRight[0] && (
            <FeaturedCard
              debate={debatesRight[0]}
              location={locations?.[debatesRight[0].locationId]}
              className={styles.rightTopCard}
              link={debateUrl(debatesRight[0], locations?.[debatesRight[0].locationId])}
            />
          )}
          {debatesRight[1] && (
            <FeaturedCard
              debate={debatesRight[1]}
              location={locations?.[debatesRight[1].locationId]}
              className={styles.rightBottomCard}
              link={debateUrl(debatesRight[1], locations?.[debatesRight[1].locationId])}
            />
          )}
        </>
      )}
    </section>
  );
};

export default FeaturedShelf;
