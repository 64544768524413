import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import OnClickOutsideCreator from '../OnClickOutside/OnClickOutside';
import SwipeDetector from '../../utils/lib/SwipeDetector';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import Icon from '../../components/Icon/Icon';
import Image from '../../components/Image/Image';
import Button from '../../components/Button/Button';

const OnClickOutside = OnClickOutsideCreator.withConfig({ ignoreClass: 'Link--infoPanel' }),
  /**
   * The component definition
   * @type {Object}
   */
  definition = {
    asideRef: createRef(),

    contextTypes: {
      getService: PropTypes.func.isRequired,
    },

    mixins: [OnClickOutside],

    propTypes: {
      infoPanel: PropTypes.object.isRequired,
    },

    componentDidMount: function () {
      this._setSwipeDetector();
    },

    componentWillUnmount: function () {
      if (this._swipeDetector) {
        this._swipeDetector.dispose();
        this._swipeDetector = null;
      }
    },

    /**
     * Set swipe detector.
     * @private
     */
    _setSwipeDetector: function () {
      const container = this.asideRef.current;

      if (this._swipeDetector) {
        return;
      }

      this._swipeDetector = new SwipeDetector(container);
      this._swipeDetector.onSwipe = this._handleSwipe;
      this._swipeDetector.init();
    },

    /**
     * Handle swipe event.
     * @param event
     * @private
     */
    _handleSwipe: function (event) {
      if (event.direction === SwipeDetector.RIGHT()) {
        this.closePanel();
      }
    },

    /**
     * Closes the panel
     */
    closePanel: function () {
      this.context.getService('info-panel').hide();
    },

    handleClickOutside: function () {
      this.closePanel();
    },

    getClickOutsideElement: function () {
      return this.asideRef.current;
    },
  },
  /**
   * The TerminologyPanel component
   * @return {React.Component}       An instance of the TerminologyPanel component
   */
  render = function ({ infoPanel }) {
    const props = infoPanel.get('props'),
      title = props.get('title'),
      description = props.get('description'),
      imageUrl = props.get('imageUrl'),
      imageAlt = '', // Intentionally empty because the image is decorative
      className = classNames(
        {
          'u-pt20': !!imageUrl,
          'u-pt70': !imageUrl,
        },
        'u-pl20 u-pb20 u-pr20 u-bgWhite',
      ),
      additionalContent = props.get('additionalContent') || null,
      additionalElement = typeof additionalContent === 'function' ? additionalContent() : additionalContent;

    /* eslint-disable no-extra-parens */
    return (
      <aside className="InfoPanel u-bgWhite" aria-labelledby="terminology-panel-title" ref={this.asideRef}>
        <Button className="InfoPanel-closeButton Button Button--close" onClick={this.closePanel} aria-label="Sluit paneel">
          <Icon name="close" />
        </Button>
        <ScrollView bodyProps={{ tabIndex: 0 }}>
          {imageUrl && <Image src={imageUrl} className="InfoPanel-photo" alt={imageAlt} />}
          <div className="InfoPanel-content Explanation">
            <div className={className}>
              <header className="InfoPanel-header Header">
                <h3 className="InfoPanel-heading Heading" id="terminology-panel-title">
                  {title}
                </h3>
              </header>
              {description ? (
                description.split('\n').map((paragraph, idx) => (
                  <p key={idx} className="Text">
                    {paragraph}
                  </p>
                ))
              ) : (
                <p className="Text">Geen omschrijving gevonden</p>
              )}
              {additionalElement}
            </div>
          </div>
        </ScrollView>
      </aside>
    );
    /* eslint-enable no-extra-parens */
  };

export default component.withDefaults({ cursorField: 'cursor' })('TerminologyPanel', definition, render);
