import { type FC } from 'react';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import { useResponsiveSize } from '@debatdirect/core-ts/hooks/useResponsiveSize';
import Button from '@debatdirect/core-ts/components/Button/Button';
import Card from '@debatdirect/core-ts/components/Card/Card';
import ChevronButton from '@debatdirect/core-ts/components/ChevronButton/ChevronButton';
import { CYCLE_MODE_STOP, TileSlider } from '@videodock/tile-slider';
import '@videodock/tile-slider/lib/style.css';
import { ChevronLeft, ChevronRight } from '@debatdirect/core/icons';
import type { AppLocationsDict } from '@debatdirect/core-ts/types/app';
import { slugify } from '@debatdirect/core-ts/utils/formatter';
import { debateUrl } from '@debatdirect/core-ts/utils/routing';

import styles from './Shelf.module.scss';

type Props = {
  title: string;
  subtitle?: string;
  debates?: AppAgendaDebate[];
  locations?: AppLocationsDict;
  link?: string;
  showProgressIndicator?: boolean;
  children?: React.ReactNode;
};

const Shelf: FC<Props> = ({ title, subtitle, debates = [], locations, link, showProgressIndicator, children }) => {
  const tilesToShowMap = { small: 1, phone: 2, tablet: 3, desktop: 4 };
  const size = useResponsiveSize();
  const tilesToShow = tilesToShowMap[size];
  const slugId = `title-${slugify(title)}`;

  if (debates.length === 0 && !children) return null;

  return (
    <section aria-labelledby={slugId}>
      <div className={styles.topbar}>
        <div>
          <h2 className={styles.title} id={slugId}>
            {title}
          </h2>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {link && <Button href={link} label="Bekijk alle" iconRight={ChevronRight} className={styles.button} />}
      </div>
      {children || (
        <TileSlider<AppAgendaDebate>
          className={styles.slider}
          items={debates}
          tilesToShow={tilesToShow}
          spacing={16}
          pageStep={'tile'}
          showControls={true}
          cycleMode={CYCLE_MODE_STOP}
          renderLeftControl={({ onClick, disabled }) => (
            <ChevronButton icon={<ChevronLeft aria-hidden={true} />} title={'Vorige slide'} onClick={!disabled ? onClick : undefined} disabled={disabled} />
          )}
          renderRightControl={({ onClick, disabled }) => (
            <ChevronButton icon={<ChevronRight aria-hidden={true} />} title={'Volgende slide'} onClick={!disabled ? onClick : undefined} disabled={disabled} />
          )}
          renderTile={({ item, isVisible }) => {
            const location = locations?.[item.locationId];
            const cardLink = debateUrl(item, location);
            return (
              <Card
                debate={item}
                key={item.id}
                location={location}
                link={cardLink}
                showProgress={showProgressIndicator}
                tabIndex={isVisible ? undefined : -1}
              />
            );
          }}
          renderPagination={({ page, pages }) => {
            return (
              <div aria-live="polite" className="srOnly">
                {`Pagina ${page + 1} van ${pages}`}
              </div>
            );
          }}
        />
      )}
    </section>
  );
};

export default Shelf;
