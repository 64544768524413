import React, { useState } from 'react';

import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import CircularProgress from '../Progress/CircularProgress';
import TextPanel from '../TextPanel/TextPanel';

const MfaForm = ({ onSubmit, hasError, isSubmitting }) => {
  const [values, setValues] = useState({
    code: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    setValues((current) => ({ ...current, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="Heading">Kamerbeelden Login</h2>
      <p>Er is een verificatiecode per SMS of e-mail verstuurd naar uw apparaat. Vul deze code in om in te loggen.</p>
      <TextField
        className="u-mb20"
        name="code"
        type="text"
        value={values.code}
        label="Verificatiecode"
        onChange={handleChange}
        error={hasError}
        readOnly={isSubmitting}
        required
      />
      {hasError ? (
        <TextPanel variant="error" className="u-mb20">
          De code is verlopen of onjuist. Probeer het opnieuw.
        </TextPanel>
      ) : null}
      <Button className="Button--medium Button--block Button--primary u-mb20" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="Button-icon">
            <CircularProgress size={16} />
          </span>
        ) : null}
        <span className="Button-label">Versturen</span>
      </Button>
    </form>
  );
};

export default MfaForm;
