import React, { Component } from 'react';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import PropTypes from 'prop-types';

import structure from '../../core/lib/structure/structure';

class LocationsSelect extends Component {
  static propTypes = {
    /**
     * Gets called when the value changes
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     * @param {Object} value The selected value
     */
    onChange: PropTypes.func,
  };

  state = {
    current: this.props.defaultValue,
  };

  handleChange = (event) => {
    const { value } = event.currentTarget;

    this.setState(
      {
        current: value,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(event, this.state.current);
        }
      },
    );
  };

  componentDidMount() {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({}, this.state.current);
    }
  }

  renderOption = (option) => {
    return (
      <option value={option.get('id')} key={option.get('id')}>
        {option.get('name')}
      </option>
    );
  };

  render() {
    const { locations, className } = this.props;
    const { current } = this.state;

    return (
      <div className={classNames('NativeSelect', className)}>
        <label htmlFor="select-zaal">Zaal</label>
        <select id="select-zaal" value={current} onChange={this.handleChange}>
          {locations.map(this.renderOption)}
        </select>
      </div>
    );
  }
}

const LocationsSelectComponent = component('DownloadOptionsSelect', (props) => {
  return <LocationsSelect {...props} />;
});

export default observer(
  structure,
  {
    locations: ['data', 'locations'],
  },
  LocationsSelectComponent,
);
