import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import PoliticianImage from '../PoliticianImage/PoliticianImage';

const definition = {
  markerRef: createRef(),

  contextTypes: {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
    pathWith: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  },

  setTimeout: function () {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.timeoutHandler, this.props.timeout);
  },

  handleTimeout: function () {
    const activationAttribute = 'data-user-activated',
      element = this.markerRef.current;

    // If user has activated the element by touchstart/mousemove, element should stay visible.
    if (element.hasAttribute(activationAttribute)) {
      return;
    }

    // If not, hide element.
    element.classList.add('u-hidden');
  },

  handleClick: function () {
    const isEmbedded = this.context.getCursor(['ui', 'isEmbedded']).deref();

    if (isEmbedded) {
      this.openSite();
    } else {
      this.openPoliticianPanel();
    }
  },

  openSite: function () {
    const { getCursor, getService, params, pathWith } = this.context,
      activeDebate = getCursor(['ui']).get('activeDebate').toJS(),
      category = activeDebate?.categoryIds.length ? activeDebate.categoryIds[0] : 'onbekend',
      debateSlug = activeDebate?.slug,
      routeParams = {
        ...params,
        category,
        debate: debateSlug,
        date: getService('date').getAgendaDate(),
      },
      domain = getService('router').getCurrentHttpHost(),
      path = pathWith('debate-markers', routeParams),
      activeEvent = getService('live').getActiveEvent(),
      fullUrl = domain + path + (activeEvent ? '?event=' + activeEvent : '');

    if (!activeDebate) {
      return;
    }

    // Open standard website.
    window.open(fullUrl, '_blank');

    // Pause player.
    getService('video').pause();
  },

  openPoliticianPanel: function () {
    const { getService } = this.context,
      { politicianId } = this.props,
      navBar = document.querySelector('.NavBar');

    if (!politicianId) {
      return;
    }

    // On phones in landscape mode we do not want to show the politician panel.
    // Since fullscreen in this situation is purely CSS based, we use the following approach.
    if (!navBar?.offsetWidth) {
      return;
    }

    getService('info-panel').show('PoliticianPanel', {
      politicianId: politicianId,
      initialHeadingLevel: 2,
    });
  },

  componentDidMount: function () {
    this.timeout = null;
    this.timeoutHandler = this.handleTimeout;

    this.setTimeout();

    this.markerRef.current.classList.remove('u-hidden');
  },

  componentDidUpdate: function (prevProps) {
    // Force showing, even if user has hidden control bar.
    this.markerRef.current.classList.remove('u-hidden');

    if (this.props.politician !== prevProps.politician) {
      this.setTimeout();
    }
  },

  componentWillUnmount: function () {
    // Prevent error messages.
    clearTimeout(this.timeout);
  },

  getDefaultProps: function () {
    return {
      timeout: 10000,
    };
  },
};

/**
 * Renders a live marker
 * @param  {object} props
 * @return {React.Component}
 */
export default component('Marker', definition, function ({ className, politician, title, href, politicianId, ...rest }) {
  const isEmbedded = this.context.getCursor(['ui', 'isEmbedded']).deref(),
    titleAttribute = isEmbedded ? 'Bekijk op de website' : 'Bekijk aanvullende informatie';

  const props = {
    ...rest,
    title: titleAttribute,
    className: classNames('Marker Politician', className, 'u-flex u-rowReversed'),
    onClick: this.handleClick,
    role: 'status',
    'aria-live': 'off',
  };

  return (
    <div {...props} data-control="true" ref={this.markerRef}>
      <div className="u-flex u-col u-centerSelf u-grow">
        <span className="Politician-name">{politician}</span>
        <span className="Politician-title">{title}</span>
      </div>
      <PoliticianImage className="Politician-photo u-flex" politicianId={politicianId} size={100} />
    </div>
  );
});
