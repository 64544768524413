/**
 * Scroll manager.
 */
class ScrollManager {
  /**
   * Constructor.
   * @param {Object} containers
   * @param {Array} pages
   */
  constructor(containers, pages) {
    this._scrollContainer = containers.scrollContainer;
    this._pagesContainer = containers.pagesContainer;
    this._pages = pages;

    this._scrollHandler = this._handleScroll.bind(this);
    this._resizeHandler = this._handleResize.bind(this);

    this._scrollTimes = { all: 0, auto: 0 };
    this._scrollTimer = null;

    this._activePage = null;
  }

  /**
   * Get pages.
   * @returns {Array}
   */
  get pages() {
    return this._pages;
  }

  /**
   * Get currently active page.
   * @returns {ScrollPage}
   */
  get activePage() {
    return this._activePage;
  }

  /**
   * Get last page.
   * @returns {ScrollPage}
   */
  get lastPage() {
    return this._pages[this._pages.length - 1] || null;
  }

  /**
   * Initialize.
   */
  init() {
    this._initPages();
    this._setListeners(true);
  }

  /**
   * Dispose.
   */
  dispose() {
    this._setListeners(false);
    clearInterval(this._scrollTimer);
  }

  /**
   * Set active page.
   * @param {ScrollPage} page
   */
  setActivePage(page) {
    if (this._pages.indexOf(page) === -1) {
      return;
    }

    clearInterval(this._scrollTimer);

    if (this._activePage) {
      this._activePage.clear();
    }

    this._scrollTimes.auto = Date.now();

    this._activePage = page;
    this._activePage.onRendered = () => this._handlePageRender(page);

    this._activePage.render();
  }

  /**
   * Insert new page.
   * @param {ScrollPage} page
   */
  insertPage(page) {
    let pagesContainer = this._pagesContainer;

    pagesContainer.appendChild(page.container);
    page.container.style.display = 'none';

    this._pages.push(page);
  }

  /**
   * Remove a page.
   * @param {ScrollPage} page
   */
  removePage(page) {
    const pagesContainer = this._pagesContainer;
    const pageIndex = this._pages.findIndex((current) => current.key === page.key);

    pagesContainer.removeChild(page.container);

    this._pages.splice(pageIndex, 1);
  }

  /**
   * Scroll to item.
   * @param {AbstractScrollItem} item
   * @param {Boolean} [animated]
   */
  scrollTo(item, animated = false) {
    if (!item || !item.isRendered) {
      return;
    }

    clearInterval(this._scrollTimer);

    const offset = this._getScrollOffset(item);

    if (animated) {
      this._animatedScroll(this._scrollContainer.scrollTop, offset);
    } else {
      this._setScroll(offset);
    }
  }

  /**
   * Check if user is currently scrolling.
   * @returns {Boolean}
   */
  isUserScrolling() {
    const scrollTimes = this._scrollTimes,
      now = Date.now();

    // If the last scroll has been more than 4 seconds ago, user isn't scrolling.
    if (now - scrollTimes.all > 4000) {
      return false;
    }

    // If this condition is true, the last scroll was most likely automated.
    if (scrollTimes.all - scrollTimes.auto < 500) {
      return false;
    }

    return true;
  }

  /**
   * Initialize pages.
   * @private
   */
  _initPages() {
    let pagesContainer = this._pagesContainer,
      pages = this._pages;

    for (let page of pages) {
      pagesContainer.appendChild(page.container);
      page.container.style.display = 'none';
    }
  }

  /**
   * Handle event that scroll page triggers when rendering is ready.
   * @param {ScrollPage} page
   * @private
   */
  _handlePageRender(page) {
    if (page !== this._activePage) {
      return;
    }

    if (page.activeItem) {
      this.scrollTo(page.activeItem, false);
    } else {
      this.scrollTo(page.items[0], false);
    }
  }

  /**
   * Set listeners.
   * @param {Boolean} activate
   * @private
   */
  _setListeners(activate) {
    const method = activate ? 'addEventListener' : 'removeEventListener';

    this._scrollContainer[method]('scroll', this._scrollHandler);
    window[method]('resize', this._resizeHandler);
  }

  /**
   * Handle resize.
   * @private
   */
  _handleResize() {
    // A resize can trigger a scroll event. We consider this not to be an explicit user scroll.
    this._scrollTimes.auto = Date.now();
  }

  /**
   * Handle scroll event (this is triggered on user and automated scrolls).
   * @private
   */
  _handleScroll() {
    this._scrollTimes.all = Date.now();
  }

  /**
   * Set scroll position.
   * @param {Number} scrollTop
   * @private
   */
  _setScroll(scrollTop) {
    this._scrollContainer.scrollTop = scrollTop;
    this._scrollTimes.auto = Date.now();
  }

  /**
   * Get scroll offset
   * @param item
   * @returns {Number}
   * @private
   */
  _getScrollOffset(item) {
    const itemIndex = item.page.getItemIndex(item);

    // Do not scroll on the upper two items.
    if (itemIndex < 2) {
      return 0;
    }

    const itemRect = item.getBoundingClientRect(),
      itemTop = itemRect.top,
      containerTop = this._scrollContainer.getBoundingClientRect().top,
      itemOffset = itemTop - containerTop + this._scrollContainer.scrollTop;

    let offsetCorrection = 0;

    // We want to show two items above the active item, so we add the heights of the two items above it.
    for (let i = itemIndex - 1; i >= 0 && i > itemIndex - 3; i--) {
      offsetCorrection += item.page.items[i].offsetHeight;
    }

    return itemOffset - offsetCorrection;
  }

  /**
   * Perform an animated scroll.
   * @param {Number} lastScrollTop
   * @param {Number} targetScrollTop
   * @private
   */
  _animatedScroll(lastScrollTop, targetScrollTop) {
    const start = Date.now(),
      difference = Math.abs(lastScrollTop - targetScrollTop),
      operator = targetScrollTop > lastScrollTop ? 1 : -1;

    // Duration never less than 500 ms and never more than 1000 ms.
    let duration = Math.max(difference / 5, 500);
    duration = Math.min(duration, 1000);

    this._scrollTimer = setInterval(() => {
      let time = Date.now() - start,
        value = this._easeInOutQuart(time, 0, difference, duration);

      this._setScroll(lastScrollTop + operator * value);

      if (time >= duration) {
        clearInterval(this._scrollTimer);
      }
    }, 1000 / 30);
  }

  /**
   * Perform calculation for ease-in-out animation.
   * Especially on longer animations ease-in-out creates the best effect.
   * @param {Number} currentTime
   * @param {Number} startValue
   * @param {Number} endValue
   * @param {Number} duration
   * @returns {Number}
   * @private
   */
  _easeInOutQuart(currentTime, startValue, endValue, duration) {
    if ((currentTime /= duration / 2) < 1) {
      return (endValue / 2) * currentTime * currentTime * currentTime * currentTime + startValue;
    }

    const result = (-endValue / 2) * ((currentTime -= 2) * currentTime * currentTime * currentTime - 2) + startValue;

    return result;
  }
}

/**
 * Public exports.
 * @type {ScrollManager}
 */
export default ScrollManager;
