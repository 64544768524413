import React, { FC } from 'react';
import { navigate } from '@debatdirect/core-ts/utils/routing';

type Props = {
  href: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const InternalLink: FC<Props> = ({ children, href, onClick, ...rest }) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (href) {
      navigate(href);
    }
    onClick?.(event);
  };
  return (
    <a href={href} onClick={handleClick} {...rest}>
      {children}
    </a>
  );
};

export default InternalLink;
