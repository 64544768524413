import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import component from 'omniscient';

const definition = {
  placeholderRef: createRef(),
  frontRef: createRef(),
  backRef: createRef(),

  preloadPlaceholder: function () {
    const image = document.createElement('img');

    this.placeholderRef.current.classList.add('is-loading');

    setTimeout(() => {
      image.onload = () => {
        if (this.placeholderRef.current) {
          this.backRef.current.style.backgroundImage = `url(${this.props.placeholder})`;
          this.placeholderRef.current.classList.remove('is-loading');

          setTimeout(() => {
            if (!this.frontRef.current) {
              return;
            }

            this.frontRef.current.style.backgroundImage = `url(${this.props.placeholder})`;
          }, 200);
        }
      };

      image.src = this.props.placeholder;
    }, 200);
  },

  componentDidMount: function () {
    this.preloadPlaceholder();
  },

  componentDidUpdate: function (prevProps) {
    if (prevProps.placeholder !== this.props.placeholder) {
      this.preloadPlaceholder();
    }
  },

  propTypes: {
    placeholder: PropTypes.string,
  },
};

export default component(definition, function ({ className }) {
  const props = {
    className: classNames('Video-placeholder is-loading', className),
  };

  return (
    <div {...props} ref={this.placeholderRef}>
      <div className="Video-placeholderBack" ref={this.backRef} />
      <div className="Video-placeholderFront" ref={this.frontRef} />
    </div>
  );
});
