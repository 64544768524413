import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import { withRouter } from 'react-router';

import Link from '../Link/Link';

const definition = {
    contextTypes: {
      navigate: PropTypes.func.isRequired,
    },

    handleClick: function (callback) {
      const self = this;

      return function (event) {
        event.preventDefault();

        if (self.props.href) {
          self.context.navigate(self.props.href);
        }

        if (callback) {
          callback(event);
        }
      };
    },

    shouldComponentUpdate: () => true,
  },
  /**
   * Renders an in-app link
   * @param  {object} props
   * @return {React.Component} The wrapped link component
   */
  render = function ({ children, className, onClick, activeClassName, onlyActiveOnIndex, router, params, routes, location, ...rest }) {
    const props = {
      ...rest,
      className: className ? 'Link--internal ' + className : 'Link--internal',
      onClick: this.handleClick(onClick),
    };

    if (activeClassName && rest.href && router.isActive(rest.href, !!onlyActiveOnIndex)) {
      props.className += ' ' + activeClassName;
    }

    return React.createElement(Link, props, children);
  };

export default withRouter(component('InternalLink', definition, render));
