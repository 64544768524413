import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { withDefaults } from 'omniscient';

import OnClickOutside from '../../OnClickOutside/OnClickOutside';
import Prompt from '../../Prompt/Prompt';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';

const component = withDefaults({ cursorField: 'cursor' }),
  definition = {
    promptRef: createRef(),

    contextTypes: {
      getService: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
    },

    mixins: [OnClickOutside],

    /**
     * Close prompt with given status
     */
    closePrompt: function () {
      const { getService } = this.context;
      const { notification } = this.props;

      // hide notification
      notification.set('visible', false);

      // mark the notification as seen (prevent showing it multiple times)
      getService('notifications').markAsSeen(notification.get('id'));
    },

    /**
     * Close button handler
     */
    closePromptHandler: function () {
      this.closePrompt();
    },

    /**
     * Click outside handler
     */
    handleClickOutside: function () {
      this.closePrompt();
    },

    getClickOutsideElement: function () {
      return this.promptRef.current;
    },
  },
  render = function ({ notification }) {
    const message = notification.get('message');

    return (
      <Prompt alignment="centerCenter" visible={true} role="alertdialog" aria-live="assertive" forwardRef={this.promptRef.current}>
        <div>
          <h2 className="Heading Prompt-heading u-flex">
            <Icon name="warning" className="u-inline" width={20} height={20} aria-hidden="true" /> Incident
          </h2>
          <p className="Text Prompt-description">{message}</p>
        </div>
        <div className="Prompt-actions">
          <Button className="Prompt-action Prompt-action--primary" onClick={this.closePromptHandler}>
            <div className="Button-label">Ga verder</div>
          </Button>
        </div>
      </Prompt>
    );
  };

export default component('IncidentPrompt', definition, render);
