import * as logger from '../../utils/logger';

export default class EventDispatcher {
  _eventListeners = [];

  addEventListener = (evt, fn) => {
    this._eventListeners.push({
      type: evt,
      callback: fn,
    });
  };

  removeEventListener = (evt, fn) => {
    const index = this._eventListeners.findIndex((event) => event.type === evt && event.callback === fn);

    if (index >= 0) {
      this._eventListeners.splice(index, 1);
    }
  };

  removeAllEventListeners = () => {
    this._eventListeners = [];
  };

  dispatchEvent = (event) => {
    this._eventListeners
      .filter((listener) => listener.type === event.type)
      .forEach((listener) => {
        try {
          listener.callback.call(this, event);
        } catch (error) {
          logger.error('Error in event handler', listener, error);
        }
      });
  };
}
