import moment from 'moment';

/**
 * Date service factory.
 * @param {Object} reference - Main data model.
 * @param {Object} config
 * @returns {Object}
 */
const DateServiceFactory = function (reference, { fromDate }) {
  const service = {
    /**
     * Get date from path of current URL.
     * @returns {String|null}
     */
    getDateFromPath: function () {
      const pathMatch = /\/(\d{4}-\d{2}-\d{2})(\/|$)/.exec(window.location.pathname);

      if (!pathMatch) {
        return null;
      }

      const dateString = pathMatch[1],
        date = moment(dateString);

      return date.isValid() ? dateString : null;
    },

    /**
     * Check if given date string is a valid path date (yyyy-mm-dd).
     * @param {String} dateString
     * @returns {Boolean}
     */
    isValidPathDate: function (dateString) {
      if (typeof dateString !== 'string' || !/\d{4}-\d{2}-\d{2}/.test(dateString)) {
        return false;
      }

      const date = moment(dateString);

      return date.isValid();
    },

    /**
     * Create week dates for given date.
     */
    createWeekDates: (function () {
      const cache = {};

      return function (dateString) {
        if (Object.prototype.hasOwnProperty.call(cache, dateString)) {
          return cache[dateString];
        }

        let date = moment(dateString),
          day = date.toDate().getDay() || 7;

        while (day > 1) {
          date.subtract(1, 'd');
          day = date.toDate().getDay();
        }

        const result = [];

        while (result.length < 7) {
          result.push(date.format('YYYY-MM-DD'));
          date.add(1, 'd');
        }

        cache[dateString] = result;

        return result;
      };
    })(),

    /**
     * Check if date is within allowed time window.
     * @param {String} agendaDateString
     * @returns {Boolean}
     */
    isWithinAllowedTimeWindow: function (agendaDateString) {
      const agendaDate = moment(agendaDateString);

      return agendaDate.isValid() && agendaDate.isSameOrAfter(moment(fromDate));
    },

    /**
     * Returns true if the given date is in the weekend (saturday or sunday)
     * @param date
     * @returns {*|boolean}
     */
    isWeekend: function (date) {
      return date && moment(date).weekday() >= 5;
    },

    /**
     * Check if we have debates in the weekend.
     * @param {String} agendaDate
     * @param {String} [currentDate]
     * @returns {Boolean}
     */
    hasWeekendDebates: function (agendaDate, currentDate) {
      if (!service.isValidPathDate(agendaDate)) {
        return false;
      }

      // if the requested date or the current day is in the weekend
      if (service.isWeekend(currentDate) || service.isWeekend(agendaDate)) {
        return true;
      }

      const weekDates = service.createWeekDates(agendaDate),
        agendaOverview = reference.cursor(['data', 'persistent', 'agendaOverview', 'days']).toJS();

      for (let i = 5; i < 7; i++) {
        const date = weekDates[i],
          dateAgenda = agendaOverview[date] || null,
          debateCount = typeof dateAgenda?.debateCount === 'number' ? dateAgenda.debateCount : 0;

        if (debateCount > 0) {
          return true;
        }
      }

      return false;
    },

    /**
     * Get difference, measured in days, between agenda date and current date.
     * @param {String} [_agendaDate]
     * @returns {Number}
     */
    getAgendaDateDifference: function (_agendaDate) {
      const currentDateString = reference.cursor(['data', 'currentDate']).deref(),
        agendaDateString = _agendaDate || reference.cursor(['data', 'date']).deref();

      if (!currentDateString || !agendaDateString) {
        return NaN;
      }

      const currentDate = moment(currentDateString),
        agendaDate = moment(agendaDateString);

      if (!currentDate.isValid() || !agendaDate.isValid()) {
        return NaN;
      }

      return agendaDate.diff(currentDate, 'd');
    },

    /**
     * Get agenda date.
     * @returns {String}
     */
    getAgendaDate: function () {
      return reference.cursor(['data', 'date']).deref();
    },

    /**
     * Get date of today.
     * @returns {String}
     */
    getTodayDate: function () {
      return reference.cursor(['data', 'currentDate']).deref();
    },

    /**
     * Check if there is a recess during the week.
     */
    isRecessWeek: function () {
      const weekDates = service.createWeekDates(this.getAgendaDate()),
        agendaOverview = reference.cursor(['data', 'persistent', 'agendaOverview', 'days']).toJS();

      for (let i = 0; i < weekDates.length; i++) {
        const date = weekDates[i],
          dateAgenda = agendaOverview[date] || null;

        // If a day is missing from overview, we can not reliably determine if there is a recess.
        if (!dateAgenda) {
          return false;
        }

        const debateCount = typeof dateAgenda.debateCount === 'number' ? dateAgenda.debateCount : NaN;

        // If we can not determine the debate count we can not conclude there is a recess.
        // If debate count is larger than zero, there is no recess or there is at least one debate during a recess.
        if (isNaN(debateCount) || debateCount > 0) {
          return false;
        }

        const recessInfo = dateAgenda.recess || null;

        // If day agenda does not contain recess info, we assume there is no recess.
        if (!recessInfo) {
          return false;
        }
      }

      // All weekdays have zero debates and all days contain recess info.
      return true;
    },

    /**
     * Get recess info for date.
     * @param {String} dateString
     * @returns {Object|null}
     */
    getRecessInfoForDate(dateString) {
      const agendaOverview = reference.cursor(['data', 'persistent', 'agendaOverview', 'days']).toJS();
      const dateAgenda = agendaOverview[dateString] || null;

      return dateAgenda?.recess || null;
    },

    /**
     * Check if agenda date is today.
     * @returns {Boolean}
     */
    isToday: function () {
      return service.getAgendaDateDifference() === 0;
    },

    /**
     * Check if agenda date is in the future.
     * @returns {Boolean}
     */
    isFutureDate: function () {
      return service.getAgendaDateDifference() > 0;
    },

    /**
     * Get a user friendly agenda date.
     * @returns {String}
     */
    getUserFriendlyAgendaDate: function (dateString) {
      const dayDiff = service.getAgendaDateDifference(dateString);

      if (isNaN(dayDiff)) {
        return '';
      }

      switch (dayDiff) {
        case 0:
          return 'vandaag';
        case 1:
          return 'morgen';
        case -1:
          return 'gisteren';
        default:
      }

      const date = moment(dateString || reference.cursor(['data', 'date']).deref());

      if (!date.isValid()) {
        return '';
      }

      return date.format('dddd D MMMM YYYY');
    },
  };

  return service;
};

export default DateServiceFactory;
