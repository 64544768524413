import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

export default class SnackBarProvider extends Component {
  static childContextTypes = {
    snackBarProvider: PropTypes.object.isRequired,
  };

  snackBars = [];

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  addSnackBar(instance) {
    if (this.snackBars.indexOf(instance) === -1) {
      this.snackBars.push(instance);
      this.notifySnackBars();
    }
  }

  removeSnackBar(instance) {
    const index = this.snackBars.indexOf(instance);

    if (index !== -1) {
      this.snackBars.splice(index, 1);
      this.notifySnackBars();
    }
  }

  handleResize = debounce(50, () => {
    this.notifySnackBars();
  });

  notifySnackBars = debounce(100, () => {
    let bottom = 0;

    this.snackBars.forEach((instance) => {
      if (instance && typeof instance.setOffset === 'function') {
        instance.setOffset(bottom);

        bottom += instance.getOffsetHeight() + 8;
      }
    });
  });

  getChildContext() {
    return {
      snackBarProvider: this,
    };
  }

  render() {
    return this.props.children;
  }
}
