import React, { Component } from 'react';

export default class LinearIndeterminate extends Component {
  render() {
    return (
      <div className="LinearIndeterminate">
        <div className="LinearIndeterminate-line" />
        <div className="LinearIndeterminate-sub inc" />
        <div className="LinearIndeterminate-sub dec" />
      </div>
    );
  }
}
