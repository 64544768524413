import React, { useState } from 'react';

import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import Link from '../Link/Link';
import CircularProgress from '../Progress/CircularProgress';
import TextPanel from '../TextPanel/TextPanel';

const ForgotPasswordForm = ({ onSubmit, hasError, isSubmitting, onBackToLoginClick }) => {
  const [values, setValues] = useState({
    email: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    setValues((current) => ({ ...current, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="Heading">Wachtwoord vergeten</h2>
      <p>
        Vul uw e-mailadres in en druk op versturen. Wij sturen een code per SMS of e-mail die u op het volgende scherm kunt gebruiken om een nieuw wachtwoord in
        te stellen.
      </p>
      <TextField className="u-mb20" name="email" type="email" value={values.email} label="E-mail" onChange={handleChange} readOnly={isSubmitting} required />
      {hasError ? (
        <TextPanel variant="error" className="u-mb20">
          Het is niet gelukt om uw wachtwoord op te vragen. Probeer het opnieuw.
        </TextPanel>
      ) : null}
      <Button className="Button--medium Button--block Button--primary u-mb20" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="Button-icon">
            <CircularProgress size={16} />
          </span>
        ) : null}
        <span className="Button-label">Versturen</span>
      </Button>
      <Link className="Link--blue" onClick={onBackToLoginClick}>
        Terug naar inloggen
      </Link>
    </form>
  );
};

export default ForgotPasswordForm;
