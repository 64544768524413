import { isWithinInterval, format, sub, startOfWeek, endOfWeek, startOfDay, endOfDay, isSameDay, isToday } from 'date-fns';
import { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import qs from 'qs';

export enum ShelfType {
  //TODAY, // TV only (unused here)
  LIVE,
  SOON,
  EARLIER_TODAY,
  TODAY_PLENARY, // Special shelf
  CONTINUE_WATCHING, // Special shelf
  YESTERDAY,
  //TOMORROW, // TV only (unused here)
  //THIS_WEEK, // TV only (unused here)
  LAST_WEEK,
  //BEFORE_LAST_WEEK, // TV only (unused here)
  EARLIER_THIS_WEEK,
  //LATER_THIS_WEEK, // TV only (unused here)
}

export type TShelf = {
  title: string;
  shelfType: ShelfType;
  debates: AppAgendaDebate[];
  startDate: Date;
  endDate: Date;
  location?: string;
  status?: 'planned' | 'live' | 'vod';
};

export const isLive = (debate: AppAgendaDebate, now = new Date()) => {
  const startTime = debate.startedAt || debate.startsAt;
  const endTime = debate.endedAt || debate.endsAt;
  const todayEnd = endOfDay(now);
  return isWithinInterval(now, { start: startTime, end: endTime || todayEnd });
};

export const hasDebates = (shelves: TShelf[], shelfTypes: ShelfType[]) => {
  return shelves.some((shelf) => shelfTypes.includes(shelf.shelfType) && shelf.debates.length > 0);
};

export const createSearchUrl = (debates: AppAgendaDebate[], location?: string, status?: 'live' | 'vod' | 'planned') => {
  if (!debates.length) return '';
  const filterStatusNames = {
    live: 'live',
    vod: 'geweest',
    planned: 'gepland',
  };
  const statusName = status ? filterStatusNames[status] : undefined;
  const firstDate = format(debates[0].debateDate, 'yyyy-MM-dd');
  const lastDate = format(debates[debates.length - 1].debateDate, 'yyyy-MM-dd');
  const queryString = qs.stringify(
    {
      van: firstDate,
      tot: lastDate,
      locatie: location ? [location] : undefined,
      status: statusName ? [statusName] : undefined,
    },
    { encodeValuesOnly: true },
  );
  return `/search?${queryString}`;
};

export const formatDebateDateRangeStr = (firstDate: Date, lastDate: Date) => {
  if (isToday(firstDate) && isToday(lastDate)) return '';
  const firstDateFormatted = format(firstDate, 'd MMMM');
  const lastDateFormatted = format(lastDate, 'd MMMM');

  if (firstDateFormatted === lastDateFormatted) return firstDateFormatted;

  return `${firstDateFormatted} - ${lastDateFormatted}`;
};

export const filterTodayByLocation = (debates: AppAgendaDebate[], locationId: string, now = new Date()) => {
  return debates.filter((debate) => debate.locationId === locationId).filter((debate) => isSameDay(now, debate.startsAt || debate.startedAt));
};

export const filterFeatured = (debates: AppAgendaDebate[], now = new Date()) => {
  const getRecentVod = () =>
    [...debates].sort((debateA: AppAgendaDebate, debateB: AppAgendaDebate): number => {
      // Vod debates will be sorted by endtime desc
      if (debateA.status === 'vod' && debateB.status === 'vod') {
        return debateB.endedAt.getTime() - debateA.endedAt.getTime();
      }
      // Planned/live debates will be behind all vod debates, sorted by starttime asc
      if (debateA.status !== 'vod') return 1;
      if (debateB.status !== 'vod') return -1;

      return debateA.startsAt.getTime() - debateB.startsAt.getTime();
    });
  const { livePlenary, liveOther, plannedPlenary, plannedOther } = debates.reduce<{
    today: AppAgendaDebate[];
    livePlenary: AppAgendaDebate[];
    liveOther: AppAgendaDebate[];
    plannedPlenary: AppAgendaDebate[];
    plannedOther: AppAgendaDebate[];
  }>(
    (acc, debate) => {
      const { debateDate, locationId, status } = debate;

      if (isSameDay(now, debateDate)) {
        acc.today.push(debate);
      }

      if (locationId === 'plenaire-zaal' && status === 'live') {
        acc.livePlenary.push(debate);
      }
      if (locationId === 'plenaire-zaal' && status === 'planned') {
        acc.plannedPlenary.push(debate);
      }
      if (locationId !== 'plenaire-zaal' && status === 'live') {
        acc.liveOther.push(debate);
      }
      if (locationId !== 'plenaire-zaal' && status === 'planned') {
        acc.plannedOther.push(debate);
      }

      return acc;
    },
    {
      today: [],
      livePlenary: [],
      liveOther: [],
      plannedPlenary: [],
      plannedOther: [],
    },
  );
  const featuredLarge = [livePlenary[0] || liveOther[0] || plannedPlenary[0] || plannedOther[0] || getRecentVod()[0]].filter(Boolean);
  const filterFeatured = (debate: AppAgendaDebate) => !featuredLarge.includes(debate);
  const featuredOtherDebates = [...liveOther, ...plannedOther, ...plannedPlenary].filter(filterFeatured);
  const featuredOtherFilled = featuredOtherDebates.length < 2 ? featuredOtherDebates.concat(getRecentVod().filter(filterFeatured)) : featuredOtherDebates;

  return { large: featuredLarge, other: featuredOtherFilled };
};

export const groupByDateRange = (debates: AppAgendaDebate[], now = new Date()) => {
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);
  const yesterdayStart = startOfDay(sub(now, { days: 1 }));
  const yesterdayEnd = endOfDay(sub(now, { days: 1 }));
  const weekStart = startOfWeek(now, { weekStartsOn: 1 });
  const previousWeekStart = startOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 });
  const previousWeekEnd = endOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 });
  const dayBeforeYesterdayEnd = endOfDay(sub(now, { days: 2 })); // TODO improve date range logic

  return debates.reduce<TShelf[]>((acc, debate) => {
    const startTime = debate.startedAt || debate.startsAt;
    let group: Pick<TShelf, 'shelfType' | 'title' | 'status' | 'startDate' | 'endDate'> | null = null;
    const isActuallyLive = isToday(now) ? debate.startedAt && !debate.endedAt : isLive(debate, now); // The absence of debate.endedAt is more reliable

    if (isActuallyLive) {
      group = { shelfType: ShelfType.LIVE, title: 'Nu live', startDate: todayStart, endDate: todayEnd, status: 'live' };
    } else if (!debate.startedAt && isWithinInterval(debate.startsAt, { start: todayStart, end: todayEnd })) {
      group = { shelfType: ShelfType.SOON, title: 'Straks', startDate: now, endDate: todayEnd, status: 'planned' };
    } else if (isWithinInterval(startTime, { start: todayStart, end: now })) {
      group = { shelfType: ShelfType.EARLIER_TODAY, title: 'Eerder vandaag', startDate: todayStart, endDate: now, status: 'vod' };
    } else if (isWithinInterval(startTime, { start: yesterdayStart, end: yesterdayEnd })) {
      group = { shelfType: ShelfType.YESTERDAY, title: 'Gisteren', startDate: yesterdayStart, endDate: yesterdayEnd };
    } else if (isWithinInterval(startTime, { start: weekStart, end: dayBeforeYesterdayEnd })) {
      group = { shelfType: ShelfType.EARLIER_THIS_WEEK, title: 'Eerder deze week', startDate: weekStart, endDate: dayBeforeYesterdayEnd };
    } else if (isWithinInterval(startTime, { start: previousWeekStart, end: previousWeekEnd })) {
      group = { shelfType: ShelfType.LAST_WEEK, title: 'Vorige week', startDate: previousWeekStart, endDate: previousWeekEnd };
    }

    // Create or append when matched
    if (group) {
      (acc.find((g) => g.shelfType === group?.shelfType)?.debates || acc[acc.push({ ...group, debates: [] }) - 1].debates).push(debate);
    }

    return acc;
  }, []);
};
