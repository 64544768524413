import classNames from 'classnames';

const /**
   * Logical negation
   * @param  {Boolean} condition The boolean to negate
   * @return {Boolean}           The negated value
   */
  not = (condition) => !condition,
  /**
   * Logical conjunction
   * @param  {*}       a Value to compare
   * @param  {*}       b Value to compare
   * @return {Boolean}   Returns whether a equals b
   */
  eq = (a, b) => a === b,
  /**
   * Returns className for the infographic's background
   * @param  {*}      highlight The optional thing to highlight
   * @param  {*}      lowlight  The optional thing to lowlight
   * @return {String}           The className
   */
  getBackgroundClass = (highlight, lowlight) =>
    classNames({
      'u-strokeWhite': !highlight,
      'u-fillGray': lowlight,
      'u-fillOffWhite': highlight && !lowlight,
      'u-noFill': !highlight,
    }),
  /**
   * Returns className for the infographic's seat
   * @param  {Array}   highlightSet The optional things to highlight
   * @param  {Array}   lowlightSet  The optional things to lowlight
   * @param  {Integer} index        Current seat index
   * @return {String}               The className
   */
  getSeatClass = (highlightSet, lowlightSet, index) =>
    classNames({
      'Infographic-seat': true,
      'u-fillBlue': -1 !== highlightSet.indexOf(index),
      'u-strokeBlue': -1 !== highlightSet.indexOf(index),
      'u-fillWhite': -1 !== lowlightSet.indexOf(index) && -1 === highlightSet.indexOf(index),
      'u-strokeWhite': -1 !== lowlightSet.indexOf(index) && -1 === highlightSet.indexOf(index),
      // 'u-fillOffWhite': -1 !== lowlightSet.indexOf(index) && -1 === highlightSet.indexOf(index),
      // 'u-strokeOffWhite': -1 !== lowlightSet.indexOf(index) && -1 === highlightSet.indexOf(index)
    }),
  /**
   * Adds stroke if shouldAddStroke is truthy
   * @param  {Boolean} shouldAddStroke Determines whether to add the stroke
   * @return {String}                  The resulting className
   */
  toggleStroke = (shouldAddStroke) =>
    classNames({
      'u-strokeBlue': shouldAddStroke,
    }),
  /**
   * Adds fill of color if shouldFill is truthy
   * @param  {Boolean} shouldFill Determines whether to add the fill
   * @param  {String}  color      The name of the fill color
   * @return {String}             The resulting className
   */
  toggleFill = (shouldFill, color) =>
    classNames({
      [`u-fill${color || 'Blue'}`]: shouldFill,
      [`u-stroke${color || 'Blue'}`]: shouldFill,
      'u-noFill': not(shouldFill),
    });

export { eq, getBackgroundClass, getSeatClass, not, toggleFill, toggleStroke };
