import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UsernamePasswordForm from '../../components/Forms/UsernamePasswordForm';
import MfaForm from '../../components/Forms/MfaForm';
import UpdatePasswordForm from '../../components/Forms/UpdatePasswordForm';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm';
import ChangePasswordWithCodeForm from '../../components/Forms/ChangePasswordWithCodeForm';

class Login extends Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
  };

  state = {
    step: 'login',
    email: '',
    loginLoading: false,
    loginError: false,
    mfaLoading: false,
    mfaError: false,
    updatePasswordLoading: false,
    updatePasswordError: false,
    forgotPasswordLoading: false,
    forgotPasswordError: false,
    changePasswordLoading: false,
    changePasswordError: false,
    passwordUpdatedFeedback: false,
  };

  handleLoginSubmit = async (values) => {
    this.setState({ loginLoading: true, loginError: false });
    const { username, password } = values;

    try {
      const loginResult = await this.context.getService('auth').login(username, password);

      switch (loginResult) {
        case 'mfa_required':
          this.setState({ step: 'mfa' });
          break;
        case 'mfa_setup':
          // @todo Show MFA setup form?
          break;
        case 'new_password_required':
          this.setState({ step: 'update_password' });
          break;
        case 'success':
        default:
          // no action needed, this Component should be unmounted when the user is authenticated
          break;
      }

      this.setState({ loginLoading: false });
    } catch (error) {
      this.setState({ loginLoading: false, loginError: true });
    }
  };

  handleMfaSubmit = async (values) => {
    this.setState({ mfaLoading: true, mfaError: false });
    const { code } = values;

    try {
      await this.context.getService('auth').sendMfaCode(code);

      this.setState({ mfaLoading: false });
    } catch (error) {
      this.setState({ mfaLoading: false, mfaError: true });
    }
  };

  handleUpdatePasswordSubmit = async (values) => {
    this.setState({ updatePasswordLoading: true, updatePasswordError: false });
    const { password } = values;

    try {
      await this.context.getService('auth').changePasswordChallenge(password);

      this.setState({ updatePasswordLoading: false, step: 'login', passwordUpdatedFeedback: true });
    } catch (error) {
      this.setState({ updatePasswordLoading: false, updatePasswordError: true });
    }
  };

  handleForgotPasswordSubmit = async (values) => {
    this.setState({ forgotPasswordLoading: true, forgotPasswordError: false });
    const { email } = values;

    try {
      await this.context.getService('auth').forgotPassword(email);

      this.setState({ forgotPasswordLoading: false, step: 'change_password_with_code', email });
    } catch (error) {
      this.setState({ forgotPasswordLoading: false, forgotPasswordError: true });
    }
  };

  handleChangePasswordSubmit = async (values) => {
    this.setState({ changePasswordLoading: true, changePasswordError: false });
    const { code, password } = values;
    const { email } = this.state;

    try {
      await this.context.getService('auth').changePasswordWithCode(email, code, password);

      this.setState({ changePasswordLoading: false, step: 'login', passwordUpdatedFeedback: true });
    } catch (error) {
      this.setState({ changePasswordLoading: false, changePasswordError: true });
    }
  };

  render() {
    switch (this.state.step) {
      case 'mfa':
        return <MfaForm onSubmit={this.handleMfaSubmit} hasError={this.state.mfaError} isSubmitting={this.state.mfaLoading} />;
      case 'update_password':
        return (
          <UpdatePasswordForm
            onSubmit={this.handleUpdatePasswordSubmit}
            hasError={this.state.updatePasswordError}
            isSubmitting={this.state.updatePasswordLoading}
          />
        );
      case 'forgot_password':
        return (
          <ForgotPasswordForm
            onSubmit={this.handleForgotPasswordSubmit}
            hasError={this.state.forgotPasswordError}
            isSubmitting={this.state.forgotPasswordLoading}
            onBackToLoginClick={() => this.setState({ step: 'login', loginError: false })}
          />
        );
      case 'change_password_with_code':
        return (
          <ChangePasswordWithCodeForm
            onSubmit={this.handleChangePasswordSubmit}
            hasError={this.state.changePasswordError}
            isSubmitting={this.state.changePasswordLoading}
            onBackToLoginClick={() => this.setState({ step: 'login', loginError: false })}
          />
        );
      default:
        return (
          <UsernamePasswordForm
            onSubmit={this.handleLoginSubmit}
            hasError={this.state.loginError}
            isSubmitting={this.state.loginLoading}
            passwordUpdatedFeedback={this.state.passwordUpdatedFeedback}
            onCloseFeedbackClick={() => this.setState({ passwordUpdatedFeedback: false })}
            onForgotPasswordClick={() => this.setState({ step: 'forgot_password', forgotPasswordError: false })}
          />
        );
    }
  }
}

export default Login;
