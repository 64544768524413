import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import component from 'omniscient';

import OnClickOutside from '../../OnClickOutside/OnClickOutside';
import Prompt from '../../Prompt/Prompt';
import Button from '../../../components/Button/Button';

const /**
   * Defined as variable so it can be passed to the prompt service on dismiss
   * @type {String}
   */
  componentName = 'NextDebate',
  /**
   * Component definition
   * @type {Object}
   */
  definition = {
    promptRef: createRef(),

    contextTypes: {
      getService: PropTypes.func.isRequired,
    },

    mixins: [OnClickOutside],

    /**
     * Closes prompt with given status
     * @param  {String} status Exit status, a callback might be attached
     */
    close: function (status) {
      this.context.getService('prompt').dismiss(status, componentName);
    },

    /**
     * Primary action handler
     */
    handleAccept: function () {
      this.close('accept');
    },

    /**
     * Secondary action handler
     */
    handleDeny: function () {
      this.close('cancel');
    },

    /**
     * ClickOutside handler
     */
    handleClickOutside: function () {
      this.close('cancel');
    },

    getClickOutsideElement: function () {
      return this.promptRef.current;
    },
  },
  render = function () {
    return (
      <Prompt alignment="centerCenter" visible={true} role="alertdialog" aria-live="assertive" aria-labelledby="prompt-description" forwardRef={this.promptRef}>
        <p className="Text Prompt-description" id="prompt-description">
          Het debat is afgelopen.
          <br />
          Wilt u door naar het volgende debat?
        </p>
        <div className="Prompt-actions">
          <Button className="Prompt-action Prompt-action--primary" onClick={this.handleAccept}>
            <span className="Button-label">Ja</span>
          </Button>
          <Button className="Prompt-action" onClick={this.handleDeny}>
            <span className="Button-label">Nee</span>
          </Button>
        </div>
      </Prompt>
    );
  };

export default component(componentName, definition, render);
