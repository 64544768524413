import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import { eq, getBackgroundClass, toggleFill, toggleStroke } from './logic';

const getSeatClass = (highlightSet, lowlightSet, index) =>
    classNames({
      'Infographic-seat': true,
      'u-fillBlue': -1 !== highlightSet.indexOf(index),
      'u-strokeBlue': -1 !== highlightSet.indexOf(index),
      'u-fillOffWhite': -1 !== lowlightSet.indexOf(index) && -1 === highlightSet.indexOf(index),
      'u-strokeOffWhite': -1 !== lowlightSet.indexOf(index) && -1 === highlightSet.indexOf(index),
    }),
  /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const { highlight, role, politicians, lowlight, seats, variant } = props;
    const className = (props.className || 'u-fill').concat(' Infographic');

    const lowlightSet = lowlight ? (Array.isArray(lowlight) ? lowlight : lowlight.toJS()) : [];
    const seatData = seats ? (Array.isArray(seats) ? seats : seats.toJS()) : [];

    const politiciansList = politicians?.join(', ');
    const title = `Visualisatie van de Plenaire zaal ${politiciansList ? ' en de plaats van ' + politiciansList : ''}`;

    /* eslint-disable no-extra-parens */
    return (
      <svg viewBox="130 130 300 147" className={className} role={role} aria-labelledby="simple-infographic-title">
        <title id="simple-infographic-title">{title}</title>
        <g className={getBackgroundClass(variant === 'light' && !!highlight, !!lowlight)}>
          <path d="M419.7,176.1c1.2-3.4,2.3-6.9,3.3-10.5c0-0.1,0-0.2,0.1-0.2 M423,165.3c1-3.6,1.8-7.3,2.5-11l0,0c0.3-1.7,0.7-3.4,0.9-5.2 c0.3-2,0.6-3.9,0.7-6l0,0c0.4-3.7,0.7-7.3,0.8-11.1h-90.2c-0.2,1.7-0.4,3.3-0.7,5.1c-0.3,1.6-0.7,3.3-1.1,4.9l0,0l0,0 c-0.4,1.6-1,3.2-1.5,4.7c-0.6,1.5-1.2,3-1.9,4.5l78.3,44.9c1.6-3.1,3.2-6.4,4.6-9.6l0,0c0.7-1.7,1.5-3.5,2.2-5.2s1.3-3.4,2-5.2" />
          <path d="M140.3,176.1c-1.2-3.4-2.3-6.9-3.3-10.5c0-0.1,0-0.2-0.1-0.2 M137,165.3c-1-3.6-1.8-7.3-2.5-11l0,0 c-0.3-1.7-0.7-3.4-0.9-5.2c-0.3-2-0.6-3.9-0.7-6l0,0c-0.4-3.7-0.7-7.3-0.8-11.1h90.2c0.2,1.7,0.4,3.3,0.7,5.1 c0.3,1.6,0.7,3.3,1.1,4.9l0,0l0,0c0.4,1.6,1,3.2,1.5,4.7c0.6,1.5,1.2,3,1.9,4.5l-78.3,44.9c-1.6-3.1-3.2-6.4-4.6-9.6l0,0 c-0.7-1.7-1.5-3.5-2.2-5.2s-1.3-3.4-2-5.2" />
          <path d="M320.8,167.7c-1.2,1.1-2.4,2.3-3.7,3.3c-1.3,1.1-2.6,2-3.9,2.9L351,239l7.6,13c3-1.9,6-3.8,8.9-6c1.5-1.1,3.1-2.3,4.6-3.4 c1.5-1.1,2.9-2.4,4.3-3.5l0,0c2.8-2.4,5.5-4.8,8.1-7.4c0.1-0.1,0.1-0.1,0.2-0.2l0,0c2.6-2.6,5.2-5.4,7.7-8.2l0,0 c1.1-1.3,2.3-2.7,3.4-4.1c1.2-1.5,2.4-3.1,3.7-4.7c2.2-2.9,4.3-6,6.3-9.1l-78.1-45.3c-1,1.4-2,2.7-3.1,3.9s-2.2,2.4-3.3,3.6 L320.8,167.7z M367.2,206.3L367.2,206.3" />
          <path d="M349.2,257.4l-7.6-13.3l-37.3-65c-1.5,0.7-3.1,1.3-4.7,1.9c-1.5,0.6-3.2,1.1-4.7,1.5l0,0l0,0c-1.6,0.4-3.3,0.7-4.8,1 c-1.6,0.2-3.3,0.5-4.9,0.6v90.3c3.6-0.1,7.1-0.4,10.7-0.7l0,0c1.9-0.2,3.7-0.4,5.6-0.7c1.8-0.2,5.5-0.9,5.5-0.9 c3.6-0.7,10.9-2.5,10.9-2.5c3.6-0.9,10.8-3.3,10.8-3.3c1.6-0.6,3.3-1.1,5-1.8c1.9-0.7,3.7-1.5,5.5-2.3l0,0 C342.5,260.8,345.8,259.1,349.2,257.4z M331,249.5L331,249.5" />
          <path d="M239.1,167.7c-1.1-1.1-2.3-2.4-3.4-3.7s-2.1-2.6-3.1-4l-78.1,45.2c2,3.2,4.1,6.2,6.3,9.2c1.2,1.6,2.4,3.2,3.7,4.7 c1.1,1.4,2.2,2.7,3.3,4.1c2.4,2.9,5,5.6,7.7,8.2l0,0c0.1,0.1,0.1,0.2,0.2,0.2c2.6,2.6,5.3,5.1,8.1,7.4l0,0c1.4,1.2,2.9,2.4,4.3,3.5 c1.5,1.2,3,2.3,4.6,3.4c2.9,2.1,5.8,4.1,8.8,6l45.4-78.1c-1.3-0.9-2.6-1.9-3.9-2.9C241.6,169.9,240.3,168.8,239.1,167.7 C239.2,167.7,239.2,167.7,239.1,167.7 M167.8,197.6L167.8,197.6C167.8,197.6,167.9,197.6,167.8,197.6 C167.9,197.6,167.8,197.6,167.8,197.6L167.8,197.6z" />
          <path d="M265.1,182.5c-1.6-0.4-3.2-0.9-4.8-1.5c-1.5-0.6-3.2-1.1-4.7-1.9l-44.8,78.4c3.3,1.7,6.7,3.3,10,4.8 c1.9,0.8,3.7,1.5,5.5,2.3c1.6,0.7,3.3,1.2,5,1.8c3.6,1.2,7.2,2.4,10.8,3.3l0,0c0.1,0,0.2,0.1,0.2,0.1c3.6,0.9,7.2,1.7,10.8,2.4l0,0 c1.8,0.3,3.7,0.7,5.5,0.9c1.9,0.2,3.7,0.5,5.6,0.7c3.6,0.4,7.1,0.7,10.7,0.7l-0.1-90.3c-1.6-0.2-3.3-0.3-4.9-0.6 C268.4,183.2,266.8,182.9,265.1,182.5L265.1,182.5 M218.6,244.3L218.6,244.3L218.6,244.3L218.6,244.3L218.6,244.3z" />
        </g>
        <g className={toggleFill(eq(highlight, 'fracties'), '').concat(' ', toggleStroke(eq(highlight, 'fracties')))}>
          {[
            // 1
            'M412.8,132.2c0-0.1,0-0.1,0-0.2H428c-0.2,3.7-0.4,7.4-0.8,11.1l-14.9-1.3C412.6,138.6,412.8,135.4,412.8,132.2',
            // 2
            'M412.3,141.8l14.8,1.3c-0.2,2-0.5,4-0.7,6c-0.2,1.7-0.6,3.5-0.9,5.2l-14.5-2.6C411.6,148.5,412,145.1,412.3,141.8',
            // 3
            'M411,151.7l14.5,2.5c-0.7,3.7-1.5,7.3-2.5,11l-14.1-3.7C409.8,158.3,410.5,155,411,151.7',
            // 4
            'M408.9,161.4l14.1,3.7c0,0.1-0.1,0.2-0.1,0.2c-1,3.6-2,7-3.3,10.5l-13.8-5C407,167.9,408.1,164.7,408.9,161.4',
            // 5
            'M405.9,171l13.8,5c-0.7,1.7-1.2,3.5-2,5.2c-0.7,1.8-1.5,3.5-2.2,5.2l-13.4-6.3C403.5,177.2,404.8,174.1,405.9,171',
            // 6
            'M415.5,186.4c-1.5,3.3-3,6.4-4.6,9.6l-13-7.5c1.5-2.7,3-5.5,4.3-8.4L415.5,186.4z',
            // 7
            'M397.4,132.2c0-0.1,0-0.1,0-0.2h8h7.3c0,0.1,0,0.1,0,0.2c0,3.9-0.2,7.8-0.6,11.7l-15.2-1.6 C397.3,138.9,397.4,135.5,397.4,132.2',
            // 8
            'M396.9,142.2l15.2,1.6c-0.4,4-1.1,7.9-1.9,11.8l-14.9-3.2C396,149.1,396.6,145.7,396.9,142.2',
            // 9
            'M395.4,152.5l14.9,3.2c-0.8,3.9-1.9,7.8-3.1,11.6l-14.5-4.7C393.8,159.2,394.7,155.8,395.4,152.5',
            // 10
            'M392.7,162.5l14.5,4.7c-1.2,3.9-2.7,7.6-4.2,11.2l-14-6.2C390.4,169,391.6,165.8,392.7,162.5',
            // 11
            'M403,178.4c-1.5,3.5-3.3,6.9-5.1,10.2l-6.5-3.7l-6.8-3.8c1.6-2.9,3.1-5.8,4.5-8.8L403,178.4z',
            // 12
            'M381.9,132.2c0-0.1,0-0.1,0-0.2h1.1h14.5c0,0.1,0,0.1,0,0.2c0,3.4-0.2,6.8-0.5,10l-15.5-1.6 C381.7,137.8,381.9,135,381.9,132.2',
            // 13
            'M381.5,140.6l15.5,1.6c-0.3,3.5-0.9,6.8-1.6,10.3l-15.2-3.3C380.7,146.4,381.1,143.5,381.5,140.6',
            // 14
            'M380.1,149.2l15.2,3.3c-0.7,3.4-1.6,6.8-2.7,10l-14.8-4.8C378.8,154.9,379.5,152.1,380.1,149.2',
            // 15
            'M377.9,157.6l14.8,4.8c-1.1,3.3-2.3,6.5-3.7,9.7l-14.3-6.4C375.9,163.2,377,160.5,377.9,157.6',
            // 16
            'M389,172.1c-1.3,3-2.9,6-4.5,8.8l-12.9-7.3l-0.6-0.4c1.4-2.4,2.6-4.9,3.7-7.4L389,172.1z',
            // 17
            'M366.5,132.2c0-0.1,0-0.1,0-0.2h15.4c0,0.1,0,0.1,0,0.2c0,3.6-0.2,7.1-0.7,10.6l-15.3-2C366.2,138,366.5,135,366.5,132.2',
            // 18
            'M365.9,140.7l15.3,2c-0.5,3.7-1.1,7.3-2.1,10.7l-14.9-4C364.9,146.6,365.5,143.7,365.9,140.7',
            // 19
            'M364.2,149.5l14.9,4c-1,3.5-2.1,7-3.5,10.4l-14.3-6C362.5,155.2,363.4,152.3,364.2,149.5',
            // 20
            'M375.7,163.8c-1.3,3.3-2.9,6.4-4.6,9.4l-13.4-7.7c1.4-2.4,2.6-5,3.7-7.7L375.7,163.8z',
            // 21
            'M351.9,132h8.5h6c0,0.1,0,0.1,0,0.2c0,3.9-0.3,7.7-1,11.5l-14.4-2.6C351.6,138.2,351.9,135.1,351.9,132',
            // 22
            'M351,141.1l14.3,2.5c-0.7,3.9-1.7,7.7-3,11.4l-13.8-5C349.7,147.3,350.6,144.2,351,141.1',
            // 23
            'M362.5,155.1c-1.3,3.6-2.9,7.1-4.8,10.4l-5.5-3.2l-7.2-4.2c1.5-2.6,2.7-5.3,3.7-8.2L362.5,155.1z',
            // 24
            'M337.1,137.1c0.3-1.6,0.6-3.3,0.7-5.1h14c0,4.7-0.7,9.3-1.8,13.6l-14-3.7C336.5,140.3,336.8,138.7,337.1,137.1',
            // 25
            'M336,142C336,141.9,336,141.9,336,142l14.1,3.7c-1.2,4.4-2.9,8.6-5.1,12.6l-12.4-7.1c0.7-1.5,1.3-3,1.9-4.5 C335,145.1,335.6,143.5,336,142',
            // 26
            'M392.2,197.6L392.2,197.6l13.3,7.6c-2,3.2-4.1,6.2-6.3,9.2l-12.2-8.6C388.8,203,390.6,200.3,392.2,197.6',
            // 27
            'M387,205.7l12.2,8.6c-1.2,1.6-2.4,3.2-3.7,4.7c-1.1,1.4-2.2,2.7-3.3,4.1l-11.2-9.5C383.1,211.1,385,208.4,387,205.7',
            // 28
            'M381,213.6l11.2,9.5c-2.4,2.9-5,5.6-7.7,8.2l-10.4-10.4C376.6,218.7,378.8,216.1,381,213.6',
            // 29
            'M374.3,221l10.4,10.4c-0.1,0.1-0.1,0.2-0.2,0.2c-2.6,2.6-5.3,5.1-8.1,7.4l-9.5-11.2C369.5,225.6,371.9,223.3,374.3,221',
            // 30
            'M366.9,227.7l9.4,11.2c-1.4,1.2-2.9,2.4-4.3,3.5c-1.5,1.2-3,2.3-4.6,3.4l-8.5-12.1C361.7,231.9,364.3,229.8,366.9,227.7',
            // 31
            'M367.5,245.9c-2.9,2.1-5.8,4.1-8.8,6l-7.6-13c2.7-1.5,5.4-3.3,7.9-5.1L367.5,245.9z',
            // 32
            'M378.9,189.9L378.9,189.9l7,3.9l6.3,3.7l-0.1,0.1c-2,3.4-4.1,6.7-6.4,9.8l-12.4-9C375.4,195.6,377.2,192.8,378.9,189.9',
            // 33
            'M373.5,198.3l12.4,9c-2.4,3.3-4.9,6.4-7.5,9.3L367,206.4C369.3,203.8,371.4,201.1,373.5,198.3',
            // 34
            'M367,206.4l11.3,10.2c-2.7,3-5.5,5.8-8.5,8.5l-10.2-11.3C362.2,211.4,364.7,209,367,206.4',
            // 35
            'M359.7,213.7l10.2,11.3c-3,2.7-6.1,5.2-9.3,7.6l-9-12.4C354.4,218.3,357.1,216.1,359.7,213.7',
            // 36
            'M360.6,232.6c-3.1,2.3-6.3,4.3-9.5,6.3l-3.7-6.5l-3.9-6.7c2.9-1.6,5.5-3.4,8.2-5.4L360.6,232.6z',
            // 37
            'M365.5,182.1L365.5,182.1l1,0.5l12.5,7.2l-0.1,0.1c-1.7,2.9-3.5,5.8-5.5,8.5l-12.6-9.1C362.5,186.9,364,184.5,365.5,182.1',
            // 38
            'M360.9,189.2l12.6,9.1c-2,2.9-4.2,5.5-6.5,8.1L355.4,196C357.3,193.8,359.1,191.5,360.9,189.2',
            // 39
            'M355.4,195.9l11.5,10.5c-2.3,2.6-4.7,5.1-7.3,7.3l-10.4-11.6C351.4,200.2,353.4,198.1,355.4,195.9',
            // 40
            'M349.3,202.1l10.4,11.6c-2.6,2.4-5.3,4.5-8.1,6.5l-9.1-12.6C344.8,206,347.1,204.1,349.3,202.1',
            // 41
            'M351.6,220.3c-2.7,2-5.4,3.7-8.2,5.4l-7.4-12.8l-0.4-0.7c2.4-1.4,4.6-2.9,6.9-4.6L351.6,220.3z',
            // 42
            'M352.1,174.3L352.1,174.3l13.5,7.7l-0.1,0.1c-1.8,3.1-3.7,6-5.9,8.8l-12.2-9.4C349,179.2,350.6,176.8,352.1,174.3',
            // 43
            'M347.3,181.5l12.2,9.4c-2.2,2.9-4.6,5.7-7.2,8.2l-10.9-10.9C343.5,186.1,345.5,183.9,347.3,181.5',
            // 44
            'M341.5,188.2l10.9,10.9c-2.6,2.6-5.3,5-8.2,7.3l-9.4-12.2C337.2,192.3,339.4,190.3,341.5,188.2',
            // 45
            'M344.2,206.4c-2.8,2.1-5.7,4.1-8.6,5.9l-7.7-13.4c2.4-1.5,4.8-3,7-4.7L344.2,206.4z',
            // 46
            'M339.6,167l7.3,4.2l5.2,3l-0.1,0.1c-2,3.4-4.2,6.5-6.6,9.5l-11.1-9.4C336.2,172.1,338,169.7,339.6,167',
            // 47
            'M334.3,174.4l11.2,9.4c-2.5,3.1-5.4,5.9-8.3,8.4l-9.5-11.2C330.1,179,332.3,176.8,334.3,174.4',
            // 48
            'M337.1,192.3c-2.9,2.4-6,4.7-9.4,6.6l-3.2-5.5l-4.2-7.2c2.5-1.5,5-3.3,7.3-5.1L337.1,192.3z',
            // 49
            'M324.2,163.9c1.1-1.3,2.1-2.6,3.1-4l12.1,7c-2.4,4.1-5.2,7.7-8.4,10.9l-10.2-10.3C322,166.4,323.2,165.2,324.2,163.9',
            // 50
            'M320.8,167.6L320.8,167.6l10.4,10.3c-3.3,3.3-6.8,6-10.8,8.3l-7.2-12.3c1.3-0.9,2.6-1.9,3.9-2.9 C318.4,169.8,319.7,168.8,320.8,167.6',
            // 51
            'M341.4,244.1L341.4,244.1l7.7,13.2c-3.3,1.7-6.7,3.3-10,4.8l-6.3-13.5C335.7,247.2,338.6,245.7,341.4,244.1',
            // 52
            'M332.9,248.6l6.3,13.5c-1.9,0.8-3.7,1.5-5.5,2.3c-1.6,0.7-3.3,1.2-5,1.8l-5-13.9C326.8,251.3,329.8,250,332.9,248.6',
            // 53
            'M323.7,252.4l5,13.9c-3.6,1.2-7.2,2.4-10.8,3.3l-3.8-14.2C317.3,254.5,320.5,253.6,323.7,252.4',
            // 54
            'M314.1,255.4l3.7,14.1c-0.1,0-0.2,0.1-0.2,0.1c-3.6,0.9-7.2,1.7-10.8,2.4l-2.5-14.4C307.7,257,310.9,256.2,314.1,255.4',
            // 55
            'M304.4,257.6l2.5,14.4c-1.8,0.3-3.7,0.7-5.5,0.9c-1.9,0.2-3.7,0.5-5.6,0.7l-1.2-14.8C297.8,258.5,301.2,258.1,304.4,257.6',
            // 56
            'M295.8,273.6c-3.6,0.4-7.1,0.7-10.7,0.7v-15c3.1,0,6.3-0.2,9.4-0.4L295.8,273.6z',
            // 57
            'M333.8,230.9C333.9,230.9,333.9,230.8,333.8,230.9l4.1,6.8l3.6,6.3l-0.1,0.1c-3.4,2-6.8,3.7-10.4,5.3l-6.2-13.9 C327.9,234.1,330.9,232.6,333.8,230.9',
            // 58
            'M324.9,235.5l6.2,13.9c-3.7,1.6-7.4,3.1-11.2,4.3l-4.7-14.5C318.4,238.1,321.7,236.8,324.9,235.5',
            // 59
            'M315.2,239.1l4.7,14.5c-3.8,1.2-7.7,2.3-11.6,3.1l-3.2-14.9C308.6,241.2,311.9,240.3,315.2,239.1',
            // 60
            'M305.1,241.8l3.2,14.9c-3.9,0.8-7.8,1.5-11.8,1.9l-1.5-15.2C298.4,243.1,301.8,242.6,305.1,241.8',
            // 61
            'M296.5,258.7c-3.8,0.4-7.6,0.6-11.4,0.7v-7.5v-7.7c3.3,0,6.6-0.2,9.9-0.6L296.5,258.7z',
            // 62
            'M326,217.4L326,217.4l0.6,0.8l7.3,12.6l-0.1,0.1c-2.9,1.7-6,3.2-9,4.6l-6.3-14.3C321,220.1,323.6,218.8,326,217.4',
            // 63
            'M318.5,221.2l6.3,14.3c-3.2,1.4-6.4,2.7-9.7,3.7l-4.8-14.8C313.1,223.4,315.8,222.4,318.5,221.2',
            // 64
            'M310.4,224.3l4.8,14.8c-3.3,1.1-6.7,2-10,2.7l-3.2-15.2C304.9,226,307.7,225.3,310.4,224.3',
            // 65
            'M302.1,226.6l3.2,15.2c-3.4,0.7-6.8,1.2-10.3,1.6l-1.6-15.5C296.3,227.6,299.2,227.2,302.1,226.6',
            // 66
            'M295,243.5c-3.3,0.3-6.6,0.5-9.9,0.6v-14.9v-0.8c2.8,0,5.5-0.2,8.2-0.5L295,243.5z',
            // 67
            'M318.4,204C318.5,204,318.5,203.9,318.4,204l7.7,13.3l-0.1,0.1c-3.1,1.8-6.3,3.3-9.5,4.6l-5.9-14.3 C313.4,206.7,315.9,205.4,318.4,204',
            // 68
            'M310.8,207.7l5.9,14.3c-3.4,1.4-6.8,2.6-10.4,3.5l-4-14.9C305.1,209.9,308,208.9,310.8,207.7',
            // 69
            'M302.3,210.7l4,14.9c-3.5,1-7.1,1.6-10.7,2.1l-2-15.3C296.5,212,299.4,211.4,302.3,210.7',
            // 70
            'M295.6,227.7c-3.5,0.5-6.9,0.7-10.4,0.7V213c2.9,0,5.6-0.2,8.5-0.6L295.6,227.7z',
            // 71
            'M311.3,191.3l4.2,7.3l3,5.2l-0.1,0.1c-3.4,2-6.9,3.6-10.4,4.9l-5-13.7C305.9,194.1,308.7,192.8,311.3,191.3',
            // 72
            'M303,195.1l5,13.7c-3.7,1.4-7.6,2.4-11.4,3.1l-2.5-14.4C297.1,197,300.1,196.2,303,195.1',
            // 73
            'M296.5,211.9c-3.8,0.7-7.6,1-11.4,1.1v-6.4v-8.3c2.9,0,6-0.2,8.9-0.8L296.5,211.9z',
            // 74
            'M299.6,181c1.5-0.6,3.2-1.1,4.7-1.9l7,12.1c-4.1,2.4-8.4,4.1-12.7,5.2l-3.7-14C296.4,182,298.1,181.6,299.6,181',
            // 75
            'M294.8,182.5C294.8,182.4,294.8,182.4,294.8,182.5l3.7,14.1c-4.5,1.2-9,1.8-13.5,1.8v-14.3c1.6-0.2,3.3-0.3,4.9-0.6 C291.6,183.2,293.2,182.9,294.8,182.5',
            // 76
            'M264.2,273.6c3.5,0.4,7.1,0.7,10.7,0.7v-15c-3.1,0-6.3-0.2-9.4-0.4L264.2,273.6z',
            // 77
            'M255.6,257.6l-2.5,14.4c1.8,0.3,3.7,0.7,5.5,0.9c1.9,0.2,3.7,0.5,5.6,0.7l1.2-14.8C262.2,258.5,258.9,258.1,255.6,257.6',
            // 78
            'M245.9,255.4l-3.7,14.1c0.1,0,0.2,0.1,0.2,0.1c3.6,0.9,7.2,1.7,10.8,2.4l2.5-14.4C252.3,257,249.1,256.2,245.9,255.4',
            // 79
            'M236.3,252.4l-5,13.9c3.6,1.2,7.2,2.4,10.8,3.3l3.7-14.1C242.7,254.5,239.5,253.6,236.3,252.4',
            // 80
            'M227.2,248.6l-6.3,13.5c1.9,0.8,3.7,1.5,5.5,2.3c1.6,0.7,3.3,1.2,5,1.8l5-13.9C233.2,251.3,230.2,250,227.2,248.6',
            // 81
            'M218.6,244.1L218.6,244.1l-7.7,13.2c3.3,1.7,6.7,3.3,10,4.8l6.3-13.5C224.3,247.2,221.4,245.7,218.6,244.1',
            // 82
            'M263.5,258.7c3.8,0.4,7.6,0.6,11.4,0.7v-7.5v-7.7c-3.3,0-6.6-0.2-9.9-0.6L263.5,258.7z',
            // 83
            'M254.9,241.8l-3.2,14.9c3.9,0.8,7.8,1.5,11.8,1.9l1.5-15.2C261.7,243.1,258.3,242.6,254.9,241.8',
            // 84
            'M244.8,239.1l-4.7,14.5c3.8,1.2,7.7,2.3,11.6,3.1l3.2-14.9C251.5,241.2,248.2,240.3,244.8,239.1',
            // 85
            'M235.2,235.5l-6.2,13.9c3.7,1.6,7.4,3.1,11.2,4.3l4.7-14.5C241.6,238.1,238.4,236.8,235.2,235.5',
            // 86
            'M226.2,230.9C226.2,230.9,226.2,230.8,226.2,230.9l-4.1,6.8l-3.6,6.3l0.1,0.1c3.4,2,6.8,3.7,10.4,5.3l6.2-13.9 C232.1,234.1,229.2,232.6,226.2,230.9',
            // 87
            'M265.1,243.5c3.3,0.3,6.6,0.5,9.9,0.6v-14.9v-0.8c-2.8,0-5.5-0.2-8.2-0.5L265.1,243.5z',
            // 88
            'M258,226.6l-3.2,15.2c3.4,0.7,6.8,1.2,10.3,1.6l1.6-15.5C263.8,227.6,260.9,227.2,258,226.6',
            // 89
            'M249.6,224.3l-4.8,14.8c3.3,1.1,6.7,2,10,2.7l3.2-15.2C255.3,226,252.4,225.3,249.6,224.3',
            // 90
            'M241.5,221.2l-6.3,14.3c3.2,1.4,6.4,2.7,9.7,3.7l4.8-14.8C246.9,223.4,244.2,222.4,241.5,221.2',
            // 91
            'M234,217.4L234,217.4l-0.6,0.8l-7.3,12.6l0.1,0.1c2.9,1.7,6,3.2,9,4.6l6.3-14.3C239,220.1,236.4,218.8,234,217.4',
            // 92
            'M264.6,227.7c3.5,0.5,6.9,0.7,10.4,0.7V213c-2.9,0-5.6-0.2-8.5-0.6L264.6,227.7z',
            // 93
            'M257.8,210.7l-4,14.9c3.5,1,7.1,1.6,10.7,2.1l2-15.3C263.6,212,260.6,211.4,257.8,210.7',
            // 94
            'M249.4,207.7l-5.9,14.3c3.4,1.4,6.8,2.6,10.4,3.5l4-14.9C254.9,209.9,252.2,208.9,249.4,207.7',
            // 95
            'M241.6,204C241.6,204,241.6,203.9,241.6,204l-7.7,13.3l0.1,0.1c3.1,1.8,6.3,3.3,9.5,4.6l5.9-14.3 C246.8,206.7,244.2,205.4,241.6,204',
            // 96
            'M263.6,211.9c3.8,0.7,7.6,1,11.4,1.1v-6.4v-8.3c-2.9,0-6-0.2-8.9-0.8L263.6,211.9z',
            // 97
            'M257.1,195.1l-5,13.7c3.7,1.4,7.6,2.4,11.4,3.1l2.5-14.4C263.1,197,260.1,196.2,257.1,195.1',
            // 98
            'M248.8,191.3l-4.2,7.3l-3,5.2l0.1,0.1c3.4,2,6.9,3.6,10.4,4.9l5-13.7C254.3,194.1,251.5,192.8,248.8,191.3',
            // 99
            'M265.3,182.5C265.3,182.4,265.3,182.4,265.3,182.5l-3.7,14.1c4.5,1.2,9,1.8,13.5,1.8v-14.3c-1.6-0.2-3.3-0.3-4.9-0.6 C268.5,183.2,266.9,182.9,265.3,182.5',
            // 100
            'M260.5,181c-1.5-0.6-3.2-1.1-4.7-1.9l-7,12.1c4.1,2.4,8.4,4.1,12.7,5.2l3.7-14C263.7,182,262.1,181.6,260.5,181',
            // 101
            'M192.6,245.9c2.9,2.1,5.8,4.1,8.8,6l7.6-13c-2.7-1.5-5.4-3.3-7.9-5.1L192.6,245.9z',
            // 102
            'M193.1,227.7l-9.4,11.2c1.4,1.2,2.9,2.4,4.3,3.5c1.5,1.2,3,2.3,4.6,3.4l8.5-12.1C198.4,231.9,195.7,229.8,193.1,227.7',
            // 103
            'M185.8,221l-10.4,10.4c0.1,0.1,0.1,0.2,0.2,0.2c2.6,2.6,5.3,5.1,8.1,7.4l9.4-11.2C190.6,225.6,188.2,223.3,185.8,221',
            // 104
            'M179,213.6l-11.2,9.5c2.4,2.9,5,5.6,7.7,8.2l10.4-10.4C183.4,218.7,181.2,216.1,179,213.6',
            // 105
            'M173,205.7l-12.2,8.6c1.2,1.6,2.4,3.2,3.7,4.7c1.1,1.4,2.2,2.7,3.3,4.1l11.2-9.5C177,211.1,175,208.4,173,205.7',
            // 106
            'M167.9,197.6L167.9,197.6l-13.4,7.5c2,3.2,4.1,6.2,6.3,9.2l12.2-8.6C171.2,203,169.5,200.3,167.9,197.6',
            // 107
            'M199.4,232.6c3.1,2.3,6.3,4.3,9.5,6.3l3.7-6.5l3.9-6.7c-2.9-1.6-5.5-3.4-8.2-5.4L199.4,232.6z',
            // 108
            'M200.3,213.7L190.1,225c3,2.7,6.1,5.2,9.3,7.6l9-12.4C205.6,218.3,202.9,216.1,200.3,213.7',
            // 109
            'M193.1,206.4l-11.3,10.2c2.7,3,5.5,5.8,8.5,8.5l10.2-11.3C197.8,211.4,195.3,209,193.1,206.4',
            // 110
            'M186.5,198.3l-12.4,9c2.4,3.3,4.9,6.4,7.5,9.3l11.4-10.2C190.8,203.8,188.6,201.1,186.5,198.3',
            // 111
            'M181.1,189.9L181.1,189.9l-7,3.9l-6.3,3.7l0.1,0.1c2,3.4,4.1,6.7,6.4,9.8l12.4-9C184.6,195.6,182.8,192.8,181.1,189.9',
            // 112
            'M208.4,220.3c2.7,2,5.4,3.7,8.2,5.4l7.4-12.8l0.4-0.7c-2.4-1.4-4.6-2.9-6.9-4.6L208.4,220.3z',
            // 113
            'M210.7,202.1l-10.4,11.6c2.6,2.4,5.3,4.5,8.1,6.5l9.1-12.6C215.2,206,212.9,204.1,210.7,202.1',
            // 114
            'M204.6,195.9l-11.5,10.5c2.3,2.6,4.7,5.1,7.3,7.3l10.4-11.6C208.6,200.2,206.6,198.1,204.6,195.9',
            // 115
            'M199.2,189.2l-12.6,9.1c2,2.9,4.2,5.5,6.5,8.1l11.6-10.4C202.7,193.8,200.9,191.5,199.2,189.2',
            // 116
            'M194.6,182.1L194.6,182.1l-1,0.5l-12.6,7.1l0.1,0.1c1.7,2.9,3.5,5.8,5.5,8.5l12.6-9.1C197.5,186.9,196,184.5,194.6,182.1',
            // 117
            'M215.8,206.4c2.8,2.1,5.7,4.1,8.6,5.9l7.7-13.4c-2.4-1.5-4.8-3-7-4.7L215.8,206.4z',
            // 118
            'M218.6,188.2l-10.9,10.9c2.6,2.6,5.3,5,8.2,7.3l9.4-12.2C222.9,192.3,220.6,190.3,218.6,188.2',
            // 119
            'M212.7,181.5l-12.2,9.4c2.2,2.9,4.6,5.7,7.2,8.2l10.9-10.9C216.5,186.1,214.5,183.9,212.7,181.5',
            // 120
            'M208,174.3L208,174.3l-13.5,7.7l0.1,0.1c1.8,3.1,3.7,6,5.9,8.8l12.2-9.4C211,179.2,209.4,176.8,208,174.3',
            // 121
            'M222.9,192.3c2.9,2.4,6,4.7,9.4,6.6l3.2-5.5l4.2-7.2c-2.5-1.5-5-3.3-7.3-5.1L222.9,192.3z',
            // 122
            'M225.7,174.4l-11.2,9.5c2.5,3.1,5.4,5.9,8.3,8.4l9.4-11.2C230,179,227.8,176.8,225.7,174.4',
            // 123
            'M220.4,167l-7.3,4.2l-5.2,3l0.1,0.1c2,3.4,4.2,6.5,6.6,9.5l11.1-9.4C223.8,172.1,222,169.7,220.4,167',
            // 124
            'M239.2,167.6L239.2,167.6l-10.4,10.2c3.3,3.3,6.8,6,10.8,8.3l7.2-12.3c-1.3-0.9-2.7-1.9-3.9-2.9 C241.6,169.8,240.3,168.8,239.2,167.6',
            // 125
            'M235.8,163.9c-1.1-1.3-2.1-2.6-3.1-4l-12.1,7c2.4,4.1,5.2,7.7,8.4,10.9l10.3-10.4C238,166.4,236.8,165.2,235.8,163.9',
            // 126
            'M144.5,186.4c1.5,3.3,3,6.4,4.6,9.6l13-7.5c-1.5-2.7-3-5.5-4.3-8.4L144.5,186.4z',
            // 127
            'M154.1,171l-13.8,5c0.7,1.7,1.2,3.5,2,5.2c0.7,1.8,1.5,3.5,2.2,5.2l13.4-6.3C156.5,177.2,155.2,174.1,154.1,171',
            // 128
            'M151.1,161.4l-14.1,3.8c0,0.1,0.1,0.2,0.1,0.2c1,3.6,2,7,3.3,10.5l13.8-5C153,167.9,152,164.7,151.1,161.4',
            // 129
            'M149,151.7l-14.5,2.5c0.7,3.7,1.5,7.3,2.5,11l14.1-3.7C150.3,158.3,149.5,155,149,151.7',
            // 130
            'M147.7,141.8l-14.9,1.3c0.2,2,0.5,4,0.7,6c0.2,1.7,0.6,3.5,0.9,5.2l14.5-2.5C148.4,148.5,148,145.1,147.7,141.8',
            // 131
            'M147.2,132.2c0-0.1,0-0.1,0-0.2H132c0.2,3.7,0.4,7.4,0.8,11.1l14.9-1.3C147.4,138.6,147.2,135.4,147.2,132.2z',
            // 132
            'M157,178.4c1.5,3.5,3.3,6.9,5.1,10.2l6.5-3.7l6.8-3.8c-1.6-2.9-3.1-5.8-4.5-8.8L157,178.4z',
            // 133
            'M167.3,162.5l-14.5,4.7c1.2,3.8,2.7,7.6,4.2,11.2l14-6.2C169.6,169,168.4,165.8,167.3,162.5',
            // 134
            'M164.7,152.5l-14.9,3.2c0.8,3.9,1.9,7.8,3.1,11.6l14.5-4.7C166.2,159.2,165.3,155.8,164.7,152.5',
            // 135
            'M163.1,142.2l-15.2,1.6c0.4,4,1.1,7.9,1.9,11.8l14.9-3.2C164,149.1,163.5,145.7,163.1,142.2',
            // 136
            'M162.6,132.2c0-0.1,0-0.1,0-0.2h-8h-7.3c0,0.1,0,0.1,0,0.2c0,3.9,0.2,7.8,0.6,11.7l15.2-1.6 C162.7,138.9,162.6,135.5,162.6,132.2',
            // 137
            'M171,172.1c1.3,3,2.9,6,4.5,8.8l12.9-7.3l0.7-0.4c-1.4-2.4-2.6-4.9-3.7-7.4L171,172.1z',
            // 138
            'M182.1,157.6l-14.8,4.8c1.1,3.3,2.3,6.5,3.7,9.7l14.3-6.4C184.1,163.2,183,160.5,182.1,157.6',
            // 139
            'M179.9,149.2l-15.2,3.3c0.7,3.4,1.6,6.8,2.7,10l14.8-4.8C181.2,154.9,180.5,152.1,179.9,149.2',
            // 140
            'M178.5,140.6l-15.5,1.6c0.3,3.5,0.9,6.8,1.6,10.3l15.2-3.3C179.4,146.4,178.9,143.5,178.5,140.6',
            // 141
            'M178.1,132.2c0-0.1,0-0.1,0-0.2H177h-14.5c0,0.1,0,0.1,0,0.2c0,3.4,0.2,6.8,0.5,10l15.5-1.6 C178.3,137.8,178.1,135,178.1,132.2',
            // 142
            'M184.4,163.8c1.3,3.3,2.9,6.4,4.6,9.4l13.4-7.7c-1.4-2.4-2.6-5-3.7-7.7L184.4,163.8z',
            // 143
            'M195.8,149.5l-14.9,4c1,3.5,2.1,7,3.5,10.4l14.3-6C197.5,155.2,196.6,152.3,195.8,149.5',
            // 144
            'M194.1,140.7l-15.3,2c0.5,3.7,1.1,7.3,2.1,10.7l14.9-4C195.1,146.6,194.5,143.7,194.1,140.7',
            // 145
            'M193.6,132.2c0-0.1,0-0.1,0-0.2h-15.5c0,0.1,0,0.1,0,0.2c0,3.6,0.2,7.1,0.7,10.6l15.3-2C193.8,138,193.6,135,193.6,132.2',
            // 146
            'M197.6,155.1c1.3,3.6,2.9,7.1,4.8,10.4l5.5-3.2l7.2-4.2c-1.5-2.6-2.7-5.3-3.7-8.2L197.6,155.1z',
            // 147
            'M209,141.1l-14.3,2.5c0.7,3.9,1.7,7.7,3,11.4l13.8-5C210.3,147.3,209.5,144.2,209,141.1',
            // 148
            'M208.1,132h-8.5h-6c0,0.1,0,0.1,0,0.2c0,3.9,0.3,7.7,1,11.5l14.3-2.5C208.5,138.2,208.1,135.1,208.1,132',
            // 149
            'M224,142C224,141.9,224,141.9,224,142l-14.1,3.7c1.2,4.4,2.9,8.6,5.1,12.6l12.4-7.1c-0.7-1.5-1.3-3-1.9-4.5 C225,145.1,224.5,143.5,224,142',
            // 150
            'M222.9,137.1c-0.3-1.6-0.6-3.3-0.7-5.1h-14c0,4.7,0.7,9.3,1.8,13.6l14.1-3.7C223.6,140.3,223.2,138.7,222.9,137.1',
          ]
            .map((d, index) => ({
              key: index,
              className: getSeatClass(seatData, lowlightSet, index + 1),
              d,
            }))
            .sort((a, b) => {
              if (a.className.includes('u-fillBlue')) {
                return 1;
              }

              if (b.className.includes('u-fillBlue')) {
                return -1;
              }

              return 0;
            })
            .map((props) => (
              <path {...props} />
            ))}
        </g>
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicPlenair', render);
