export const copyToClipboard = (text) => {
  if ('clipboard' in navigator) {
    // ignore promise
    navigator.clipboard.writeText(text);

    return true;
  }

  return false;
};
