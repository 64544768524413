import React from 'react';

import ArrowUp from '../../icons/ArrowUp';
import Button from '../Button/Button';

const ScrollToTopButton = (props) => {
  return (
    <Button className="ScrollToTopButton" onClick={props.onClick} aria-label="Scroll naar boven">
      <ArrowUp className="ScrollToTopButton-icon" />
    </Button>
  );
};

export default ScrollToTopButton;
