/**
 * Get initial active event from URL parameter
 * @param {String} queryString is optional default argument is window.location.search
 * @returns {Object|null}
 */
const getInitialEvent = (queryString = window.location.search) => {
  const eventRegex = /^(.+)(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\.\d+)?(Z|[+ ]\d{4})$/;
  const searchParams = new window.URLSearchParams(queryString);
  const event = searchParams.get('event');

  if (!event || !eventRegex.test(event)) {
    return null;
  }

  // eventStart has a plus character before the timezone, this becomes a whitespace when serializing from search params
  return {
    disableTime: Date.now() + 10000,
    event: event.replace(' ', '+'),
  };
};

/**
 * Get URL params.
 * @param {String} queryString is optional default argument is window.location.search
 * @returns {{raw: String, entries: Object}}
 */
const getUrlParams = (queryString = window.location.search) => {
  const searchParams = new window.URLSearchParams(queryString);

  return {
    entries: searchParams.values(),
    raw: queryString.replace('?', ''),
  };
};

/**
 * Public exports.
 */
export default {
  getInitialEvent,
  getUrlParams,
};
