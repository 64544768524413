import { QueryClient } from '@tanstack/react-query';
import { setDefaultOptions as setDateFnsDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale';
import { type Config } from '@debatdirect/core-ts/types/app';
import SocketService from '@debatdirect/core-ts/services/SocketService/SocketService';
import { ReactQueryUpdater } from '@debatdirect/core-ts/services/ReactQueryUpdater/ReactQueryUpdater';

export const bootstrap = (config: Config) => {
  const queryClient = new QueryClient(config.queryClientConfig);

  if (config.socketService.enabled) {
    const socketService = new SocketService(config.socketService);
    socketService.setObserver(new ReactQueryUpdater(queryClient));
    socketService.start();
  }

  setDateFnsDefaultOptions({ locale: nl });
  return { queryClient };
};
