import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDebounce } from '../../hooks/useDebounce.js';

const getParentScrollView = function (parent) {
  let element = parent;

  while ((element = element.parentNode)) {
    if (/(Native)?ScrollView/.test(element.className)) {
      return element;
    }
  }

  return window;
};

const InfiniteScroll = ({ children, pageStart = 0, hasMore = false, loadMore = () => undefined, threshold = 250 }) => {
  const elementRef = useRef();
  const scrollViewRef = useRef();
  const [page, setPage] = useState(pageStart);

  const getHasMore = useCallback(() => {
    return typeof hasMore === 'function' ? hasMore() : hasMore;
  }, [hasMore]);

  const handleScroll = useDebounce(function () {
    const hasMore = getHasMore();
    const scrollView = scrollViewRef.current;

    if (!hasMore) {
      detachScrollListener();
    }

    if (!scrollView) return;

    if (scrollView.scrollHeight - (scrollView.offsetHeight + scrollView.scrollTop) < Number(threshold)) {
      setPage(page + 1);
      loadMore(page + 1);
    }
  }, 100);

  const attachScrollListener = useCallback(
    function () {
      scrollViewRef.current?.addEventListener('scroll', handleScroll);
      scrollViewRef.current?.addEventListener('resize', handleScroll);
    },
    [handleScroll],
  );

  const detachScrollListener = useCallback(
    function () {
      scrollViewRef.current?.removeEventListener('scroll', handleScroll);
      scrollViewRef.current?.removeEventListener('resize', handleScroll);
    },
    [handleScroll],
  );

  useEffect(() => {
    scrollViewRef.current = getParentScrollView(elementRef.current);
    attachScrollListener();

    return () => detachScrollListener();
  }, [attachScrollListener, detachScrollListener]);

  return <div ref={elementRef}>{children}</div>;
};

export default InfiniteScroll;
