import PropTypes from 'prop-types';

import { util } from '../../core';

/**
 * Sets up dependency injection through Component context
 * @param  {React.Component} Component - The component to wrap
 * @param  {Function} getCursor - Used to query the state tree
 * @param  {Function} getService - The service getter
 * @return {React.Component} - The wrapped component
 */
const createRootComponent = (Component, getCursor, getService) => {
  const router = getService('router'),
    pathTo = router.generate,
    navigate = (path) => router.navigate(path),
    requiredFunc = PropTypes.func.isRequired,
    provideThrough = util.provide({
      navigate: { type: requiredFunc, value: navigate },
      getCursor: { type: requiredFunc, value: getCursor },
      getService: { type: requiredFunc, value: getService },
      pathTo: { type: requiredFunc, value: pathTo },
    });

  return provideThrough(Component);
};

/**
 * Public exports.
 */
export default createRootComponent;
