import React from 'react';
import { withDefaults } from 'omniscient';

import Prompt from '../../Prompt/Prompt';

const component = withDefaults({ cursorField: 'cursor' }),
  render = function () {
    return (
      <Prompt alignment="centerCenter" visible={true} role="alertdialog" aria-labelledby="prompt-heading" aria-live="assertive">
        <div>
          <h2 className="Heading Prompt-heading" id="prompt-heading">
            Netwerkverbinding foutmelding
          </h2>
          <p className="Text Prompt-description">
            Uw netwerkverbinding is weggevallen. Zodra uw apparaat weer verbinding heeft met het internet, wordt Debat Direct automatisch hervat.
          </p>
        </div>
      </Prompt>
    );
  };

export default component('PromptNoConnection', render);
