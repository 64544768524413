/**
 * A mixin for handling (effectively) onClickOutside for React components.
 * Note that we're not intercepting any events in this approach, and we're
 * not using double events for capturing and discarding in layers or wrappers.
 *
 * The idea is that components define function
 *
 *   handleClickOutside: function() { ... }
 *
 * If no such function is defined, an error will be thrown, as this means
 * either it still needs to be written, or the component should not be using
 * this mixing since it will not exhibit onClickOutside behaviour.
 *
 */
const factory = function (config) {
  // Use a parallel array because we can't use
  // objects as keys, they get toString-coerced
  const registeredComponents = [];
  const handlers = [];

  const IGNORE_CLASS = config.ignoreClass || 'ignore-react-onclickoutside';

  const isSourceFound = function (source, localNode) {
    if (source === localNode) {
      return true;
    }
    const regex = new RegExp(IGNORE_CLASS);

    // SVG <use/> elements do not technically reside in the rendered DOM, so
    // they do not have classList directly, but they offer a link to their
    // corresponding element, which can have classList. This extra check is for
    // that case.
    // See: http://www.w3.org/TR/SVG11/struct.html#InterfaceSVGUseElement
    // Discussion: https://github.com/Pomax/react-onclickoutside/pull/17
    if (source.correspondingElement) {
      return regex.test(source.correspondingElement.className);
    }

    return regex.test(source.className);
  };

  return {
    componentDidMount: function () {
      if (typeof this.handleClickOutside !== 'function')
        throw new Error('Component lacks a handleClickOutside(event) function for processing outside click events.');

      if (typeof this.getClickOutsideElement !== 'function')
        throw new Error('Component lacks a getClickOutsideElement function for processing outside click events.');

      const fn = (this.__outsideClickHandler = (function (localNode, eventHandler) {
        return function (evt) {
          evt.stopPropagation();
          let source = evt.target;
          let found = false;

          // If source=local then this event came from "somewhere"
          // inside and should be ignored. We could handle this with
          // a layered approach, too, but that requires going back to
          // thinking in terms of Dom node nesting, running counter
          // to React's "you shouldn't care about the DOM" philosophy.
          while (source.parentNode) {
            found = isSourceFound(source, localNode);
            if (found) return;
            source = source.parentNode;
          }
          eventHandler(evt);
        };
      })(this.getClickOutsideElement(), this.handleClickOutside));

      const pos = registeredComponents.length;

      registeredComponents.push(this);
      handlers[pos] = fn;

      // If there is a truthy disableOnClickOutside property for this
      // component, don't immediately start listening for outside events.
      if (!this.props.disableOnClickOutside) {
        this.enableOnClickOutside();
      }
    },

    componentWillUnmount: function () {
      this.disableOnClickOutside();
      this.__outsideClickHandler = false;
      const pos = registeredComponents.indexOf(this);

      if (pos > -1) {
        if (handlers[pos]) {
          // clean up so we don't leak memory
          handlers.splice(pos, 1);
          registeredComponents.splice(pos, 1);
        }
      }
    },

    /**
     * Can be called to explicitly enable event listening
     * for clicks and touches outside of this element.
     */
    enableOnClickOutside: function () {
      const fn = this.__outsideClickHandler;

      if (document != null) {
        document.addEventListener('mousedown', fn);
        document.addEventListener('touchstart', fn);
      }
    },

    /**
     * Can be called to explicitly disable event listening
     * for clicks and touches outside of this element.
     */
    disableOnClickOutside: function () {
      const fn = this.__outsideClickHandler;

      if (document != null) {
        document.removeEventListener('mousedown', fn);
        document.removeEventListener('touchstart', fn);
      }
    },
  };
};

const OnClickOutside = factory({});

// Expose the factory function to enable the
// setting of different ignore classes per instance.
OnClickOutside.withConfig = factory;

// Initialize the exposed mixin without passing config
// so default behaviour remains the same.
export default OnClickOutside;
