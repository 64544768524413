/**
 * Check if status is valid.
 * @param {Number} status
 * @returns {Boolean}
 */
const isValidStatus = (status) => {
  // Everything in the 200 range is deemed valid.
  if (200 <= status && status < 300) {
    return true;
  }

  // A 304 is also deemed valid, since we want to make it possible for the browser to do an If-Modified-Since request.
  return status === 304;
};

/**
 * Create an object that can be passed to the reject call.
 * @param {string} method
 * @param {string} url
 * @param {Error} [originalError]
 * @returns {Error}
 */
const createRejectArgument = (method, url, originalError) => {
  const errorMessage = originalError ? ` with error ${originalError.message}` : '';

  return new Error(`Failed to fetch URL: [${method}] ${url}${errorMessage}`);
};

/**
 * Performs an XMLHTTPrequest and returns a promise
 * @param  {String}              method
 * @param  {String}              url
 * @param  {String|Object|Array} [payload]
 * @param  {Array}               [headers] { name: 'headerName', value: 'headerValue' }
 * @return {Promise}                       Is resolved when result status is 2xx
 */
function request(method, url, payload, headers) {
  headers = headers || [];

  if (payload) {
    headers.push({ name: 'Content-Type', value: 'application/json' });
    payload = payload instanceof String ? payload : JSON.stringify(payload);
  }

  return new Promise(function (resolve, reject) {
    // @TODO replace with fetch
    const request = new XMLHttpRequest();

    request.onload = handleLoad;
    request.onerror = handleError;
    request.open(method, url);

    headers.forEach(function (header) {
      request.setRequestHeader(header.name, header.value);
    });

    if (payload) {
      request.send(payload);
    } else {
      request.send(null);
    }

    /**
     * Called when a response is received, resolves if status is 2xx
     */
    function handleLoad() {
      const status = this.status;

      if (4 === this.readyState && isValidStatus(status)) {
        try {
          let response = this.response || this.responseText;

          resolve(response ? JSON.parse(response) : undefined);
        } catch (e) {
          reject(createRejectArgument(method, url, e));
        }
      } else {
        reject(createRejectArgument(method, url, new Error(`Invalid statusCode ${status}`)));
      }
    }

    /**
     * Called when an error occurs, rejects the promise
     */
    function handleError() {
      reject(createRejectArgument(method, url));
    }
  });
}

export default request;
