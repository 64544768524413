/**
 * Share service factory.
 * @param {Object} reference - Main data model.
 * @param {Object} config
 * @returns {Object}
 */
const ShareServiceFactory = function (reference, config) {
  const shareConfig = config.share,
    getService = this.getService;

  /**
   * Get the domain from the config
   * @returns {*}
   */
  const getShareDomain = () => shareConfig.url;

  /**
   * Get params for debate
   * @param debate
   * @returns {{category: *, debate: string}}
   */
  const paramsForDebate = (debate) => ({
    category: debate ? debate.getIn(['categoryIds', 0], 'onbekend') : 'onbekend',
    debate: debate ? debate.get('slug') : 'onbekend',
    location: debate ? debate.get('locationId') : 'onbekend',
  });

  /**
   * Create path to debate
   */
  const createPath = (debate, event, iframe = false) => {
    const router = getService('router'),
      pathWith = router.generate,
      route = router.getRoute(),
      isEmbedded = router.isEmbedded(route),
      params = isEmbedded ? paramsForDebate(debate) : router.getParams(),
      agendaDate = getService('date').getAgendaDate();

    const routeParams = {
      ...params,
      date: agendaDate,
    };

    // In case of iframe always use embed path.
    if (iframe) {
      return pathWith('debate-embedded', routeParams);
    }

    // If full debate is being shared, we direct to subject page.
    if (!event) {
      return pathWith('debate-subject', routeParams);
    }

    // In all other cases we redirect to markers tab.
    return pathWith('debate-markers', routeParams);
  };

  const createBaseURL = (debate, event, iframe) => {
    const baseURl = getShareDomain(),
      path = createPath(debate, event, iframe);

    return baseURl + path;
  };

  const createParameters = (debate, event, iframe) => {
    // In case of a debate share we ignore event parameters
    if (!event || iframe) {
      return '';
    }

    const eventType = event.type || event.eventType || '';
    const eventStart = event.start || event.eventStart || '';
    const eventParam = encodeURIComponent(eventType + eventStart);

    return `event=${eventParam}`;
  };

  const createShareURL = function (debate, event, iframe = false) {
    const url = createBaseURL(debate, event, iframe),
      parameters = createParameters(debate, event, iframe);

    if (!parameters) {
      return url;
    }

    return url + '?' + parameters;
  };

  const createEmbedCode = (debate, event) => {
    const url = createShareURL(debate, event, true);

    return `<div style="position: relative; width: 100%; max-width: 650px;"><div style="padding-top: 56.25%; width: 100%;"><iframe style="position: absolute; left: 0; top: 0; width: 100%; height: 100%" src="${url}" frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div></div>`;
  };

  const createLocationEmbedCode = (location) => {
    const router = getService('router'),
      pathWith = router.generate,
      baseURl = getShareDomain();

    let pathname = pathWith('location-embedded', { location });

    if (getService('auth').isLoggedIn()) {
      pathname += '?hd=1';
    }

    return `<div style="position: relative; width: 100%; max-width: 650px;"><div style="padding-top: 56.25%; width: 100%;"><iframe style="position: absolute; left: 0; top: 0; width: 100%; height: 100%" src="${
      baseURl + pathname
    }" frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div></div>`;
  };

  const createFacebookURL = (debate, event) => {
    const url = createShareURL(debate, event);

    return 'https://www.facebook.com/dialog/share?app_id=263180220858829&display=popup&redirect_uri=https%3A%2F%2Ffacebook.com&href=' + encodeURIComponent(url);
  };

  const createTwitterURL = (debate, event) => {
    const url = createShareURL(debate, event);

    return 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url);
  };

  return {
    /**
     * Get configured share domain.
     * @returns {String}
     */
    getShareDomain,

    /**
     * Get embed code.
     * @param {String} url
     * @returns {String}
     */
    createEmbedCode,
    createLocationEmbedCode,

    createShareURL,
    createFacebookURL,
    createTwitterURL,
  };
};

/**
 * Public exports.
 * @type {ShareServiceFactory}
 */
export default ShareServiceFactory;
