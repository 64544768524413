let listeners = [],
  isInitialized = false,
  isSupported = false;

const hasSupport = () => isSupported;

/**
 * Handle full screen change event and dispatch to external listeners.
 * @param {Event} event
 */
const handleFullScreenChange = (event) => {
  const customEvent = {
    nativeEvent: event,
    fullScreenElement: fullScreenElement(),
  };

  for (let listener of listeners) {
    listener(customEvent);
  }
};

/**
 * Initialize.
 */
const init = () => {
  if (isInitialized) {
    return;
  } else {
    isInitialized = true;
  }

  const methods = ['exitFullscreen', 'webkitExitFullscreen', 'mozCancelFullScreen', 'msExitFullscreen'];

  const events = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange'];

  for (let i = 0; i < events.length; i++) {
    if (methods[i] in document) {
      document.addEventListener(events[i], handleFullScreenChange);
      isSupported = true;
    }
  }
};

/**
 * Add listener.
 * @param {Function} listener
 */
const addEventListener = (listener) => {
  if (typeof listener === 'function') {
    listeners.push(listener);
  }
};

/**
 * Remove listener.
 * @param {Function} listener
 */
const removeEventListener = (listener) => {
  listeners = listeners.filter((func) => func !== listener);
};

/**
 * Check if full screen is enabled.
 * @returns {Boolean}
 */
const fullScreenEnabled = () => {
  const isEnabled = Boolean(document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled);

  return isEnabled;
};

/**
 * Check if we can only set the video fullscreen.
 * @returns {Boolean}
 */
const videoFullScreenEnabled = (() => {
  let video = document.createElement('video'),
    fullScreenEnabled = 'webkitEnterFullScreen' in video;

  return () => {
    return fullScreenEnabled;
  };
})();

/**
 * Get element that is full screen.
 * @returns {HTMLElement|null}
 */
const fullScreenElement = () => {
  return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null;
};

/**
 * Enter full screen.
 * {HTMLElement} element
 */
const enterFullScreen = (element) => {
  const method = element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen || element.msRequestFullscreen;

  if (typeof method === 'function') {
    method.call(element);
  }
};

/**
 * Exit full screen.
 */
const exitFullScreen = () => {
  const method =
    document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.mozExitFullScreen || document.msExitFullscreen;

  if (typeof method === 'function') {
    method.call(document);
  }
};

/**
 * Public exports.
 */
export default {
  init,
  hasSupport,
  addEventListener,
  removeEventListener,
  fullScreenEnabled,
  videoFullScreenEnabled,
  fullScreenElement,
  enterFullScreen,
  exitFullScreen,
};
