import React from 'react';
import component from 'omniscient';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const className = (props.className || 'u-fill').concat(' Infographic');

    /* eslint-disable no-extra-parens */
    return (
      <svg x="0px" y="0px" viewBox="0 0 256 256" className={className}>
        <g className="u-noFill u-strokeWhite">
          <path d="M118.9,49H50.6V0h68.4V49z M51.5,48.1H118V0.9H51.5V48.1z" />
          <path d="M118.9,99.3H50.6V54h68.4V99.3z M51.5,98.4H118V54.9H51.5V98.4z" />
          <path d="M75.8,116.8H50.6v-9.4h25.2V116.8z M51.5,115.9h23.4v-7.5H51.5V115.9z" />
          <path d="M75.8,155H50.6v-35.2h25.2V155z M51.5,154h23.4v-33.3H51.5V154z" />
          <path d="M75.8,167.4H50.6v-9.2h25.2V167.4z M51.5,166.5h23.4v-7.4H51.5V166.5z" />
          <path d="M118.9,155H87.6v-10.3H119L118.9,155z M88.5,154H118v-8.5H88.6L88.5,154z" />
          <path d="M195.3,49h-58.9V0h58.9V49z M137.3,48.1h57.1V0.9h-57.1V48.1z" />
          <path d="M195.3,99.3h-58.9V54h58.9V99.3z M137.3,98.4h57.1V54.9h-57.1V98.4z" />
          <path d="M171,155h-34.6v-49.1H171V155z M137.3,154h32.8v-47.3h-32.8V154z" />
          <path d="M195.3,174.1h-58.9v-13h58.9V174.1z M137.3,173.2h57.1V162h-57.1V173.2z" />
          <path d="M195.3,256h-58.9v-77.8h58.9V256z M137.3,255.1h57.1v-76h-57.1V255.1z" />
          <path d="M205.4,256h-6.7v-77.8h6.7V256z M199.6,255.1h4.9v-76h-4.9V255.1z" />
          <path d="M205.4,179.1h-6.7v-26.2h6.7V179.1z M199.6,178.2h4.9v-24.4h-4.9V178.2z" />
          <path d="M118.9,256H50.6v-42.6h68.4V256z M51.5,255.1H118v-40.8H51.5V255.1z" />
          <path d="M118.9,205.5H50.6v-15.8l68.4-28V205.5z M51.5,204.6H118v-41.5l-66.5,27.2V204.6z" />
          <path d="M195.3,144h-18.9v-27.6h18.9V144z M177.4,143.1h17v-25.8h-17V143.1z" />
        </g>
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicRidder', render);
