import React, { type FC } from 'react';

import styles from './Notification.module.scss';

type Props = {
  title: string;
  message: string;
  headingLevel?: number;
  button?: React.ReactNode;
};

const Notification: FC<Props> = ({ title, message, headingLevel = 2, button }) => {
  const HeadingTag = `h${headingLevel}` as keyof JSX.IntrinsicElements;

  return (
    <div className={styles.notification}>
      <HeadingTag className={styles.title}>{title}</HeadingTag>
      <p className={styles.message}>{message}</p>
      {button}
    </div>
  );
};

export default Notification;
