import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

const AuthServiceFactory = function (reference, { clientId, userPoolId, region, identityPoolId, mxfBucket }) {
  let currentUser;

  const /**
     * The CognitoAuth instance
     * @type {CognitoUserPool}
     */
    auth = new CognitoUserPool({
      ClientId: clientId,
      UserPoolId: userPoolId,
    }),
    /**
     * The service definition
     * @type {Object}
     */
    AuthService = {
      /**
       * AWS Cognito Identity Credentials
       */
      identityCredentials: null,

      /**
       * Log in using an email and password combination.
       *
       * @param email
       * @param password
       * @returns {Promise<unknown>}
       */
      login: async (email, password) => {
        const authenticationData = {
          Username: email,
          Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const userData = {
          Username: email,
          Pool: auth,
        };

        currentUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
          currentUser.authenticateUser(authenticationDetails, {
            onSuccess: () => {
              AuthService._updateCredentials();
              resolve('success');
            },
            onFailure: (error) => {
              reject(error);
              AuthService._updateCredentials();
            },
            mfaRequired: function () {
              resolve('mfa_required');
            },
            mfaSetup: function () {
              resolve('mfa_setup');
            },
            newPasswordRequired: function () {
              resolve('new_password_required');
            },
          });
        });
      },

      /**
       * The system has requested a MFA authentication challenge. This call confirms the MFA code and authenticates the
       * user when valid.
       *
       * @param mfaCode
       * @returns {Promise<unknown>}
       */
      sendMfaCode: async (mfaCode) => {
        if (currentUser) {
          return new Promise((resolve, reject) => {
            currentUser.sendMFACode(mfaCode, {
              onSuccess: (session) => {
                AuthService._updateCredentials();
                resolve(session);
              },
              onFailure: (error) => {
                reject(error);
                AuthService._updateCredentials();
              },
            });
          });
        }

        throw new Error('User not logged in!');
      },

      /**
       * The system has requested that the user needs to change its password, this call changes the user password after
       * being authenticated.
       *
       * @param newPassword
       * @returns {Promise<unknown>}
       */
      changePasswordChallenge: async (newPassword) => {
        if (currentUser) {
          return new Promise((resolve, reject) => {
            currentUser.completeNewPasswordChallenge(
              newPassword,
              {},
              {
                onSuccess: (session) => {
                  AuthService._updateCredentials();
                  resolve(session);
                },
                onFailure: (error) => {
                  reject(error);
                },
              },
            );
          });
        }

        throw new Error('User not logged in!');
      },

      /**
       * Initiate the forgot password flow for the given user.
       *
       * @param email
       * @returns {Promise<unknown>}
       */
      forgotPassword: async (email) => {
        const userData = {
          Username: email,
          Pool: auth,
        };

        const user = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
          user.forgotPassword({
            onSuccess: function () {
              resolve();
            },
            onFailure: function (error) {
              reject(error);
            },
          });
        });
      },

      /**
       * Change the user password using a code.
       *
       * @param email
       * @param code
       * @param password
       * @returns {Promise<unknown>}
       */
      changePasswordWithCode: async (email, code, password) => {
        const userData = {
          Username: email,
          Pool: auth,
        };

        const user = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
          user.confirmPassword(code, password, {
            onSuccess: function () {
              resolve();
            },
            onFailure: function (error) {
              reject(error);
            },
          });
        });
      },

      /**
       * Sign out a user
       */
      logout: () => {
        if (currentUser) {
          currentUser.signOut();
          AuthService._updateCredentials();
        }
      },

      /**
       * Returns true if the user is logged in
       * @returns {boolean}
       */
      isLoggedIn: () => {
        return !!currentUser;
      },

      /**
       * Returns the Cognito Jwt token of the current logged-in session
       * @returns {String|null}
       */
      getCognitoJwtToken: () => {
        return currentUser.getSession((error, session) => {
          if (!session || !!error || !session.isValid()) {
            return null;
          }

          return session.getIdToken().getJwtToken();
        });
      },

      /**
       * Get a signed S3 URL using the current session details of the authenticated user.
       *
       * @param videoUrl
       * @returns {Promise<string>}
       */
      signVideoUrl: async (videoUrl) => {
        const s3Client = new S3Client({
          credentials: this.identityCredentials,
          region,
        });
        const parsedUrl = new URL(videoUrl);

        if (!AuthService.isLoggedIn()) {
          throw new Error('Must be logged in.');
        }

        const command = new GetObjectCommand({
          Bucket: mxfBucket,
          Key: parsedUrl.pathname.slice(1),
        });

        try {
          return await getSignedUrl(s3Client, command, { expiresIn: 60 * 15 });
        } catch (error) {
          throw new Error('Could not get signed url');
        }
      },

      /**
       * Update identity pool credentials
       * @private
       */
      _updateCredentials: () => {
        if (!currentUser) {
          reference.cursor().set('user', null);
          this.identityCredentials = null;
          return;
        }

        currentUser.getSession((error, session) => {
          if (!session || !!error || !session.isValid()) {
            reference.cursor().set('user', null);
            this.identityCredentials = null;
            return;
          }

          reference.cursor().set('user', currentUser.getUsername());

          const idToken = session.getIdToken();
          const payload = idToken.decodePayload();
          const group = payload?.['cognito:groups']?.[0];

          if (group && window._paq) {
            window._paq.push(['setCustomVariable', 3, 'Kamerbeelden', group, 'visit']);
          }

          this.identityCredentials = fromCognitoIdentityPool({
            identityPoolId: `${region}:${identityPoolId}`,
            logins: {
              [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken.getJwtToken(),
            },
            clientConfig: { region },
          });
        });
      },
    };

  // always validate an existing session when this service is loaded
  currentUser = auth.getCurrentUser();

  if (currentUser) {
    AuthService._updateCredentials();
  }

  return AuthService;
};

export default AuthServiceFactory;
