import moment from 'moment';

export async function getDataFromManifest(url, masterPlaylist = true) {
  if (!url) return Promise.resolve({});

  if (masterPlaylist) {
    const manifestBaseUrl = url.substring(0, url.lastIndexOf('/') + 1);
    const manifestData = await fetch(url).then((response) => response.text());
    const manifestPath = manifestData.match(/#EXT-X-STREAM-INF[^\n]*\n(.+)/)?.[1]; // Get first manifest

    if (!manifestPath) return Promise.resolve({});

    url = `${manifestBaseUrl}${manifestPath}`;
  }

  const text = await fetch(url).then((response) => response.text());
  const firstPdt = text.match(/#EXT-X-PROGRAM-DATE-TIME:(.+)/);
  const isLive = text.match(/#EXT-X-ENDLIST/) === null;

  return {
    firstPdt: firstPdt?.[1] ? moment(firstPdt[1]) : undefined,
    isLive,
  };
}
