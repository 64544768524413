import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import Link from '../Link/Link';

const handleClick = (href, target, callback) => (event) => {
  window.open(href, target);
  event.preventDefault();

  callback?.(event);
};

/**
 * Renders a link to an external website
 * @param  {object} props
 * @return {React.Component}
 */
export default component(({ children, className, href, onClick, ...rest }) => {
  const target = typeof window.cordova !== 'undefined' ? '_system' : '_blank';
  const props = {
    ...rest,
    href,
    target,
    onClick: handleClick(href, target, onClick),
    className: classNames('Link--external', 'Link--underline', className),
  };

  return <Link {...props}>{children}</Link>;
});
