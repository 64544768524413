import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import Icon from '../Icon/Icon';
import Button from '../Button/Button';

const /**
   * Renders the notification toggle
   * @param  {object} props
   * @return {React.Component}
   */
  render = function ({ className, toggled, pending, error, label, ...rest }) {
    const iconClassName = classNames('Button-icon', {
        'Icon--rotate': pending,
      }),
      props = {
        ...rest,
        className: classNames(
          'NotificationButton Button--notify u-flex u-row',
          {
            'is-toggled': toggled,
          },
          className,
        ),
      };

    let icon;

    icon = toggled ? 'bell-on' : 'bell';

    // pending request
    if (pending) {
      icon = 'bell-loading';
    }

    // error after request
    if (error) {
      icon = 'bell-error';
    }

    // alt icon
    if (rest.altIcon) {
      icon += '-alt';
    }

    return (
      <Button aria-checked={toggled} role="switch" {...props}>
        <Icon className={iconClassName} name={icon} />
        {label}
      </Button>
    );
  };

export default component('NotificationButton', render);
