import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import passive from '../../utils/passive';

/**
 * ScrollView component definition
 *
 * @type {Object}
 */
const componentDefinition = {
  /**
   * Get the default props for this component
   *
   * @returns {Object} defaultProps
   */
  getDefaultProps: function () {
    return {
      scrollbar: 'floating',
      direction: 'vertical',
    };
  },

  /**
   * Prop types
   */
  propTypes: {
    scrollbar: PropTypes.oneOf(['floating', 'native', 'static']),
    direction: PropTypes.oneOf(['vertical', 'horizontal', 'both']),
  },

  /**
   * getInitialState
   *
   * @returns {Object} initial state
   */
  getInitialState: function () {
    return {
      hTrackVisible: false,
      vTrackVisible: false,
      viewRef: createRef(),
      bodyRef: createRef(),
      horizontalTrackRef: createRef(),
      horizontalThumbRef: createRef(),
      verticalTrackRef: createRef(),
      verticalThumbRef: createRef(),
    };
  },

  /**
   * shouldComponentUpdate
   *
   * @returns {boolean} should update
   */
  shouldComponentUpdate: function () {
    return true;
  },

  /**
   * componentDidUpdate
   */
  componentDidUpdate: function () {
    this.handleResize();
    this.handleScroll();
  },

  /**
   * Handle resize proxy
   */
  handleResize: function () {
    handleResize(this);
  },

  /**
   * Handle scroll proxy
   * @param event
   */
  handleScroll: function (event) {
    handleScroll(this);

    if (this.props.onScroll && event) {
      this.props.onScroll(event);
    }
  },

  handleUserInteractionEvent: function (event) {
    const keyCodes = [32, 33, 34, 38, 40];
    const isScrollingKeyDownEvent = event.type === 'keydown' && keyCodes.includes(event.which);
    const isScrollingEvent = event.type === 'wheel' || event.type === 'touchmove' || isScrollingKeyDownEvent;

    if (isScrollingEvent) {
      this.dispatchUserScrollEvent();
    }
  },

  handleWheelEvent: function (event) {
    const body = this.state.bodyRef.current,
      menu = document.querySelector('.Menu'),
      infoPanel = document.querySelector('.InfoPanel'),
      deltaY = event.deltaMode === 1 ? event.deltaY * 18 : event.deltaY;

    let current = event.target;
    let isAppView = false;

    while ((current = current.parentNode)) {
      if (current.className === 'AppView') {
        isAppView = true;
        break;
      }
    }

    if (!isAppView || !body) {
      return;
    }

    if (menu?.contains(event.target)) {
      return;
    }

    if (infoPanel?.contains(event.target)) {
      return;
    }

    if (isNaN(deltaY)) {
      this.dispatchUserScrollEvent();

      return;
    }

    const position = this.getPosition(),
      newPositionY = position.y + deltaY;

    event.preventDefault();
    this.scrollTo(position.x, newPositionY);
    this.dispatchUserScrollEvent();
  },

  /**
   * Handle mousedown event on the vertical scroll track -> perform single scroll.
   * @param {MouseEvent} event
   */
  handleTrackMouseDown: function (event) {
    const body = this.state.bodyRef.current;
    const verticalThumb = this.state.verticalThumbRef.current;

    body.scrollTop = this.determineMouseScrollPosition(verticalThumb.offsetHeight / 2, event);
  },

  /**
   * Handle mousedown event on the vertical scroll thumb -> initiate mouse scroll.
   * @param {MouseEvent} event
   */
  handleThumbMouseDown: function (event) {
    // Make sure the event doesn't reach the track.
    event.stopPropagation();

    const verticalThumb = this.state.verticalThumbRef.current,
      rect = verticalThumb.getBoundingClientRect(),
      mouseStartOffset = event.clientY - rect.top;

    // Store position relative to thumb.
    this.mouseScrollInfo = {
      direction: 'vertical',
      mouseStartOffset: mouseStartOffset,
    };

    // Activate global mouse listeners.
    this.setMouseScrollListeners(true);
  },

  /**
   * Handle mousemove element on body element when using mouse scroll.
   * @param {MouseEvent} event
   */
  handleMouseMove: function (event) {
    const body = this.state.bodyRef.current,
      { mouseStartOffset } = this.mouseScrollInfo;

    body.scrollTop = this.determineMouseScrollPosition(mouseStartOffset, event);
  },

  /**
   * Stop using mousescroll on mouseup and mouseleave.
   */
  handleMouseScrollStop: function () {
    // Nullify info.
    this.mouseScrollInfo = null;

    // Deactivate global mouse listeners.
    this.setMouseScrollListeners(false);
  },

  /**
   * Handle mouse enter.
   */
  handleMouseEnter: function () {
    this.setFloatingScrollBar();
  },

  /**
   * Show or hide floating scrollbar.
   */
  setFloatingScrollBar: function () {
    const { direction, scrollbar } = this.props;

    if (scrollbar !== 'floating' || direction !== 'vertical') {
      return;
    }

    this.setState({ hTrackVisible: true });
  },

  /**
   * Activate or deactivate global mouse listeners.
   * @param activate
   */
  setMouseScrollListeners: function (activate) {
    const method = activate ? 'addEventListener' : 'removeEventListener';

    document.body[method]('mousemove', this.handleMouseMove);
    document.body[method]('mouseup', this.handleMouseScrollStop);
    document.body[method]('mouseleave', this.handleMouseScrollStop);
  },

  /**
   * Determine new scroll position for mouse scroll.
   * @param {Number} correction
   * @param {MouseEvent} event
   */
  determineMouseScrollPosition: function (correction, event) {
    const body = this.state.bodyRef.current,
      verticalTrack = this.state.verticalTrackRef.current,
      rect = verticalTrack.getBoundingClientRect(),
      relativeMousePos = event.clientY - rect.top - correction;

    return (relativeMousePos / rect.height) * body.scrollHeight;
  },

  dispatchUserScrollEvent: function () {
    if (typeof this.props.onUserScrollEvent === 'function') {
      this.props.onUserScrollEvent();
    }
  },

  scrollTo: function (x, y) {
    const body = this.state.bodyRef.current;

    body.scrollLeft = x || 0;
    body.scrollTop = y || 0;
  },

  getPosition: function () {
    const body = this.state.bodyRef.current;

    return {
      x: body.scrollLeft,
      y: body.scrollTop,
    };
  },

  /**
   * componentDidMount
   */
  componentDidMount: function () {
    const body = this.state.bodyRef.current,
      verticalTrack = this.state.verticalTrackRef.current,
      verticalThumb = this.state.verticalThumbRef.current,
      externalWheelHandling = this.props.externalWheelHandling;

    body?.addEventListener('scroll', this.handleScroll);
    body?.addEventListener('touchstart', this.handleUserInteractionEvent);

    // Standard scrolling for non-mousewheel mouses.
    verticalTrack?.addEventListener('mousedown', this.handleTrackMouseDown);
    verticalThumb?.addEventListener('mousedown', this.handleThumbMouseDown);

    window.addEventListener('keydown', this.handleUserInteractionEvent);
    window.addEventListener('resize', this.handleResize);

    body?.addEventListener('mouseenter', this.handleMouseEnter);

    if (externalWheelHandling) {
      document.addEventListener('wheel', this.handleWheelEvent, passive(false));
    } else {
      body?.addEventListener('wheel', this.handleUserInteractionEvent);
    }

    // initial resize
    this.handleResize();
  },

  /**
   * componentWillUnmount
   */
  componentWillUnmount: function () {
    const body = this.state.bodyRef.current,
      verticalTrack = this.state.verticalTrackRef.current,
      verticalThumb = this.state.verticalThumbRef.current,
      externalWheelHandling = this.props.externalWheelHandling;

    body?.removeEventListener('scroll', this.handleScroll);
    body?.removeEventListener('touchstart', this.handleUserInteractionEvent);

    verticalTrack?.removeEventListener('mousedown', this.handleTrackMouseDown);
    verticalThumb?.removeEventListener('mousedown', this.handleThumbMouseDown);

    this.setMouseScrollListeners(false);

    window.removeEventListener('keydown', this.handleUserInteractionEvent);
    window.removeEventListener('resize', this.handleResize);

    body?.removeEventListener('mouseenter', this.handleMouseEnter);

    if (externalWheelHandling) {
      document.removeEventListener('wheel', this.handleWheelEvent);
    } else {
      body?.removeEventListener('wheel', this.handleUserInteractionEvent);
    }
  },
};

/**
 * Renders a view container which can be scrolled by the user, allowing it to be larger than the physical display.
 *
 * @param {object} props
 * @param {string} props.direction The scrollbar direction(s). Allowed options: 'both', 'vertical' or 'horizontal'.
 * @param {string} props.scrollbar The scrollbar type. Allowed options: 'native', 'floating' or 'static'.
 *
 * @example
 *
 * ```jsx
 * <ScrollView direction="vertical">
 *   <h1>Title</h1>
 *   <p>scrollable content</p>
 * </ScrollView>
 * ```
 *
 * @return {React.Component}
 */
export default component(
  'ScrollView',
  componentDefinition,
  function ({ children, className, bodyProps = {}, scrollbar, direction, scrollbarOffset, bodyOffset, externalWheelHandling, onUserScrollEvent, ...rest }) {
    const noOffset = scrollbarOffset === 0,
      bodyStyle = {
        paddingBottom: bodyOffset || '',
      };

    this.timeout = {
      horizontal: null,
      vertical: null,
    };

    const props = {
      className: classNames(
        'ScrollView',
        `ScrollView--${direction}`,
        `ScrollView--${scrollbar}`,
        {
          'ScrollView--noOffset': noOffset,
        },
        className,
      ),
      ...rest,
    };

    const vTrackClassName = classNames('ScrollView-track ScrollView-track--vertical', {
      'is-hidden': !this.state.vTrackVisible || noOffset,
    });

    const hTrackClassName = classNames('ScrollView-track ScrollView-track--horizontal', {
      'is-hidden': !this.state.hTrackVisible || noOffset,
    });

    return (
      <div {...props} ref={this.state.viewRef}>
        <div className="ScrollView-wrapper">
          <div className="ScrollView-body" style={bodyStyle} ref={this.state.bodyRef} data-scroll-body="true" {...bodyProps}>
            {children}
          </div>
        </div>
        <div className={vTrackClassName} ref={this.state.verticalTrackRef}>
          <div className="ScrollView-thumb" ref={this.state.verticalThumbRef} />
        </div>
        <div className={hTrackClassName} ref={this.state.horizontalTrackRef}>
          <div className="ScrollView-thumb" ref={this.state.horizontalThumbRef} />
        </div>
      </div>
    );
  },
);

/**
 * Handle the body scroll event
 *
 * @param {React.Component} context
 */
function handleScroll(context) {
  const body = context.state.bodyRef.current;
  const hthumb = context.state.horizontalThumbRef.current;
  const htrack = context.state.horizontalTrackRef.current;
  const vthumb = context.state.verticalThumbRef.current;
  const vtrack = context.state.verticalTrackRef.current;
  const view = context.state.viewRef.current;

  if (body && view) {
    scrollAxis('vertical', context, view, body, vthumb, vtrack);
    scrollAxis('horizontal', context, view, body, hthumb, htrack);
  }
}

/**
 * Handle the window resize event
 *
 * @param {React.Component} context
 */
function handleResize(context) {
  const body = context.state.bodyRef.current;
  const hthumb = context.state.horizontalThumbRef.current;
  const htrack = context.state.horizontalTrackRef.current;
  const vthumb = context.state.verticalThumbRef.current;
  const vtrack = context.state.verticalTrackRef.current;
  const view = context.state.viewRef.current;

  if (body && view) {
    resizeAxis('vertical', context, view, body, vthumb, vtrack);
    resizeAxis('horizontal', context, view, body, hthumb, htrack);
  }
}

/**
 * Resize a track axis
 *
 * @param {string} axis
 * @param {React.Component} context
 * @param {HTMLElement} view
 * @param {HTMLElement} body
 * @param {HTMLElement} thumb
 * @param {HTMLElement} track
 */
function resizeAxis(axis, context, view, body, thumb, track) {
  let viewportSize,
    bodySize,
    contentSize,
    trackSize,
    contentRatio,
    thumbSize,
    visible,
    trackVisibleState = 'vTrackVisible';
  const thumbMinSize = 25;
  let sizeProp = 'height';

  if ('horizontal' === axis) {
    sizeProp = 'width';
    trackVisibleState = 'hTrackVisible';
  }

  if ('native' === context.props.scrollbar) {
    return;
  }

  viewportSize = view[`offset${ucFirst(sizeProp)}`];
  contentSize = body[`scroll${ucFirst(sizeProp)}`];
  bodySize = body[`offset${ucFirst(sizeProp)}`];
  trackSize = track[`offset${ucFirst(sizeProp)}`];
  contentRatio = viewportSize / contentSize;
  visible = contentSize > bodySize;

  thumbSize = Math.min(trackSize, Math.max(thumbMinSize, trackSize * contentRatio));

  // setState if track needs to be hidden
  if (context.state[trackVisibleState] !== visible) {
    context.setState({ [trackVisibleState]: visible });
  }

  // update thumb size
  thumb.style[sizeProp] = `${thumbSize}px`;
}

/**
 * Scroll a track axis
 *
 * @param {string} axis
 * @param {React.Component} context
 * @param {HTMLElement} view
 * @param {HTMLElement} body
 * @param {HTMLElement} thumb
 * @param {HTMLElement} track
 */
function scrollAxis(axis, context, view, body, thumb, track) {
  let viewportSize,
    trackSize,
    thumbRatio,
    scrollOffset,
    scrollSize,
    thumbOffset,
    thumbSize,
    transform,
    sizeProp = 'height',
    offsetProp = 'top',
    translateProp = 'translateY';

  if ('horizontal' === axis) {
    sizeProp = 'width';
    offsetProp = 'left';
    translateProp = 'translateX';
  }

  if ('native' === context.props.scrollbar) {
    return;
  }

  viewportSize = view[`offset${ucFirst(sizeProp)}`];
  trackSize = track[`offset${ucFirst(sizeProp)}`];
  scrollSize = body[`scroll${ucFirst(sizeProp)}`];
  thumbSize = thumb[`offset${ucFirst(sizeProp)}`];
  scrollOffset = body[`scroll${ucFirst(offsetProp)}`];

  thumbRatio = Math.max(0, Math.min(1, scrollOffset / (scrollSize - viewportSize)));
  thumbOffset = (trackSize - thumbSize) * thumbRatio;

  transform = `${translateProp}(${thumbOffset}px)`;

  // no change
  if (thumb.style.transform === transform) {
    return;
  }

  // hide thumb when scrollbar is floating
  if ('floating' === context.props.scrollbar) {
    // remove is-hidden class
    thumb.classList.remove('is-hidden');

    // clear hide timeout
    clearTimeout(context.timeout[axis]);

    // set timeout to add is-hidden class after x milliseconds
    context.timeout[axis] = setTimeout(function () {
      thumb.classList.add('is-hidden');
    }, 2000);
  }

  // set transform style
  thumb.style.transform = transform;
}

/**
 * Uppercase first char of string
 * @param {string} string
 * @returns {string} Uppercased string
 */
function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
