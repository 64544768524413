/**
 * Creates a renderloop which executes callback on render
 * @param  {Function} callback
 * @return {Object}
 */
const createRenderLoop = function (callback) {
  let args;
  let renderIsRequested = false;

  return {
    /**
     * Adds a callback to requestAnimationFrame
     * if one isn't already queued
     */
    render: function () {
      args = [].slice.call(arguments, 0);

      if (renderIsRequested) {
        return;
      }

      // Fallback to setTimeout if requestAnimationFrame is not present
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(handleAnimationFrame);
      } else {
        window.setTimeout(handleAnimationFrame, 0);
      }

      renderIsRequested = true;
    },
  };

  /**
   * Executes the callback
   */
  function handleAnimationFrame() {
    callback.apply(this, args);

    renderIsRequested = false;
  }
};

export default createRenderLoop;
