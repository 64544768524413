import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

import VotingExplanationButton from '../../../containers/VotingExplanationButton/VotingExplanationButton';
import LiveDataDisclaimer from '../../../components/LiveDataDisclaimer/LiveDataDisclaimer';
import SpinnerWrapper from '../../../components/SpinnerWrapper/SpinnerWrapper';

import RoundVote from './DebateVotingRoundVote';

const /**
   * Renders list of votes
   * @param  {String}   type             voting type
   * @param  {Cursor}   votes            Votes cursor
   * @param  {Cursor}   politicians      Politicians cursor
   * @param  {Cursor}   parties          Parties cursor
   * @param  {Function} getService
   * @return {Array}                     List of react elements
   */
  renderVotes = (type, votes, politicians, parties, getService) => {
    const votesData = votes.toJS(),
      domElements = votesData.map(([key, vote]) => {
        return <RoundVote key={key} id={key} politiciansDict={politicians} type={type} vote={vote} partiesDict={parties} getService={getService} />;
      });

    return domElements;
  },
  definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      pathWith: PropTypes.func.isRequired,
    },
  },
  render = function (props) {
    const { type, votes, dataQuality, parties, politicians } = props,
      { getService } = this.context;

    if (type === 'Hoofd') {
      return (
        <SpinnerWrapper>
          <div className="Voting-votes u-width600 u-horizontalCenter">
            <div style={{ margin: '0 10px' }}>
              {dataQuality === 'live' ? (
                <LiveDataDisclaimer icon={<VotingExplanationButton />} />
              ) : (
                <VotingExplanationButton className="Voting-votesInfoButton" />
              )}
            </div>
            <ul className="List">{renderVotes(type, votes, politicians, parties, getService)}</ul>
          </div>
        </SpinnerWrapper>
      );
    }

    return (
      <div className="Voting-votes u-width600 u-horizontalCenter">
        <div style={{ margin: '0 10px' }}>
          {dataQuality === 'live' ? <LiveDataDisclaimer icon={<VotingExplanationButton />} /> : <VotingExplanationButton className="Voting-votesInfoButton" />}
        </div>
        <ul className="List">{renderVotes(type, votes, politicians, parties, getService)}</ul>
      </div>
    );
  };

export default component('DebateVotingRoundList', definition, render);
