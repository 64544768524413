import { add, sub, format, isAfter } from 'date-fns';
import type { AppAgendaDebate, AppDebate } from '@debatdirect/core-ts/types/debate';

import type { ScreenshotConfig } from '../types/app';

/**
 * Rounds the seconds component of a Date object to the nearest specified interval.
 */
export const roundSecondsInterval = (date: Date, secondsInterval: number): Date => {
  const rounded = Math.floor(date.getSeconds() / secondsInterval) * secondsInterval;
  const newDate = new Date(date);

  newDate.setSeconds(rounded);

  return newDate;
};

const getScreenshotMoment = ({ status, startedAt, endedAt }: AppDebate | AppAgendaDebate, config: ScreenshotConfig) => {
  const { offsetInSeconds, refreshInterval } = config;

  switch (status) {
    case 'planned':
      return null;
    case 'live':
      // An offset is used to prevent 502 errors when the screenshot is requested too soon
      return roundSecondsInterval(sub(new Date(), { seconds: offsetInSeconds }), refreshInterval);
    case 'vod': {
      const screenshotMoment = add(startedAt, { seconds: offsetInSeconds });
      return isAfter(screenshotMoment, endedAt) ? startedAt : screenshotMoment; // Fail-safe
    }
  }
};

export const getDebateScreenshotUri = (debate: AppDebate | AppAgendaDebate, prefix: string | undefined, config: ScreenshotConfig) => {
  const { bitrate, fileType } = config;
  const screenshotMoment = getScreenshotMoment(debate, config);

  if (!screenshotMoment || !prefix) return null;

  const screenshotDate = format(screenshotMoment, 'yyyy-MM-dd'); // I.E.: 2021-12-31
  const screenshotTime = format(screenshotMoment, 'HH:mm:ssxx'); // I.E.: 23:56:50+0100

  return `${prefix}/${bitrate}/${screenshotDate}/${screenshotTime}.${fileType}`;
};

export const getDebateMetadata = (debate: AppDebate | AppAgendaDebate) => {
  const date = format(debate.debateDate, 'd MMM');
  const startTime = format(debate.startedAt || debate.startsAt, 'HH:mm');
  const endTime = format(debate.endedAt || debate.endsAt, 'HH:mm');
  const runningTime = `${startTime} - ${endTime}`;
  let description = '';
  if (!debate.locationName) {
    description = debate.debateType;
  } else if (debate.categoryNames.length === 0) {
    description = `${debate.debateType} in de ${debate.locationName}`;
  } else {
    description = `${debate.categoryNames.join(' - ')} in de ${debate.locationName}`;
  }
  return [date, runningTime, description];
};
