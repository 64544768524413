/**
 * Get the data dirEntry
 *
 * @returns {Promise<DirectoryEntry>}
 */
const getDataDirEntry = () => {
  return new Promise((resolve, reject) => {
    /**
     * @param {DirectoryEntry} dataDirEntry
     */
    const handleSuccess = (dataDirEntry) => {
      dataDirEntry.getDirectory(
        'debatdirect',
        { create: true, exclusive: false },
        (dirEntry) => {
          resolve(dirEntry);
        },
        (error) => reject(error),
      );
    };

    const handleError = (error) => reject(error);

    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, handleSuccess, handleError);
  });
};

/**
 * Open a file using the file opener plugin
 *
 * @param filename
 * @param mimeType
 * @returns {Promise}
 */
export const openFile = (filename, mimeType) =>
  new Promise((resolve, reject) => {
    if (!window.cordova || !window.cordova.plugins || !window.cordova.plugins.fileOpener2) {
      return reject('fileOpener plugin not installed');
    }

    getDataDirEntry().then((dirEntry) => {
      dirEntry.getFile(
        filename,
        { create: false },
        function (fileEntry) {
          cordova.plugins.fileOpener2.showOpenWithDialog(decodeURI(fileEntry.nativeURL), mimeType, {
            error: (error) => reject(error),
            success: () => resolve(),
          });
        },
        (error) => reject(error),
      );
    });
  });

/**
 * Clear the temp data directory
 * @returns {Promise}
 */
export const clearTmpDirectory = () =>
  new Promise((resolve, reject) => {
    return getDataDirEntry().then((dirEntry) => {
      dirEntry.removeRecursively(resolve, reject);
    });
  });

/**
 * Download file to the local device
 * @param url
 * @param fileName
 * @returns {Promise}
 */
export const downloadUrl = (url, fileName) =>
  new Promise((resolve, reject) => {
    if (!window.cordova || typeof window.resolveLocalFileSystemURL === 'undefined') {
      return reject('no cordova/file plugin');
    }

    function writeFile(fileEntry) {
      const fileTransfer = new FileTransfer();
      const fileURL = fileEntry.nativeURL;

      fileTransfer.download(
        url,
        fileURL,
        () => {
          resolve(fileName);
        },
        (error) => reject(error),
      );
    }

    return getDataDirEntry().then((dirEntry) => {
      dirEntry.getFile(
        fileName,
        { create: true },
        function (fileEntry) {
          // get file
          fileEntry.file(
            (file) => {
              // if file already exists, we don't need to download
              if (file.size > 0) {
                return resolve(fileName);
              }

              writeFile(fileEntry);
            },
            () => {
              writeFile(fileEntry);
            },
          );
        },
        (error) => reject(error),
      );
    });
  });

/**
 * Check if a file exists
 * @param filename
 * @returns {Promise<Boolean>}
 */
export const fileExists = (filename) =>
  new Promise((resolve, reject) => {
    if (!window.cordova || typeof window.resolveLocalFileSystemURL === 'undefined') {
      return reject('no cordova/file plugin');
    }

    return getDataDirEntry().then((dirEntry) => {
      dirEntry.getFile(
        filename,
        { create: false },
        function () {
          resolve(true);
        },
        () => {
          resolve(false);
        },
      );
    });
  });
