import React from 'react';
import classNames from 'classnames';

export default (viewBox, icon) =>
  ({ className, ...props }) => {
    const svgClassName = classNames('SvgIcon', className);

    return (
      <svg className={svgClassName} viewBox={viewBox} {...props} xmlns="http://www.w3.org/2000/svg">
        {icon}
      </svg>
    );
  };
