import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Tabs extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.tabsRef = React.createRef();
  }

  clickHandler = (event) => {
    if (this.props.disabled) {
      return false;
    }

    const index = parseInt(event.currentTarget.getAttribute('data-index'), 10);

    this.props.onChange(index);
  };

  render() {
    return (
      <div
        ref={this.tabsRef}
        className="Tabs"
        role="tablist"
        onKeyDown={(event) => {
          const { index, onChange, children } = this.props;
          const maxIndex = children.length - 1;

          if (event.key === 'ArrowRight') {
            const next = index === maxIndex ? 0 : index + 1;

            onChange(next);
            this.tabsRef.current.querySelector(`[data-index="${next}"]`).focus();
          } else if (event.key === 'ArrowLeft') {
            const prev = index === 0 ? maxIndex : index - 1;

            onChange(prev);
            this.tabsRef.current.querySelector(`[data-index="${prev}"]`).focus();
          }
        }}
      >
        {React.Children.map(this.props.children, (child, index) => {
          return React.cloneElement(child, {
            active: this.props.index === index,
            onClick: this.clickHandler,
            index,
          });
        })}
      </div>
    );
  }
}
