import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { isBefore } from 'date-fns';

import { trackEvent } from '../../common';
import Popover from '../Popover/Popover';
import SlideTransition from '../Animation/SlideTransition';
import MenuList from '../MenuList/MenuList';
import MenuItem from '../MenuList/MenuItem';
import { Download, Done, Facebook, Link, Twitter, Close, Share } from '../../icons/index';
import createLookupTable from '../../utils/lib/createLookupTable';
import { copyToClipboard } from '../../utils/copyToClipboard';
import MenuListHeader from '../MenuList/MenuListHeader';

export default class SpeakerEventPopover extends React.Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  };

  state = {
    firstEnter: true,
    activeIndex: 0,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ activeIndex: 0 });
    } else if (prevProps.open && !this.props.open) {
      // reset state
      this.setState({
        firstEnter: true,
      });
    }
  }

  getSpeakerTitle() {
    const { event } = this.props;
    const time = moment(event.eventStart).format('HH:mm:ss');
    const politicians = this.context.getCursor(['data', 'actors', 'politicians']);
    const politiciansLookUp = createLookupTable(politicians.toJS(), 'id');
    const politician = politiciansLookUp[event.objectId];
    const politicianName = politician ? `${politician.firstName} ${politician.lastName}` : 'Onbekende spreker';

    return `${time} / ${politicianName}`;
  }

  close() {
    this.props.onClose();
  }

  goToIndex(index) {
    this.setState({
      activeIndex: index,
      firstEnter: false,
    });
  }

  shareFacebook() {
    const { getService } = this.context;
    const href = getService('share').createFacebookURL(this.props.debate, this.props.event);

    if (href) {
      trackEvent('speaker', 'shareFacebook', this.getSpeakerTitle(), 1);
      window.open(href, '_blank');
    }
  }

  shareTwitter() {
    const { getService } = this.context;
    const href = getService('share').createTwitterURL(this.props.debate, this.props.event);

    if (href) {
      trackEvent('speaker', 'shareTwitter', this.getSpeakerTitle(), 1);
      window.open(href, '_blank');
    }
  }

  copyUrl() {
    const url = this.context.getService('share').createShareURL(this.props.debate, this.props.event);
    const copied = copyToClipboard(url);

    trackEvent('speaker', 'copyUrl', this.getSpeakerTitle(), 1);

    this.setState({
      copiedUrl: copied,
      copiedUrlFailure: !copied,
      copiedEmbedCode: false,
      copiedEmbedCodeFailure: false,
    });

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () =>
        this.setState({
          copiedUrl: false,
          copiedUrlFailure: false,
        }),
      2000,
    );
  }

  shareSpeakerEvent() {
    // open native share in Cordova app
    if (window.cordova && typeof navigator.share === 'function') {
      const shareService = this.context.getService('share');

      try {
        navigator.share(shareService.createShareURL(this.props.debate, this.props.event), 'Debat Direct');
        trackEvent('speaker', 'shareNative', this.getVotingTitle(), 1);
      } catch (error) {
        if (import.meta.env.MODE !== 'production') {
          console.log(error);
        }
      }

      // close popover
      return this.close();
    }

    this.goToIndex(1);
  }

  renderCopyUrlMenuItem() {
    const { copiedUrl, copiedUrlFailure } = this.state;

    if (copiedUrl) {
      return <MenuItem label="Link is gekopieerd" icon={<Done />} active />;
    }

    if (copiedUrlFailure) {
      return <MenuItem label="Kopiëren mislukt" icon={<Close style={{ color: 'red' }} />} />;
    }

    return <MenuItem label="Link kopiëren" icon={<Link />} onClick={() => this.copyUrl()} />;
  }

  renderDownloadFragmentMenuItem() {
    const { debate } = this.props;
    const { getService, getCursor } = this.context;
    const isMobileWeb = getService('platform').isMobileWeb();

    const debateDate = debate.get('debateDate');
    const downloadFragmentDebateFromDate = getCursor(['config', 'dateLimit']).get('downloadFragmentDebateFromDate');
    const isDownloadDisabled = isBefore(moment(debateDate).toDate(), downloadFragmentDebateFromDate);

    if (isMobileWeb) {
      return null;
    }

    return <MenuItem label="Spreekmoment downloaden" icon={<Download />} onClick={() => this.openDownloadModal()} disabled={isDownloadDisabled} />;
  }

  openDownloadModal() {
    this.context.getCursor(['ui', 'downloadModal']).set('open', true).set('marker', this.props.event).set('initiator', this.props.initiator);

    this.close();
  }

  renderShareMenu() {
    return (
      <SlideTransition in={this.state.activeIndex === 1} key="share" className="SlideRight">
        <MenuListHeader onClick={() => this.goToIndex(0)} label="Spreekmoment delen" />
        <hr className="u-m0" />
        <MenuList>
          <MenuItem label="Twitter" icon={<Twitter />} onClick={() => this.shareTwitter()} />
          <MenuItem label="Facebook" icon={<Facebook />} onClick={() => this.shareFacebook()} />
          {this.renderCopyUrlMenuItem()}
        </MenuList>
      </SlideTransition>
    );
  }

  renderMainMenu() {
    return (
      <SlideTransition
        in={this.state.activeIndex === 0}
        appear={this.state.firstEnter}
        timeout={this.state.firstEnter ? 0 : 300}
        key="main"
        className={this.state.firstEnter ? '' : 'SlideLeft'}
      >
        <MenuList>
          <MenuItem label="Spreekmoment delen" icon={<Share />} onClick={() => this.shareSpeakerEvent()} />
          {this.renderDownloadFragmentMenuItem()}
        </MenuList>
      </SlideTransition>
    );
  }

  render() {
    return (
      <Popover
        open={this.props.open}
        anchor={this.props.anchor}
        anchorOffset={this.props.anchorOffset}
        transformOrigin={this.props.transformOrigin}
        onClose={() => this.close()}
        initiator={this.props.initiator}
      >
        <div style={{ position: 'relative' }}>
          {this.renderMainMenu()}
          {this.renderShareMenu()}
        </div>
        <hr className="u-m0" />
        <MenuList>
          <MenuItem className="MenuItem--footer" label="Annuleren" icon={<Close />} onClick={() => this.close()} />
        </MenuList>
      </Popover>
    );
  }
}
