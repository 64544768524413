import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

import ScrollView from '../../components/ScrollView/SmartScrollView';
import Icon from '../../components/Icon/Icon';
import InternalLink from '../../components/InternalLink/InternalLink';

const definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
    },

    componentWillUnmount: function () {
      this.context.getCursor(['ui']).update('error', () => Immutable.fromJS({}));
    },
  },
  /**
   * Renders the SupportPage
   * @return {React.Component}
   */
  render = function () {
    const pathTo = this.context.pathTo;

    return (
      <div className="Layer ViewStack-layer Layer--white">
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          <ScrollView>
            <div className="Main-content Content GenericErrorPage">
              <p className="Text">De pagina die u bezocht, kan niet worden getoond.</p>
              <InternalLink href={pathTo('home')}>
                Terug naar de index
                <Icon name="arrow" className="u-fillBlue u-inline" width={8.57} height={10} aria-hidden="true" />
              </InternalLink>
            </div>
          </ScrollView>
        </div>
      </div>
    );
  };

export default component.withDefaults({ cursorField: 'cursor' })('GenericErrorComponent', definition, render);
