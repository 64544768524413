const EMPTY = 'empty',
  RENDERING = 'rendering',
  COMPLETE = 'complete';

const sortOnEventStart = (a, b) => b._date.format('x') - a._date.format('x');

/**
 * Scroll page.
 */
class ScrollPage {
  /**
   * Constructor.
   * @param {HTMLElement} container
   * @param {String} key
   * @param {Array} items
   */
  constructor(container, key, items) {
    this._container = container;
    this._key = key;
    this._items = items;

    this._renderTimer = null;
    this._renderState = EMPTY;

    this.onRendered = null;

    this._addedItems = [];
  }

  /**
   * Key.
   * @returns {String}
   */
  get key() {
    return this._key;
  }

  /**
   * Get items.
   * @returns {Array}
   */
  get items() {
    return this._items;
  }

  /**
   * Get last item.
   * @returns {AbstractScrollItem}
   */
  get lastItem() {
    return this._items[this._items.length - 1];
  }

  /**
   * Get active item.
   * @returns {AbstractScrollItem}
   */
  get activeItem() {
    const activeItems = this._items.filter((item) => item.isActive);
    return activeItems.length === 1 ? activeItems[0] : null;
  }

  /**
   * Check rendering state.
   * @returns {Boolean}
   */
  get isRendered() {
    return this._renderState === COMPLETE;
  }

  /**
   * Get container.
   * @returns {HTMLElement}
   */
  get container() {
    return this._container;
  }

  /**
   * Initialize.
   */
  init() {
    for (let item of this._items) {
      item.setPage(this);
    }
  }

  /**
   * Dispose.
   */
  dispose() {
    clearTimeout(this._renderTimer);
  }

  /**
   * Render items.
   */
  render() {
    if (this._renderState === EMPTY) {
      this._container.style.display = 'block';
      this._renderItems(0);
    }
  }

  removeItem(item) {
    const addedItemsIndex = this._addedItems.indexOf(item);

    // item is still being rendered
    if (addedItemsIndex !== -1) {
      this._addedItems.splice(addedItemsIndex, 1);
    }

    const itemIndex = this._items.indexOf(item);

    if (itemIndex !== -1) {
      this._items.splice(itemIndex, 1);
    }

    item.clear();
  }

  /**
   * Clear items.
   */
  clear() {
    this.onRendered = null;
    clearTimeout(this._renderTimer);

    this._container.style.display = 'none';
    this._renderState = EMPTY;

    // If clear is called during a rendering timeout, we want to make sure they are added to the list.
    if (this._addedItems.length) {
      this.insertItems(this._addedItems);
      this._addedItems = [];
    }

    this._clearItems();
  }

  /**
   * Get index for given item.
   * @param {AbstractScrollItem} item
   * @returns {Number}
   */
  getItemIndex(item) {
    return this._items.indexOf(item);
  }

  /**
   * Insert new items.
   * @param {Array} newItems
   */
  insertItems(newItems) {
    const container = this._container;

    switch (this._renderState) {
      case EMPTY:
        this._items.unshift(...newItems);
        this._items = this._items.toSorted(sortOnEventStart);
        break;
      case RENDERING:
        this._addedItems = [...newItems, ...this._addedItems];
        break;
      case COMPLETE:
        this._items.unshift(...newItems);
        this._items = this._items.toSorted(sortOnEventStart);

        for (let event of newItems) {
          event.render();
          const targetSiblingIndex = this.getItemIndex(event);

          if (container.hasChildNodes()) {
            container.insertBefore(event.container, container.childNodes[targetSiblingIndex]);
          } else {
            container.appendChild(event.container);
          }
        }
        break;
      default:
    }
  }

  /**
   * Render items.
   * @param {Number} startIndex
   * @private
   */
  _renderItems(startIndex) {
    this._renderState = RENDERING;

    let container = this._container,
      items = this._items,
      startTime = Date.now();

    for (let i = startIndex; i < items.length; i++) {
      let item = items[i];

      item.render();
      container.appendChild(item.container);

      if (Date.now() - startTime > 100) {
        // Give the browser some breathing space and make sure it updates the UI before we continue.
        this._renderTimer = setTimeout(() => this._renderItems(i + 1), 50);
        return;
      }
    }

    this._renderTimer = setTimeout(() => this._afterItemsRender(), 50);
  }

  /**
   * Perform tasks after rendering of items.
   * @private
   */
  _afterItemsRender() {
    this._renderState = COMPLETE;

    // Render items that were added during rendering phase.
    if (this._addedItems.length > 0) {
      this.insertItems(this._addedItems);
      this._addedItems = [];
    }

    if (typeof this.onRendered === 'function') {
      this.onRendered({ target: this });
    }
  }

  /**
   * Clear items.
   * @private
   */
  _clearItems() {
    for (let item of this._items) {
      item.clear();
    }
  }
}

/**
 * Public exports.
 * @type {ScrollPage}
 */
export default ScrollPage;
