import React from 'react';
import classNames from 'classnames';

const TextField = ({ className, label, value, onChange, onClick, readOnly, type, name, endOrdonnement, error, ...rest }) => {
  return (
    <div className={classNames('TextField', { 'TextField--error': error }, className)}>
      <label onClick={onClick}>
        <div className="TextField-input">
          {label}
          <input value={value} type={type} name={name} onChange={onChange} readOnly={readOnly} {...rest} />
        </div>
        {endOrdonnement && <div className="TextField-endOrdonmement">{endOrdonnement}</div>}
      </label>
    </div>
  );
};

export default TextField;
