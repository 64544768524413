import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { isBefore } from 'date-fns';

import { trackEvent } from '../../common';
import Popover from '../Popover/Popover';
import SlideTransition from '../Animation/SlideTransition';
import MenuList from '../MenuList/MenuList';
import MenuListHeader from '../MenuList/MenuListHeader';
import MenuItem from '../MenuList/MenuItem';
import { Download, Done, Facebook, Link, Twitter, Close, Share } from '../../icons/index';
import CreateFragmentHelper from '../../services/InteractionAdditionsService/helpers/CreateFragmentHelper';
import { copyToClipboard } from '../../utils/copyToClipboard';

export default class VotingRoundPopover extends React.Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  };

  state = {
    firstEnter: true,
    activeIndex: 0,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ activeIndex: 0 });
    } else if (prevProps.open && !this.props.open) {
      // reset state
      this.setState({
        firstEnter: true,
      });
    }
  }

  getVotingTitle() {
    const { debate, votingRound } = this.props;
    const maxChars = 40;
    const title = votingRound ? votingRound.title : 'Onbekende stemmingstitel';
    const titleFormatted = title.length >= maxChars - 3 ? `${title.substring(0, maxChars - 3)}...` : title;
    const time = moment(debate.get('startsAt')).format('HH:mm:ss');

    return `${time} / ${titleFormatted}`;
  }

  close() {
    this.props.onClose();
  }

  goToIndex(index) {
    this.setState({
      activeIndex: index,
      firstEnter: false,
    });
  }

  generateEvent() {
    if (!this.props.votingRound) {
      return null;
    }

    return {
      type: 'voting_round',
      start: this.props.votingRound.startedAt,
    };
  }

  shareFacebook() {
    const { getService } = this.context;
    const href = getService('share').createFacebookURL(this.props.debate, this.generateEvent());

    if (href) {
      trackEvent('voting', 'shareFacebook', this.getVotingTitle(), 1);
      window.open(href, '_blank');
    }
  }

  shareTwitter() {
    const { getService } = this.context;
    const href = getService('share').createTwitterURL(this.props.debate, this.generateEvent());

    if (href) {
      trackEvent('voting', 'shareTwitter', this.getVotingTitle(), 1);
      window.open(href, '_blank');
    }
  }

  copyUrl() {
    const url = this.context.getService('share').createShareURL(this.props.debate, this.generateEvent());
    const copied = copyToClipboard(url);

    trackEvent('voting', 'copyUrl', this.getVotingTitle(), 1);

    this.setState({
      copiedUrl: copied,
      copiedUrlFailure: !copied,
      copiedEmbedCode: false,
      copiedEmbedCodeFailure: false,
    });

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () =>
        this.setState({
          copiedUrl: false,
          copiedUrlFailure: false,
        }),
      2000,
    );
  }

  downloadFragment(params) {
    this.context.getCursor(['ui', 'downloadModal']).merge({
      open: true,
      fromDate: params.startsAt,
      toDate: params.endsAt,
      initiator: this.props.initiator,
    });

    this.close();
  }

  renderCopyUrlMenuItem() {
    const { copiedUrl, copiedUrlFailure } = this.state;

    if (copiedUrl) {
      return <MenuItem label="Link is gekopieerd" icon={<Done />} active />;
    }

    if (copiedUrlFailure) {
      return <MenuItem label="Kopiëren mislukt" icon={<Close style={{ color: 'red' }} />} />;
    }

    return <MenuItem label="Link kopiëren" icon={<Link />} onClick={() => this.copyUrl()} />;
  }

  renderDownloadFragmentMenuItem() {
    const { getService, getCursor } = this.context;
    const { debate, votingRound } = this.props;

    const debateDate = debate.get('debateDate');
    const downloadFragmentDebateFromDate = getCursor(['config', 'dateLimit']).get('downloadFragmentDebateFromDate');
    const isDownloadDisabled = isBefore(moment(debateDate).toDate(), downloadFragmentDebateFromDate);

    // disable download on mobile web
    if (getService('platform').isMobileWeb()) {
      return null;
    }

    const config = getCursor(['config', 'fragments']).toJS();
    const params = { debate: debate.toJS(), votingRound };
    const title = votingRound?.title;
    const fragmentParams = CreateFragmentHelper.getFragmentParam(config, params, title, getService);

    // params are not valid, don't render download button
    if (!fragmentParams) {
      return null;
    }

    return <MenuItem label="Fragment downloaden" icon={<Download />} onClick={() => this.downloadFragment(fragmentParams)} disabled={isDownloadDisabled} />;
  }

  shareVotingRound() {
    // open native share in Cordova app
    if (window.cordova && typeof navigator.share === 'function') {
      const shareService = this.context.getService('share');

      try {
        navigator.share(shareService.createShareURL(this.props.debate, this.generateEvent()), 'Debat Direct');
        trackEvent('voting', 'shareNative', this.getVotingTitle(), 1);
      } catch (error) {
        if (import.meta.env.MODE !== 'production') {
          console.log(error);
        }
      }

      // close popover
      return this.close();
    }

    this.goToIndex(1);
  }

  renderShareMenu() {
    return (
      <SlideTransition in={this.state.activeIndex === 1} key="share" className="SlideRight">
        <MenuListHeader onClick={() => this.goToIndex(0)} label="Uitslag delen" />
        <hr className="u-m0" />
        <MenuList>
          <MenuItem label="Twitter" icon={<Twitter />} onClick={() => this.shareTwitter()} />
          <MenuItem label="Facebook" icon={<Facebook />} onClick={() => this.shareFacebook()} />
          {this.renderCopyUrlMenuItem()}
        </MenuList>
      </SlideTransition>
    );
  }

  renderMainMenu() {
    return (
      <SlideTransition
        in={this.state.activeIndex === 0}
        appear={this.state.firstEnter}
        timeout={this.state.firstEnter ? 0 : 300}
        key="main"
        className={this.state.firstEnter ? '' : 'SlideLeft'}
      >
        <MenuList>
          <MenuItem label="Uitslag delen" icon={<Share />} disabled={this.state.fragmentCreating} onClick={() => this.shareVotingRound()} />
          {this.renderDownloadFragmentMenuItem()}
        </MenuList>
      </SlideTransition>
    );
  }

  render() {
    return (
      <Popover
        open={this.props.open}
        anchor={this.props.anchor}
        anchorOffset={this.props.anchorOffset}
        transformOrigin={this.props.transformOrigin}
        onClose={() => this.close()}
        initiator={this.props.initiator}
      >
        <div style={{ position: 'relative' }}>
          {this.renderMainMenu()}
          {this.renderShareMenu()}
        </div>
        <hr className="u-m0" />
        <MenuList>
          <MenuItem className="MenuItem--footer" label="Annuleren" icon={<Close />} onClick={() => this.close()} />
        </MenuList>
      </Popover>
    );
  }
}
