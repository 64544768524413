import { browserHistory, hashHistory } from 'react-router';

const history = window.cordova ? hashHistory : browserHistory,
  flattenRoutes = (routes, prefix) => {
    return routes.reduce((acc, current) => {
      var children = current.children || current.childRoutes,
        indexRoute = current.indexRoute;

      prefix = prefix || '';

      current.fullPath = prefix + '/' + current.path;
      current.fullPath = current.fullPath.replace(/\/{2,}/g, '/');

      acc.push(current);

      if (indexRoute) {
        indexRoute.fullPath = current.fullPath;
        acc.push(indexRoute);
      }

      if (children) {
        acc = acc.concat(flattenRoutes(children, current.fullPath));
      }

      return acc;
    }, []);
  };

/**
 * Router stats.
 */
(function () {
  let lastPath = window.location.pathname;

  history.listen((location) => {
    if (location.pathname === lastPath) {
      return;
    }

    lastPath = location.pathname;

    if (!window._paq) {
      return;
    }

    window._paq.push(['setCustomUrl', lastPath]);
    window._paq.push(['trackPageView']);
  });
})();

/**
 * Router factory.
 */
function RouterFactory(reference, rootRoute) {
  let route = {};
  let params = {};
  let homeRoute = 'home';

  const flattenedRoutes = flattenRoutes([rootRoute]);

  const navigate = function (path) {
    if (!path) {
      path = '';
    }

    history.push(path);
  };

  const replace = function (path) {
    // When we use push() in the context of an embedded player, this will lead to unexpected and unwanted back button behavior.
    // Using replace solves this.
    history.replace(path || '');
  };

  const setHomeRoute = function (name) {
    // This will override the default homepage
    homeRoute = name;
  };

  /**
   * Go back to last visited index page. If there isn't one, we go to home.
   * @param {Array} routeHistory - history is created and maintained by AppView (todo: should be handled by Router).
   */
  const goBackToIndex = function (routeHistory) {
    let toRoute = null,
      toRouteParams = {},
      i = 1;

    for (; i < routeHistory.length; i++) {
      let [routeName, routeParams] = routeHistory[i];

      if (routeName === homeRoute) {
        break;
      }

      if (routeName === 'votings') {
        toRoute = 'votings';
        break;
      }

      if (routeName === 'search') {
        toRoute = 'search';
        break;
      }

      if (routeName === 'index-for-date') {
        toRoute = 'index-for-date';
        toRouteParams.date = routeParams.date;
        break;
      }

      // navigate to category page from debate page
      if (routeName === 'category') {
        toRoute = 'category';
        toRouteParams.category = routeParams.category;
        toRouteParams.date = routeParams.date;
        break;
      }
    }

    if (i === 1 && toRoute) {
      history.goBack();

      return;
    }

    const path = generate(toRoute || homeRoute, toRouteParams);

    navigate(path);
  };

  const generate = function (routeName, params) {
    const route = flattenedRoutes.find((route) => route.name === routeName);

    if (!route) {
      return;
    }

    let template = route.fullPath.replace('^', '').replace('$', '');

    template = Object.entries(params || {}).reduce((acc, [key, value]) => {
      const regexp = new RegExp('\\(?:' + key + '\\)?');

      return acc.replace(regexp, value || '');
    }, template);

    // remove optional params
    return template.replace(/\(:\w+\)/g, '');
  };

  const setRouteAndParams = function (_route, _params) {
    route = _route;
    params = _params;
  };

  const getRoute = function () {
    return route;
  };

  const isEmbedded = function (route) {
    if (!route) {
      return false;
    }

    return route.name === 'debate-embedded' || route.name.indexOf('location-embedded') > -1;
  };

  const getCurrentHttpHost = function () {
    const protocol = window.location.protocol;

    if ((protocol || '').indexOf('http') !== 0) {
      // If not HTTP(S), we might be on a file URL or another protocol
      return '';
    }

    const hostname = window.location.hostname,
      port = parseInt(window.location.port);

    return protocol + '//' + hostname + (port ? ':' + port : '');
  };

  const getParams = function () {
    return params;
  };

  return {
    generate,
    navigate,
    replace,
    goBackToIndex,
    setRouteAndParams,
    setHomeRoute,
    getRoute,
    isEmbedded,
    getCurrentHttpHost,
    getParams,
    history,
  };
}

export default RouterFactory;
