import { browserHistory, hashHistory } from 'react-router';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import type { DebateLocation } from '@debatdirect/typings';

const history = window.cordova ? hashHistory : browserHistory;

// This is the same slugify function used in the API endpoints. Do not change this function.
// Exception: replaceAll is replaced with a regex to avoid a polyfill
const slugify = (str: string) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[&\\#,+()$~%.'"*?<>{}'']/g, '')
    .replace(/\s+/g, '-')
    .replace(/:/g, '-')
    .replace(/\//g, '-')
    .replace(/-{2,}/g, '-')
    .replace(/([^A-Za-z0-9-])+/g, '');
};

export const debateUrl = (debate: AppAgendaDebate, location: DebateLocation | undefined) => {
  const categorySlug = slugify(debate.categoryNames[0]);
  const params = {
    date: debate.debateDateStr,
    category: categorySlug,
    location: location?.slug ?? '',
    debate: debate.slug,
  };

  const path = [params.date, params.category, params.location, params.debate, 'onderwerp'].filter(Boolean).join('/');

  return `/${path}`;
};

export const navigate = (path: string) => history.push(path);
