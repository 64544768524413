import UAParser from 'ua-parser-js';

import config from './config';

const addScript = (src, callback) => {
  const script = document.createElement('script');

  script.onload = function () {
    if (typeof callback === 'function') {
      callback();
    }
  };
  script.src = src;

  document.body.appendChild(script);
};

const addCss = (href) => {
  const link = document.createElement('link');

  link.setAttribute('rel', 'stylesheet');
  link.href = href;

  document.body.appendChild(link);
};

export const injectPlayer = () => {
  addScript(`${config.theoplayer.url}/THEOplayer.chromeless.js`, () => {
    window._paq = window._paq || [];
    window._paq.push(['setCustomVariable', 2, 'THEOPlayer_version', window.THEOplayer.version, 'visit']);
  });
};

export const injectFullPlayer = (callback) => {
  addScript(`${config.theoplayer.url}/THEOplayer.js`, () => {
    window._paq = window._paq || [];
    window._paq.push(['setCustomVariable', 2, 'THEOPlayer_version', window.THEOplayer.version, 'visit']);

    addCss(`${config.theoplayer.url}/ui.css`);

    if (typeof callback === 'function') {
      callback();
    }
  });
};

export const injectStats = () => {
  window._paq = window._paq || [];
  window._paq.push(['enableLinkTracking']);
  window._paq.push(['trackPageView']);
  window._paq.push(['setIgnoreClasses', 'no-tracking']);
  window._paq.push(['disableCookies']);
  window._paq.push(['setCustomVariable', 1, 'Version', __APP_VERSION__, 'visit']);
  window._paq.push(['setTrackerUrl', `${config.stats.baseUrl}/matomo.php`]);
  window._paq.push(['setSiteId', 1]);

  if (config.stats.trackErrors === '1') {
    window._paq.push(['enableJSErrorTracking']);
  }

  addScript(`${config.stats.baseUrl}/matomo.js`);
  addScript(`${config.stats.baseUrl}/plugins/MediaAnalytics/tracker.min.js`);
};

export const injectCastSender = () => {
  const parser = new UAParser();
  const os = parser.getOS();
  const browser = parser.getBrowser();

  // there is a bug with React and the Cast SDK on iOS Chrome which breaks the website completely
  // since Safari doesn't support casting anyway, we can disable the Cast SDK on all iOS devices.
  // @https://stackoverflow.com/questions/48635643/how-to-disable-avoid-shimming-of-createevent-by-google-cast-sender-api-on-ios
  if (os.name && os.name.toLowerCase() === 'ios') {
    return;
  }

  // Chromecast is only supported on Chrome 72 and above
  // https://support.google.com/chromecast/answer/3228332
  if (browser.name === 'Chrome' && Number(browser.major) < 72) {
    return;
  }

  window.gStaticUrl = config.chromecast.gStaticUrl;

  addScript(`/static/js/cast_sender.js?loadCastFramework=1`);
};
