import React from 'react';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    // The this.setState(null) is a dirty hack to prevent this component
    // from throwing a warning taken from:
    // https://github.com/reactjs/reactjs.org/issues/3028#issuecomment-786576491
    this.setState(null);
    this.handleError(error, errorInfo);
  }

  async handleError(error, errorInfo) {
    /* global __LOAD_SENTRY__ */
    if (__LOAD_SENTRY__) {
      const Sentry = window.cordova ? await import('@sentry/capacitor') : await import('@sentry/react');

      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    } else {
      console.error('Uncaught error:', error, errorInfo);
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
