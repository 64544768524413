import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import InternalLink from '../../components/InternalLink/InternalLink';

const definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
    },

    componentWillUnmount: function () {
      this.context.getCursor(['ui', 'error']).update(() => Immutable.fromJS({}));
    },
  },
  /**
   * DebateErrorComponent render method
   * @param cursor
   * @returns {XML}
   */
  render = function () {
    return (
      <main className="Layer ViewStack-layer">
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          <SmartScrollView>
            <div className="Main-content Content">
              <header className="Content-header Header">
                <h1 className="Heading Heading--primary">Debat niet gevonden</h1>
              </header>
              <p className="Text">Debat Direct heeft geen informatie over het debat dat u zoekt.</p>
              <p className="Text">
                Voor een overzicht van actuele debatten gaat u naar de&nbsp;
                <InternalLink className="Link Link--underline" href={this.context.pathTo('home')}>
                  indexpagina.
                </InternalLink>
              </p>
            </div>
          </SmartScrollView>
        </div>
      </main>
    );
  };

export default component.withDefaults({ cursorField: 'cursor' })('DebateErrorComponent', definition, render);
