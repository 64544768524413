/**
 * This module is used in case an error occurs before the actual app mount.
 */
export default {
  CONNECTION_ERROR: 'U heeft geen netwerkverbinding. Zodra uw apparaat verbinding met het internet heeft zal Debat Direct automatisch worden gestart.',
  DATA_ERROR:
    'Er heeft zich een fout voorgedaan bij het ophalen van de gegevens. <br/>Meld dit probleem aan ons op <a class="Link Link--blue" href="mailto:support@debat.direct">support@debat.direct</a>',

  show: function (message) {
    document.querySelector('.Prompt-heading').innerHTML = 'Foutmelding';
    document.querySelector('.Prompt-description').innerHTML = message;
    document.querySelector('.SplashScreen').classList.add('has-overlay');
  },

  hide: function () {
    const promptHeading = document.querySelector('.Prompt-heading'),
      promptDescription = document.querySelector('.Prompt-description'),
      splashScreen = document.querySelector('.SplashScreen');

    promptHeading && (promptHeading.innerHTML = '');
    promptDescription && (promptDescription.innerHTML = '');

    splashScreen?.classList.remove('has-overlay');
  },
};
