import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import InternalLink from '../../components/InternalLink/InternalLink';
import Icon from '../../components/Icon/Icon';

class WeekNavigation extends Component {
  static contextTypes = {
    getCursor: PropTypes.func.isRequired,
  };

  state = {
    weeks: [],
  };

  componentDidMount() {
    const config = this.context.getCursor(['config', 'date']);
    const fromDate = moment(config.get('fromDate')).startOf('week');
    const totalWeeks = moment().diff(fromDate, 'weeks') + 1;
    const weeks = [];

    for (let i = 0; i < totalWeeks; i += 1) {
      weeks.unshift(fromDate.clone().add(i, 'weeks'));
    }

    this.setState({ weeks });
  }

  /**
   * @param {moment.Moment} date
   * @returns {*}
   */
  renderWeek = (date) => {
    const week = date.get('week');
    const fromDay = date.format('DD');
    const fromMonth = date.format('MMMM');
    const toDate = date.clone().add(6, 'days');
    const toDay = toDate.format('DD');
    const toMonth = toDate.format('MMMM');

    const weekString = week < 10 ? `0${week}` : week;
    const fromString = fromMonth !== toMonth ? `${fromDay} ${fromMonth}` : fromDay;
    const toString = `${toDay} ${toMonth}`;

    return (
      <div className="Kamerbeelden-weekNav-item" key={`week_of_${date}`}>
        <InternalLink href={`/search?van=${encodeURIComponent(date.format('YYYY-MM-DD'))}&tot=${encodeURIComponent(toDate.format('YYYY-MM-DD'))}`}>
          WEEK {weekString} | {fromString} - {toString} <Icon className="u-inline" name="arrow" width="8.57" height="12" aria-hidden="true" />
        </InternalLink>
      </div>
    );
  };

  render() {
    return <nav>{this.state.weeks.map(this.renderWeek)}</nav>;
  }
}

export default WeekNavigation;
