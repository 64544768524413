import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

import Icon from '../../components/Icon/Icon';
import PoliticianImage from '../../components/PoliticianImage/PoliticianImage';

const definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
    },

    propTypes: {
      politician: PropTypes.object.isRequired,
    },

    shouldComponentUpdate: function () {
      return false;
    },
  },
  /**
   * PoliticianListItem render function.
   * @param {Object} props
   * @returns {React.Component}
   */
  render = function ({ politician }) {
    const getService = this.context.getService,
      id = politician.get('id'),
      firstName = politician.get('firstName'),
      lastName = politician.get('lastName'),
      name = firstName + ' ' + lastName,
      onClick = (event) => {
        event.preventDefault();
        getService('info-panel').show('PoliticianPanel', {
          politicianId: id,
          initialHeadingLevel: 2,
        });
      },
      onKeyPress = (event) => {
        const enterOrSpace = event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar' || event.which === 13 || event.which === 32;

        if (enterOrSpace) {
          // prevent scroll when using the Space bar
          event.preventDefault();

          getService('info-panel').show('PoliticianPanel', {
            politicianId: id,
            initialHeadingLevel: 2,
          });
        }
      };

    return (
      <li className="List-item Politician">
        <div role="button" className="u-flex u-grow u-row u-pointer Link--infoPanel" tabIndex="0" onClick={onClick} onKeyPress={onKeyPress}>
          <PoliticianImage className="Politician-photo u-flex" politicianId={id} size={50} />
          <div className="u-flex u-col u-centerSelf u-grow u-windowsInline">
            <div className="Politician-name">
              <span className="Politician-name__Label">{name}</span>
              <Icon className="Politician-name__Arrow u-inline" name="arrow" width="8.57" height="12" aria-hidden="true" />
            </div>
          </div>
        </div>
      </li>
    );
  };

export default component('PoliticianListItem', definition, render);
