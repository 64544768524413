import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { isBefore } from 'date-fns';

import { trackEvent } from '../../common';
import Popover from '../Popover/Popover';
import SlideTransition from '../Animation/SlideTransition';
import MenuList from '../MenuList/MenuList';
import MenuListHeader from '../MenuList/MenuListHeader';
import MenuItem from '../MenuList/MenuItem';
import { Code, Done, Download, Facebook, Link, Twitter, Close, Share } from '../../icons/index';
import { strMaxChars } from '../../utils/formatting';
import { copyToClipboard } from '../../utils/copyToClipboard';

export default class DebateSubjectPopover extends React.Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  };

  state = {
    firstEnter: true,
    activeIndex: 0,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ activeIndex: 0 });
    } else if (prevProps.open && !this.props.open) {
      // reset state
      this.setState({
        firstEnter: true,
      });
    }
  }

  close() {
    this.props.onClose();
  }

  getDebateTitle() {
    if (!this.props.debate) return;

    const maxChars = 40;
    const name = this.props.debate.get('name');
    const nameFormatted = strMaxChars(name, maxChars);
    const date = moment(this.props.debate.get('startsAt')).format('YYYY-MM-DD');

    return `${date} / ${nameFormatted}`;
  }

  goToIndex(index) {
    this.setState({
      activeIndex: index,
      firstEnter: false,
    });
  }

  shareFacebook() {
    const { getService } = this.context;
    const href = getService('share').createFacebookURL(this.props.debate);

    if (href) {
      trackEvent('debate', 'shareFacebook', this.getDebateTitle(), 1);
      window.open(href, '_blank');
    }
  }

  shareTwitter() {
    const { getService } = this.context;
    const href = getService('share').createTwitterURL(this.props.debate);

    if (href) {
      trackEvent('debate', 'shareTwitter', this.getDebateTitle(), 1);
      window.open(href, '_blank');
    }
  }

  copyUrl() {
    const url = this.context.getService('share').createShareURL(this.props.debate);
    const copied = copyToClipboard(url);

    trackEvent('debate', 'copyUrl', this.getDebateTitle(), 1);

    this.setState({
      copiedUrl: copied,
      copiedUrlFailure: !copied,
    });

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () =>
        this.setState({
          copiedUrl: false,
          copiedUrlFailure: false,
        }),
      2000,
    );
  }

  shareDebate() {
    // open native share in Cordova app
    if (window.cordova && typeof navigator.share === 'function') {
      const shareService = this.context.getService('share');

      try {
        navigator.share(shareService.createShareURL(this.props.debate), 'Debat Direct');
        trackEvent('debate', 'shareNative', this.getDebateTitle(), 1);
      } catch (error) {
        if (import.meta.env.MODE !== 'production') {
          console.log(error);
        }
      }

      // close popover
      return this.close();
    }

    this.goToIndex(1);
  }

  openDownloadModal() {
    this.context.getCursor(['ui', 'downloadModal']).merge({ open: true, initiator: this.props.initiator });

    this.close();
  }

  embedDebate() {
    this.context.getCursor(['ui', 'embedModal']).merge({ open: true, initiator: this.props.initiator });

    this.close();
  }

  renderCopyUrlMenuItem() {
    const { copiedUrl, copiedUrlFailure } = this.state;

    if (copiedUrl) {
      return <MenuItem label="Link is gekopieerd" icon={<Done />} active />;
    }

    if (copiedUrlFailure) {
      return <MenuItem label="Kopiëren mislukt" icon={<Close style={{ color: 'red' }} />} />;
    }

    return <MenuItem label="Link kopiëren" icon={<Link />} onClick={() => this.copyUrl()} />;
  }

  renderShareMenu() {
    return (
      <SlideTransition in={this.state.activeIndex === 1} key="share" className="SlideRight">
        <MenuListHeader onClick={() => this.goToIndex(0)} label="Delen" />
        <hr className="u-m0" />
        <MenuList>
          <MenuItem label="Twitter" icon={<Twitter />} onClick={() => this.shareTwitter()} />
          <MenuItem label="Facebook" icon={<Facebook />} onClick={() => this.shareFacebook()} />
          {this.renderCopyUrlMenuItem()}
        </MenuList>
      </SlideTransition>
    );
  }

  renderMainMenu() {
    const startedAt = this.props.debate?.has('startedAt');
    const downloadFragmentDebateFromDate = this.context.getCursor(['config', 'dateLimit']).get('downloadFragmentDebateFromDate');
    const isDownloadDisabled = !this.props.debate?.has('debateDate') || isBefore(this.props.debate.get('debateDate'), downloadFragmentDebateFromDate);

    return (
      <SlideTransition
        in={this.state.activeIndex === 0}
        appear={this.state.firstEnter}
        timeout={this.state.firstEnter ? 0 : 300}
        key="main"
        className={this.state.firstEnter ? '' : 'SlideLeft'}
        onEntered={this.handleMainMenuEntered}
      >
        <MenuList>
          <MenuItem label="Delen" icon={<Share />} onClick={() => this.shareDebate()} />
          <MenuItem label="Embedden" icon={<Code />} onClick={() => this.embedDebate()} />
          <MenuItem
            label="Debat of fragment downloaden"
            icon={<Download />}
            disabled={!startedAt || isDownloadDisabled}
            onClick={() => this.openDownloadModal()}
          />
        </MenuList>
      </SlideTransition>
    );
  }

  render() {
    return (
      <Popover
        open={this.props.open}
        anchor={this.props.anchor}
        anchorOffset={this.props.anchorOffset}
        transformOrigin={this.props.transformOrigin}
        initiator={this.props.initiator}
        onClose={() => this.close()}
      >
        <div style={{ position: 'relative' }}>
          {this.renderMainMenu()}
          {this.renderShareMenu()}
        </div>
        <hr className="u-m0" />
        <MenuList>
          <MenuItem className="MenuItem--footer" label="Annuleren" icon={<Close />} onClick={() => this.close()} />
        </MenuList>
      </Popover>
    );
  }
}
