import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import { fetchJson, transformAppData } from '@debatdirect/core-ts/utils/apiTransformers';

import useApiData from './useApiData';

const useApp = () => {
  const { config } = useAppProvider();

  return useApiData({
    key: ['app'],
    queryFn: () => fetchJson(`${config.api.baseUrl}/app`),
    transformFn: transformAppData,
  });
};

export default useApp;
