import moment from 'moment';
const getOption = (key) => (window.runtime && window.runtime[key]) || import.meta.env[key];

/* global __PLAYER_URL__, __PLAYER_LICENSE__ */
const playerUrl = __PLAYER_URL__ || import.meta.env.REACT_APP_THEOPLAYER_URL;
const license = __PLAYER_LICENSE__ || '';

export default {
  api: {
    enabled: true,
    baseUrl: getOption('REACT_APP_API_URL'),
    cdnBaseUrl: getOption('REACT_APP_CDN_URL'),
  },
  liveApi: {
    enabled: true,
    url: getOption('REACT_APP_LIVE_URL'),
    worker: '/static/js/worker.js',
    maxDelay: 300000,
    interval: 250,
    maxAge: 900000,
  },
  socketService: {
    enabled: true,
    eventTimeout: 2000,
  },
  social: {
    facebookAppId: getOption('REACT_APP_FACEBOOK_APP_ID'),
  },
  fragments: {
    url: getOption('REACT_APP_STREAM_SHARE_URL'),
    eventAddToEndSeconds: 5,
    eventSubtractFromStartSeconds: 0,
  },
  theoplayer: {
    url: playerUrl,
    license,
  },
  chromecast: {
    gStaticUrl: getOption('REACT_APP_CHROMECAST_GSTATIC_URL'),
    appId: getOption('REACT_APP_CHROMECAST_APPLICATION_ID'),
  },
  stats: {
    baseUrl: getOption('REACT_APP_PIWIK_URL'),
    trackErrors: getOption('REACT_APP_PIWIK_TRACK_ERRORS'),
  },
  auth: {
    clientId: getOption('REACT_APP_AUTH_CLIENT_ID'),
    userPoolId: getOption('REACT_APP_AUTH_USER_POOL_ID'),
    region: getOption('REACT_APP_AUTH_REGION'),
    identityPoolId: getOption('REACT_APP_AUTH_IDENTITY_POOL_ID'),
    mxfBucket: getOption('REACT_APP_AUTH_MXF_BUCKET'),
  },
  share: {
    url: getOption('REACT_APP_BASE_URL'),
  },
  date: {
    fromDate: getOption('REACT_APP_DATE_FROM_DATE'),
  },
  search: {
    url: getOption('REACT_APP_ELASTIC_URL'),
    debateIndex: getOption('REACT_APP_ELASTIC_DEBATE_INDEX'),
    eventIndex: getOption('REACT_APP_ELASTIC_EVENT_INDEX'),
  },
  staticUrl: getOption('REACT_APP_STATIC_URL'),
  baseUrl: getOption('REACT_APP_BASE_URL') || '',
  sentry: {
    dsn: getOption('REACT_APP_SENTRY_DSN'),
    environment: getOption('REACT_APP_SENTRY_ENVIRONMENT'),
    release: __APP_VERSION__,
  },
  dateLimit: {
    downloadUnmarkedDebateFromDate: moment(getOption('REACT_APP_FEATURE_DOWNLOAD_UNMARKED_DEBATE_FROM_DATE') || '1970-01-01').toDate(),
    downloadFragmentDebateFromDate: moment(getOption('REACT_APP_FEATURE_DOWNLOAD_FRAGMENT_DEBATE_FROM_DATE') || '1970-01-01').toDate(),
  },
  licensesPageEnabled: getOption('REACT_APP_ENABLE_LICENSES_PAGE'),
  queryClientConfig: {
    defaultOptions: {
      queries: {
        staleTime: 15 * 60 * 1000, // 15 minutes
      },
    },
  },
  screenshot: {
    bitrate: 400, // equals 640x360
    offsetInSeconds: 10,
    refreshInterval: 10,
    fileType: 'jpg',
  },
};
