import React, { useState } from 'react';

import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import Link from '../Link/Link';
import CircularProgress from '../Progress/CircularProgress';
import TextPanel from '../TextPanel/TextPanel';

const UsernamePasswordForm = ({ onSubmit, hasError, isSubmitting, onForgotPasswordClick, onCloseFeedbackClick, passwordUpdatedFeedback }) => {
  const [values, setValues] = useState({
    username: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    setValues((current) => ({ ...current, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="Heading">Kamerbeelden Login</h2>
      <p>Vul uw Kamerbeelden inloggegevens in:</p>
      {passwordUpdatedFeedback ? (
        <TextPanel variant="info" className="u-mb20" onClose={onCloseFeedbackClick}>
          Uw wachtwoord is aangepast.
        </TextPanel>
      ) : null}
      <TextField
        className="u-mb20"
        name="username"
        type="email"
        value={values.username}
        label="E-mail"
        onChange={handleChange}
        readOnly={isSubmitting}
        error={hasError}
        required
      />
      <TextField
        className="u-mb20"
        name="password"
        type="password"
        value={values.password}
        label="Wachtwoord"
        onChange={handleChange}
        error={hasError}
        readOnly={isSubmitting}
        required
      />
      {hasError ? (
        <TextPanel variant="error" className="u-mb20">
          De inloggegevens zijn niet juist, probeer het opnieuw
        </TextPanel>
      ) : null}
      <Button className="Button--medium Button--block Button--primary u-mb20" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="Button-icon">
            <CircularProgress size={16} />
          </span>
        ) : null}
        <span className="Button-label">Inloggen</span>
      </Button>
      <Link className="Link--blue" onClick={onForgotPasswordClick}>
        Wachtwoord vergeten?
      </Link>
    </form>
  );
};

export default UsernamePasswordForm;
