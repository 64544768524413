import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import component from 'omniscient';

import Prompt from '../../Prompt/Prompt';
import Button from '../../../components/Button/Button';
import OnClickOutside from '../../OnClickOutside/OnClickOutside';

const definition = {
    promptRef: createRef(),

    contextTypes: {
      getService: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
    },

    mixins: [OnClickOutside],

    /**
     * Close prompt with given status
     * @param {string} status
     */
    closePrompt: function (status) {
      const { getService } = this.context;

      getService('prompt').dismiss(status, 'VideoError');
    },

    /**
     * Close button handler
     */
    closePromptHandler: function () {
      this.closePrompt('dismiss');
    },

    handleClickOutside: function () {
      this.closePrompt('dismiss');
    },

    getClickOutsideElement: function () {
      return this.promptRef.current;
    },
  },
  render = function () {
    return (
      <Prompt
        alignment="centerCenter"
        visible={true}
        role="alertdialog"
        aria-labelledby="prompt-title"
        aria-describedby="prompt-description"
        aria-live="assertive"
        forwardRef={this.promptRef}
      >
        <div>
          <h2 className="Heading Prompt-heading" id="prompt-title">
            Foutmelding
          </h2>
          <p className="Text Prompt-description" id="prompt-description">
            Er is iets fout gegaan, de video kan niet worden afgespeeld.
          </p>
        </div>
        <div className="Prompt-actions">
          <Button className="Prompt-action Prompt-action--primary" onClick={this.closePromptHandler}>
            <div className="Button-label">Sluiten</div>
          </Button>
        </div>
      </Prompt>
    );
  };

export default component.withDefaults({ cursorField: 'cursor' })('VideoError', definition, render);
