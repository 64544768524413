import Immutable from 'immutable';

import CancelablePromise from './CancelablePromise';

const /**
   * RefreshService factory method
   * @param {Reference}  reference  Immstruct reference to the data cursor
   */
  RefreshServiceFactory = function (reference) {
    let operations = [];

    const getService = this.getService,
      /**
       * Enqueues a CancelablePromise with the passed executor function
       * @param  {Function}          executor Function object with two arguments resolve and reject.
       *                                      The first argument fulfills the promise, the second
       *                                      argument rejects it. We can call these functions
       *                                      once our operation is completed.
       * @return {CancelablePromise}          Promises/A+ compliant promise with a cancel function
       */
      enqueue = (executor) => {
        const promise = new CancelablePromise(executor);

        operations.push(promise);

        return promise;
      },
      /**
       * Updates the data cursor
       * @param  {Object}  agenda  The new agenda data
       * @return {Cursor}          The updated cursor
       */
      resolveData = (agenda) =>
        Object.keys(agenda).reduce((cursor, key) => {
          return cursor.update(key, () => Immutable.fromJS(agenda[key]));
        }, reference.cursor()),
      /**
       * Enqeues a CancelablePromise which refreshes the agenda overview data
       * @param api
       * @returns {CancelablePromise}
       */
      enqueueAgendaOverviewRefresh = (api) =>
        enqueue((resolve) => {
          return api
            .getAgendaOverview()
            .then((data) => {
              reference.cursor().setIn(['persistent', 'agendaOverview'], Immutable.fromJS(data));
            })
            .then(() => resolve());
        }),
      /**
       * Enqueues a CancelablePromise which refreshes the agenda data
       * @param  {Object}            api The API service
       * @return {CancelablePromise}     Promises/A+ compliant promise with a cancel function
       */
      enqueueAgendaRefresh = (api) =>
        enqueue((resolve, reject) => {
          const dateService = getService('date'),
            router = getService('router'),
            agendaDate = dateService.getAgendaDate(),
            currentDate = dateService.getTodayDate();

          // we always want to update the agenda and votings overview
          Promise.all([api.getAgenda(), api.getVotingsOverview()])
            .then(([agenda, votings]) => {
              const dayChanged = currentDate !== agenda.currentDate;

              // only get dated agenda when the day has changed and it is the same week
              if (!dayChanged && dateService.isWithinAllowedTimeWindow(agendaDate)) {
                return api.getDatedAgenda(agendaDate).then((datedAgenda) =>
                  resolveData({
                    overview: agenda.overview,
                    votings,
                    ...datedAgenda,
                  }),
                );
              }

              // update the cursor first so the Index.js component doesn't change the path back to the date in the data.
              // e.g. update data first then navigate to home
              resolveData({
                votings,
                ...agenda,
              });

              // navigate to index
              router.navigate(router.generate('index-for-date', { date: agenda.currentDate }));
            })
            .then(resolve, reject);
        }),
      /**
       * Enqueues a CancelablePromise which refreshes the agenda and current debate data
       * @param  {Object}            api The API service
       * @return {CancelablePromise}     Promises/A+ compliant promise with a cancel function
       */
      enqueueDebateRefresh = (api, debateId) =>
        enqueue((resolve, reject) => {
          const agendaDate = getService('date').getAgendaDate();

          // get agenda first to obtain the currentDate and verify the debate is still valid (present in agenda)
          api.getAgenda().then((agenda) => {
            // debate is not present is the agenda, but we still need to update the agenda and votings overview
            if (!getService('date').isWithinAllowedTimeWindow(agendaDate)) {
              return api
                .getVotingsOverview()
                .then((votings) => ({
                  votings,
                  ...agenda,
                }))
                .then((data) => {
                  resolveData(data);

                  // we reject here so the router navigates to the home route.
                  reject();
                });
            }

            // debate is still valid, update the debate, agenda and votings overview
            Promise.all([api.getDatedAgenda(agendaDate), api.getDebate(agendaDate, debateId), api.getVotingsOverview()]).then(resolve, reject);
          }, reject);
        }),
      /**
       * Logs to the console
       * @param  {Error}  error  The error to log
       */
      handleError = (error) => {
        if (import.meta.env.DEV) {
          console.error('Refresh Error: ' + error.message);
        }
      },
      /**
       * Merges the debate details into the agenda response
       * @param  {Object}  agenda  Data from the getAgenda call
       * @param  {Object}  debate  Data from the getDebate call
       * @return {Object}          THe merged data
       */
      mergeData = ([agenda, debate, votings]) => {
        const debateId = debate.id,
          debateIndex = agenda.debates.findIndex(({ id }) => id === debateId);

        if (-1 !== debateIndex) {
          agenda.debates[debateIndex] = debate;
        }

        return {
          votings,
          ...agenda,
        };
      },
      /**
       * The service definition
       * @type {Object}
       */
      RefreshService = {
        /**
         * Refreshes debate data
         * @param  {String}  [debateId] If passed, refresh additionally requests detailed
         *                              data for the debate with id debateId.
         * @return {Promise}            Resolves when data has been updated
         */
        refresh: async function (debateId) {
          const api = getService('api');

          // this should always happen
          enqueueAgendaOverviewRefresh(api);

          if (!debateId || 'index' === debateId) {
            return enqueueAgendaRefresh(api).catch(handleError);
          }

          return await enqueueDebateRefresh(api, debateId).then(mergeData, handleError).then(resolveData, handleError);
        },

        /**
         * Cancels all currently registered operations.
         */
        cancel: function () {
          operations.forEach((operation) => operation.cancel());
          operations = [];
        },
      };

    return RefreshService;
  };

export default RefreshServiceFactory;
