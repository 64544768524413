import React from 'react';
import { Helmet } from 'react-helmet';
import component from 'omniscient';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import ExternalLink from '../../components/ExternalLink/ExternalLink';

const /**
   * Component render function
   *
   * @returns {React.Component} An instance of AboutPage
   */
  render = function () {
    const appDigitoegankelijkLink = 'https://dashboard.digitoegankelijk.nl/organisaties/42/websites-apps/11773';
    const webDigitoegankelijkLink = 'https://dashboard.digitoegankelijk.nl/organisaties/42/websites-apps/11779';

    return (
      <div className="Layer ViewStack-layer Layer--white">
        <Helmet>
          <title>Toegankelijkheidsverklaring | Debat Direct</title>
          <meta name="description" content="Toegankelijkheidsverklaring voor Debat direct met een referentie naar de rapportage" />
        </Helmet>
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          <SmartScrollView>
            <div className="Main-content Main-content--full About Accessibility">
              <div className="AboutPanel">
                <h2 className="AboutPanel__Title Accessibility--title">Toegankelijkheidsverklaring</h2>
                <p className="Text">
                  Bij Debat Direct streven wij naar het creëren van een digitale omgeving die voor iedereen toegankelijk is. Voor gedetailleerde informatie en
                  de volledige rapportage, kunt u de toegankelijkheidsverklaring bekijken op:{' '}
                  <ExternalLink className="Link Link--blue" href={window.cordova ? appDigitoegankelijkLink : webDigitoegankelijkLink} target="_blank">
                    Digitoegankelijk.nl
                  </ExternalLink>
                  .
                </p>
                {window.cordova ? (
                  <ExternalLink href="https://www.toegankelijkheidsverklaring.nl/register/11773">
                    <img
                      className="Image"
                      src="https://www.toegankelijkheidsverklaring.nl/files/verklaring/label/322d487ee9d69adcaa98bab52257884d.11773.svg?1699653137"
                      alt="Status toegankelijkheidslabel van de Debat Direct app. Volg de link voor de volledige toegankelijkheidsverklaring."
                    />
                  </ExternalLink>
                ) : (
                  <ExternalLink href="https://www.toegankelijkheidsverklaring.nl/register/11779">
                    <img
                      className="Image"
                      src="https://www.toegankelijkheidsverklaring.nl/files/verklaring/label/13c1c7f3fc8d6752dceddd87b4fc1c55.11779.svg?1697822132"
                      alt="Status toegankelijkheidslabel van de Debat Direct website. Volg de link voor de volledige toegankelijkheidsverklaring."
                    />
                  </ExternalLink>
                )}
              </div>
            </div>
          </SmartScrollView>
        </div>
      </div>
    );
  };

export default component('AccessibilityComponent', render);
