import { useEffect, useState } from 'react';

let memoizedMediaQueries: MediaQueryList[] = [];

const getMediaQueries = () => {
  if (!memoizedMediaQueries.length && typeof window !== 'undefined') {
    const small: MediaQueryList = matchMedia('screen and (max-width: 479px)');
    const phone: MediaQueryList = matchMedia('screen and (min-width: 480px) and (max-width: 720px)');
    const tablet: MediaQueryList = matchMedia('screen and (min-width: 721px) and (max-width: 1023px)');
    const desktop: MediaQueryList = matchMedia('screen and (min-width: 1024px)');

    memoizedMediaQueries = [small, phone, tablet, desktop];
  }

  return memoizedMediaQueries;
};

type Size = 'small' | 'phone' | 'tablet' | 'desktop';

const getSize = (): Size => {
  const [small, phone, tablet, desktop] = getMediaQueries();

  if (small.matches) return 'small';
  if (phone.matches) return 'phone';
  if (tablet.matches) return 'tablet';
  if (desktop.matches) return 'desktop';

  return 'desktop';
};

export const useResponsiveSize = (): Size => {
  const [size, setSize] = useState(getSize());

  useEffect(() => {
    const mediaQueries = getMediaQueries();

    const handleChange = () => {
      setSize(getSize());
    };

    mediaQueries.forEach((matchMedia) => matchMedia.addEventListener('change', handleChange));

    return () => {
      mediaQueries.forEach((matchMedia) => matchMedia.removeEventListener('change', handleChange));
    };
  }, []);

  return size;
};
