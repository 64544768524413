import React, { createRef } from 'react';
import component from 'omniscient';

import ScrollView from './ScrollView';

let calculateScrollbarOffset = function () {
  let container = document.createElement('div'),
    scroller = document.createElement('div'),
    offset;

  container.style.height = '100px';
  container.style.width = '100px';
  container.style.overflow = 'auto';

  scroller.style.height = '200px';

  container.appendChild(scroller);
  document.body.appendChild(container);

  offset = container.offsetWidth - scroller.offsetWidth;

  document.body.removeChild(container);

  container = null;
  scroller = null;

  calculateScrollbarOffset = function () {
    return offset;
  };

  return offset;
};

/**
 * Renders a ScrollView component but automatically determines which scrolling to use.
 *
 * @param {object} props
 *
 * @example
 *
 * ```jsx
 * <SmartScrollView>
 *   <h1>Title</h1>
 *   <p>scrollable content</p>
 * </SmartScrollView>
 * ```
 *
 * @return {React.Component}
 */
export default component(
  'SmartScrollView',
  {
    scrollViewRef: createRef(),
    nativeScrollViewRef: createRef(),

    shouldComponentUpdate: () => true,

    scrollTo: function (x, y) {
      const scrollView = this.scrollViewRef.current;
      const nativeScrollView = this.nativeScrollViewRef.current;

      if (scrollView) {
        return scrollView?.scrollTo(...arguments);
      }

      if (nativeScrollView) {
        nativeScrollView.scrollLeft = x || 0;
        nativeScrollView.scrollTop = y || 0;
      }
    },

    scrollToTop: function () {
      const nativeScrollView = this.nativeScrollViewRef.current;
      const offset = this.getPosition().y;
      const step = offset / (300 / 15);
      let current = 1;

      if (nativeScrollView) {
        nativeScrollView.style.overflow = 'hidden';

        setTimeout(function () {
          nativeScrollView.style.overflow = 'auto';
        }, 10);
      }

      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.scrollTo(0, offset - current * step);

        if (current === 20) {
          clearInterval(this.interval);
        }

        current++;
      }, 15);
    },

    getPosition: function () {
      const scrollView = this.scrollViewRef.current;
      const nativeScrollView = this.nativeScrollViewRef.current;

      if (nativeScrollView) {
        return {
          x: nativeScrollView.scrollLeft,
          y: nativeScrollView.scrollTop,
        };
      }

      return scrollView.getPosition();
    },

    componentDidMount: function () {
      this.setTouchMoveHandler(true);
    },

    componentWillUnmount: function () {
      this.setTouchMoveHandler(false);
      clearInterval(this.interval);
    },

    /**
     * Add and remove touch move handler.
     * @param {Boolean} activate
     */
    setTouchMoveHandler: function (activate) {
      const { nativeScrollView } = this.refs;

      if (!nativeScrollView) {
        return;
      }

      nativeScrollView[activate ? 'addEventListener' : 'removeEventListener']('touchmove', this.handleTouchMove, false);
    },

    /**
     * Handle touch move.
     * @param {TouchEvent} event
     */
    handleTouchMove: function (event) {
      const nativeScrollView = this.nativeScrollViewRef.current;

      if (typeof this.props.onUserScrollEvent === 'function') {
        this.props.onUserScrollEvent();
      }

      // If there is room for scrolling, we let it scroll.
      if (nativeScrollView.scrollHeight > nativeScrollView.offsetHeight) {
        return;
      }

      // If the content fits, we prevent the default action.
      // This prevents IOS from scrolling on other elements under this element.
      event.preventDefault();
    },
  },
  function ({ children, onScroll, ...props }) {
    const scrollbarOffset = calculateScrollbarOffset();

    if ('ontouchstart' in window || (window.navigator.msPointerEnabled && 0 === scrollbarOffset)) {
      return (
        <div className="NativeScrollView" ref={this.nativeScrollViewRef} onScroll={onScroll} data-scroll-body="true">
          {children}
        </div>
      );
    }

    return (
      <ScrollView {...props} scrollbarOffset={scrollbarOffset} ref={this.scrollViewRef} onScroll={onScroll}>
        {children}
      </ScrollView>
    );
  },
);
