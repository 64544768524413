/**
 * Appends a CSS link tag to the document head
 * @param  {String} href the location of the css file
 */
export default function appendCSSLink(href) {
  const css = document.createElement('link');

  css.rel = 'stylesheet';
  css.type = 'text/css';
  css.href = href;

  document.head.appendChild(css);
}
