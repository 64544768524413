import React from 'react';

import DebateSpeakersComponent from './speakers/DebateSpeakersComponent';
import DebateVotingsComponent from './votings/DebateVotingsComponent';

export default (props) => {
  if ('Stemmingen' === props.debate.get('debateType')) {
    return <DebateVotingsComponent {...props} />;
  }

  return <DebateSpeakersComponent {...props} />;
};
