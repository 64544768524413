import classNames from 'classnames';
import React from 'react';

const SkipLink = ({ href = '#main', text = 'Naar hoofdinhoud', inert, className }) => {
  if (window.cordova) return null;

  return (
    <a href={href} className={classNames('SkipLink', className)} inert={inert}>
      {text}
    </a>
  );
};

export default SkipLink;
