import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import formatters from '../../common/lib/formatters';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { Close, PlayArrow, Pause } from '../../icons';

const getCoords = function (event) {
  const originalEvent = event.originalEvent || event;
  const touches = originalEvent.touches?.length ? originalEvent.touches : [originalEvent];
  const e = originalEvent.changedTouches?.[0] || touches[0];

  // mouse event
  if (event.type.indexOf('touch') === -1) {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }

  // touch event
  return {
    x: e.clientX,
    y: e.clientY,
  };
};

class VideoPlayer extends Component {
  static contextTypes = {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  };

  playerRef = createRef();
  timeSliderRef = createRef();
  railRef = createRef();

  state = {
    playing: false,
    ended: false,
    startTime: 0,
    endTime: 0,
    position: 0,
    duration: 0,
    thumbProgress: 0,
    railProgress: 0,
    dragging: false,
  };

  componentDidMount() {
    const timeSlider = this.timeSliderRef.current,
      playerUrl = this.context.getCursor(['config', 'theoplayer']).get('url'),
      playerElement = this.playerRef.current;

    this.context.getService('video').pause();

    this.player = new window.THEOplayer.Player(playerElement, {
      libraryLocation: playerUrl,
      isEmbeddable: true,
    });

    this.player.addEventListener('loadedmetadata', this.handleLoadedDataEvent);
    this.player.addEventListener('playing', this.handlePlayingEvent);
    this.player.addEventListener('timeupdate', this.handleTimeUpdateEvent);
    this.player.addEventListener('ended', this.handleEndedEvent);

    this.player.source = {
      sources: [
        {
          src: this.props.src,
          type: 'application/x-mpegurl',
        },
      ],
    };

    this.player.autoplay = true;
    this.player.preload = 'auto';

    timeSlider.addEventListener('mousedown', this.handleStartInteraction);
    timeSlider.addEventListener('touchstart', this.handleStartInteraction);

    window.addEventListener('resize', this.handleResize);

    this.handleResize();
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.destroy();
      delete this.player;
    }

    window.removeEventListener('resize', this.handleResize);

    const timeSlider = this.timeSliderRef.current;

    timeSlider?.removeEventListener('mousedown', this.handleStartInteraction);
    timeSlider?.removeEventListener('touchstart', this.handleStartInteraction);
  }

  handleResize = () => {
    const timeSlider = this.timeSliderRef.current;
    const rail = this.railRef.current;
    const rect = timeSlider.getBoundingClientRect();

    const offsetWidth = rail ? rail.offsetWidth : 0;

    if (this.state.railWidth !== offsetWidth || rect.left !== this.state.timeSliderOffset) {
      this.setState({
        railWidth: offsetWidth,
        timeSliderOffset: rect.left,
      });
    }
  };

  handleStartInteraction = (event) => {
    const timeSlider = this.timeSliderRef.current;

    if (!timeSlider) return;

    const coords = getCoords(event),
      leftOffset = Math.min(this.state.railWidth, Math.max(0, coords.x - this.state.timeSliderOffset)),
      progress = leftOffset / timeSlider.offsetWidth;

    event.preventDefault();

    this.setState({
      dragging: true,
      thumbProgress: progress,
      startCoords: coords,
    });

    if (event.type === 'touchstart') {
      timeSlider.addEventListener('touchmove', this.handleMoveInteraction);
      timeSlider.addEventListener('touchend', this.handleEndInteraction);
    } else {
      timeSlider.addEventListener('mousemove', this.handleMoveInteraction);
      timeSlider.addEventListener('mouseleave', this.handleEndInteraction);
      timeSlider.addEventListener('mouseup', this.handleEndInteraction);
    }
  };

  handleMoveInteraction = (event) => {
    const { railWidth } = this.state,
      coords = getCoords(event),
      leftOffset = Math.min(railWidth, Math.max(0, coords.x - this.state.timeSliderOffset)),
      progress = leftOffset / railWidth;

    if (!this.state.dragging) {
      return this.setState({
        cursorProgress: progress,
      });
    }

    this.setState({
      cursorProgress: progress,
      thumbProgress: progress,
    });
  };

  handleEndInteraction = (event) => {
    const timeSlider = this.timeSliderRef.current;

    if (!timeSlider) return;

    const { dragging, duration } = this.state,
      coords = getCoords(event),
      timeSliderWidth = timeSlider.offsetWidth,
      leftOffset = Math.min(timeSliderWidth, Math.max(0, coords.x - this.state.timeSliderOffset)),
      progress = leftOffset / timeSliderWidth;

    if (dragging) {
      let toPosition = progress * duration + this.state.startTime;

      if (event.type.indexOf('touch') === 0) {
        timeSlider.removeEventListener('touchmove', this.handleMoveInteraction);
        timeSlider.removeEventListener('touchend', this.handleEndInteraction);
      } else {
        timeSlider.removeEventListener('mousemove', this.handleMoveInteraction);
        timeSlider.removeEventListener('mouseleave', this.handleEndInteraction);
        timeSlider.removeEventListener('mouseup', this.handleEndInteraction);
      }

      this.player.currentTime = toPosition;
      this.player.play();
    }

    this.setState({
      dragging: false,
    });
  };

  handleLoadedDataEvent = () => {
    if (this.props.start && this.player.currentProgramDateTime) {
      const startTime = moment(this.props.start).diff(this.player.currentProgramDateTime, 's');
      const endTime = moment(this.props.end).diff(this.player.currentProgramDateTime, 's');

      this.player.clip.startTime = startTime;
      this.player.clip.endTime = endTime;

      this.setState({
        startTime,
        endTime,
        position: 0,
        duration: endTime - startTime,
      });
    } else {
      this.setState({
        position: this.player.currentTime,
        duration: this.player.duration,
      });
    }
  };

  handlePlayingEvent = () => {
    this.setState({
      playing: !this.player.paused,
    });
  };

  handleTimeUpdateEvent = () => {
    let position = this.player.currentTime;
    let duration = this.player.duration;

    if (this.state.startTime > 0 || this.state.endTime > 0) {
      duration = this.state.endTime - this.state.startTime;
      position = position - this.state.startTime;
    }

    const progress = Math.min(1, Math.max(0, position / duration));

    this.setState({
      railProgress: progress,
      thumbProgress: this.state.dragging ? this.state.thumbProgress : progress,
      position,
      duration,
    });
  };

  handleEndedEvent = () => {
    this.setState({
      playing: false,
      ended: true,
    });
  };

  handlePlayButtonClick = () => {
    if (this.player.paused) {
      // reset position
      if (this.state.ended) {
        this.player.currentTime = 0;
      }

      this.player.play();
    } else {
      this.player.pause();
    }

    this.setState({
      playing: !this.player.paused,
      ended: false,
    });
  };

  getThumbStyles() {
    const { thumbProgress, railWidth } = this.state;
    const left = thumbProgress * railWidth;

    return {
      left: `${left}px`,
    };
  }

  getRailStyles() {
    const { railProgress, railWidth } = this.state;
    const width = railProgress * railWidth;

    return {
      width: `${width}px`,
    };
  }

  render() {
    return (
      <div className="VideoPlayer">
        <div ref={this.playerRef} style={{ width: '100%', height: '100%' }} />
        <div className="VideoPlayer-controls">
          <div className="VideoPlayer-timeSlider" ref={this.timeSliderRef}>
            <div className="VideoPlayer-timeSlider-rail" ref={this.railRef}>
              <div className="VideoPlayer-timeSlider-progress" style={this.getRailStyles()} />
              <div className="VideoPlayer-timeSlider-thumb" style={this.getThumbStyles()} />
            </div>
          </div>
          <div className="VideoPlayer-controlbar">
            <Button className="VideoPlayer-button" onClick={this.handlePlayButtonClick}>
              {this.state.playing ? <Pause /> : <PlayArrow />}
            </Button>
            <div className="VideoPlayer-text">
              {formatters.formatDuration(this.state.position)} / {formatters.formatDuration(this.state.duration)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class PreviewFragmentModal extends Component {
  render() {
    return (
      <Modal onClose={this.props.onClose} open={this.props.open} type="full" aria-label="Videofragment bekijken">
        <Button className="Modal-closeButton" aria-label="Sluit videospeler" onClick={this.props.onClose}>
          <Close />
        </Button>
        <VideoPlayer src={this.props.src} start={this.props.start} end={this.props.end} />
      </Modal>
    );
  }
}
