import React from 'react';

const /**
   * Renders a Link component
   * @param  {object} props
   * @return {React.Component} Anchor tag
   */
  Link = ({ children, className, ...rest }) => {
    const props = {
      ...rest,
      className: className ? 'Link ' + className + ' ignore-react-onclickoutside' : 'Link ignore-react-onclickoutside',
    };

    return React.createElement('a', props, children);
  };

export default Link;
