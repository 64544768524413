import React from 'react';
import component from 'omniscient';

import { eq, getBackgroundClass, toggleFill } from './logic';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const { highlight, variant, politicians } = props;
    const fillColor = variant === 'dark' ? 'White' : '';
    const className = (props.className || 'u-fill').concat(' Infographic');

    const politiciansList = politicians?.join(', ');
    const title = `Visualisatie van de Thorbecke zaal ${politiciansList ? ' en de plaats van ' + politiciansList : ''}`;

    /* eslint-disable no-extra-parens */
    return (
      <svg viewBox="170 -125 300 284" className={className} role="img" aria-labelledby="infographic-title">
        <title id="infographic-title">{title}</title>
        <g className={getBackgroundClass(variant === 'light' && !!highlight)}>
          <rect x="389.7" y="5" width="35.2" height="19.9" />
          <rect x="172" y="10" width="214" height="12" />
          <path d="M404.9-11.4c0-46.7-37.8-84.5-84.5-84.5s-84.5,37.8-84.5,84.5h-20.3c0-57.8,46.9-104.7,104.7-104.7S425-69.2,425-11.4 H404.9z" />
          <path d="M240.1-113.5C214-93,196-63,191.5-28.6l-12-1.5c5-37.6,24.7-70.6,53.1-92.9L240.1-113.5z" />
          <path d="M448.6-28.6c-4.5-34.4-22.5-64.6-48.6-84.9l7.4-9.5c28.5,22.3,48.2,55.3,53.1,92.9L448.6-28.6z" />
          <rect x="172" y="66" width="122.3" height="91.4" />
          <rect x="345.7" y="66" width="122.3" height="91.4" />
          <rect x="444.1" y="-3.9" width="15.4" height="35.9" />
          <line x1="451" y1="-3.9" x2="451" y2="32" />
        </g>
        <rect className={toggleFill(eq(highlight, 'pers'), fillColor)} x="172" y="10" width="214" height="12" />
        <g className={toggleFill(eq(highlight, 'publiek'), fillColor)}>
          <rect x="172" y="66" width="122.3" height="91.4" />
          <rect x="345.7" y="66" width="122.3" height="91.4" />
        </g>
        <rect className={toggleFill(eq(highlight, 'bode'), fillColor)} x="444.1" y="-3.9" width="15.4" height="35.9" />
        <rect className={toggleFill(eq(highlight, 'verslaglegger'), fillColor)} x="389.7" y="5" width="35.2" height="19.9" />
        <g className={toggleFill(eq(highlight, 'kabinet-en-ambtenaren'), fillColor)}>
          <path d="M313-95.6c-43.2,3.7-77.2,40-77.2,84.2h-20.2c0-54.8,42-99.7,95.7-104.3L313-95.6z" />
          <path d="M240.1-113.5C214-93,196-63,191.5-28.6l-12-1.5c5-37.6,24.7-70.6,53.1-92.9L240.1-113.5z" />
        </g>
        <g className={toggleFill(eq(highlight, 'kamerleden'), fillColor)}>
          <path d="M344.8-113.2c46,11,80.2,52.4,80.2,101.8h-20.2c0-40-27.7-73.4-64.9-82.2L344.8-113.2z" />
          <path d="M448.6-28.6c-4.5-34.4-22.5-64.6-48.6-84.9l7.4-9.5c28.5,22.3,48.2,55.3,53.1,92.9L448.6-28.6z" />
        </g>
        <path
          className={toggleFill(eq(highlight, 'griffier'), fillColor)}
          d="M336.7-114.8c-2.8-0.4-5.6-0.8-8.4-1l-1.6,20c2.3,0.2,4.5,0.4,6.9,0.8c2,0.3,4.1,0.8,6.1,1.2l4.6-19.6 C341.8-113.8,339.3-114.4,336.7-114.8z"
        />
        <path
          className={toggleFill(eq(highlight, 'voorzitter'), fillColor)}
          d="M320.3-116.1c-2.8,0-5.7,0.1-8.5,0.3l1.6,20c2.3-0.2,4.5-0.3,6.9-0.3c2.2,0,4.2,0.1,6.3,0.2l1.6-20 C325.6-116,323-116.1,320.3-116.1z"
        />
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicThorbecke', render);
