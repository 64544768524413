import React from 'react';

import LinearIndeterminate from '../Progress/LinearIndeterminate';

const DownloadModalBody = ({ title, description, actions, progress, children }) => (
  <div>
    <div className="Modal-body-title">{title}</div>
    <p className="Modal-body-paragraph">{description}</p>
    {progress && (
      <div className="Modal-body-progress">
        <LinearIndeterminate />
      </div>
    )}
    {actions && <p className="Modal-body-actions">{actions}</p>}
    {children}
  </div>
);

export default DownloadModalBody;
