import React, { useState } from 'react';

import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import CircularProgress from '../Progress/CircularProgress';
import TextPanel from '../TextPanel/TextPanel';
import Link from '../Link/Link';

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{12,}$/;

const ChangePasswordWithCodeForm = ({ onSubmit, hasError, isSubmitting, onBackToLoginClick }) => {
  const [values, setValues] = useState({
    code: '',
    password: '',
  });
  const [passwordError, setPasswordError] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    // re-check password error when there is a password error and the user updates the password field
    if (name === 'password' && passwordError) {
      setPasswordError(!PASSWORD_REGEX.test(value));
    }

    setValues((current) => ({ ...current, [name]: value }));
  };

  const handleBlur = () => {
    setPasswordError(!PASSWORD_REGEX.test(values.password));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!passwordError) {
      onSubmit(values);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="Heading">Wachtwoord vergeten</h2>
      <p>Er is een verificatiecode verstuurd per SMS of e-mail. Vul deze code in en kies een nieuw wachtwoord die voldoet aan de volgende vereisten:</p>
      <ul>
        <li>Minimaal 12 karakters</li>
        <li>Minimaal 1 hoofdletter</li>
        <li>Minimaal 1 kleine letter</li>
        <li>Minimaal 1 cijfer</li>
        <li>Minimaal 1 symbool</li>
      </ul>
      <TextField
        className="u-mb20"
        name="code"
        type="text"
        value={values.code}
        label="Verificatiecode"
        onChange={handleChange}
        error={hasError}
        readOnly={isSubmitting}
        required
      />
      <TextField
        className="u-mb20"
        name="password"
        type="password"
        value={values.password}
        label="Nieuw wachtwoord"
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError || passwordError}
        readOnly={isSubmitting}
        required
      />
      {passwordError ? (
        <TextPanel variant="error" className="u-mb20">
          Gekozen wachtwoord is niet geschikt. Kies een nieuw wachtwoord dat voldoet aan de vereisten.
        </TextPanel>
      ) : null}
      <Button className="Button--medium Button--block Button--primary u-mb20" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="Button-icon">
            <CircularProgress size={16} />
          </span>
        ) : null}
        <span className="Button-label">Versturen</span>
      </Button>
      {hasError ? (
        <TextPanel variant="error" className="u-mb20">
          Het is niet gelukt om uw wachtwoord aan te passen. Probeer het opnieuw.
        </TextPanel>
      ) : null}
      <Link className="Link--blue" onClick={onBackToLoginClick}>
        Terug naar inloggen
      </Link>
    </form>
  );
};

export default ChangePasswordWithCodeForm;
