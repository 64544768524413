import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';

import Notification from '../Notification/Notification';
import InternalLink from '../InternalLink/InternalLink';
import PoliticianImage from '../PoliticianImage/PoliticianImage';

/**
 * Renders the notification toggle
 * @param  {object} props
 * @return {React.Component}
 */
const render = ({ notification, description, href, politicianId, className, onDismiss, ...rest }) => {
  const notificationProps = {
      ...rest,
      onDismiss,
      className: classNames('Notification--speaker', className),
    },
    imageProps = {
      className: 'Notification-photo u-flex',
      size: 80,
      politicianId,
    },
    setSeekEventClick = () => {
      if (typeof onDismiss === 'function') {
        onDismiss({ followedLink: true, notification: notification });
      }
    };

  return (
    <Notification {...notificationProps}>
      <PoliticianImage {...imageProps} />
      <InternalLink className="Notification-message u-flex u-grow" href={href} onClick={setSeekEventClick}>
        {description}
      </InternalLink>
    </Notification>
  );
};

export default component(render);
