import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import component from 'omniscient';

import DateTime from '../DateTime/DateTime';

/**
 * Renders a DateTime component with formatting for time
 * @param  {object} props
 * @return {React.Component}
 */
export default component(({ className, dateTime, ...rest }) => {
  const formatter = (ts) => moment(ts, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm');
  const props = {
    ...rest,
    dateTime,
    formatter,
    className: classNames('Time--time', className),
  };

  return <DateTime {...props} />;
});
