const InfoPanelService = function (reference) {
  const /**
     * Function to show InfoPanel
     *
     * @param {string} type
     * @param {object} [props={}]
     */
    show = function (type, props) {
      reference.cursor().merge({
        isVisible: true,
        component: type,
        props: props,
      });
    },
    /**
     * Function to hide InfoPanel and clear cursor
     */
    hide = function () {
      reference.cursor().merge({
        isVisible: false,
        component: null,
        props: {},
      });
    };

  return {
    show,
    hide,
  };
};

export default InfoPanelService;
