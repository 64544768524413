import React from 'react';
import component from 'omniscient';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';

const LABEL = (
    <span className="Button-label u-centerSelf">
      Nu naar <br />
      live debat
    </span>
  ),
  render = function (props) {
    /* eslint-disable no-extra-parens */
    return (
      <Button aria-label="Nu naar live debat" {...props}>
        <Icon className="Button-icon" name="play" />
        {LABEL}
      </Button>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('DebatePlayLiveButton', render);
