import React from 'react';
import { Helmet } from 'react-helmet';
import component from 'omniscient';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import ExternalLink from '../../components/ExternalLink/ExternalLink';

const /**
   * Component render function
   *
   * @returns {React.Component} A instance of AboutPage
   */
  render = function () {
    /* eslint-disable no-extra-parens */
    return (
      <div className="Layer ViewStack-layer Layer--white">
        <Helmet>
          <title>Disclaimer | Debat Direct</title>
        </Helmet>
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          <SmartScrollView>
            <div className="Main-content Content Disclaimer">
              <p className="Text">
                De gegevens op deze app worden alleen beschikbaar gesteld met als doel het verstrekken van informatie. Hoewel de Tweede Kamer der
                Staten-Generaal zeer zorgvuldig let op de juistheid en volledigheid van de verstrekte informatie, is er geen garantie op de juistheid en
                volledigheid van de verstrekte informatie, is er geen garantie dat de app vrij is van fouten.
              </p>
              <p className="Text">
                De gegevens op deze app kunnen niet worden gezien als een aanbod of een professioneel advies. Alle aansprakelijkheid in het geval van nadeel of
                schade, direct dan wel indirect, als gevolg van het gebruik van of het vertrouwen op de gegevens ontleend aan deze app inclusief nadeel of
                schade veroorzaakt door virussen, foutieve of onvolledige gegevens of informatie, is uitgesloten voor zover is toegestaan bij wet en
                rechtspraak. De Tweede Kamer der Staten-Generaal is ook niet aansprakelijk voor schade die volgt uit de onmogelijkheid de app te gebruiken.
              </p>
              <p className="Text">
                De Tweede Kamer der Staten-Generaal is evenmin aansprakelijk voor schade ten gevolge van het gebruik van elektronische berichten, inclusief –
                maar niet beperkt tot – schade als gevolg van het niet of verlaat aankomen van elektronische berichten, onderschepping of manipulatie daarvan
                door derden of computerprogramma's die worden gebruikt voor elektronische berichten en het overbrengen van virussen.
              </p>
              <p className="Text">De volledige algemene voorwaarden en de Debat Direct privacyverklaring zijn te vinden op:</p>
              <p className="Text">
                &nbsp;-&nbsp;
                <ExternalLink className="Link Link--blue" href="https://www.tweedekamer.nl/applicaties/disclaimer" target="_blank">
                  https://www.tweedekamer.nl/applicaties/disclaimer
                </ExternalLink>
                <br />
                &nbsp;-&nbsp;
                <ExternalLink className="Link Link--blue" href="https://cdn.debatdirect.tweedekamer.nl/documents/privacyverklaring-debat-direct-2022.html">
                  https://cdn.debatdirect.tweedekamer.nl/documents/privacyverklaring-2022.html
                </ExternalLink>
              </p>
            </div>
          </SmartScrollView>
        </div>
      </div>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('DisclaimerComponent', render);
