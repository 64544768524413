import Button from '@debatdirect/core/components/Button/Button';
import React from 'react';

import Popover from '../../../components/Popover/Popover';

const ConfirmationPopover = ({ open, title, body, onCancel, onConfirm }) => {
  return (
    <Popover
      className="Popover--center u-blueText u-p25"
      anchor={document.body}
      transformOrigin={{ horizontal: 'center', vertical: 'center' }}
      onClose={onCancel}
      open={open}
    >
      <h2 className="Heading">{title}</h2>
      <p className="u-mb30">{body}</p>
      <div className="u-actionsRow">
        <Button className="Button--medium Button--light Button--uppercase" onClick={onCancel}>
          <span className="Button-label">Annuleren</span>
        </Button>
        <Button className="Button--medium Button--primary Button--uppercase" onClick={onConfirm}>
          <span className="Button-label">Ja, ik weet het zeker</span>
        </Button>
      </div>
    </Popover>
  );
};

export default ConfirmationPopover;
