import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchSortingOptionsSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    selectIdPrefix: PropTypes.string,
  };

  state = {
    options: [
      { name: 'Meest recent', value: 'recent' },
      { name: 'Meest relevant', value: 'relevant' },
    ],
  };

  renderOption = (option) => {
    return (
      <option value={option.value} key={option.name} className="SearchPage-sortingButton-label">
        {option.name}
      </option>
    );
  };

  render() {
    const { options } = this.state;
    const { value, selectIdPrefix } = this.props;
    const selectId = `${selectIdPrefix ? `${selectIdPrefix}-` : ''}sort-select`;
    const selected = options.find((option) => option.value === value);

    return (
      <div className="SearchPage-sortingButton">
        <label className="srOnly" htmlFor={selectId}>
          {`Sorteren op ${selected.name.toLowerCase()}`}
        </label>
        <select id={selectId} className="SearchPage-sortingButton-select" value={value} onChange={this.props.onChange}>
          {options.map(this.renderOption)}
        </select>
      </div>
    );
  }
}

export default SearchSortingOptionsSelect;
