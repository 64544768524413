import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import observer from 'omnipotent/decorator/observer';
import component from 'omniscient';

import { structure } from '../../core';
import { byId } from '../../predicates';
import NotificationFactory from '../NotificationFactory/NotificationFactory';
import Marker from '../../components/Marker/Marker';
import NotificationsUpdateModal from '../Modals/NotificationsUpdateModal';

import VideoError from './prompt/VideoError';
import FormSubmitError from './prompt/FormSubmitError';
import NextDebate from './prompt/NextDebate';
import GenericError from './prompt/GenericError';
import NoConnection from './prompt/NoConnection';
import LoginRequired from './prompt/LoginRequired';

const prompts = {
  VideoError,
  FormSubmitError,
  NextDebate,
  GenericError,
  NoConnection,
  LoginRequired,
};

const definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
    },

    shouldComponentUpdate: () => true,
  },
  getPromptComponent = (identifier) => {
    if (prompts[identifier]) {
      return prompts[identifier];
    }

    throw new Error(`Prompt with name ${identifier} isn't defined.`);
  },
  /**
   * Parses a politician title
   *
   * @param {Cursor} politician
   * @param {Cursor} parties
   *
   * @returns {String} the parsed politician title
   */
  parsePoliticianTitle = (politician, parties) => {
    let title = politician.get('title');

    if ('Tweede Kamerlid' !== title) {
      return title;
    }

    const partyShorthand = parties.find(byId(politician.get('partyId'))).get('shorthand');

    return `${title} (${partyShorthand})`;
  },
  renderMarker = function (getCursor, politicianId) {
    const actorsCursor = getCursor(['data', 'actors']);
    const politicians = actorsCursor.get('politicians');
    const parties = actorsCursor.get('parties');

    if (!politicianId) {
      return null;
    }

    const politician = politicians.find(byId(politicianId));

    if (!politician) {
      return null;
    }

    const name = politician.get('firstName') + ' ' + politician.get('lastName');
    const href = politician.get('profileUrl');
    const title = parsePoliticianTitle(politician, parties);

    return <Marker politician={name} politicianId={politicianId} href={href} title={title} timeout={10000} />;
  },
  renderPrompt = (cursor) => {
    const Component = getPromptComponent(cursor.get('identifier'));

    if (!Component) {
      return null;
    }

    return <Component cursor={cursor} />;
  },
  render = function ({ prompt, notification, marker, isLoading }) {
    const { getCursor } = this.context,
      isNotificationSet = !notification.isEmpty(),
      promptVisible = !prompt.isEmpty(),
      className = classNames('ViewStack-layer Layer Layer--prompt', {
        'has-backdrop': promptVisible && !!prompt.get('backdrop'),
      }),
      loadingLayerClassName = classNames('ViewStack-layer Layer Layer--loader', {
        'is-visible': isLoading.deref(),
      });

    return (
      <div className="ViewStack-layer Layer Layer--overlay">
        <div className="ViewStack-layer Layer Layer--marker">{renderMarker(getCursor, marker.deref())}</div>
        <div className="ViewStack-layer Layer Layer--notifications">{isNotificationSet && <NotificationFactory cursor={notification} />}</div>
        <div className={className}>{promptVisible && renderPrompt(prompt)}</div>
        <div className={loadingLayerClassName}>
          <div className="Loader" />
        </div>
        <NotificationsUpdateModal />
      </div>
    );
  },
  LayerOverlay = component('LayerOverlayApp', definition, render);

export default observer(
  structure,
  {
    marker: ['ui', 'marker'],
    prompt: ['ui', 'prompt'],
    notification: ['ui', 'pushNotification', 'notification'],
    isLoading: ['ui', 'isLoading'],
  },
  LayerOverlay,
);
