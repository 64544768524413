import request from './HTTPRequest';
import HTTPRequestMethod from './HTTPRequestMethod';

/**
 * Used to make HTTP requests
 * @param {String}  baseUrl The base url to prepend to paths
 * @return {Object}         The HTTP service
 */
function http(baseUrl) {
  return {
    /**
     * Performs an HTTP DELETE request
     * @param  {String} url
     * @param  {String|Object|Array} [payload]
     * @param  {Array}  [headers] { name: 'headerName', value: 'headerValue' }
     * @return {Promise}          Carries the response content
     */
    delete: function (url, payload, headers) {
      return request(HTTPRequestMethod.DELETE, baseUrl + url, payload, headers);
    },

    /**
     * Performs an HTTP GET request
     * @param  {String} url
     * @param  {Array}  [headers] { name: 'headerName', value: 'headerValue' }
     * @return {Promise}          Carries the response content
     */
    get: function (url, headers) {
      return request(HTTPRequestMethod.GET, baseUrl + url, null, headers);
    },

    /**
     * Performs an HTTP PATCH request
     * @param  {String} url
     * @param  {String|Object|Array} [payload]
     * @param  {Array}  [headers] { name: 'headerName', value: 'headerValue' }
     * @return {Promise}          Carries the response content
     */
    patch: function (url, payload, headers) {
      return request(HTTPRequestMethod.PATCH, baseUrl + url, payload, headers);
    },

    /**
     * Performs an HTTP PATCH request
     * @param  {String} url
     * @param  {String|Object|Array} [payload]
     * @param  {Array}  [headers] { name: 'headerName', value: 'headerValue' }
     * @return {Promise}          Carries the response content
     */
    post: function (url, payload, headers) {
      return request(HTTPRequestMethod.POST, baseUrl + url, payload, headers);
    },

    /**
     * Performs an HTTP PATCH request
     * @param  {String} url
     * @param  {String|Object|Array} [payload]
     * @param  {Array}  [headers] { name: 'headerName', value: 'headerValue' }
     * @return {Promise}          Carries the response content
     */
    put: function (url, payload, headers) {
      return request(HTTPRequestMethod.PUT, baseUrl + url, payload, headers);
    },
  };
}

export default http;
