const /**
   * Returns a function which can be assigned a value which then returns a predicate
   * @param  {String}   name The property name to match on
   * @return {Function}      Partially applied predicate
   */
  byProperty = (name) => (value) => (datum) => value === datum.get(name),
  /**
   * Returns a function which can be assigned an indexed iterable which then returns a predicate
   * @param  {String}   name The property name to match on
   * @return {Function}      Partially applied predicate
   */
  multipleByProperty = (name) => (values) => (datum) => values.includes(datum.get(name)),
  /**
   * Returns a predicate which acts on the "id" property
   * @param  {String}   value The id to match
   * @return {Function}       The predicate function
   */
  byId = byProperty('id'),
  /**
   * Returns a predicate which acts on the "id" property
   * @param {IndexedIterable} values The ids to match
   * @type {Function}                The predicate function
   */
  byIds = multipleByProperty('id'),
  /**
   * Returns a predicate which acts on the "slug" property
   * @param  {String}   value The slug to match
   * @return {Function}       The predicate function
   */
  bySlug = byProperty('slug');

export { byId, byIds, byProperty, bySlug, multipleByProperty };
