import PropTypes from 'prop-types';
import React from 'react';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import { Close } from '../../icons';
import MenuItem from '../../components/MenuList/MenuItem';
import MenuList from '../../components/MenuList/MenuList';
import Modal from '../../components/Modal/Modal';
import persist from '../../utils/persist';

class NotificationsUpdateModal extends React.Component {
  static contextTypes = {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
  };

  close() {
    // remove oldRegistrationId
    persist.removeItem('registrationId');

    // hide the modal
    this.props.modalVisible.set(false);
  }

  render() {
    const { modalVisible } = this.props;
    const open = modalVisible.deref(false);

    return (
      <React.Fragment>
        <Modal type="auto" className="NotificationsUpdateModal" open={open} onClose={() => this.close()} aria-labelledby="modal-title">
          <div className="Modal-body">
            <div className="Modal-body-title u-fontSize24 u-mb20" id="modal-title">
              Notificaties update
            </div>
            <p className="Modal-body-paragraph">
              Het ontvangen van notificaties is verbeterd. Om hiervan gebruik te maken moet u uw eerder ingestelde notificaties opnieuw activeren.
            </p>
            <div className="Modal-body-title u-fontSize18 u-mb10">Wat is er nieuw?</div>
            <p className="Modal-body-paragraph">
              - U ontvangt voortaan maximaal één sprekersnotificatie per debat op het moment dat de spreker voor het eerst het woord neemt.
            </p>
            <p className="Modal-body-paragraph">- U kunt een geluid voor notificaties aanzetten. Dit kan op zowel iOS als Android apparaten.</p>
          </div>
          <hr className="u-m0" />
          <MenuList>
            <MenuItem className="MenuItem--footer" icon={<Close />} label="Sluiten" onClick={() => this.close()} />
          </MenuList>
        </Modal>
      </React.Fragment>
    );
  }
}

export default observer(
  structure,
  {
    modalVisible: ['ui', 'pushNotification', 'modalVisible'],
  },
  (props) => <NotificationsUpdateModal {...props} />,
);
