let resizeTimeout = null;

/**
 * Initialize
 * @param {Object} structure
 */
const init = (structure) => {
  /**
   * Handle resize.
   */
  const handleResize = () => {
    clearTimeout(resizeTimeout);

    resizeTimeout = window.setTimeout(() => {
      structure.cursor(['ui']).set('width', document.body.offsetWidth);
    }, 200);
  };

  window.addEventListener('resize', handleResize);
};

export default {
  init,
};
