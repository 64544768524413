import classNames from 'classnames';

import AlertOutline from '../../icons/AlertOutline';
import Button from '../Button/Button';
import Close from '../../icons/Close';

const TextPanel = ({ className, variant, children, onClose }) => {
  return (
    <div className={classNames('TextPanel', `TextPanel--${variant}`, className)} role="alert" aria-live="assertive">
      <AlertOutline className="TextPanel-icon" />
      <span className="TextPanel-text">{children}</span>
      {onClose ? (
        <Button className="IconButton TextPanel-closeButton" onClick={onClose}>
          <Close />
        </Button>
      ) : null}
    </div>
  );
};

export default TextPanel;
