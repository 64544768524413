import { util } from '../../core';

const AdditionsServiceFactory = function (reference, config) {
  // Only enable service when ENABLE_PLUS environment is true
  if (util.getOption('REACT_APP_ENABLE_PLUS') !== '1') {
    return {
      getEmbedLocationButton: () => null,
      getFeedUrls: () => null,
    };
  }

  // We need to set an extra class on the body, because we need to override some CSS.
  document.body.classList.add('InteractionAdditions');

  return {
    /**
     * Get URLs for relevant feeds.
     * @returns {Object|null}
     */
    getFeedUrls: () => {
      const apiBaseUrl = config.api?.baseUrl;

      if (!apiBaseUrl || typeof apiBaseUrl !== 'string') {
        return null;
      }

      return {
        RSS: `${apiBaseUrl}/feeds/rss`,
      };
    },
  };
};

/**
 * Public exports.
 * @type {AdditionsServiceFactory}
 */
export default AdditionsServiceFactory;
