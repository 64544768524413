import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import component from 'omniscient';

/**
 * Renders a time component
 * @param  {object} props
 * @return {React.Component}
 */
export default component(({ className, dateTime, formatter, ...rest }) => {
  const isoDate = moment(dateTime, 'YYYY-MM-DDTHH:mm:ssZ').toISOString(),
    props = {
      ...rest,
      dateTime: isoDate,
      className: classNames('Time', className),
    };

  return <time {...props}>{formatter(dateTime)}</time>;
});
