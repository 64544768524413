import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import component from 'omniscient';
import classNames from 'classnames';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import { byId } from '../../predicates';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Time from '../../components/Time/Time';

import DebateEmbeddedNotFoundComponent from './DebateEmbeddedNotFoundComponent';

const renderPlayButton = function (debate, handlePlayButtonClick) {
  const className = classNames('Embed-Play-Button');

  return (
    <Button className={className} id="play-button" title="Afspelen debat" onClick={handlePlayButtonClick}>
      <Icon className="Button-icon" name="play" />
    </Button>
  );
};

const renderTime = function (debate) {
  const hasStartedAt = debate.has('startedAt'),
    hasEndedAt = debate.has('endedAt'),
    from = hasStartedAt ? debate.get('startedAt') : debate.get('startsAt'),
    until = hasEndedAt ? debate.get('endedAt') : debate.get('endsAt');

  if (hasStartedAt && !hasEndedAt) {
    return <div className="Embed-Time">NU LIVE</div>;
  }

  return (
    <div className="Embed-Time">
      {moment(from).format('LL')}
      {' van '}
      <Time className="Embed-Debate-Start" dateTime={from} />
      {' tot '}
      <Time className="Embed-Debate-End" dateTime={until} />
    </div>
  );
};

const renderTitle = function (debate) {
  const title = debate.get('name'),
    maxLength = 90;

  if (!title) {
    return null;
  }

  const safeTitle = title.length > maxLength ? title.substr(0, maxLength) + ' ..' : title;

  return (
    <h1 className="Embed-Title" title={title}>
      {safeTitle}
    </h1>
  );
};

const renderSubHeader = function (debate, getCursor) {
  return (
    <div className="Embed-Misc-Details">
      {renderCategories(debate)}
      {renderType(debate)}
      {renderLocation(debate, getCursor)}
    </div>
  );
};

const renderCategories = function (debate) {
  const categoryNames = debate.get('categoryNames');

  if (!categoryNames || categoryNames.count()) {
    return null;
  }

  return <span>{categoryNames.join(' - ') + ' - '}</span>;
};

const renderType = function (debate) {
  const debateType = debate.get('debateType', 'Overig');

  return <span>{debateType}</span>;
};

const renderLocation = function (debate, getCursor) {
  const data = getCursor(['data']),
    locationId = debate.get('locationId'),
    location = data.get('locations').find(byId(locationId));

  if (!location) {
    return null;
  }

  const locationName = location.get('name');

  if (!locationName) {
    return null;
  }

  return <span> in de {locationName}</span>;
};

const definition = {
  contextTypes: {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
  },

  componentDidMount: function () {
    this.playRequestTime = Date.now();

    // Update structure.
    this.context.getCursor(['ui']).update('isEmbedded', () => true);

    // Add custom class to body, so we can create custom CSS.
    document.body.classList.add('Embedded-Player');

    // Set keyup handler.
    document.addEventListener('keyup', this.handleKeyUp);

    // let application know what the active debate is
    this.context.getCursor(['ui']).set('activeDebate', this.props.debate);
  },

  componentWillUnmount: function () {
    // Remove keyup handler.
    document.removeEventListener('keyup', this.handleKeyUp);
  },

  renderIntro: function (debate, getCursor) {
    return (
      <div className="Embed-Intro">
        <div className="Embed-Intro-Header">
          <span className="Embed-Intro-Header-Label">Vandaag in de Tweede Kamer</span>
        </div>
        <div className="Embed-Intro-Content">
          {renderPlayButton(debate, this.handlePlayButtonClick)}
          {debate?.has('startedAt') ? (
            <div className="Embed-Details">
              {renderTime(debate)}
              {renderTitle(debate)}
              {renderSubHeader(debate, getCursor)}
            </div>
          ) : null}
        </div>
      </div>
    );
  },

  renderPausedOverlay: function (debate) {
    return (
      <div className="Embed-Paused">
        <div className="Embed-Details">{debate?.has('startedAt') ? renderTitle(debate) : ''}</div>
      </div>
    );
  },

  handlePlayButtonClick: function () {
    this.playRequestTime = Date.now();

    const videoService = this.context.getService('video');

    videoService.play();
  },

  handleKeyUp: function (event) {
    switch (event.keyCode) {
      case 39: // Right key.
        this.seek(event, 1);
        break;

      case 37: // Left key.
        this.seek(event, -1);
        break;
      default:
    }
  },

  seek: function (event, direction) {
    const videoService = this.context.getService('video');

    videoService.keyboardSeek(direction);
  },
};

const render = function (props) {
  const routeName = this.context.route.name;

  if ((!props.debate || props.debateError) && routeName !== 'location-embedded' && routeName !== 'location-embedded-audio') {
    return <DebateEmbeddedNotFoundComponent debate={props.debate} />;
  }

  const { getCursor } = this.context,
    playRequested = props.video.get('playRequested'),
    isPlaying = props.video.get('isPlaying');

  if (!playRequested && !isPlaying) {
    return this.renderIntro(props.debate, getCursor, props.location);
  }

  // There is always a moment between playRequested and the playing event.
  // We want to avoid to briefly show the pause overlay in this period.
  if (!isPlaying && Date.now() - this.playRequestTime > 500) {
    return this.renderPausedOverlay(props.debate, getCursor);
  }

  return null;
};

const DebateEmbeddedComponent = component('DebateEmbeddedComponent', definition, render);

export default observer(
  structure,
  {
    video: ['ui', 'video'],
  },
  DebateEmbeddedComponent,
);
