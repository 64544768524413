import './polyfills'; // Must be first import

import React from 'react';
import { createRoot } from 'react-dom/client';
import Immutable from 'immutable';
import App from '@debatdirect/core/containers/App/App';
import { service, structure } from '@debatdirect/core/core';
import { bootstrap } from '@debatdirect/core-ts/lib/bootstrap';
import RootComponent from '@debatdirect/core-ts/lib/RootComponent';
import { ConnectionHandler, fetchMainFeeds, InitError, ResizeHandler, SplashScreenHandler, urlParams } from '@debatdirect/core/bootstrap';

import services from './services';
import config from './config';
import routes from './routes';
import { injectCastSender, injectPlayer, injectStats } from './di';
import './scss/index.scss';

const { ServiceContainer } = service;

// Save the initialUrl
const initialUrl = window.location.href;
const initialUrlParams = new URLSearchParams(window.location.search);

// @todo use the following condition when we've switched to the new CDK stack
// if (__LOAD_SENTRY__) {
if (config.sentry.dsn && !import.meta.env.DEV) {
  (async () => {
    const SentryReact = await import('@sentry/react');
    SentryReact.init(config.sentry);
  })();
}

injectPlayer();
injectStats();
injectCastSender();

/**
 * Get cursor from structure
 * @param {Array} keyPath
 * @returns {*}
 */
const getCursor = (keyPath) => {
  return structure.cursor(keyPath);
};

/**
 * Initialize.
 */
const init = () => {
  window.global = window;

  // Update structure with config and url parameters.
  structure
    .cursor()
    .setIn(['config'], Immutable.fromJS(config))
    .setIn(['ui', 'settings', 'baseUrl'], config.baseUrl)
    .setIn(['ui', 'settings', 'hd'], initialUrlParams.get('hd') === '1')
    .setIn(['ui', 'settings', 'api', 'baseUrl'], config.api.baseUrl)
    .setIn(['ui', 'settings', 'api', 'cdnBaseUrl'], config.api.cdnBaseUrl)
    .setIn(['ui', 'width'], document.body.offsetWidth)
    .setIn(['ui', 'seekEvent'], urlParams.getInitialEvent())
    .setIn(['ui', 'entryParams'], urlParams.getUrlParams().raw)
    .setIn(['auth', 'initialUrl'], initialUrl);

  // Show splash screen.
  SplashScreenHandler.show(config.staticUrl);

  // Initialize connection handler.
  ConnectionHandler.init(structure, ServiceContainer);

  // Initialize resize handler.
  ResizeHandler.init(structure);

  // Initialize app.
  App({
    ...config,
    structure,
    routes,
    services,
  });

  // Add touch-device class to HTML element if appropriate.
  if (ServiceContainer.get('platform').isTouchDevice()) {
    document.documentElement.classList.add('touch-device');
  }

  ServiceContainer.get('live').start();

  fetchData();
};

/**
 * Fetch feeds.
 */
const fetchData = () => {
  const api = ServiceContainer.get('api');
  Promise.all([fetchMainFeeds(), api.getAbValues()]).then(initView, () => {
    InitError.show(InitError.DATA_ERROR);
    setTimeout(fetchData, 20000); // Try again in 20 seconds.
  });
};

/**
 * Initialize view.
 */
let initView = ([_, values]) => {
  const { ServiceContainer } = service,
    getService = ServiceContainer.get;

  // Decide new homepage redirect
  const random = getService('user-preferences').getLuckyValue();
  const optOut = getService('user-preferences').getHomepageOptOut();
  const treatmentProbability = (values?.home || 0) / 100;
  const isTreatmentGroup = random < treatmentProbability;
  const treatmentGroupHomePage = isTreatmentGroup && optOut !== true;
  const controlGroupHomePage = !isTreatmentGroup && optOut === false;
  getService('user-preferences').setTreatmentGroup(isTreatmentGroup);

  // @TODO this redirects users that have shared the new homepage (masked URL) to the correct path
  if (window.location.search === '?home') {
    getService('router').replace('/home');
  } else if (window.location.pathname === '/' && (treatmentGroupHomePage || controlGroupHomePage)) {
    getService('router').replace('/home');
  }

  // Setup
  const { queryClient } = bootstrap(config);

  // Hide error.
  InitError.hide();

  // Fade out splash screen.
  SplashScreenHandler.hide();

  // Render root component.
  const container = document.querySelector('.AppView');
  const root = createRoot(container);
  root.render(<RootComponent getCursor={getCursor} getService={ServiceContainer.get} routes={routes} queryClient={queryClient} config={config} />);

  if (import.meta.env.DEV) {
    window.structure = structure;
  }

  // Override init view to prevent multiple calls.
  initView = function () {};
};

/**
 * Call init.
 */
init();

// @todo disabled due to stacking lifecycle events using class components!
// if (import.meta.hot) {
//   import.meta.hot.accept();
// }
