import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import component from 'omniscient';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';

const definition = {
  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  },

  componentWillUnmount: function () {
    this.context.getCursor(['ui', 'error']).update(() => Immutable.fromJS({}));
  },
};

const render = function () {
  return (
    <div className="Embed-Error">
      <SmartScrollView>
        <div className="Main-content Content">
          <header className="Content-header Header">
            <h1 className="Heading Heading--primary">Debat niet gevonden</h1>
          </header>
          <p className="Text">Debat Direct heeft geen informatie over het debat dat u zoekt.</p>
        </div>
      </SmartScrollView>
    </div>
  );
};

export default component('DebateEmbeddedErrorComponent', definition, render);
