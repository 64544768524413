import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class Tab extends Component {
  static propTypes = {
    onClick: PropTypes.func,
  };

  render() {
    const { active, onClick, ...rest } = this.props;

    return (
      <div
        className={classNames('Tab', { 'Tab-active': active })}
        onClick={!this.props.disabled ? onClick : undefined}
        data-index={this.props.index}
        tabIndex={active ? 0 : -1}
        aria-disabled={this.props.disabled}
        {...rest}
      >
        {this.props.children}
      </div>
    );
  }
}
