/**
 * Connection handler.
 */
const ConnectionHandler = {
  /**
   * Initialize.
   */
  init: function (structure, ServiceContainer) {
    this._getCursor = (keyPath) => structure.cursor(keyPath);
    this._getService = ServiceContainer.get;

    this._offlineHandler = this._handleOffline.bind(this);
    this._onlineHandler = this._handleOnline.bind(this);

    this._offlineTasksHandler = this._performOfflineTasks.bind(this);
    this._onlineTasksHandler = this._performOnlineTasks.bind(this);

    this._offlineTimer = null;

    this._setListeners(true);
  },

  /**
   * Dispose.
   */
  dispose: function () {
    this._setListeners(false);
    this._clearOfflineTimer();
  },

  /**
   * Add or remove listeners.
   * @param {Boolean} activate
   * @private
   */
  _setListeners: function (activate) {
    const method = activate ? 'addEventListener' : 'removeEventListener';

    window[method]('offline', this._offlineHandler, false);
    window[method]('online', this._onlineHandler, false);
  },

  /**
   * Handle offline event.
   * @private
   */
  _handleOffline: function () {
    this._clearOfflineTimer();

    // Prevent displaying the NoConnection prompt if connection is restored almost immediately
    this._offlineTimer = setTimeout(this._offlineTasksHandler, 2000);
  },

  /**
   * Handle online event.
   * @private
   */
  _handleOnline: function () {
    this._clearOfflineTimer();

    // Needs a timeout because Android triggers the online handler too soon.
    setTimeout(this._onlineTasksHandler, 250);
  },

  /**
   * Perform offline tasks.
   * @private
   */
  _performOfflineTasks: function () {
    this._clearOfflineTimer();
    this._setPrompt(true);
  },

  /**
   * Perform online tasks.
   * @private
   */
  _performOnlineTasks: function () {
    // Hide prompt.
    this._setPrompt(false);

    const isToday = this._getService('date').isToday();

    // No purpose in refreshing if agenda date is not today.
    if (!isToday) {
      return;
    }

    const router = this._getService('router'),
      routeName = router.getRoute().name,
      routeParameters = router.getParams();

    // We are on a non-debate page, only refresh the agenda.
    if (!this._isDebateRoute(routeName, routeParameters)) {
      this._getService('refresh').refresh();

      return;
    }

    let debate = this._getCursor(['data', 'debates']).find((debate) => debate.get('slug') === routeParameters.debate);

    // Could not find debate in debates list -> reload.
    if (!debate) {
      window.location.reload(false);

      return;
    }

    // Try to refresh debate.
    let debateId = debate.get('id');

    this._getService('refresh')
      .refresh(debateId)
      .catch((error) => {
        if (import.meta.env.DEV) {
          console.error('Error during refresh after online event: ', error);
        }

        window.location.reload();
      });
  },

  /**
   * Check if we are on a debate route.
   * @param {String} routeName
   * @param {Object} routeParameters
   * @returns {Boolean}
   * @private
   */
  _isDebateRoute: function (routeName, routeParameters) {
    return !!routeParameters?.debate;
  },

  /**
   * Show or hide no connection prompt.
   * @param {Boolean} activate
   * @private
   */
  _setPrompt: function (activate) {
    if (activate) {
      this._getService('prompt').prompt({
        identifier: 'NoConnection',
        backdrop: true,
      });
    } else {
      this._getService('prompt').dismiss('dismiss', 'NoConnection');
    }
  },

  /**
   * Clear offline timer.
   * @private
   */
  _clearOfflineTimer: function () {
    clearTimeout(this._offlineTimer);
    this._offlineTimer = null;
  },
};

/**
 * Public exports
 */
export default ConnectionHandler;
