import React from 'react';

import createIcon from './createIcon';

export default createIcon(
  '0 0 24 24',
  <g>
    <path d="M7 9v6h4l5 5V4l-5 5H7z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </g>,
);
