import persist from '@debatdirect/core/utils/persist';

const LOCAL_STORAGE_KEY = 'debatdirect-voorkeuren';

type Preferences = {
  luckyValue?: number;
  homepageOptOut?: boolean;
};

const UserPreferenceFactory = () => {
  const preferences: Preferences = JSON.parse(persist.getItem(LOCAL_STORAGE_KEY)) || {};
  const savePreferences = (preferences: Preferences) => persist.setItem(LOCAL_STORAGE_KEY, JSON.stringify(preferences));

  let isTreatmentGroup: undefined | boolean;
  const getLuckyValue = () => preferences.luckyValue ?? 0;
  const getHomepageOptOut = () => preferences.homepageOptOut;

  const setHomepageOptOut = (value: boolean) => {
    preferences.homepageOptOut = value;
    savePreferences(preferences);
  };

  const setTreatmentGroup = (value: boolean) => {
    isTreatmentGroup = value;
  };

  if (!preferences.luckyValue) {
    preferences.luckyValue = Math.random();
    savePreferences(preferences);
  }

  return {
    getLuckyValue,
    getHomepageOptOut,
    setHomepageOptOut,
    setTreatmentGroup,
    getTreatmentGroup: () => isTreatmentGroup,
  };
};

export default UserPreferenceFactory;
