import React from 'react';
import component from 'omniscient';

import { eq, getBackgroundClass, toggleFill } from './logic';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const { highlight, variant, politicians } = props;
    const fillColor = variant === 'dark' ? 'White' : '';
    const className = (props.className || 'u-fill').concat(' Infographic');

    const politiciansList = politicians?.join(', ');
    const title = `Visualisatie van de Suze Groenewegzaal ${politiciansList ? ' en de plaats van ' + politiciansList : ''}`;

    /* eslint-disable no-extra-parens */
    return (
      <svg viewBox="170 165 300 505" className={className} role="img" aria-labelledby="infographic-title">
        <title id="infographic-title">{title}</title>
        <g className={getBackgroundClass(variant === 'light' && !!highlight)}>
          <rect x="172" y="410.1" width="26.6" height="62.1" />
          <rect x="183.5" y="490.4" width="39.6" height="38.1" />
          <line fill="none" x1="186.7" y1="472.1" x2="186.7" y2="410.1" />
          <rect x="232.6" y="451.7" width="202.6" height="20.8" />
          <polygon points="468,667.6 183.5,667.6 183.7,561.4 249,561.4 249,518.7 436.7,518.7  " />
          <path d="M379.5,240.4c0-40.6-32.8-73.4-73.4-73.4s-73.4,32.8-73.4,73.4v147.8h34.7V240.4l0,0c0-21.3,17.4-38.7,38.7-38.7  c21.3,0,38.7,17.4,38.7,38.7v147.8h34.7V240.4z" />
        </g>
        <rect className={toggleFill(eq(highlight, 'pers'), fillColor)} x="232.6" y="451.7" width="202.6" height="20.8" />
        <polygon className={toggleFill(eq(highlight, 'publiek'), fillColor)} points="468,667.6 183.5,667.6 183.7,561.4 249,561.4 249,518.7 436.7,518.7 " />
        <rect className={toggleFill(eq(highlight, 'bode'), fillColor)} x="172" y="410.1" width="26.6" height="62.1" />
        <rect className={toggleFill(eq(highlight, 'verslaglegger'), fillColor)} x="183.5" y="490.4" width="39.6" height="38.1" />
        <path
          className={toggleFill(eq(highlight, 'kamerleden'), fillColor)}
          d="M320.6,204.6c14.2,5.7,24.2,19.6,24.2,35.9v147.8h34.7V240.4c0-30-17.9-55.7-43.8-67.2L320.6,204.6z"
        />
        <path
          className={toggleFill(eq(highlight, 'kabinet-en-ambtenaren'), fillColor)}
          d="M295.5,167.8c-35.5,5.1-62.9,35.7-62.9,72.7v147.8h34.7V240.4l0,0c0-19.4,14.3-35.7,33.2-38.3L295.5,167.8z"
        />
        <path
          className={toggleFill(eq(highlight, 'griffier'), fillColor)}
          d="M335.1,173c-6-2.6-12.5-4.3-19.3-5.3l-5.3,34.4c3.2,0.4,6.2,1.1,9.1,2.3L335.1,173z"
        />
        <path
          className={toggleFill(eq(highlight, 'voorzitter'), fillColor)}
          d="M306,167c-3.4,0-6.8,0.2-10,0.8l5.3,34.4c1.5-0.2,3-0.4,4.7-0.4c1.5,0,3,0.2,4.5,0.2l5.3-34.4 C312.6,167.2,309.4,167,306,167z"
        />
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicGroeneweg', render);
