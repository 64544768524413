import Immutable from 'immutable';

import PriorityQueue from '../../utils/lib/PriorityQueue';

const strategy = PriorityQueue.BinaryHeapStrategy;

const PromptServiceFactory = function (reference) {
  let visiblePrompt;

  const comparator = (a, b) => a.priority - b.priority,
    queue = new PriorityQueue({ strategy, comparator }),
    setPrompt = (props) => {
      const rest = Object.entries(props).reduce((acc, [key, value]) => ('function' === typeof value ? acc : { [key]: value, ...acc }), {});

      visiblePrompt = props;

      reference.cursor().update(() => Immutable.fromJS(rest));
    };

  return {
    dismiss: (dismissAction, identifier) => {
      // Prevent JS errors in case no prompt is active.
      if (!visiblePrompt) {
        return;
      }

      const ucfirst = ([c, ...cs]) => [c.toUpperCase(), ...cs].join(''),
        handlerName = 'on' + ucfirst(dismissAction),
        handler = visiblePrompt[handlerName];

      if (identifier !== visiblePrompt.identifier) {
        return;
      }

      reference.cursor().update(() => Immutable.fromJS({}));

      if (handler) {
        handler();
      }

      visiblePrompt = null;

      if (!queue.length) {
        return;
      }

      const { priority, ...props } = queue.dequeue();

      setPrompt(props);
    },

    prompt: (props) => {
      const { priority, ...rest } = props;

      if (visiblePrompt) {
        if (visiblePrompt.identifier !== props.identifier) {
          return queue.queue(props);
        }

        return;
      }

      setPrompt(rest);
    },
  };
};

export default PromptServiceFactory;
