import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { Helmet } from 'react-helmet';
import component from 'omniscient';
import immutable from 'immutable';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';

import ParliamentSection from './ParliamentSection';
import Cabinet from './Cabinet';

const /**
   * The component definition
   * @type {Object}
   */
  definition = {
    scrollViewRef: createRef(),

    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      pathWith: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
    },

    getInitialState: () => ({
      partialRender: false,
    }),

    componentWillUnmount: function () {
      clearTimeout(this.fullRenderTimeout);
    },

    componentDidMount: function () {
      const { route } = this.props;

      this.state.partialRender = route.name === 'parliament-members';
      this.fullRenderTimeout = setTimeout(this.renderComplete, 700);
    },

    componentDidUpdate: function (prevProps) {
      if (prevProps.route?.name !== this.props.route?.name) {
        this.scrollViewRef.current?.scrollTo(0, 0);
      }

      this.fullRenderTimeout = setTimeout(this.renderComplete, 700);
    },

    renderComplete: function () {
      if (this.state.partialRender === false) {
        return;
      }

      this.setState({ partialRender: false });
    },
  },
  /**
   * Creates list of sorted cabinet members.
   * @param {IndexedCursor} politicians
   * @returns {IndexedCursor}
   */
  getSortedCabinetMembers = function (politicians) {
    const filteredPoliticians = politicians.filter((politician) => politician.get('title') !== 'Tweede Kamerlid').toJS();

    let primeMinister = [],
      deputyPrimeMinisters = [],
      ministers = [],
      stateSecretaries = [];

    for (let politician of filteredPoliticians) {
      let title = (politician.title || '').toLowerCase();

      if (title.indexOf('viceminister-president') > -1) {
        deputyPrimeMinisters.push(politician);
      } else if (title.indexOf('minister-president') > -1) {
        primeMinister.push(politician);
      } else if (title.indexOf('minister') > -1) {
        ministers.push(politician);
      } else if (title.indexOf('staatssecretaris') > -1) {
        stateSecretaries.push(politician);
      }
    }

    let sortCallback = (a, b) => {
      if (a.slug < b.slug) {
        return -1;
      }

      if (b.slug < a.slug) {
        return 1;
      }

      return 0;
    };

    deputyPrimeMinisters.sort(sortCallback);
    ministers.sort(sortCallback);
    stateSecretaries.sort(sortCallback);

    let result = [...primeMinister, ...deputyPrimeMinisters, ...ministers, ...stateSecretaries];

    return immutable.fromJS(result);
  },
  /**
   * Creates list of sorted parliament sections.
   * @param {IndexedCursor} partiesCursor
   * @param {IndexedCursor} politiciansCursor
   * @returns {Array}
   */
  getSortedParliamentMembers = function (partiesCursor, politiciansCursor) {
    if (!partiesCursor || !politiciansCursor) {
      return [];
    }

    const parties = partiesCursor.toJS();
    const politicians = politiciansCursor.toJS();

    // Generate key (party id), value (party) for quick look-ups.
    const partiesTable = (() => {
      const result = {};

      for (let party of parties) {
        let id = party ? party.id || '' : '';

        if (id) {
          party.members = [];
          result[id] = party;
        }
      }

      return result;
    })();

    // Connect politicians to parties.
    for (let politician of politicians) {
      let partyId = politician ? politician.partyId || '' : '';

      if (partyId && Object.prototype.hasOwnProperty.call(partiesTable, partyId)) {
        partiesTable[partyId].members.push(politician);
      }
    }

    // Sort parties.
    parties.sort((a, b) => {
      if (typeof a.position === 'number' && typeof b.position === 'number') {
        return a.position - b.position;
      } else {
        return b.members.length - a.members.length;
      }
    });

    // Return parties.
    return parties;
  },
  /**
   * Render cabinet members.
   * @param {Object} parameters - Object containing parties and politicians.
   * @returns {React.Component}
   */
  renderCabinetMembers = function ({ politicians }) {
    const members = getSortedCabinetMembers(politicians);

    return <Cabinet members={members} />;
  },
  /**
   * Render parliament members.
   * @param {Object} parameters - Object containing parties and politicians.
   * @returns {React.Component}
   */
  renderParliamentMembers = function ({ parties, politicians }) {
    const sortedParties = getSortedParliamentMembers(parties, politicians);

    if (this.state.partialRender && sortedParties.length > 0) {
      let party = sortedParties[0];

      return <ParliamentSection party={party} key={party.id} />;
    } else {
      return sortedParties.map((party) => <ParliamentSection key={party.id} party={party} />);
    }
  },
  /**
   * PoliticiansComponent render function.
   * @param {Object} props
   * @returns {React.Component}
   */
  render = function ({ route }) {
    const { getCursor } = this.context,
      listRenderer = route.name === 'parliament-members' ? renderParliamentMembers : renderCabinetMembers,
      actors = getCursor(['data', 'persistent', 'actors']), // Use today's actors.
      parameters = {
        parties: actors.get('parties'),
        politicians: actors.get('politicians'),
      };

    return (
      <div className="Layer ViewStack-layer">
        <Helmet>
          <title>Kamerleden | Debat Direct</title>
          <meta name="description" content="Wie zijn de Tweede Kamerleden?" />
        </Helmet>
        <div className="Main-wrapper is-expanded" id="main" tabIndex={-1}>
          <SmartScrollView ref={this.scrollViewRef} externalWheelHandling={true}>
            <div className="Main-content--full Content">{listRenderer.call(this, parameters)}</div>
          </SmartScrollView>
        </div>
      </div>
    );
  };

export default component('PoliticiansComponent', definition, render);
