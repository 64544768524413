const DebugServiceFactory = function (reference) {
  let unobserve, initialState, changeLog;

  const /**
     * Checks if maybeCursor is a cursor
     * @param  {*}       maybeCursor This could be a cursor
     * @return {Boolean}             True if maybeCursor is a cursor
     */
    isCursor = (maybeCursor) => 'undefined' !== typeof maybeCursor.toJS,
    /**
     * Writes to the changeLog
     * @param  {Cursor} nextState Next application state
     * @param  {Cursor} state     Current application state
     * @param  {Array}  keyPath   Path to cursor where change originated
     */
    handleSwap = (nextState, state, keyPath) => {
      const cursorOrValue = nextState.getIn(keyPath),
        value = isCursor(cursorOrValue) ? cursorOrValue.toJS() : cursorOrValue;

      changeLog.push({ keyPath, value });
    },
    /**
     * The service definition
     * @type {Object}
     */
    DebugService = {
      /**
       * Returns the log data
       * @return {Object} The log data
       */
      getData: function () {
        return {
          initialState,
          changeLog,
        };
      },

      /**
       * Starts the debug logger
       */
      start: function () {
        changeLog = [];
        unobserve = reference.observe('swap', handleSwap);
        initialState = reference.cursor().toJS();

        reference.cursor('ui').update('debug', () => true);
      },

      /**
       * Stops the debug logger and clears the data
       */
      stop: function () {
        if (unobserve) {
          unobserve();
          unobserve = null;
        }

        initialState = null;
        changeLog = null;

        reference.cursor('ui').update('debug', () => false);
      },
    };

  return DebugService;
};

export default DebugServiceFactory;
