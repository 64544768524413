import React from 'react';

import Checkbox from '../Checkbox/Checkbox';
import Link from '../Link/Link';

const DownloadAgreement = ({ onChange, checked, id }) => {
  return (
    <div className="u-flex u-row u-mb15">
      <div className="u-shrink u-mr10">
        <Checkbox onChange={onChange} checked={checked} id={id} aria-required />
      </div>
      <div className="u-grow u-pt10">
        <label className="u-mt0 u-pointer" htmlFor={id}>
          <Link className="u-blueText">Ik accepteer de licentievoorwaarden van de Tweede Kamer</Link>
        </label>
        <p>
          (i) accepteert de gebruiker de licentievoorwaarden als onderdeel van de rechtsverhouding tussen Tweede Kamer en de gebruiker, (ii) verkrijgt de
          gebruiker een licentie voor het gebruik van het audiovisuele materieel in overeenstemming met de geldende licentievoorwaarden, en (iii) verkrijgt de
          gebruiker toegang tot het beschikbare audiovisuele materiaal.
        </p>
        <p>
          U kunt deze licentievoorwaarden als PDF-bestand downloaden door op onderstaande link te klikken:
          <br />
          <Link href="https://www.tweedekamer.nl/applicaties/disclaimer" target="_blank" className="u-blueText Link--underline">
            Download de licentievoorwaarden van de Tweede Kamer
          </Link>
        </p>
      </div>
    </div>
  );
};

export default DownloadAgreement;
