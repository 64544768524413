import React from 'react';
import component from 'omniscient';

import { eq, getBackgroundClass, toggleFill } from './logic';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const { highlight, variant, politicians } = props;
    const fillColor = variant === 'dark' ? 'White' : '';
    const className = (props.className || 'u-fill').concat(' Infographic');

    const politiciansList = politicians?.join(', ');
    const title = `Visualisatie van de Alleta Jacobszaal ${politiciansList ? ' en de plaats van ' + politiciansList : ''}`;

    /* eslint-disable no-extra-parens */
    return (
      <svg viewBox="170 165 300 694" className={className} role="img" aria-labelledby="infographic-title">
        <title id="infographic-title">{title}</title>
        <g className={getBackgroundClass(variant === 'light' && !!highlight)}>
          <path d="M364.6,369.8c32.7-18.8,54.6-53.8,54.6-94.3c0-60-48.5-108.5-108.5-108.5s-108.5,48.5-108.5,108.5 c0,40.4,22.2,75.8,55.2,94.6l26.8-47.1c-16.3-9.4-27.6-27.1-27.6-47.1c0-30,24.4-54.4,54.4-54.4s54.4,24.4,54.4,54.4 c0,20.1-11,37.8-27.3,47.1L364.6,369.8z" />
          <path d="M209,489.5v69.9c0,0,119.2,126.2,119.2,297.1h139.6V541h-69.6v-51.4H209z" />
          <line x1="446.6" y1="419.3" x2="446.6" y2="509.1" />
          <rect x="429.4" y="419.3" width="38.6" height="89.7" />
          <rect x="342.9" y="400.3" width="57.3" height="55.2" />
          <rect x="172" y="400.3" width="162.3" height="30" />
        </g>
        <rect className={toggleFill(eq(highlight, 'pers'), fillColor)} x="172" y="400.3" width="162.3" height="30" />
        <path className={toggleFill(eq(highlight, 'publiek'), fillColor)} d="M209,489.5v69.9c0,0,119.2,126.2,119.2,297.1h139.6V541h-69.6v-51.4H209z" />
        <rect className={toggleFill(eq(highlight, 'bode'), fillColor)} x="429.4" y="419.3" width="38.6" height="89.7" />
        <rect className={toggleFill(eq(highlight, 'verslaglegger'), fillColor)} x="342.9" y="400.3" width="57.3" height="55.2" />
        <path
          className={toggleFill(eq(highlight, 'kamerleden'), fillColor)}
          d="M361.7,179.9c34,18.2,57.3,54.4,57.3,95.6c0,40.4-22.2,75.8-55.2,94.6L337,322.9c16.3-9.4,27.6-27.1,27.6-47.1 c0-20.6-11.5-38.6-28.7-47.9L361.7,179.9z"
        />
        <path
          className={toggleFill(eq(highlight, 'kabinet-en-ambtenaren'), fillColor)}
          d="M292.8,168.6c-51.4,8.6-90.5,53.3-90.5,107.1c0,40.4,22.2,75.8,55.2,94.6l26.8-47.1c-16.3-9.4-27.6-27.1-27.6-47.1 c0-27.1,19.6-49.3,45.3-53.6L292.8,168.6z"
        />
        <path
          className={toggleFill(eq(highlight, 'griffier'), fillColor)}
          d="M328.4,168.3l-9.1,53.6c5.9,1.1,11.5,2.9,16.6,5.6l25.7-47.9C351.2,174.2,340,170.5,328.4,168.3z"
        />
        <path
          className={toggleFill(eq(highlight, 'voorzitter'), fillColor)}
          d="M293.1,168.6l8.8,53.6c5.9-1.1,11.8-1.1,17.7,0l8.8-53.6C316.7,166.5,304.6,166.5,293.1,168.6z"
        />
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicJacobs', render);
