import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';

const definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
    },

    componentDidMount: function () {
      this.context.getCursor(['ui', 'video']).update('isFullScreen', () => true);
    },

    componentWillUnmount: function () {
      this.context.getCursor(['ui', 'video']).update('isFullScreen', () => false);
    },
  },
  /**
   * Renders the DebateSubjectPage
   * @return {React.Component}        An instance of DebateSubjectComponent
   */
  render = function () {
    return <div />;
  },
  DebateVideoComponent = component('DebateVideoComponent', definition, render);

export default observer(
  structure,
  {
    video: ['ui', 'video'],
  },
  DebateVideoComponent,
);
