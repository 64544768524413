import PropTypes from 'prop-types';
import React from 'react';
import { withDefaults } from 'omniscient';
import classNames from 'classnames';

import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import { MoreVert } from '../../../icons';

import AgainstIcon from './icons/Against';
import FavourIcon from './icons/Favour';
import DebateVotingRoundList from './DebateVotingRoundList';

const component = withDefaults({ cursorField: 'cursor' }),
  VOTE_FAVOUR = 'Voor',
  VOTE_AGAINST = 'Tegen',
  VOTE_UNKNOWN = 'Onbekend',
  RESULT_ACCEPTED = 'Aangenomen',
  RESULT_REJECTED = 'Verworpen',
  RESULT_UNKNOWN = 'Onbekend',
  TYPE_PARTY = 'Fractie',
  TYPE_POLITICIAN = 'Hoofd',
  TYPE_SHARED = 'Gedeeld',
  TYPE_UNKNOWN = 'Onbekend',
  SORTING_POLITICIAN = 'politician',
  SORTING_VOTE = 'vote',
  /**
   * Component definition
   *
   * @type {Object}
   */
  definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
    },

    /**
     * Component did update lifecycle function
     */
    componentDidUpdate: function () {
      if (!this.state.expanded || this.state.inProgress) {
        return;
      }

      // @TODO React refs don't work in omnipotent components, because it will result in the same `createRef` for all
      //       instances.
      const details = document.querySelector(`#votingRoundDetails-${this.props.cursor.get('id')}`);

      if (!details) {
        return;
      }

      window.setTimeout(() => {
        const scrollHeight = details?.scrollHeight;

        if (scrollHeight && this.state.expanded && !this.state.inProgress) {
          details.style.height = scrollHeight + 'px';
        }
      }, 30);
    },

    /**
     * Toggle voting details
     */
    toggleVotingDetails: function () {
      const toExpanded = !this.state.expanded;

      // collapse immediately, but expand the next frame
      if (!toExpanded) {
        const details = document.querySelector(`#votingRoundDetails-${this.props.cursor.get('id')}`);

        if (details) details.style.height = 0;
      }

      if ('Hoofd' === this.props.cursor.get('type') && !this.state.expanded) {
        document.querySelector('.Layer--loader').classList.add('is-visible');
      }

      // set the inProgress flag so we know we shouldn't update the height while waiting
      this.state.inProgress = true;

      // add a small delay so the votes will be removed when the details element is already in transition
      setTimeout(() => {
        this.state.inProgress = false;
        this.setState({ expanded: toExpanded });
      }, 50);
    },

    /**
     * Initial state lifecycle function
     *
     * @returns {Object} Initial state
     */
    getInitialState: function () {
      return {
        expanded: false,
      };
    },
  },
  renderVotesList = (type, votes, dataQuality, parties, politicians) => {
    return <DebateVotingRoundList type={type} votes={votes} dataQuality={dataQuality} parties={parties} politicians={politicians} />;
  },
  /**
   * Renders the DebateVotingRound
   *
   * @param  {Cursor}          votingRound    Voting round cursor
   *
   * @return {React.Component}                An instance of DebateVotingRound
   */
  render = function (votingRound) {
    const state = this.state,
      id = votingRound.get('id'),
      title = votingRound.get('title'),
      subject = votingRound.get('subject'),
      type = votingRound.get('type'),
      result = votingRound.get('result'),
      votes = votingRound.get('votes'),
      started = votingRound.get('startedAt'),
      dataQuality = votingRound.get('dataQuality'),
      ended = result !== RESULT_UNKNOWN,
      hasEnded = !!(ended && votes),
      isCurrent = started && !hasEnded,
      verifyingVotes = dataQuality === 'live',
      canViewDetails = hasEnded && dataQuality !== 'live',
      toggleIconName = state.expanded ? 'collapse' : 'expand',
      showShareButton = started && hasEnded && dataQuality !== 'live',
      className = classNames('List-item Voting', {
        'Voting--currentVoting': true === isCurrent,
        'Voting--parties': type === TYPE_PARTY,
        'Voting--politicians': type === TYPE_POLITICIAN,
        'Voting--denied': result === RESULT_REJECTED && !verifyingVotes,
        'Voting--accepted': result === RESULT_ACCEPTED && !verifyingVotes,
      }),
      votingDetailsClassName = classNames('Voting-details', {
        'is-expanded': state.expanded,
      }),
      toggleIconClassName = classNames('u-inline u-verticalCenter u-centerSelf', {
        'u-hidden': !canViewDetails,
      });

    // Voting is started
    let status = verifyingVotes ? 'Uitslag stemming na 2 uur beschikbaar.' : 'In stemming';

    // Voting isn't started yet
    if (!started) {
      return null;
    }

    // Votes are being verified
    if (type === TYPE_POLITICIAN && verifyingVotes && !isCurrent) {
      status = 'Uitslag hoofdelijke stemming na 2 uur beschikbaar.';
    } else if (type === TYPE_PARTY && verifyingVotes && !isCurrent) {
      status = 'Uitslag stemming per fractie na 2 uur beschikbaar.';
    } else if (started && hasEnded) {
      status = result;
    }

    return (
      <li className={className}>
        <section className="Voting-summary u-width500 u-hmc u-flex u-col">
          <header className="Voting-header">
            <div className="Voting-line u-pt20" />
            <h2 className="Voting-title">
              <div className="Voting-result">
                {!verifyingVotes && result === RESULT_REJECTED && <AgainstIcon />}
                {!verifyingVotes && result === RESULT_ACCEPTED && <FavourIcon />}
              </div>
              {title}
            </h2>
            {showShareButton ? (
              <Button className="IconButton Voting-moreButton" onClick={(event) => this.props.onSettings(event, votingRound)} aria-label="Meer opties">
                <MoreVert />
              </Button>
            ) : null}
          </header>
          <div className="Voting-info">
            <div className="Voting-line u-pt10 u-pb10">
              {subject && <p className="Voting-description">{subject}</p>}
              <Button className="Button--expand" onClick={this.toggleVotingDetails} disabled={!canViewDetails}>
                <span className="Button-label u-centerSelf">{status}</span>
                <Icon name={toggleIconName} className={toggleIconClassName} width="16" height="16" />
              </Button>
            </div>
          </div>
        </section>
        <section className={votingDetailsClassName} id={`votingRoundDetails-${id}`}>
          {votes && state.expanded ? renderVotesList(type, votes, dataQuality, this.props.parties, this.props.politicians) : null}
        </section>
      </li>
    );
  };

export default component('DebateVotingRound', definition, render);
