import { type FC, useState, useRef, useEffect } from 'react';
import Button from '@debatdirect/core-ts/components/Button/Button';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import Switch from '@debatdirect/core-ts/components/Switch/Switch';
import { trackEvent } from '@debatdirect/core-ts/utils/trackEvent';

import AgendaIcon from './icons/AgendaIcon';
import HomeIcon from './icons/HomeIcon';
import styles from './PromotionBanner.module.scss';

const PromotionBanner: FC = () => {
  const { getService } = useAppProvider();
  const userPreferences = getService('user-preferences');
  const hasTrackedEvent = useRef(false); // Prevents firing tracking event multiple times
  const isTreatmentGroup = userPreferences.getTreatmentGroup();
  const [optout, setOptout] = useState<boolean>(userPreferences.getHomepageOptOut());

  useEffect(() => {
    if (optout === undefined) {
      setOptout(false); // When visiting the page for the first time, default to opt-in
    }
    getService('user-preferences').setHomepageOptOut(optout);
  }, [optout, getService]);

  const handleOptOutToggle = () => {
    const newValue = !optout;
    const shouldTrackEvent = newValue && !hasTrackedEvent.current;
    setOptout(newValue);

    if (shouldTrackEvent && window._paq) {
      hasTrackedEvent.current = true;
      trackEvent('homepage', 'preferAgendaPage', isTreatmentGroup ? 'treatmentGroup' : 'controlGroup');
    }
  };

  return (
    <section className={styles.section}>
      <div className={styles.block}>
        <div className={styles.blockIcon}>
          <AgendaIcon aria-hidden="true" />
        </div>
        <div className={styles.blockText}>
          <h2 className={styles.blockTitle}>De pagina Debatten van vandaag blijft beschikbaar</h2>
          <div className={styles.blockParagraph}>
            <p>U kunt via het menu of met de knop hieronder eenvoudig naar Debatten van vandaag gaan.</p>
            <p>
              <Switch
                name="optout"
                checked={optout}
                onChange={handleOptOutToggle}
                label="Stel Debatten van vandaag in als startscherm in plaats van de nieuwe homepage"
              />
            </p>
          </div>
          <Button href="/" label="Terug naar Debatten van vandaag" variant="light" className={styles.blockButton} />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.blockIcon}>
          <HomeIcon aria-hidden="true" />
        </div>
        <div className={styles.blockText}>
          <h2 className={styles.blockTitle}>Debat Direct heeft een nieuwe homepage</h2>
          <div className={styles.blockParagraph}>
            <p>Natuurlijk horen we graag wat u van onze nieuwe homepage vindt! Ervaart u problemen of heeft u vragen? Neem gerust contact met ons op.</p>
            <Button href="/support/" label="Neem contact op" variant="light" className={styles.blockButton} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PromotionBanner;
