import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import { bySlug } from '../../predicates';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import Icons from '../../components/Icons/Icons';
import SkipLink from '../../components/SkipLink/SkipLink';
import LayerOverlay from '../LayerOverlay/LayerOverlay';
import VideoPoster from '../Video/VideoPoster';
import VideoSwitcher from '../Video/VideoSwitcher';
import SnackBarProvider from '../SnackBarProvider/SnackBarProvider';
import NavBar from '../NavBar/NavBar';
import Menu from '../Menu/Menu';
import InfoPanelFactory from '../InfoPanelFactory/InfoPanelFactory';
import VideoControls from '../Controlbar/VideoControls';
import NotificationsProvider from '../NotificationsProvider/NotificationsProvider';

const definition = {
    mixins: [],

    contextTypes: {
      getService: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
      navigate: PropTypes.func.isRequired,
      router: PropTypes.object.isRequired,
    },

    childContextTypes: {
      location: PropTypes.object,
      route: PropTypes.object,
      params: PropTypes.object,
      routeHistory: PropTypes.array,
      pathWith: PropTypes.func,
      embedded: PropTypes.bool,
    },

    getInitialState: () => ({
      routeHistory: null,
    }),

    getChildContext: function () {
      const route = this.props.routes[this.props.routes.length - 1];

      return {
        location: this.props.location,
        route,
        routeHistory: this.state.routeHistory,
        params: this.props.params,
        pathWith: (routeName, params) => this.context.pathTo(routeName, { ...this.props.params, ...params }),
        embedded: this.context.getService('router').isEmbedded(route),
      };
    },

    handleFirstTab: function (event) {
      if (event.keyCode === 9) {
        // tab
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', this.handleFirstTab);
        window.addEventListener('mousedown', this.handleMouseDownOnce);
        window.addEventListener('pointerdown', this.handleMouseDownOnce);
      }
    },

    // Hide focus outline after mouse click
    handleMouseDownOnce: function () {
      document.body.classList.remove('user-is-tabbing');
      window.removeEventListener('mousedown', this.handleMouseDownOnce);
      window.removeEventListener('pointerdown', this.handleMouseDownOnce);
      window.addEventListener('keydown', this.handleFirstTab);
    },

    componentDidMount: function () {
      const route = this.props.routes[this.props.routes.length - 1],
        pathWith = (routeName, params) => this.context.pathTo(routeName, { ...this.props.params, ...params });

      if (route.indexRedirect) {
        this.context.navigate(pathWith(route.indexRedirect));
      }

      this.state.routeHistory = [[route.name, this.props.params]];

      // set initial route and params
      this.context.getService('router').setRouteAndParams(route, this.props.params);

      // Show focus outline for keyboard users
      window.addEventListener('keydown', this.handleFirstTab);

      // Track if embedded
      if (window._paq) {
        const isEmbedded = this.context.getService('router').isEmbedded(route);
        const environment = isEmbedded ? 'embedded' : 'regular';

        window._paq.push(['setCustomVariable', 5, 'Environment', environment, 'visit']);
      }
    },

    componentDidUpdate: function (prevProps) {
      const route = prevProps.routes[prevProps.routes.length - 1],
        params = prevProps.params,
        lastRoute = this.state.routeHistory[0];

      if (!lastRoute || lastRoute[0] !== route.name || JSON.stringify(params) !== JSON.stringify(lastRoute[1])) {
        this.state.routeHistory.unshift([route.name, params]);
        this.state.routeHistory.splice(15);
      }

      this.context.getService('router').setRouteAndParams(route, params);
    },

    shouldComponentUpdate: function (nextProps) {
      const nextFullScreen = nextProps.video.get('isFullScreen'),
        currentFullScreen = this.props.video.get('isFullScreen');

      // Full screen is triggered by orientation change.
      // React re-render is not needed, everything is handled by CSS.
      return !(this.state.isPhone && currentFullScreen !== nextFullScreen);
    },
  },
  /**
   * The base AppView component
   * @param  {Cursor}          cursor The data structure's root cursor
   * @return {React.Component}        The root React component
   */
  render = function ({ video, debates, prompt }) {
    const cursor = this.context.getCursor(),
      route = this.props.routes[this.props.routes.length - 1],
      isEmbedded = this.context.getService('router').isEmbedded(route),
      isPlaying = video.get('isPlaying'),
      audioOnly = video.get('audioOnly'),
      playRequested = video.get('playRequested'),
      playingDebate = video.get('playingDebate'),
      // The location-embedded uses the 'playingDebate' cursor for the current debate.
      // All other pages use the location slug to find the current debate
      debate = route.name === 'location-embedded' ? playingDebate : debates.find(bySlug(this.props.params.debate)),
      debateIsStarted = debate?.has('startedAt'),
      debateHasVideo = debate?.has('video'),
      menuVisible = this.context.getCursor(['ui', 'menu']).get('isVisible'),
      infoPanelVisible = this.context.getCursor(['ui', 'infoPanel']).get('isVisible'),
      embedModalOpen = this.context.getCursor(['ui', 'embedModal']).get('open'),
      downloadModalOpen = this.context.getCursor(['ui', 'downloadModal']).get('open'),
      searchSidebarOpen = this.context.getCursor(['ui', 'search']).get('sidebarOpen'),
      datePickerOpen = this.context.getCursor(['ui', 'datePicker']).get('open'),
      promptVisible = !prompt.isEmpty(),
      shouldEnableInert = menuVisible || promptVisible || infoPanelVisible || embedModalOpen || downloadModalOpen || searchSidebarOpen || datePickerOpen,
      inert = shouldEnableInert ? '' : null, // React doesn't play nice with inert property being a boolean
      videoPosterClassName = classNames({
        'u-opaque': (isPlaying || playRequested) && !audioOnly,
      }),
      appClassName = `App App--${route.name} ViewStack`,
      mainClassName = `ViewStack Main Main--${route.name}`,
      layerInfoClassName = classNames('ViewStack-layer Layer Layer--info'),
      videoLayerClassName = classNames('ViewStack-layer Layer Layer--video', {
        'video-is-playing': isPlaying,
        'debate-has-video': debateHasVideo,
        'debate-is-started': debateIsStarted,
        'audio-only': audioOnly,
      }),
      staticUrl = this.context.getCursor(['config']).get('staticUrl');

    let placeholder = `${staticUrl}/img/blauwe-stoelen.jpg`;

    if (debateHasVideo) {
      placeholder = debate.getIn(['video', 'imageUrl']);
    }

    if (route.name === 'location-plenary') {
      placeholder = `${staticUrl}/img/plenaire-zaal.jpg`;
    }

    const isDebateScreen = route.name.indexOf('debate-') === 0;
    const isDebateVideoScreen = route.name === 'debate-video';
    const skipLinkHref = isDebateVideoScreen ? '#video-controls' : '#main';
    const skipLinkHrefMobile = isDebateScreen ? '#video-controls' : '#main'; // On mobile, for all sub routes the videoControls are above the main content

    return (
      <ErrorBoundary>
        <SnackBarProvider>
          <div className="ViewStack u-cover">
            <Icons />
            <div className={appClassName}>
              <SkipLink inert={inert} href={skipLinkHref} className="u-sm-invisible" />
              <SkipLink inert={inert} href={skipLinkHrefMobile} className="u-sm-visible" />
              <div className={videoLayerClassName}>
                <VideoSwitcher poster={placeholder} debate={debate} />
                <VideoPoster className={videoPosterClassName} placeholder={placeholder} />
              </div>
              {!isEmbedded && <Menu />}
              <div className={layerInfoClassName}>
                {!isEmbedded && <NavBar route={route} debate={debate} inert={inert} />}
                <div className={mainClassName}>
                  <div className="ViewStack-layer Layer Layer--controlbar" inert={inert}>
                    {(debateHasVideo && debateIsStarted) || isEmbedded ? <VideoControls debate={debate} /> : null}
                  </div>
                  {!isEmbedded && <InfoPanelFactory />}
                  <div className="u-cover" inert={inert}>
                    {this.props.children}
                  </div>
                </div>
              </div>
              <LayerOverlay cursor={cursor} />
              <NotificationsProvider debate={debate} />
            </div>
          </div>
        </SnackBarProvider>
      </ErrorBoundary>
    );
  },
  AppView = component('AppView', definition, render);

export default observer(
  structure,
  {
    debates: ['data', 'debates'],
    video: ['ui', 'video'],
    menu: ['ui', 'menu'],
    prompt: ['ui', 'prompt'],
    infoPanel: ['ui', 'infoPanel'],
    embedModal: ['ui', 'embedModal'],
    downloadModal: ['ui', 'downloadModal'],
    datePicker: ['ui', 'datePicker'],
    search: ['ui', 'search'],
  },
  AppView,
);
