import Immutable from 'immutable';
import moment from 'moment';

import { byId } from '../../../predicates';

import resolvers from './resolvers';

const immute = (value) => () => Immutable.fromJS(value),
  /**
   * Capitalizes the first letter of a string
   * @param  {String} value The string to capitalize
   * @return {String}       The string with the capitalized first character
   */
  ucfirst = ([c, ...cs]) => [c.toUpperCase(), ...cs].join(''),
  /**
   * Transforms a snake_case string to camelCase
   * @param  {String} string Ths string to transform
   * @return {String}        The transformed string
   */
  snakeToCamelCase = (string) => string.split('_').map(ucfirst).join(''),
  /**
   * Creates an updater function which prepends the new value to the current value
   * @param  {Array}    value An iterable value
   * @return {Function}       The updater function
   */
  prepend = (value) => (current) => Immutable.fromJS(value).concat(current),
  /**
   * Updates the debate with changes in diff
   * @param  {Reference} reference Immstruct reference to debates data
   * @param  {String}    debateId  Current debate id
   * @param  {Array}     diff      Everything that needs to be changed
   * @return {Cursor}              The resulting debate cursor
   */
  update = (reference, debateId, diff) => {
    const debate = reference.cursor(['data', 'debates']).find(byId(debateId));

    return diff.reduce((cursor, { operation, keyPath, value }) => {
      if ('prepend' === operation) {
        return cursor.updateIn(keyPath, (current) => {
          const prepended = prepend(value)(current);

          // if we have less than 2 events we don't need to sort
          if (prepended.count() < 2) {
            return prepended;
          }

          const lastEvent = prepended.get(0);
          const secondLastEvent = prepended.get(1);

          // test if the last added event time is after the second last event
          if (moment(lastEvent.get('eventStart')).isAfter(secondLastEvent.get('eventStart'))) {
            return prepended;
          }

          // the last added event has an eventStart before the secondLastEvent. This could mean the event is dispatched
          // later or lost a race condition. Sort the events list.
          return prepended.sort((a, b) => {
            return moment(b.get('eventStart')).format('x') - moment(a.get('eventStart')).format('x');
          });
        });
      }

      if (!value) {
        return cursor[operation](keyPath);
      }

      return cursor[operation](keyPath, immute(value));
    }, debate);
  },
  /**
   * Starts a voting round
   * @param  {Reference} reference Immstruct reference to debates data
   * @param  {String}    debateId  Current debate id
   * @param  {Object}    eventData The data to process
   * @return {Cursor}              The resulting voting cursor
   */
  updateVotingStart = (reference, debateId, eventData) => {
    const debate = reference.cursor(['data', 'debates']).find(byId(debateId)),
      votingId = eventData.objectId;

    return debate
      .get('votings')
      .find(byId(votingId))
      .update('startedAt', () => eventData.eventStart);
  },
  /**
   * Updates a voting round
   * @param  {Reference} reference Immstruct reference to debates data
   * @param  {String}    debateId  Current debate id
   * @param  {Object}    eventData The data to process
   * @return {Cursor}              The resulting voting cursor
   */
  updateVoting = (reference, debateId, eventData) => {
    const debate = reference.cursor(['data', 'debates']).find(byId(debateId)),
      votingRoundId = eventData.id,
      debateRaw = debate.toJS();

    debateRaw.votings = debateRaw.votings.map((voting) => {
      const foundIndex = voting.votingRounds.findIndex((round) => round.id === votingRoundId);

      if (foundIndex !== -1) {
        if (!eventData.title) {
          eventData.title = voting.votingRounds[foundIndex].title;
        }
        voting.votingRounds[foundIndex] = eventData;
      }

      return voting;
    });

    return debate.get('votings').update(() => Immutable.fromJS(debateRaw.votings));
  },
  /**
   * Update debate with debate_start event
   *
   * @param  {Reference} reference  Immstruct reference to debates data
   * @param  {String}    debateId   Current debate id
   * @param  {Event}     eventData  The live event data
   * @return {Cursor}               The resulting debate cursor
   */
  updateDebateStart = function (reference, debateId, eventData) {
    const getHash = (value) => `${value.eventStart}-${value.eventType}`;
    const hash = getHash(eventData);

    const debate = reference.cursor(['data', 'debates']).find(byId(debateId));
    const foundEvent = debate.get('events').find((value) => {
      return getHash(value.toJS()) === hash;
    });

    // debate start event is already added to the list
    if (foundEvent) {
      return debate;
    }

    return genericResolve(reference, debateId, eventData);
  },
  /**
   * Shared code for both resolveForIndex and resolve
   * @param  {Reference} reference  Immstruct reference to debates data
   * @param  {String}    debateId   Current debate id
   * @param  {Event}     eventData  The live event data
   * @return {Cursor}               The resulting debate cursor
   */
  genericResolve = function (reference, debateId, eventData) {
    const { eventType } = eventData,
      resolverName = 'resolve' + snakeToCamelCase(eventType),
      resolver = resolvers[resolverName];

    if (!resolver) {
      return false;
    }

    const diff = resolver(eventData);

    return update(reference, debateId, diff);
  },
  /**
   * Update notifications
   *
   * @param reference
   * @param notification
   * @returns {*}
   */
  updateNotifications = (reference, notification) => {
    if (!notification.id) {
      return;
    }

    const { eventType, ...notificationProps } = notification;

    const existingNotification = reference.cursor(['data', 'liveNotifications']).find((datum) => datum.get('id') === notification.id);

    // we want to remove a notification if there is no message property.
    if (!notificationProps.message) {
      if (existingNotification) {
        reference.cursor(['data', 'liveNotifications']).update((notifications) => {
          return notifications.splice(
            notifications.findIndex((datum) => datum.get('id') === notification.id),
            1,
          );
        });
      }

      return;
    }

    // update existing notification
    if (existingNotification) {
      return existingNotification.merge(notificationProps);
    }

    // add new notification
    reference.cursor(['data', 'liveNotifications']).update((notifications) => {
      return notifications.concat(Immutable.fromJS([Immutable.Map(notificationProps)]));
    });
  },
  /**
   * Handle an agenda update event
   *
   * @param reference
   * @param eventData
   */
  updateAgenda = (reference, eventData) => {
    if (!eventData.agenda?.debates) {
      return;
    }

    // get the current agenda date.
    const date = reference.cursor(['data', 'date']).deref();

    // loop over each debate and determine the updates required.
    // the updates only apply if we are currently on the same agendaDate
    // when the debate can't be found in the agenda, it will be added
    eventData.agenda.debates.forEach((updatedDebate) => {
      // not in the current agenda, do nothing
      if (date !== updatedDebate.debateDate) {
        return;
      }

      const { id, endedAt, startedAt, ...updates } = updatedDebate;

      // only set the startedAt if it has a value. Started at is null in the update, but we expect the property not to
      // exist when the debate is not started. We need the `null` value to be able to unset properties though.
      if (startedAt) {
        updates.startedAt = startedAt;
      }

      // same as startedAt for the endedAt property
      if (endedAt) {
        updates.endedAt = endedAt;
      }

      // find the debate
      let debate = reference.cursor(['data', 'debates']).find(byId(id));
      let sortDebates = false;

      if (debate) {
        // delete the endedAt property if the property exists in the debate, but not in the update.
        if (!endedAt && debate.has('endedAt')) {
          debate = debate.delete('endedAt');
        }

        // delete the startedAt property if the property exists in the debate, but not in the update.
        if (!startedAt && debate.has('startedAt')) {
          debate = debate.delete('startedAt');
          sortDebates = true;
        }

        // startedAt or startsAt has been changed. These properties will change the agenda sorting.
        if (startedAt !== debate.get('startedAt') || updates.startsAt !== debate.get('startsAt')) {
          sortDebates = true;
        }

        // merge all updates in the found debate
        debate.merge(Immutable.fromJS(updates));
      } else {
        // it's an unknown debate, add it to the list of debates
        reference.cursor('data').update('debates', (debates) => {
          return debates.concat(
            Immutable.fromJS([
              {
                id,
                ...updates,
              },
            ]),
          );
        });

        sortDebates = true;
      }

      // sort all debates on startedAt or startsAt properties
      if (sortDebates) {
        reference.cursor('data').update('debates', (debates) => {
          return debates.sort((a, b) => {
            const aStart = moment(a.get('startedAt') || a.get('startsAt')).format('x');
            const bStart = moment(b.get('startedAt') || b.get('startsAt')).format('x');

            return aStart - bStart;
          });
        });
      }
    });
  },
  /**
   * Handle an agenda delete event (actually removes debates).
   *
   * @param reference
   * @param eventData
   */
  deleteAgenda = (reference, eventData) => {
    if (!eventData.agenda?.debates) {
      return;
    }

    eventData.agenda.debates.forEach((deletedDebate) => {
      const index = reference.cursor(['data', 'debates']).findIndex((datum) => datum.get('id') === deletedDebate.id);

      if (index !== -1) {
        reference.cursor(['data']).update('debates', (debates) => debates.splice(index, 1));
      }
    });
  },
  /**
   * Resolves live data
   * @param  {Reference} reference  Immstruct reference to debates data
   * @param  {String}    debateId   Current debate id
   * @param  {Event}     eventData  The live event data
   * @param  {Function}  refresh    The refresh agenda/debate function
   * @return {Cursor}               The resulting debate cursor
   */
  resolve = function (reference, debateId, eventData, refresh) {
    const { eventType } = eventData;

    if ('reconnected' === eventType) {
      return refresh(debateId);
    }

    if ('notification' === eventType) {
      return updateNotifications(reference, eventData);
    }

    if ('agenda_update' === eventType) {
      return updateAgenda(reference, eventData);
    }

    if ('agenda_delete' === eventType) {
      return deleteAgenda(reference, eventData);
    }

    if ('voting_start' === eventType) {
      return updateVotingStart(reference, debateId, eventData);
    }

    if ('voting_round' === eventType) {
      return updateVoting(reference, debateId, eventData);
    }

    if ('debate_start' === eventType) {
      return updateDebateStart(reference, debateId, eventData);
    }

    return genericResolve(reference, debateId, eventData);
  };

export default resolve;
