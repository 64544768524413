import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import immutable from 'immutable';
import observer from 'omnipotent/decorator/observer';

import { byId, bySlug } from '../../predicates';
import { structure } from '../../core';
import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import FullScreenHelper from '../../utils/FullScreenHelper';

import DebateEmbeddedComponent from './DebateEmbeddedComponent';

const definition = {
  contextTypes: {
    getService: PropTypes.func.isRequired,
    getCursor: PropTypes.func.isRequired,
    pathWith: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  },

  /**
   * Get initial state.
   * @returns {Object}
   */
  getInitialState: function () {
    return {
      noDebatesFound: false, // True if we find no debates in the current location.
      initDebateError: false, // True if an error occurs during the init of a debate.
      ignoreDebateIds: [], // Debates we want to filter out (e.g. not found debates).
      location: this.getLocation(), // Resolve location only once.,
      isFullScreen: false,
      currentDebate: null,
    };
  },

  /**
   * Get location.
   * @returns {?Object}
   */
  getLocation: function () {
    const { params, getCursor } = this.context,
      locations = getCursor(['data', 'locations']),
      locationSlug = params.location,
      location = locations.find(bySlug(locationSlug));

    return location;
  },

  /**
   * React lifecycle method.
   */
  componentDidMount: function () {
    // Update structure.
    this.context.getCursor(['ui']).update('isEmbedded', () => true);

    // Add custom class to body, so we can create custom CSS.
    document.body.classList.add('Embedded-Player');

    // Bail out if path is invalid.
    if (!this.state.location) {
      return;
    }

    // Open debate page.
    this.openRelevantDebatePage();

    // Full screen.
    FullScreenHelper.init();
    FullScreenHelper.addEventListener(this.handleFullScreenChange);
  },

  /**
   * React lifecycle method.
   */
  componentDidUpdate: function (prevProps) {
    const currentProps = this.props;

    if (prevProps.debates === currentProps.debates) {
      return;
    }

    if (this.state.currentDebate) {
      return;
    }

    // Agenda has been refreshed as a consequence of a socket event.
    // It turns out we are waiting for a future debate which has been cancelled.
    // Find new relevant debate and open that one.
    this.openRelevantDebatePage();
  },

  /**
   * React lifecycle method.
   */
  componentWillUnmount: function () {
    // Remove full screen listener.
    FullScreenHelper.removeEventListener(this.handleFullScreenChange);
  },

  /**
   * Open the debate which is currently relevant.
   * This can be a debate which is currently running or the first future debate.
   */
  openRelevantDebatePage: function () {
    // Get active debate slug.
    const newDebateInfo = this.determineNewActiveDebateInfo();

    // If we can not find a relevant debate, we render an appropriate message.
    if (!newDebateInfo) {
      this.setState({ noDebatesFound: true, currentDebate: null });
      this.clearUI();

      return;
    }

    // Otherwise we navigate to the right path first and fetch the data.
    this.fetchDebateData(newDebateInfo);
  },

  /**
   * Determine the relevant debate to render.
   * @returns {?Object}
   */
  determineNewActiveDebateInfo: function () {
    const { getCursor } = this.context,
      { location } = this.state,
      debates = getCursor(['data', 'debates']),
      locationId = location.get('id'),
      { ignoreDebateIds } = this.state,
      filteredDebates = debates.filter(this.debateFilter(locationId, ignoreDebateIds)).toList(),
      currentDebates = filteredDebates.filter((debate) => debate.has('startedAt') && !debate.has('endedAt')),
      futureDebates = filteredDebates.filter((debate) => !debate.has('startedAt')),
      activeDebate = currentDebates.count() > 0 ? currentDebates.get(0) : futureDebates.count() > 0 ? futureDebates.get(0) : null;

    if (!activeDebate) {
      return null;
    }

    const id = activeDebate.get('id'),
      slug = activeDebate.get('slug'),
      categoryIds = activeDebate.get('categoryIds'), // This can be an empty list.
      category = !categoryIds || categoryIds.count() === 0 || !categoryIds.first() ? 'onbekend' : categoryIds.first();

    return {
      id,
      slug,
      category,
    };
  },

  /**
   * Returns a filter method callback.
   * It takes care of filtering out debates in another location and cancelled debates.
   * @param {String} locationId
   * @param {Array} ignoreDebateIds
   * @returns {Boolean}
   */
  debateFilter: (locationId, ignoreDebateIds) => (debate) => {
    // Filter out debates in other locations.
    if (debate.get('locationId') !== locationId) {
      return false;
    }

    // Filter out invalid debate ID and debates that we already showed.
    if (ignoreDebateIds.indexOf(debate.get('id')) !== -1) {
      return false;
    }

    return true;
  },

  /**
   * Fetch debate data from server.
   * @param {Object} debateInfo
   */
  fetchDebateData: function (debateInfo) {
    const debateId = debateInfo.id,
      { getService } = this.context;

    // Render loader.
    this.setLoader(true);

    // Stop live data service.
    getService('live').unsubscribeFromDebate();

    const createCallback =
        (method) =>
        (...args) => {
          this[method].apply(this, [debateId, ...args]);
        },
      dateService = getService('date'),
      debateDate = dateService.getAgendaDate();

    // the date is outside the allowed time window, don't get the data
    if (!dateService.isWithinAllowedTimeWindow(debateDate)) {
      this.context.getCursor(['ui', 'video']).update('audioOnly', () => this.isAudioOnly());
      this.setLoader(false);

      return;
    }

    // Fetch data.
    getService('api')
      .getDebate(debateDate, debateId)
      .then(createCallback('handleDebateData'), createCallback('handleDebateDataError'))
      .catch(createCallback('handleDebateInitError'));
  },

  /**
   * Handle debate data.
   * @param debateId
   * @param data
   */
  handleDebateData: function (debateId, data) {
    // In case we receive a 200 but invalid data, we treat this as an error.
    if (!data || Object.keys(data).length === 0) {
      this.handleDebateInitError(debateId, new Error('Promised resolved with invalid data'));

      return;
    }

    const { getService, getCursor } = this.context,
      liveService = getService('live');

    // Update relevant properties.
    // getCursor(['ui', 'video']).update('playRequested', () => false);
    getCursor(['ui', 'video']).update('audioOnly', () => this.isAudioOnly());

    // Hide loader.
    this.setLoader(false);

    // Add debate to list of debates we can ignore in the future.
    if (data.endedAt) {
      this.state.ignoreDebateIds = [...this.state.ignoreDebateIds, debateId];
    }

    const debate = immutable.fromJS(data);

    if (data.startedAt) {
      getCursor(['ui', 'video']).set('playingDebate', debate);
    }

    // Update structure. Since we observe data.debates, this will trigger a re-render.
    getCursor(['data', 'debates'])
      .find(byId(debateId))
      .update(() => debate);

    liveService.subscribeToDebate(debateId);
    liveService.onDebateEnd = this.handleDebateEnd;

    // keep track of current debate
    this.setState({
      currentDebate: debate,
    });

    // let application know what the active debate is
    getCursor(['ui']).set('activeDebate', debate);
  },

  /**
   * Handle debate data error.
   * @param {String} debateId
   * @param {Object} error
   */
  handleDebateDataError: function (debateId, error) {
    // Hide loader.
    this.setLoader(false);

    switch (error.status) {
      case 404:
        // Handle debate not found scenario.
        setTimeout(() => this.handleDebateNotFound(debateId), 500);
        break;

      default:
        // Handle other responses in the 400-500 range as errors.
        this.handleDebateInitError(debateId, new Error('Received ' + error.status + ' from server'));
        break;
    }
  },

  /**
   * Handle debate not found (404).
   * @param {String} debateId
   */
  handleDebateNotFound: function (debateId) {
    // Add id to list of debates we want to ignore.
    this.setState({
      ignoreDebateIds: [...this.state.ignoreDebateIds, debateId],
      currentDebate: null,
    });

    // Clear UI and update path to root.
    this.clearUI();

    // Refresh agenda.
    this.context
      .getService('refresh')
      .refresh() // Refresh agenda.
      .then(() => this.openRelevantDebatePage()) // Open relevant debate using refreshed agenda.
      .catch(() => this.handleDebateInitError(debateId, new Error('Error during refresh after not found')));
  },

  /**
   * Handle exceptions that occur during init of debate.
   * @param {String} debateId
   * @param {Error} error
   */
  handleDebateInitError: function (debateId, error) {
    // Always log an error.
    if (window.console && console.error) {
      console.error('Error during init of debate: ', error);
    }

    // Set error state to force a re-render.
    this.setState({ initDebateError: true, currentDebate: null });
    this.clearUI();
  },

  /**
   * Handle debate end.
   * @param {Object} event
   */
  handleDebateEnd: function (event) {
    // Set handled property.
    event.handled = true;

    this.state.ignoreDebateIds = [...this.state.ignoreDebateIds, this.state.currentDebate.get('id')];

    // Go to next debate.
    this.openRelevantDebatePage();
  },

  /**
   * Handle click on full screen button.
   */
  setVideoFullScreen: function () {
    try {
      window.theoplayer.player(1).requestFullscreen();
    } catch (e) {
      // noop
    }
  },

  /**
   * Handle click on full screen button.
   */
  toggleDocumentFullScreen: function () {
    if (this.state.isFullScreen) {
      FullScreenHelper.exitFullScreen();
    } else {
      FullScreenHelper.enterFullScreen(document.documentElement);
    }
  },

  /**
   * Handle full screen change.
   * @param {Object} event
   */
  handleFullScreenChange: function (event) {
    this.setState({
      isFullScreen: Boolean(event.fullScreenElement),
    });
  },

  /**
   * Check if we are in audio only mode.
   * @returns {Boolean}
   */
  isAudioOnly: function () {
    const { route } = this.props,
      audioOnly = Boolean(route && route.name === 'location-embedded-audio');

    return audioOnly;
  },

  /**
   * Show or hide loader image.
   * @param {Boolean} show
   */
  setLoader: function (show) {
    this.context.getCursor(['ui']).update('isLoading', () => show);
  },

  /**
   * Clear UI.
   */
  clearUI: function () {
    const { getService, getCursor } = this.context;

    // Hide loader.
    this.setLoader(false);

    getService('live').unsubscribeFromDebate();
    getService('video').pause(); // Pause player.

    // AppView looks at the debate part of the path to - indirectly - render a player
    // We need to remove the debate part to clear the player and stop it from loading new manifests.
    // getService('router').replace(path);

    getCursor(['ui', 'activeDebate']).update(() => null);
    getCursor(['ui', 'marker']).update(() => null);
    getCursor(['ui', 'video']).update('playingDebate', () => null);
    getCursor(['ui', 'video']).update('playRequested', () => false);
    getCursor(['ui', 'video']).update('audioOnly', () => this.isAudioOnly());
  },

  /**
   * Render error message.
   * @param {String} header
   * @param {String} text
   * @returns {React.Component}
   */
  renderError: function (header, text) {
    return (
      <div className="Embed-Error">
        <SmartScrollView>
          <div className="Main-content Content">
            <header className="Content-header Header">
              <h1 className="Heading Heading--primary">{header}</h1>
            </header>
            <p className="Text">{text}</p>
          </div>
        </SmartScrollView>
      </div>
    );
  },

  /**
   * Render embedded player.
   * @param {Object} debate
   * @returns {React.Component}
   */
  renderPlayer: function (debate, location) {
    return <DebateEmbeddedComponent debate={debate} location={location} />;
  },
};

/**
 * Main render method.
 * @returns {?React.Component}
 */
const render = function () {
  const { currentDebate, location, initDebateError } = this.state;

  // Render error if path is invalid.
  if (!location) {
    return this.renderError('Zaal onbekend', 'De opgegeven zaal is niet gevonden');
  }

  // Render error if an error during init of debate occurred.
  if (initDebateError) {
    return this.renderError('Fout opgetreden', 'Er is een fout opgetreden bij het openen van de player');
  }

  return this.renderPlayer(currentDebate, location);
};

/**
 * Public exports.
 */
export default observer(
  structure,
  {
    debates: ['data', 'debates'],
    video: ['ui', 'video'],
  },
  component('LocationEmbeddedComponent', definition, render),
);
