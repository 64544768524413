import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import classNames from 'classnames';
import { validate as validateUUID } from 'uuid';

import Icon from '../../../components/Icon/Icon';
import Link from '../../../components/Link/Link';

import AgainstIcon from './icons/Against';
import FavourIcon from './icons/Favour';

const VOTE_FAVOUR = 'Voor',
  VOTE_AGAINST = 'Tegen',
  VOTE_UNKNOWN = 'Onbekend',
  TYPE_PARTY = 'Fractie',
  TYPE_POLITICIAN = 'Hoofd',
  TYPE_SHARED = 'Gedeeld',
  TYPE_UNKNOWN = 'Onbekend',
  icon = React.createElement(Icon, {
    className: 'u-inline',
    name: 'arrow',
    width: 8.57,
    height: 12,
    'aria-hidden': 'true',
  }),
  /**
   * Component definition
   *
   * @type {Object}
   */
  definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
    },

    shouldComponentUpdate: function (nextProps) {
      return nextProps.id !== this.props.id || nextProps.vote !== this.props.vote;
    },
  },
  /**
   * Renders a party vote
   *
   * @param {Cursor}   party
   * @param {string}   vote
   * @param {string}   className
   *
   * @returns {React.Component} Party vote instance
   */
  renderPartyVote = (party, vote, className) => {
    const name = party && party.get('shorthand');

    // party not known
    if (!party) {
      return null;
    }

    return renderSimpleVote(name, vote, className);
  },
  /**
   * Renders a politician vote
   *
   * @param {function} handler
   * @param {Object}   partiesDict
   * @param {Cursor}   politician
   * @param {string}   vote
   * @param {string}   className
   *
   * @returns {React.Component} Politician vote instance
   */
  renderPoliticianVote = (handler, partiesDict, politician, vote, className) => {
    if (!politician) {
      return null;
    }

    const politicianId = politician.get('id');
    const partyId = politician.get('partyId');
    const party = partyId ? partiesDict[partyId] : null;
    const firstName = politician.get('firstName');
    const lastName = politician.get('lastName');
    const name = firstName + ' ' + lastName;
    const partyDescr = party ? party.get('shorthand') : '';

    return (
      <li className="Voting-voter Politician">
        <span className={className}>
          {vote === VOTE_AGAINST && <AgainstIcon aria-hidden="true" />}
          {vote === VOTE_FAVOUR && <FavourIcon aria-hidden="true" />}
        </span>
        <Link className="Politician-name Link--infoPanel" onClick={handler(politicianId)}>
          <div>
            <span>{name}</span>
            <span>{partyDescr}</span>
            <span className="srOnly">
              {vote === VOTE_AGAINST && 'tegen'}
              {vote === VOTE_FAVOUR && 'voor'}
            </span>
            {icon}
          </div>
        </Link>
      </li>
    );
  },
  /**
   * Renders a vote
   *
   * @param {string}   name
   * @param {string}   vote
   * @param {string}   className
   *
   * @returns {React.Component} Vote instance
   */
  renderSimpleVote = (name, vote, className) => {
    return (
      <li className="Voting-voter Party">
        <span className={className}>
          {vote === VOTE_AGAINST && <AgainstIcon aria-hidden="true" />}
          {vote === VOTE_FAVOUR && <FavourIcon aria-hidden="true" />}
        </span>
        <span className="Party-name" role="text">
          {name}
          <span className="srOnly">
            {vote === VOTE_AGAINST && 'tegen'}
            {vote === VOTE_FAVOUR && 'voor'}
          </span>
        </span>
      </li>
    );
  },
  /**
   * Renders DebateVotingRoundVote
   *
   * @param  {string}          id                 Politician or party id
   * @param  {string}          vote               Vote result
   * @param  {string}          type               Voting type
   * @param  {Array}           politiciansDict    Politicians indexed by id
   * @param  {Array}           partiesDict        Parties indexed by id
   * @param  {function}        getService
   *
   * @return {React.Component}          An instance of DebateSubjectComponent
   */
  render = function ({ id, vote, type, politiciansDict, partiesDict, getService }) {
    const className = classNames('Voting-vote', {
        'Voting-vote--favour': vote === VOTE_FAVOUR,
        'Voting-vote--against': vote === VOTE_AGAINST,
        'Voting-vote--unknown': vote === VOTE_UNKNOWN,
      }),
      handler = function (politicianId) {
        return (event) => {
          event.preventDefault();

          getService('info-panel').show('PoliticianPanel', {
            politicianId: politicianId,
            initialHeadingLevel: 2,
          });
        };
      };

    if (!validateUUID(id)) {
      return renderSimpleVote(id, vote, className);
    }

    if (TYPE_PARTY === type) {
      return renderPartyVote(partiesDict[id], vote, className);
    }

    return renderPoliticianVote(handler, partiesDict, politiciansDict[id], vote, className);
  };

export default component('DebateVotingRoundVote', definition, render);
