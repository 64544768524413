import { type FC } from 'react';
import classNames from 'classnames';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import { format, isToday } from 'date-fns';
import { Calendar, Live } from '@debatdirect/core/icons';

import styles from './StatusTag.module.scss';

type Props = {
  debate: AppAgendaDebate;
  isFeatured?: boolean;
  className?: string;
};

const formatStatusTag = (debate: AppAgendaDebate, isFeatured: boolean) => {
  if (debate.status === 'live') {
    return { text: 'LIVE', icon: <Live className={styles.live} aria-hidden="true" /> };
  } else if (debate.status === 'vod') {
    return { text: debate.duration };
  } else if (isToday(debate.debateDate) && isFeatured) {
    return { text: `Gepland om ${format(debate.startsAt, 'HH:mm')}`, icon: <Calendar aria-hidden="true" /> };
  }
  return { text: 'Gepland', icon: <Calendar aria-hidden="true" /> };
};

const StatusTag: FC<Props> = ({ debate, className, isFeatured = false }) => {
  const { text, icon } = formatStatusTag(debate, isFeatured);
  return (
    <div className={classNames(styles.status, className)}>
      {icon}
      {text}
    </div>
  );
};

export default StatusTag;
