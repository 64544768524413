import React from 'react';

/**
 * Wraps the last word of string with domElement
 * to make sure domElement is not rendered on next line
 * @param  {String}         string     The string to wrap with
 * @param  {React.Element}  domElement The element to wrap with the string
 * @return {React.Element}             The wrapped element
 */
const wrapLastWordWith = (string, domElement) => {
  // Don't split stuff if there's no whitespace in the string
  if (!/\s/.test(string)) {
    return (
      <span className="u-noWrap">
        {string + ' '}
        {domElement}
      </span>
    );
  }

  const words = string.split(' ');
  const lastWord = words[words.length - 1] + ' ';
  const restOfWords = words.slice(0, -1).join(' ') + ' ';

  return (
    <span role="text">
      {restOfWords}
      <span className="u-noWrap">
        {lastWord}
        {domElement}
      </span>
    </span>
  );
};

export default wrapLastWordWith;
