import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import component from 'omniscient';
import classNames from 'classnames';
import observer from 'omnipotent/decorator/observer';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import Image from '../../components/Image/Image';
import { structure } from '../../core';
import InternalLink from '../../components/InternalLink/InternalLink';

import audioMode from './images/audio-modus.svg';
import notifications from './images/notifications.svg';
import onDemand from './images/on-demand.svg';
import onDemandMobile from './images/on-demand-mobile.svg';
import papers from './images/papers.svg';
import share from './images/share.svg';
import shareMobile from './images/share-mobile.svg';
import votes from './images/votes.svg';

/**
 * AboutComponent definition.
 */
const definition = {
  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  },
};

/**
 * AboutComponent render method.
 * @returns {React.Component}
 */
const render = function ({ config }) {
  let versionText = `Versie: ${__APP_VERSION__}`;

  if (typeof import.meta.env.REACT_APP_BUILD !== 'undefined') {
    versionText += ` (build ${import.meta.env.REACT_APP_BUILD})`;
  }

  const showLicenses = config.get('licensesPageEnabled') === '1';

  return (
    <div className="Layer ViewStack-layer Layer--white AboutContainer">
      <Helmet>
        <title>Over Debat Direct</title>
        <meta name="description" content="Wat is Debat Direct en hoe kunt u het gebruiken?" />
      </Helmet>
      <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
        <SmartScrollView externalWheelHandling>
          <div className="Main-content Main-content--full Content About">
            <div className="AboutPanel">
              <h2 className="AboutPanel__Title">Live & on demand</h2>
              <div className="AboutPanel__ImageRow">
                <Image
                  src={onDemand}
                  alt="Illustratie die uitbeeldt dat live debatten vanaf het begin zijn terug te kijken"
                  className="AboutPanel__Image AboutPanel__Image--desktop OnDemand"
                />
                <Image
                  src={onDemandMobile}
                  alt="Illustratie die uitbeeldt dat live debatten vanaf het begin zijn terug te kijken"
                  className="AboutPanel__Image AboutPanel__Image--mobile"
                />
              </div>
              <h3 className="AboutPanel__SubTitle">Kijken wanneer het u uitkomt</h3>
              <p className="AboutPanel__Text">
                Het begin gemist? Geen probleem. Ook als een debat al is begonnen, kunt u het vanaf het begin zien. Na afloop blijft een debat beschikbaar in
                Debat Direct.
              </p>
            </div>
            <div className="AboutPanel">
              <h2 className="AboutPanel__Title">Audiomodus</h2>
              <div className="AboutPanel__ImageRow">
                <Image src={audioMode} alt="Illustratie van een smartphone in de standbymodus" className="AboutPanel__Image AboutPanel__Image--adjust-left" />
              </div>
              <h3 className="AboutPanel__SubTitle">Luister naar het debat terwijl uw smartphone of tablet in standbymodus staat</h3>
              <p className="AboutPanel__Text">
                Luister naar een debat in de audiomodus. Na activeren van de audiomodus kunt u een andere app openen of uw telefoon of tablet op standby zetten.
              </p>
            </div>
            <div className="AboutPanel">
              <h2 className="AboutPanel__Title">Notificaties</h2>
              <div className="AboutPanel__ImageRow">
                <Image
                  src={notifications}
                  alt="Illustratie van een smartphone met een pushbericht"
                  className="AboutPanel__Image AboutPanel__Image--adjust-left"
                />
              </div>
              <h3 className="AboutPanel__SubTitle">Mis geen moment: notificaties voor de inbreng van Kamerleden en kabinetsleden of de start van debatten.</h3>
              <p className="AboutPanel__Text">
                Debat Direct kan u een pushbericht sturen wanneer een politicus spreekt die u heeft geselecteerd, of bij aanvang van een specifiek debat.
                Sprekersnotificaties activeert u via de persoonlijke infopagina's van politici, notificaties voor debatten in het debatoverzicht.
              </p>
            </div>
            <div className="AboutPanel">
              <h2 className="AboutPanel__Title">Stemmingen</h2>
              <div className="AboutPanel__ImageRow">
                <Image src={votes} alt="Illustratie van een stemmingsuitslag met een deel-knop" className="AboutPanel__Image" />
              </div>
              <h3 className="AboutPanel__SubTitle">Stemmingsuitslag in één oogopslag</h3>
              <p className="AboutPanel__Text">
                Debat Direct toont de uitslag van elke stemming in een overzichtelijk beeld. De uitslagen worden twee uur na de stemming getoond. Alle
                stemmingsuitslagen kunt u ongeveer twee uur na de stemming delen via social media, dan zijn ze gecontroleerd.
              </p>
            </div>
            <div className="AboutPanel">
              <h2 className="AboutPanel__Title">Delen</h2>
              <div className="AboutPanel__ImageRow">
                <Image
                  src={share}
                  alt="Illustratie van verschillende onderdelen in de app die allemaal voorzien zijn van een deel-knop"
                  className="AboutPanel__Image AboutPanel__Image--desktop"
                />
                <Image
                  src={shareMobile}
                  alt="Illustratie van verschillende onderdelen in de app die allemaal voorzien zijn van een deel-knop"
                  className="AboutPanel__Image AboutPanel__Image--mobile"
                />
              </div>
              <h3 className="AboutPanel__SubTitle">Deel eenvoudig stemmingsuitslagen, spreekmomenten of hele debatten</h3>
              <p className="AboutPanel__Text">
                Vanuit de app kunt u zowel een debat als een specifiek spreekmoment delen. Het debat delen kan direct vanuit het agenda-overzicht. Bij het
                SPREKERS-overzicht kunt u ook elk spreekmoment los delen. Bij Stemmingen is elke stemmingsuitslag ook los te delen.
              </p>
            </div>
            <div className="AboutPanel">
              <h2 className="AboutPanel__Title">Stukken</h2>
              <div className="AboutPanel__ImageRow">
                <Image src={papers} alt="Illustratie van een overzicht met stukken en een download-knop" className="AboutPanel__Image" />
              </div>
              <h3 className="AboutPanel__SubTitle">Raadpleeg actuele stukken bij een vergadering</h3>
              <p className="AboutPanel__Text">
                Bij elk debat tonen we onder STUKKEN welke bijbehorende officiële documenten besproken worden. Indien beschikbaar, kunt u ze direct downloaden.
              </p>
            </div>
            {showLicenses && (
              <div className="AboutPanel">
                <h2 className={classNames('AboutPanel__Title', 'AboutPanel__Title-marginBottom')}>Licenties</h2>
                <h3 className="AboutPanel__SubTitle">Debat Direct gebruikt 'open-source' software van derde partijen</h3>
                <p className="AboutPanel__Text">
                  Ga naar{' '}
                  <InternalLink className="Link Link--underline" href="/licenties">
                    Licenties
                  </InternalLink>{' '}
                  om te zien welke software van derde partijen gebruikt is bij het ontwikkelen van Debat Direct.
                </p>
              </div>
            )}
            {
              <div className="AboutPanel">
                <h2 className={classNames('AboutPanel__Title', 'AboutPanel__Title-marginBottom')}>Toegankelijkheidsverklaring</h2>
                <h3 className="AboutPanel__SubTitle">Debat Direct voldoet aan de overheidsstandaard WCAG versie 2.1 op niveau AA</h3>
                <p className="AboutPanel__Text">
                  Dit betekent dat Debat Direct gebruikt kan worden door iedereen, inclusief mensen die slechtziend, blind of doof zijn. Ga naar de pagina{' '}
                  <InternalLink className="Link Link--underline" href="/toegankelijkheid">
                    Toegankelijkheid
                  </InternalLink>{' '}
                  om de rapportage hiervan in te zien.
                </p>
              </div>
            }
            <div className="AboutFooter">
              <p className="AboutVersion">{versionText}</p>
            </div>
          </div>
        </SmartScrollView>
      </div>
    </div>
  );
};

const AboutComponent = component('AboutComponent', definition, render);

export default observer(
  structure,
  {
    config: ['config'],
  },
  AboutComponent,
);
