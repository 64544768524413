import React from 'react';
import observer from 'omnipotent/decorator/observer';
import component from 'omniscient';
import PropTypes from 'prop-types';

import DatePicker from '../DatePicker/DatePicker';
import Popover from '../Popover/Popover';
import { structure } from '../../core';

const definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
    },
    componentDidUpdate: function (prevProps) {
      if (this.props.open !== prevProps.open) {
        this.props.datePicker.set('open', this.props.open);
      }
    },
    componentWillUnmount: function () {
      this.props.datePicker.set('open', false);
    },
  },
  render = function () {
    return (
      <Popover
        open={this.props.open}
        anchor={this.props.anchor}
        anchorOffset={this.props.anchorOffset}
        transformOrigin={this.props.transformOrigin}
        onClose={this.props.onClose}
        autoFocus={false}
      >
        <DatePicker
          title={this.props.title}
          onChange={this.props.onChange}
          onClose={this.props.onClose}
          selectedDate={this.props.selectedDate}
          initialMonth={this.props.selectedDate}
          disabledDays={this.props.disabledDays || [{ after: new Date() }]}
          toMonth={this.props.toMonth || new Date()}
          fromDate={this.props.fromDate}
        />
      </Popover>
    );
  };

const DatePickerPopover = component('DatePickerPopover', definition, render);

export default observer(
  structure,
  {
    datePicker: ['ui', 'datePicker'],
  },
  DatePickerPopover,
);
