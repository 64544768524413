/**
 * Enum with keys for all HTTP request methods
 * @enum {String}
 */
const HTTPRequestMethod = {
  DELETE: 'delete',
  GET: 'get',
  PATCH: 'PATCH', // Needs to be in uppercase, or else it is rejected by NGINX.
  POST: 'post',
  PUT: 'put',
};

export default HTTPRequestMethod;
