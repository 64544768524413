import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import component from 'omniscient';

import ScrollView from '../../components/ScrollView/SmartScrollView';
import InternalLink from '../../components/InternalLink/InternalLink';
import Icon from '../../components/Icon/Icon';

import ContactForm from './ContactForm';

const /**
   * Creates a document list item
   * @param  {Function} pathTo             The pathTo function
   * @param  {String}   [expandedItemSlug] The slug of the expanded item, undefined if not set
   * @return {Function}                    The list item constructor
   */
  createSupportListItem = (pathTo, expandedItemSlug) => (datum) => {
    const slug = datum.get('slug'),
      question = datum.get('question'),
      answer = datum.get('answer'),
      isExpandedItem = expandedItemSlug === slug,
      className = classNames({
        'Questions-answer': true,
        'is-expanded': expandedItemSlug === slug,
        'is-collapsed': expandedItemSlug !== slug,
      }),
      iconName = isExpandedItem ? 'collapse' : 'expand',
      href = isExpandedItem ? pathTo('support') : pathTo('support', { faq: slug });

    return (
      <React.Fragment key={slug}>
        <dt className="Questions-header">
          <InternalLink role="button" href={href} className="Questions-question u-flex u-row" aria-expanded={isExpandedItem} aria-controls={slug}>
            <span className="Questions-questionLabel u-centerSelf">{question}</span>
            <Icon className="u-inline u-flex u-centerSelf" name={iconName} width="16" height="16" />
          </InternalLink>
        </dt>
        <dd className={className} id={slug}>
          {answer.split('\n').map((paragraph, idx) => {
            const html = {
              __html: generateHTML(paragraph),
            };

            return <p key={idx} className="Questions-text" dangerouslySetInnerHTML={html} />;
          })}
        </dd>
      </React.Fragment>
    );
  },
  generateHTML = (function () {
    const markdownLinkPattern = /\[([^[]+)]\(((https?:\/\/|www\.)?[^)]+)\)/gi;

    const replaceCallback = (fullMatch, content, link) => {
      const linkSplit = link.split(/\s+/);

      return '<a href="' + linkSplit[0] + '" target="_blank">' + content + '</a>';
    };

    return (markDown) => {
      return markDown.replace(markdownLinkPattern, replaceCallback);
    };
  })(),
  /**
   * The component definition
   * @type {Object}
   */
  definition = {
    scrollViewRef: React.createRef(),
    containerRef: React.createRef(),

    contextTypes: {
      pathTo: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
    },

    getInitialState: () => ({ formState: 0 }),

    componentDidMount() {
      const { params } = this.context,
        container = this.containerRef.current;

      if (!params.faq) return;

      const scrollElement = container?.querySelector(`[aria-controls='${params.faq}']`);

      if (scrollElement) {
        this.scrollViewRef.current.scrollTo(0, scrollElement.offsetTop);
      }
    },

    /**
     * Handles form submission
     */
    handleSubmit: function (event, formData) {
      const { getService, getCursor } = this.context,
        isDebugEnabled = getCursor('ui').get('debug', false),
        debug = isDebugEnabled ? getService('debug').getData() : undefined,
        data = {
          ...formData,
          debug,
        };

      this.setState({ formState: 1 });

      if (data.debug) {
        getService('debug').stop();
      }

      const post = () => {
        getService('api')
          .postSupport(data)
          .then(
            () => {
              this.setState({ formState: 2 });
            },
            () => {
              getService('prompt').prompt({
                identifier: 'FormSubmitError',
                dismissable: true,
                priority: 50,
                onRetry: post,
              });
            },
          );
      };

      event.preventDefault();
      post();
    },
  },
  /**
   * Renders the DebateDocumentComponent
   *
   * @return {React.Component}        An instance of DebateDocumentComponent
   */
  render = function () {
    const { getCursor, pathTo, params } = this.context,
      { formState } = this.state,
      data = getCursor(['data']),
      faqs = data.get('faq').toArray(),
      expandedItemSlug = params.faq;

    // The item with the lowest order should end up on top.
    faqs.sort((a, b) => {
      const orderA = a.get('order'),
        orderB = b.get('order');

      if (isNaN(orderA)) {
        return 1;
      }

      if (isNaN(orderB)) {
        return -1;
      }

      return orderA - orderB;
    });

    return (
      <div className="Layer ViewStack-layer Layer--white" ref={this.containerRef}>
        <Helmet>
          <title>Support | Debat Direct</title>
          <meta
            name="description"
            content="Heeft u een opmerking, suggesties of een probleem bij het gebruik van de app? Kijk bij de veelgestelde vragen of neem contact op."
          />
        </Helmet>
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          <ScrollView ref={this.scrollViewRef} externalWheelHandling={true}>
            <div className="Main-content Main-content--full Content Support">
              <section className="Questions">
                <h2 className="Questions-header u-blueText">Veelgestelde vragen</h2>
                <dl className="List Questions-items u-mt20">{faqs.map(createSupportListItem(pathTo, expandedItemSlug))}</dl>
              </section>
              <section className="Contact">
                <h2 id="supportformulier" className="Contact-heading Heading Heading--secondary">
                  Contact
                </h2>
                <p className="Text Contact-description">
                  Heeft u een opmerking, suggesties of een probleem bij het gebruik van de app?
                  <br />
                  Laat het ons weten:
                </p>
                <ContactForm onSubmit={this.handleSubmit} state={formState} />
              </section>
            </div>
          </ScrollView>
        </div>
      </div>
    );
  };

export default component.withDefaults({ cursorField: 'cursor' })('SupportComponent', definition, render);
