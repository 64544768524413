import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import Backdrop from '../Backdrop/Backdrop';
import Portal from '../Modal/Portal';
import modalManager from '../Modal/ModalManager';

export default class Sidebar extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      modalManager.addVisibleModal(this);
    } else if (prevProps.open && !this.props.open) {
      modalManager.removeVisibleModal(this);
    }
  }

  componentWillUnmount() {
    if (this.props.open) {
      modalManager.removeVisibleModal(this);
    }
  }

  render() {
    return (
      <Portal>
        <Backdrop open={this.props.open} onClick={this.props.onClose}>
          <CSSTransition classNames="Sidebar--animation" timeout={200} in={this.props.open} appear mountOnEnter unmountOnExit>
            <div className={classNames('Sidebar', this.props.className)} ref={this.props.forwardRef}>
              {this.props.children}
            </div>
          </CSSTransition>
        </Backdrop>
      </Portal>
    );
  }
}
