import { useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

export const useDebounce = (callback, delay) => {
  const fnRef = useRef(callback);
  const debounceRef = useRef(() => {});

  useEffect(
    (...args) => {
      debounceRef.current = debounce(() => fnRef.current(...args), delay);
    },
    [delay],
  );

  useEffect(() => {
    fnRef.callback = callback;
  }, [callback]);

  return fnRef.current;
};
