import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

import TabBar from '../../containers/TabBar/TabBar';

const definition = {
    contextTypes: {
      route: PropTypes.object.isRequired,
    },

    componentDidMount: function () {
      document.querySelector('.Layer--loader').classList.add('is-visible');
    },

    componentWillUnmount: function () {
      document.querySelector('.Layer--loader').classList.remove('is-visible');
    },
  },
  /**
   * DebateLoadingComponent render method
   */
  render = function ({ debate }) {
    const { route } = this.context.route;

    return (
      <main className="Layer ViewStack-layer">
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          {debate ? <TabBar debate={debate} route={route} /> : null}
        </div>
      </main>
    );
  };

export default component('DebateLoadingComponent', definition, render);
