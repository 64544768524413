import React from 'react';
import PropTypes from 'prop-types';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import ScrollView from '../../components/ScrollView/SmartScrollView';
import Button from '../../components/Button/Button';
import { AccountCircle, Email, ExitToApp } from '../../icons';
import Login from '../../containers/Login/Login';
import { structure } from '../../core';
import Link from '../../components/Link/Link';

import { body, subject } from './templates/signUp';
import WeekNavigation from './WeekNavigation';

const /**
   * The component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      pathTo: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
    },

    getInitialState: () => ({
      loginVisible: false,
    }),

    handleLoginClick: function () {
      this.setState({ loginVisible: true });
    },

    handleRegisterClick: function () {
      const email = 'kamerbeelden@tweedekamer.nl';
      const encodedSubject = encodeURIComponent(subject);
      const encodedBody = encodeURIComponent(body);

      window.open(`mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`, '_blank');
    },

    handleLogoutClick: function () {
      const { getService } = this.context;

      getService('auth').logout();
    },

    renderGuestContent: function () {
      if (this.state.loginVisible) {
        return this.renderLoginContent();
      }

      return (
        <section className="Main-section">
          <h2 className="Heading">Debat Direct Kamerbeelden</h2>
          <p>
            Welkom bij Debat Direct voor media. Media kunnen deze functionaliteit gebruiken voor journalistieke doeleinden, om fragmenten in hoge beeldkwaliteit
            te downloaden of te embedden op de eigen website. In Debat Direct voor media zijn de beelden van alle openbare vergaderingen van de Tweede Kamer
            beschikbaar die sinds 3 september 2019 plaatsvinden. Het gaat dan zowel om plenaire als om commissievergaderingen.
          </p>
          <p>
            Om beelden in hoge beeldkwaliteit te verkrijgen, dient eerst een account te worden aangemaakt door een erkend massamedium. Voor de definitie hiervan
            gebruikt de Tweede Kamer de definitie die de Nederlandse Vereniging voor Journalisten (NVJ) hanteert als richtsnoer. Concreet gaat het om elk medium
            dat zich richt op een groot deel van het publiek en voor iedereen beschikbaar is. Het medium bevat verder in belangrijke mate nieuws, analyse,
            commentaar en/of achtergrondinformatie over een gevarieerd deel van de (parlementaire) actualiteit.
          </p>
          <p>In bijzondere omstandigheden kan worden besloten om toestemming te verlenen aan andere (media)partijen.</p>
          <p>
            Op het gebruik van de beelden zijn de licentievoorwaarden audiovisueel materiaal Tweede Kamer van toepassing.{' '}
            <a href="https://www.tweedekamer.nl/contact-en-bezoek/persinformatie/audiovisuele-vergaderbeelden-afnemen" target="_blank" rel="noreferrer">
              Lees hier de licentievoorwaarden.
            </a>
          </p>
          <p>
            Voor vragen kunt u contact opnemen via{' '}
            <a href="mailto:kamerbeelden@tweedekamer.nl" target="_blank" rel="noreferrer">
              Kamerbeelden@tweedekamer.nl
            </a>
          </p>
          <p>U kunt een account aanvragen via onderstaande knop.</p>
          <div className="Kamerbeelden-actions">
            <Button className="Button--primary Button--medium Button--block Button--uppercase" onClick={this.handleLoginClick}>
              <AccountCircle className="Button-icon" />
              <span className="Button-label">Login</span>
            </Button>
            <Button className="Button--primary Button--medium Button--block Button--uppercase" onClick={this.handleRegisterClick}>
              <Email className="Button-icon" />
              <span className="Button-label">Vraag een account aan</span>
            </Button>
          </div>
        </section>
      );
    },

    renderLoginContent: function () {
      return (
        <section className="Main-section">
          <Login />
          <Link className="u-inlineBlock u-mt20" onClick={() => this.setState({ loginVisible: false })}>
            Terug naar Kamerbeelden
          </Link>
        </section>
      );
    },

    renderMobileContent: function () {
      return (
        <section className="Main-section">
          <h2 className="Heading">Debat Direct Kamerbeelden</h2>
          <p>
            Welkom bij Debat Direct voor media. Media kunnen deze functionaliteit gebruiken voor journalistieke doeleinden, om fragmenten in hoge beeldkwaliteit
            te downloaden of te embedden op de eigen website.
          </p>
          <p>
            Open deze pagina op een computer om gebruik te kunnen maken van de Debat Direct Kamerbeelden functionaliteiten. Debat Direct Kamerbeelden werkt niet
            optimaal op uw smartphone of tablet.
          </p>
        </section>
      );
    },

    renderMemberContent: function () {
      const staticUrl = this.context.getCursor(['config']).get('staticUrl');

      return (
        <React.Fragment>
          <section className="Main-section Main-section--borderBottom">
            <h2 className="Heading">Debat Direct Kamerbeelden</h2>
            <p>
              Welkom bij Debat Direct voor media. Media kunnen deze functionaliteit gebruiken voor journalistieke doeleinden, om fragmenten in hoge
              beeldkwaliteit te downloaden of te embedden op de eigen website.
            </p>
            <p>
              Op het gebruik van de beelden zijn de Licentievoorwaarden Audiovisueel Materiaal Tweede Kamer van toepassing.{' '}
              <a href="https://www.tweedekamer.nl/contact-en-bezoek/persinformatie/audiovisuele-vergaderbeelden-afnemen" target="_blank" rel="noreferrer">
                Lees hier de licentievoorwaarden.
              </a>
            </p>
            <p>
              Voor vragen kunt u contact opnemen via{' '}
              <a href="mailto:kamerbeelden@tweedekamer.nl" target="_blank" rel="noreferrer">
                Kamerbeelden@tweedekamer.nl
              </a>
            </p>
            <div className="Kamerbeelden-actions">
              <Button className="Button--primary Button--medium Button--uppercase" onClick={this.handleLogoutClick}>
                <ExitToApp className="Button-icon" />
                <span className="Button-label">Log uit</span>
              </Button>
            </div>
          </section>
          <section className="Main-section Main-section--borderBottom">
            <h2 className="Heading">Downloaden debatten en fragmenten</h2>
            <p>
              Debatten van de Tweede Kamer kunnen worden gedownload om te worden verwerkt tot beelden voor eigen toepassingen. Het is mogelijk om specifieke
              fragmenten te selecteren en deze te downloaden.
            </p>
            <p>Hieronder vindt u een korte uitleg hoe u dit kunt doen.</p>
            <video src={`${staticUrl}/video/download-explanation.mp4`} poster={`${staticUrl}/img/kamerbeelden-download-debate.jpeg`} controls />
          </section>
          <section className="Main-section Main-section--borderBottom">
            <h2 className="Heading">Embedden debatten</h2>
            <p>Media kunnen alle openbare vergaderingen embedden op hun eigen website.</p>
            <video src={`${staticUrl}/video/embed-explanation.mp4`} poster={`${staticUrl}/img/kamerbeelden-embed-debate.jpeg`} controls />
          </section>
          <section className="Main-section Main-section--borderBottom">
            <h2 className="Heading">Vergadering zonder verslaglegging downloaden</h2>
            <p>
              Niet alle debatten van de Tweede Kamer worden direct voorzien van markeringen van sprekers. Deze (ongemarkeerde) vergaderingen kunnen wel
              gedownload worden om verwerkt te worden tot bruikbare beelden voor eigen toepassingen.
            </p>
            <p>Hieronder vindt u een korte uitleg hoe u dit kunt doen.</p>
            <video src={`${staticUrl}/video/unmarked-debate-explanation.mp4`} poster={`${staticUrl}/img/kamerbeelden-unmarked-debate.jpeg`} controls />
          </section>
          <section className="Main-section Main-section--borderBottom">
            <h2 className="Heading">Eerdere weken</h2>
            <p />
            <WeekNavigation />
          </section>
        </React.Fragment>
      );
    },
  },
  /**
   * Renders the DebateDocumentComponent
   *
   * @return {React.Component}        An instance of DebateDocumentComponent
   */
  render = function ({ user }) {
    const authService = this.context.getService('auth');
    const isAuthenticated = user.deref() && authService.isLoggedIn();
    const isMobileWeb = this.context.getService('platform').isMobileWeb();

    return (
      <div className="Layer ViewStack-layer Layer--white">
        <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
          <ScrollView externalWheelHandling>
            <div className="Main-content Main-content--full Content Kamerbeelden">
              {isMobileWeb ? this.renderMobileContent() : isAuthenticated ? this.renderMemberContent(user) : this.renderGuestContent()}
            </div>
          </ScrollView>
        </div>
      </div>
    );
  };

const Component = component('KamerbeeldenComponent', definition, render);

export default observer(
  structure,
  {
    user: ['auth', 'user'],
  },
  Component,
);
