import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import { Plenair as Infographic } from '../../infographics';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import { bySlug } from '../../predicates';
import Icon from '../../components/Icon/Icon';

const /**
   * The component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
      pathWith: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired,
    },

    getInitialState: () => ({
      expanded: false,
    }),

    collapseParties: function () {
      if (!this.state.expanded) {
        document.querySelector('.Layer--loader').classList.add('is-visible');
      }

      setTimeout(() => {
        this.setState({
          expanded: !this.state.expanded,
        });
      }, 50);
    },

    /**
     * Creates a Legend list item
     */
    createLegendItem: function (datum) {
      const { getService, getCursor } = this.context,
        staticUrl = getCursor(['config']).get('staticUrl'),
        name = datum.get('name'),
        slug = datum.get('slug'),
        location = getCursor(['data', 'locations']).find(bySlug('plenaire-zaal')),
        legendItem = location.get('legend').find(bySlug(slug)),
        title = legendItem.get('name'),
        description = legendItem.get('description'),
        imageUrl = `${staticUrl}/img/plenair-zoom-${slug}.jpg`,
        onClick = (event) => {
          event.preventDefault();
          getService('info-panel').show('TerminologyPanel', {
            title,
            description,
            imageUrl,
            slug,
          });
        },
        onKeyDown = (event) => {
          const enterOrSpace = event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar' || event.which === 13 || event.which === 32;
          if (enterOrSpace) {
            // prevent scroll when using the Space bar
            event.preventDefault();

            getService('info-panel').show('TerminologyPanel', {
              title,
              description,
              imageUrl,
              slug,
            });
          }
        };

      return (
        <li key={slug} className="Legend-item List-item">
          <div role="button" className="u-flex u-grow u-row u-pointer Link--infoPanel" tabIndex="0" onClick={onClick} onKeyDown={onKeyDown}>
            <span className="u-flex u-grow">{name}</span>
            <Icon className="u-inline u-flex" name="arrow" width="10" height="14" aria-hidden="true" />
          </div>
        </li>
      );
    },

    composeInfographicProps: function () {
      const { infoPanel } = this.props,
        className = 'Location-infographic u-fill',
        role = 'img',
        highlight = infoPanel.getIn(['props', 'slug']),
        variant = 'dark';

      return {
        className,
        role,
        highlight,
        variant,
      };
    },
  },
  /**
   * Renders the DebateDocumentComponent
   *
   * @return {React.Component}        An instance of DebateDocumentComponent
   */
  render = function () {
    const { getCursor } = this.context,
      data = getCursor(['data']),
      location = data.get('locations').find(bySlug('plenaire-zaal')),
      legend = location.get('legend'),
      infographicProps = this.composeInfographicProps(),
      layoutClassName = classNames('u-flex u-grow', {
        'u-colReversed': true,
      });

    /* eslint-disable no-extra-parens */
    return (
      <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
        <Helmet>
          <title>Over de Plenaire Zaal | Debat Direct</title>
          <meta
            name="description"
            content="De vergadering van alle 150 Tweede Kamerleden noemen we de plenaire vergadering. Lees hoe de Tweede Kamer vergadert in de plenaire zaal."
          />
        </Helmet>
        <ScrollView>
          <div className="Main-content Main-content--wide Content Location">
            <div className={layoutClassName}>
              <section className="Location-topSection Section">
                <ul className="Legend List">{legend.map(this.createLegendItem)}</ul>
              </section>
              <Infographic {...infographicProps} />
            </div>
          </div>
        </ScrollView>
      </div>
    );
    /* eslint-enable no-extra-parens */
  },
  PlenaryLocationComponent = component('PlenaryLocationComponent', definition, render);

export default observer(
  structure,
  {
    infoPanel: ['ui', 'infoPanel'],
  },
  PlenaryLocationComponent,
);
