import React from 'react';
import classNames from 'classnames';

import CheckboxIcon from '../../icons/Checkbox';
import CheckboxSelectedIcon from '../../icons/CheckboxSelected';

const Checkbox = ({ className, checked, onChange, ...rest }) => {
  const Icon = checked ? CheckboxSelectedIcon : CheckboxIcon;

  return (
    <div className={classNames('Checkbox', className)}>
      <Icon className="Checkbox-icon" />
      <input className="Checkbox-input" type="checkbox" onChange={onChange} checked={checked} {...rest} />
      <div className="Checkbox-focus" />
    </div>
  );
};

export default Checkbox;
