import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import * as infographics from '../../infographics';
import { byId, byIds, byProperty } from '../../predicates';
import { parsers, wrapLastWordWith } from '../../common';
import ScrollView from '../../components/ScrollView/SmartScrollView';
import Icon from '../../components/Icon/Icon';
import PoliticianImage from '../../components/PoliticianImage/PoliticianImage';

const { parsePoliticianTitle } = parsers,
  byTitle = byProperty('title'),
  isCabinetMember = (politician) => {
    const title = (politician.get('title') || '').toLowerCase();

    if (title === 'tweede kamerlid') {
      return false;
    }

    return title.indexOf('minister') > -1 || title.indexOf('staatssecretaris') > -1;
  },
  /**
   * Returns the correct infographic for the current page
   * @param  {Cursor}          debate The debate cursor
   * @return {React.Component}        The infographic
   */
  getInfographicFor = (debate) => {
    const location = debate.get('locationId');

    switch (location) {
      case 'aletta-jacobszaal':
        return infographics.Jacobs;
      case 'wttewaall-van-stoetwegenzaal':
        return infographics.Stoetwegen;
      case 'ridderzaal':
        return infographics.Ridder;
      case 'enquetezaal':
        return infographics.Enquete;
      case 'oude-zaal':
        return infographics.Oude;
      case 'klompezaal':
      case 'suze-groenewegzaal':
        return infographics.Groeneweg;
      case 'groen-van-prinstererzaal':
      case 'thorbeckezaal':
      case 'troelstrazaal':
        return infographics.Thorbecke;
      default:
        return infographics.Plenair;
    }
  },
  /**
   * Definition of DebateLocationComponent.
   */
  definition = {
    contextTypes: {
      pathWith: PropTypes.func.isRequired,
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
    },

    /**
     * Create a poltician list item.
     * @param {Object} politician
     * @param {Object} parties
     * @returns {React.Component}
     */
    createPoliticianListItem: function (politician, parties) {
      const firstName = politician.get('firstName'),
        lastName = politician.get('lastName'),
        name = firstName + ' ' + lastName,
        key = politician.get('id'),
        title = parsePoliticianTitle(politician, parties),
        politicianName = wrapLastWordWith(name, <Icon name="arrow" className="u-inline" width="8.57" height="12" aria-hidden="true" />);

      return (
        <li key={key} className="List-item Politician">
          <div
            className="u-flex u-grow u-row u-pointer Link--infoPanel"
            role="button"
            tabIndex="0"
            onClick={this.showPoliticianInfo(politician)}
            onKeyDown={(event) => this.showPoliticianInfoKeyPress(politician, event)}
          >
            <PoliticianImage className="Politician-photo u-flex" politicianId={key} size={50} />
            <div className="Politician-label u-flex u-col u-centerSelf u-grow">
              <div className="Link Politician-name">{politicianName}</div>
              <span className="Politician-title">{title}</span>
            </div>
          </div>
        </li>
      );
    },

    /**
     * show politician names
     * @param {Object} politicians
     * @returns {String}
     */
    getPoliticianNames(politician) {
      const firstName = politician.get('firstName');
      const lastName = politician.get('lastName');
      return `${firstName} ${lastName}`;
    },

    /**
     * Create click handler for politician item.
     * @param {Object} politician
     * @returns {Function}
     */
    showPoliticianInfo: function (politician) {
      return (event) => {
        event.preventDefault();

        this.context.getService('info-panel').show('PoliticianPanel', {
          politicianId: politician.get('id'),
        });
      };
    },

    /**
     * Create key handler for politician item.
     * @param {Object} politician
     * @returns {Function}
     */
    showPoliticianInfoKeyPress: function (politician, event) {
      const enterOrSpace = event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar' || event.which === 13 || event.which === 32;

      if (enterOrSpace) {
        event.preventDefault();

        this.context.getService('info-panel').show('PoliticianPanel', {
          politicianId: politician.get('id'),
        });
      }
    },
  },
  /**
   * Definition of DebateLocationComponent.
   */
  render = function ({ debate, widthReference }) {
    const { getCursor, getService } = this.context,
      isFutureDate = getService('date').isFutureDate(),
      actors = getCursor(isFutureDate ? ['data', 'persistent', 'actors'] : ['data', 'actors']),
      ui = getCursor(['ui']),
      sync = ui.get('sync'),
      width = widthReference.deref(),
      current = sync.get('current'),
      ended = debate.has('endedAt'),
      parties = actors.get('parties'),
      politicianIds = debate.get('politicianIds'),
      politicians = actors.get('politicians'),
      relevantPoliticians = politicianIds ? politicians.filter(byIds(politicianIds)) : politicians,
      cabinet = relevantPoliticians.filter(isCabinetMember),
      speakers = relevantPoliticians.filter(byTitle('Tweede Kamerlid')),
      chairmanId = current ? current.get('chairman', debate.getIn(['current', 'chairmanId'])) : undefined,
      chairman = politicians.find(byId(chairmanId)),
      Infographic = getInfographicFor(debate),
      highlight = chairman ? 'voorzitter' : undefined,
      infographicSeats = relevantPoliticians.map((politician) => politician.get('seat')).concat(chairman ? [chairman.get('seat')] : []),
      layoutClassName = classNames('u-flex u-grow', {
        'u-row': width >= 600,
        'u-colReversed': width < 600,
      }),
      hiddenClassName = width < 600 ? 'u-hidden' : 'u-invisible',
      chairmanClassName = classNames('Location-topSection Section u-bordered u-borderLeft u-height80', {
        [hiddenClassName]: !chairman,
      });

    // Combine cabinet, speakers, and chairman arrays with nullish coalescing for default values
    const attendingPoliticians = [...(cabinet ?? []), ...(speakers ?? []), ...(chairman ? [chairman] : [])];
    const politicianNames = attendingPoliticians.map(this.getPoliticianNames);

    return (
      <ScrollView>
        <main role="main" className="Main-content Content Location">
          <div className={layoutClassName}>
            <section className={chairmanClassName}>
              <h2 className="Section-heading Location-heading">Voorzitter</h2>
              <ul className="List u-flex u-row u-wrap">{chairman && this.createPoliticianListItem(chairman, parties)}</ul>
            </section>
            <Infographic
              className="Location-infographic u-fill"
              role="img"
              lowlight={infographicSeats}
              highlight={highlight}
              politicians={politicianNames}
              variant="dark"
            />
          </div>
          {cabinet.count() > 0 ? (
            <section className="Section u-bordered u-borderLeft u-mt20">
              <h2 className="Section-heading Location-heading">Kabinetsleden</h2>
              <ul className="List u-flex u-row u-wrap">{cabinet.map((politician) => this.createPoliticianListItem(politician, parties))}</ul>
            </section>
          ) : null}
          {speakers.count() > 0 ? (
            <section className="Section u-bordered u-borderLeft u-mt45">
              <h2 className="Section-heading Location-heading">{ended ? 'Sprekers' : 'Verwachte sprekers'}</h2>
              <ul className="List u-flex u-row u-wrap">{speakers.map((politician) => this.createPoliticianListItem(politician, parties))}</ul>
            </section>
          ) : null}
        </main>
      </ScrollView>
    );
  },
  DebateLocationComponent = component('DebateLocationComponent', definition, render);

export default observer(
  structure,
  {
    widthReference: ['ui', 'width'],
  },
  DebateLocationComponent,
);
