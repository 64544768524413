import React from 'react';
import component from 'omniscient';

const definition = {
    componentDidMount: function () {
      document.querySelector('.Layer--loader').classList.remove('is-visible');
    },

    shouldComponentUpdate: function () {
      return true;
    },
  },
  render = function ({ children }) {
    return children;
  };

export default component('SpinnerWrapper', definition, render);
