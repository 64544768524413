import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AccessTime, Close, ChevronLeft, PlayArrow, Download, Calendar } from '../../icons';
import { trackEvent } from '../../common';
import addSourceParameters from '../../utils/addSourceParameters';
import { DOWNLOAD_STATUS } from '../../services/DownloadService/DownloadService';
import DownloadOptionsSelectComponent from '../DownloadOptionsSelect/DownloadOptionsSelect';
import LocationsSelect from '../LocationsSelect/LocationsSelect';
import DownloadAgreement from '../DownloadAgreement/DownloadAgreement';
import Button from '../Button/Button';
import DatePickerPopover from '../Popovers/DatePickerPopover';
import PreviewFragmentModal from '../Modals/PreviewFragmentModal';
import TextField from '../TextField/TextField';
import TimePicker from '../TimePicker/TimePicker';
import Modal from '../Modal/Modal';
import MenuList from '../MenuList/MenuList';
import MenuListHeader from '../MenuList/MenuListHeader';
import MenuItem from '../MenuList/MenuItem';

import DownloadModalBody from './DownloadModalBody';

class DownloadUnmarkedModal extends Component {
  static contextTypes = {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  };

  state = {
    videoLocationId: 'plenaire-zaal',
    videoDuration: 600,
    videoFromTime: moment().subtract(13, 'minutes').milliseconds(0),
    videoToTime: moment().subtract(3, 'minutes').milliseconds(0),
    videoQuality: null,

    previewFragmentOpen: false,
    previewFragmentSrc: null,
    previewFragmentStart: null,
    previewFragmentEnd: null,

    timePickerProperty: null,
    timePickerOpen: false,
    timePickerAnchor: null,
    timePickerHours: 0,
    timePickerMinutes: 0,
    timePickerSeconds: 0,

    datePickerOpen: false,
    datePickerAnchor: null,

    debateDownloadId: null,

    agreementAccepted: false,
  };

  constructor(props, context) {
    super(props);

    this.state.isTouchDevice = context.getService('platform').isTouchDevice();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        debateDownloadId: null,
        videoLocationId: 'plenaire-zaal',
        videoDuration: 600,
        videoFromTime: moment().subtract(12, 'minutes').milliseconds(0),
        videoToTime: moment().subtract(2, 'minutes').milliseconds(0),
        videoQuality: null,
      });
    }
  }

  generateVideoUrl() {
    const { getCursor } = this.context;
    const { videoLocationId, videoFromTime, videoToTime } = this.state;
    const isToday = moment().endOf('day').diff(videoFromTime, 'days') === 0;
    const location = getCursor(['data', 'locations']).find((current) => current.get('id') === videoLocationId);
    const url = location
      .get('streamUrl')
      .replace('{date}', isToday ? 'live' : videoFromTime.format('YYYY-MM-DD'))
      .replace('{slug}', location.get('slug').replace(/-/g, '_'));
    const startedAt = videoFromTime.format('YYYY-MM-DDTHH:mm:ssZZ');
    const endedAt = videoToTime.format('YYYY-MM-DDTHH:mm:ssZZ');

    return addSourceParameters(url, startedAt, endedAt);
  }

  generateDownloadName() {
    const { videoLocationId } = this.state;
    const { getCursor } = this.context;

    const location = getCursor(['data', 'locations']).find((current) => current.get('id') === videoLocationId);

    return location.get('name');
  }

  close = () => {
    this.props.onClose();
  };

  handlePreviewFragmentClose = () => {
    this.setState({ previewFragmentOpen: false });
  };

  handleTimePickerClose = () => {
    this.setState({ timePickerOpen: false });
  };

  handleTimePickerConfirm = (values) => {
    const { videoFromTime, videoToTime, timePickerProperty } = this.state;
    const { hours, minutes, seconds } = values;

    let newVideoFromTime = videoFromTime.clone();
    let newVideoToTime = videoToTime.clone();

    if (timePickerProperty === 'videoFromTime') {
      const maxFromTime = moment().subtract(181, 'seconds').milliseconds(0);

      newVideoFromTime = moment.min(maxFromTime, newVideoFromTime.set({ hours, minutes, seconds }));

      // if we surpass the "videoToTime", we increase this to "videoFromTime" + 1 seconds
      if (newVideoFromTime.isSameOrAfter(newVideoToTime)) {
        newVideoToTime = newVideoFromTime.clone().add(1, 'seconds');
      }
    } else {
      const maxToTime = moment().subtract(180, 'seconds').milliseconds(0);

      newVideoToTime = moment.min(maxToTime, newVideoToTime.set({ hours, minutes, seconds }));

      // if we surpass the "videoFromTime", we increase this to "videoToTime" - 1 seconds
      if (newVideoFromTime.isSameOrAfter(newVideoToTime)) {
        newVideoFromTime = newVideoToTime.clone().subtract(1, 'seconds');
      }
    }

    this.setState({
      timePickerOpen: false,
      videoFromTime: newVideoFromTime,
      videoToTime: newVideoToTime,
      videoDuration: newVideoToTime.diff(newVideoFromTime, 'seconds'),
    });
  };

  handleTimePickerOpen = (property) => (event) => {
    const { hours, minutes, seconds } = this.state[property].toObject();

    this.setState({
      timePickerProperty: property,
      timePickerOpen: true,
      timePickerAnchor: event.target,
      timePickerHours: hours,
      timePickerMinutes: minutes,
      timePickerSeconds: seconds,
    });
  };

  handleDateChange = (selectedDate) => {
    const { videoFromTime, videoToTime } = this.state;
    const { date, months, years } = moment.min(moment(selectedDate), moment()).toObject();

    const newVideoFromTime = videoFromTime.clone().set({ date, months, years });
    const newVideoToTime = videoToTime.clone().set({ date, months, years });

    this.setState({
      videoFromTime: newVideoFromTime,
      videoToTime: newVideoToTime,
      datePickerOpen: false,
      datePickerAnchor: null,
    });
  };

  handleDatePickerClose = () => {
    this.setState({
      datePickerOpen: false,
      datePickerAnchor: null,
    });
  };

  handleLocationChange = (event, value) => {
    this.setState({
      videoLocationId: value,
    });
  };

  handleQualityChange = (event, value) => {
    this.setState({
      videoQuality: value,
    });
  };

  handlePreviewFragmentClick = () => {
    this.setState({
      previewFragmentOpen: true,
      previewFragmentSrc: this.generateVideoUrl(),
    });
  };

  handleDownloadDebateClick = () => {
    const { videoQuality, videoFromTime, videoToTime, videoLocationId } = this.state;

    // no quality or location selected
    if (!videoQuality || !videoLocationId) {
      return false;
    }

    const { debateDownloadId } = this.state;
    const { downloads } = this.props;

    const downloadService = this.context.getService('download');

    const item = downloads.find((item) => item.get('id') === debateDownloadId);

    if (item) {
      return downloadService.open(item.get('id'));
    }

    // track download event
    const title = `${videoFromTime.format('YYYY:MM:DD')} / ${videoLocationId}`;

    trackEvent('debate', 'downloadUnmarked', title, 1);

    const name = this.generateDownloadName();
    const videoUrl = this.generateVideoUrl();

    // start generating video fragment
    downloadService.generateFragment(name, videoUrl, videoLocationId, videoFromTime.format(), videoToTime.format(), videoQuality).then((item) => {
      this.setState({
        debateDownloadId: item.get('id'),
      });
    });
  };

  handleResetDebateClick = () => {
    this.setState({
      debateDownloadId: null,
    });
  };

  renderForm() {
    const { videoDuration, videoFromTime, videoToTime, debateDownloadId } = this.state;
    const { downloads } = this.props;
    const downloadUnmarkedDebateFromDate = this.context.getCursor(['config', 'dateLimit']).get('downloadUnmarkedDebateFromDate');

    const item = downloads.find((item) => item.get('id') === debateDownloadId);

    if (item) {
      const status = item.get('status');
      const url = item.get('url');
      const filename = item.get('filename');

      // progress
      if (status === DOWNLOAD_STATUS.GENERATING || status === DOWNLOAD_STATUS.DOWNLOADING) {
        const action = status === DOWNLOAD_STATUS.DOWNLOADING ? 'downloaden' : 'genereren';
        const platformName = window.cordova ? 'app' : 'browser';

        return (
          <DownloadModalBody
            title={`Videofragment aan het ${action}.`}
            description={`Bij lange videofragmenten of een hoge videokwaliteit kan het ${action} van de video meer tijd kosten. Ondertussen kunt u Debat Direct blijven gebruiken. Sluit de ${platformName} niet. U kunt het videobestand terugvinden bij Downloads in het menu.`}
            progress
          />
        );
      }

      // error
      if (status === DOWNLOAD_STATUS.ERROR) {
        return (
          <DownloadModalBody
            title="Er is helaas iets misgegaan!"
            description="Het is helaas mislukt om het debat te downloaden. Probeer het later opnieuw."
            actions={
              <Button className="Button--light Button--medium Button--block Button--uppercase" onClick={this.handleResetDebateClick}>
                <ChevronLeft className="Button-icon" /> <span className="Button-label">Probeer opnieuw</span>
              </Button>
            }
          />
        );
      }

      // ready!
      if ((status === DOWNLOAD_STATUS.GENERATED && url) || (status === DOWNLOAD_STATUS.DOWNLOADED && filename)) {
        let text = 'Het bestand wordt automatisch in uw downloadmap geplaatst. Druk op de onderstaande knop indien de download niet automatisch wordt gestart.';

        return (
          <DownloadModalBody
            title="Klaar!"
            description={text}
            actions={
              <React.Fragment>
                <Button className="Button--primary Button--medium Button--block Button--uppercase" onClick={this.handleDownloadDebateClick}>
                  <Download className="Button-icon" /> <span className="Button-label">Download debat</span>
                </Button>
                <Button className="Button--light Button--medium Button--block" onClick={this.handleResetDebateClick}>
                  <ChevronLeft className="Button-icon" />
                  <span className="Button-label">Ga terug</span>
                </Button>
              </React.Fragment>
            }
          />
        );
      }
    }

    return (
      <form>
        <TextField
          className="u-mb15"
          label="Dag"
          value={videoFromTime.format('D MMMM YYYY')}
          onClick={(event) => this.setState({ datePickerOpen: true, datePickerAnchor: event.currentTarget })}
          endOrdonnement={
            <Button
              aria-label="Kies datum"
              className="u-blueText IconButton"
              onClick={(event) => this.setState({ datePickerOpen: true, datePickerAnchor: event.currentTarget })}
            >
              <Calendar />
            </Button>
          }
          readOnly
        />
        <LocationsSelect className="u-mb15" onChange={this.handleLocationChange} defaultValue={this.state.videoLocationId} />
        <DownloadOptionsSelectComponent
          className="u-mb15"
          onChange={this.handleQualityChange}
          locationId={this.state.videoLocationId}
          seconds={videoDuration}
          prefix="unmarked"
        />
        <div className="u-flex u-row u-mb15">
          <TextField
            className="u-grow u-mr5"
            label="Starttijd"
            value={videoFromTime.format('HH:mm:ss')}
            onClick={this.handleTimePickerOpen('videoFromTime')}
            endOrdonnement={
              <Button className="u-blueText IconButton" onClick={() => this.handleTimePickerOpen('videoFromTime')} aria-label="Selecteer starttijd">
                <AccessTime />
              </Button>
            }
            readOnly
          />
          <TextField
            className="u-grow u-ml5"
            label="Eindtijd"
            value={videoToTime.format('HH:mm:ss')}
            onClick={this.handleTimePickerOpen('videoToTime')}
            endOrdonnement={
              <Button className="u-blueText IconButton" onClick={() => this.handleTimePickerOpen('videoToTime')} aria-label="Selecteer eindtijd">
                <AccessTime />
              </Button>
            }
            readOnly
          />
        </div>
        <div>
          <DownloadAgreement
            id="unmarked-agreement"
            checked={this.state.agreementAccepted}
            onChange={(event) => this.setState({ agreementAccepted: event.currentTarget.checked })}
          />
        </div>
        <div className="u-actionsRow">
          <Button className="Button--primary Button--medium Button--uppercase" onClick={this.handlePreviewFragmentClick} disabled={videoDuration < 1}>
            <PlayArrow className="Button-icon" /> <span className="Button-label">Bekijk videofragment</span>
          </Button>
          <Button
            className="Button--primary Button--medium Button--uppercase"
            onClick={this.handleDownloadDebateClick}
            disabled={videoDuration < 1 || !this.state.agreementAccepted}
          >
            <Download className="Button-icon" /> <span className="Button-label">Download videofragment</span>
          </Button>
        </div>
        <TimePicker
          open={this.state.timePickerOpen}
          anchor={this.state.timePickerAnchor}
          hours={this.state.timePickerHours}
          minutes={this.state.timePickerMinutes}
          seconds={this.state.timePickerSeconds}
          onClose={this.handleTimePickerClose}
          onConfirm={this.handleTimePickerConfirm}
          keyboardEnabled={!this.state.isTouchDevice}
        />
        <DatePickerPopover
          open={this.state.datePickerOpen}
          anchor={this.state.datePickerAnchor}
          anchorOffset={{ vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          fromDate={downloadUnmarkedDebateFromDate}
          selectedDate={this.state.videoFromTime.toDate()}
          onChange={this.handleDateChange}
          onClose={this.handleDatePickerClose}
          title={'Kies een dag'}
        />
        <PreviewFragmentModal
          open={this.state.previewFragmentOpen}
          src={this.state.previewFragmentSrc}
          start={this.state.videoFromTime}
          end={this.state.videoToTime}
          onClose={this.handlePreviewFragmentClose}
        />
      </form>
    );
  }

  render() {
    return (
      <Modal
        type="auto"
        className="DownloadModal"
        open={this.props.open}
        onClose={this.close}
        inert={this.state.previewFragmentOpen || this.state.datePickerOpen || this.state.timePickerOpen}
        aria-labelledby="modal-title"
      >
        <MenuListHeader onClick={this.close} label="Vergadering zonder verslaglegging downloaden" titleId={'modal-title'} />
        <div className="Modal-body">{this.renderForm()}</div>
        <MenuList>
          <MenuItem className="MenuItem--footer" icon={<Close />} label="Sluiten" onClick={() => this.close()} />
        </MenuList>
      </Modal>
    );
  }
}

export default DownloadUnmarkedModal;
