const ANDROID = /^android/i,
  IOS = /^ios/i,
  WINDOWS = /^win(32nt|dows)/i,
  /**
   * Returns the platform identifier as defined by the Device plugin
   * @see https://github.com/apache/cordova-plugin-device
   * @return {String} The platform identifier
   */
  getPlatformString = () => window.device?.platform?.toLowerCase(),
  /**
   * Tests regex against platformString
   * @param  {RegExp}  regex The regex to test against
   * @return {Boolean}       Indicates whether string matches regex
   */
  testPlatform = (regex) => regex.test(getPlatformString()),
  /**
   * Checks whether device platform is android or Android
   * @return {Boolean}
   */
  isAndroid = () => testPlatform(ANDROID),
  /**
   * Checks whether device platform is iOS
   * @return {Boolean}
   */
  isIOS = () => testPlatform(IOS),
  /**
   * Checks whether device platform is Windows
   * @return {Boolean}
   */
  isWindows = () => testPlatform(WINDOWS),
  /**
   * Returns true if the screen width or height is smaller than 600px
   */
  isSmallScreen = () => window.screen.width < 600 || window.screen.height < 600,
  Platform = function () {
    const methods = {
      getPlatform: function () {
        if (isWindows()) {
          return 'windows';
        }

        if (isAndroid()) {
          return 'android';
        }

        if (isIOS()) {
          return 'ios';
        }
      },

      /**
       * Detect if user uses an Android device in a web safe manner.
       * @returns {Boolean}
       */
      isAndroid: function () {
        return /android/i.test(navigator.userAgent || '') && !this.isWindowsPhone();
      },

      /**
       * Detect if user uses an IOS device in a web safe manner.
       * @returns {Boolean}
       */
      isIOS: function () {
        return /iphone|ipad|ipod/i.test(navigator.userAgent || '');
      },

      /**
       * Detect if the user uses a Safari browser
       * @return {boolean}
       */
      isSafari: function () {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      },

      /**
       * Detect if unlucky user uses a Windows Phone.
       * @returns {Boolean}
       */
      isWindowsPhone: function () {
        return /windows phone/i.test(navigator.userAgent || '');
      },

      /**
       * Detect if the user is using mobile web
       * @returns {boolean|*|Boolean}
       */
      isMobileWeb: function () {
        return !window.cordova && (methods.isIOS() || methods.isAndroid());
      },

      /**
       * Detect if the user is using their TV
       * @returns {boolean}
       */
      isTV: function () {
        return /(LG|PhilipsNetTV|SamsungBrowser|SmartTV|SonyDTV|Tizen|web0S|webOS)/i.test(navigator.userAgent || '');
      },

      /**
       * Decides if the platform supports file downloads
       * @returns {boolean}
       */
      supportsFileDownload: function () {
        return !this.isTV();
      },

      /**
       * Detect if browser/device offers reliable writing to clipboard.
       */
      supportsWritingToClipboard: function () {
        return !this.isIOS();
      },

      /**
       * Detect if user uses a touch device.
       * @returns {Boolean}
       */
      isTouchDevice: function () {
        // In the app, we assume touch events to be present.
        if (window.cordova) {
          return true;
        }

        if (!('ontouchstart' in window)) {
          return false;
        }

        return this.isIOS() || this.isAndroid() || this.isWindowsPhone();
      },

      /**
       * Detects whether the current device is a phone, based on screensize.
       */
      isPhone: () => Boolean(window.cordova && isSmallScreen()),

      /**
       * Detects whether the current device is a tablet, based on screensize.
       */
      isTablet: () => Boolean(window.cordova && !isSmallScreen()),
    };

    return methods;
  };

export default Platform;
