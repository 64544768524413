import { useMemo } from 'react';

type MemoizedDataRecord = {
  hash: string;
  data: unknown;
  transformedData: unknown;
};

const memoizedData: { [key: string]: MemoizedDataRecord } = {};

type Options<Data, TransformedData> = {
  key: (string | undefined)[];
  data: Data | undefined;
  transformFn: (data: Data) => TransformedData;
};
/**
 * useDataTransformer(): Transforms raw API data into memoized app data
 **/
function useDataTransformer<Data, TransformedData>({ key, data, transformFn }: Options<Data, TransformedData>): TransformedData | null {
  const hash = key.join('-');

  return useMemo(() => {
    const memoizedEntry = memoizedData[hash];

    if (!!data && (!memoizedEntry || data !== memoizedEntry.data)) {
      const transformedData = transformFn(data);

      memoizedData[hash] = { hash, data, transformedData };
    }
    return (memoizedData[hash]?.transformedData as TransformedData) || null;
  }, [hash, data, transformFn]);
}

export default useDataTransformer;
