import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default class MenuItem extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    label: PropTypes.node,
    badge: PropTypes.node,
    icon: PropTypes.node,
    iconAfter: PropTypes.node,
    onClick: PropTypes.func,
    isModalHeader: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  onKeyDown(event, triggeredFunction) {
    const enterOrSpace = event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar' || event.which === 13 || event.which === 32;

    if (enterOrSpace) {
      event.preventDefault();
      triggeredFunction();
    }
  }

  render() {
    const { className, active, disabled, badge, label, icon, iconAfter, onClick, ...rest } = this.props;

    return (
      <li {...rest}>
        <div
          aria-label={label}
          tabIndex={0}
          onClick={(event) => {
            !disabled && onClick(event);
          }}
          role="button"
          onKeyDown={(event) => {
            !disabled && this.onKeyDown(event, onClick);
          }}
          className={classNames('MenuItem', className, { 'MenuItem--active': active })}
          aria-disabled={disabled}
        >
          <div className="MenuItem-icon">{icon}</div>
          <span className="MenuItem-label">
            {label} {iconAfter}
          </span>
          <span className="MenuItem-badge">{badge}</span>
        </div>
      </li>
    );
  }
}
