/**
 * Base class for item inside scroll.
 */
class AbstractScrollItem {
  /**
   * Constructor.
   */
  constructor() {
    this._container = null;
    this._elements = null;
    this._page = null;
    this._active = false;
  }

  /**
   * Check if item is rendered.
   * @returns {Boolean}
   */
  get isRendered() {
    return Boolean(this._container);
  }

  /**
   * Check if item is active.
   * @returns {Boolean}
   */
  get isActive() {
    return this._active;
  }

  /**
   * Reference to containing page.
   * @returns {ScrollPage|null}
   */
  get page() {
    return this._page;
  }

  /**
   * Container element.
   * @returns {HTMLElement|null}
   */
  get container() {
    return this._container;
  }

  /**
   * Get offset height of element.
   * @returns {Number}
   */
  get offsetHeight() {
    return this._container ? this._container.offsetHeight : 0;
  }

  /**
   * Get bounding client rect of container.
   * @returns {Object|null}
   */
  getBoundingClientRect() {
    return this._container ? this._container.getBoundingClientRect() : null;
  }

  /**
   * Set container page.
   * @param {ScrollPage} page
   */
  setPage(page) {
    this._page = page;
  }

  /**
   * Set active.
   * @param {Boolean} active
   */
  setActive() {
    // Implementation in subclass.
  }

  /**
   * Clear.
   */
  clear() {
    if (!this._container) {
      return;
    }

    if (this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
    }

    // Clear memory.
    this._container = null;
    this._elements = null;
  }
}

/**
 * Public exports.
 * @type {AbstractScrollItem}
 */
export default AbstractScrollItem;
