import { format } from 'date-fns';
import type { AppRecess } from '@debatdirect/core-ts/types/app';

export const slugify = (text: string) => {
  return text
    .toString() // Ensure the input is a string
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading/trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\w-]+/g, '') // Remove all non-word characters (except hyphens)
    .replace(/--+/g, '-'); // Replace multiple hyphens with a single one
};

export const formatRecessTitle = ({ endDate }: AppRecess) => {
  const to = format(endDate, 'EEEE d MMMM y');
  return `De Tweede Kamer is met reces tot en met ${to}`;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
