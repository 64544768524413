import React, { type FC, useCallback } from 'react';
import classNames from 'classnames';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import Image from '@debatdirect/core-ts/components/Image/Image';
import type { DebateLocation } from '@debatdirect/typings';
import { getDebateScreenshotUri } from '@debatdirect/core-ts/utils/debate';
import StatusTag from '@debatdirect/core-ts/components/StatusTag/StatusTag';
import { navigate } from '@debatdirect/core-ts/utils/routing';
import InternalLink from '@debatdirect/core-ts/components/InternalLink/InternalLink';
import { trackEvent } from '@debatdirect/core-ts/utils/trackEvent';

import styles from './FeaturedCard.module.scss';

type Props = {
  debate: AppAgendaDebate;
  location?: DebateLocation;
  style?: React.CSSProperties;
  className?: string;
  link: string;
  tabIndex?: number;
};

const FeaturedCard: FC<Props> = ({ debate, location, style, link, className, tabIndex }) => {
  const { config } = useAppProvider();
  const screenshotConfig = config.screenshot;
  const imageUri = getDebateScreenshotUri(debate, location?.dynamicThumbnailUrl, { ...screenshotConfig, bitrate: 2000 });
  const fallbackImageUri = location?.cardImageUrl || location?.imageUrl;
  const alt = ''; // Intentionally empty because of adject text alternative

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigate(link);
    }
  };

  const handleError = useCallback(() => {
    if (imageUri) {
      trackEvent('thumbnail error', debate.status, `${debate.id} / ${imageUri}`, 1);
    }
  }, [debate.id, debate.status, imageUri]);

  return (
    <InternalLink role="button" href={link} className={classNames(styles.featuredCard, className)} style={style} onKeyDown={handleKeyDown} tabIndex={tabIndex}>
      <div className={styles.description}>
        <h3 className={styles.title}>{debate.name}</h3>
        <p className={styles.location}>{debate.locationName}</p>
      </div>
      <Image id={debate.id} image={imageUri} fallbackImage={fallbackImageUri} alt={alt} className={styles.image} onError={handleError} />
      <StatusTag debate={debate} className={styles.tag} isFeatured />
    </InternalLink>
  );
};

export default FeaturedCard;
