import React from 'react';
import component from 'omniscient';

import SmartScrollView from '../../components/ScrollView/SmartScrollView';

/**
 * SecurityPolicy render method.
 * @returns {React.Component}
 */
const render = function () {
  return (
    <div className="Layer ViewStack-layer Layer--white SecurityPolicyContainer">
      <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
        <SmartScrollView externalWheelHandling={true}>
          <div className="Main-content Main-content--full Content SecurityPolicy">
            <div className="SecurityPolicyPanel">
              <p className="SecurityPolicyPanel__Text">
                We consider the security of our systems a top priority. But no matter how much effort we put into system security, there can still be
                vulnerabilities present. If you discover a vulnerability, we would like to know about it, so we can take steps to address it as quickly as
                possible. We would like to ask you to help us better protect our clients and our systems.
              </p>
              <p />
              <p className="SecurityPolicyPanel__Text">Please do the following</p>
              <ul className="SecurityPolicyPanel__List">
                <li>
                  E-mail your findings to security@videodock.com. Encrypt your findings using our PGP key to prevent this critical information from falling into
                  the wrong hands;
                </li>
                <li>
                  Do not take advantage of the vulnerability or problem you have discovered, for example by downloading more data than necessary to demonstrate
                  the vulnerability or deleting or modifying other people&#39;s data;
                </li>
                <li>Do not reveal the problem to others until it has been resolved;</li>
                <li>Do not use attacks on physical security, social engineering, distributed denial of service, spam or applications of third parties; and</li>
                <li>
                  Do provide sufficient information to reproduce the problem, so we will be able to resolve it as quickly as possible. Usually, the IP address
                  or the URL of the affected system and a description of the vulnerability will be sufficient, but complex vulnerabilities may require further
                  explanation.
                </li>
              </ul>
              <p className="SecurityPolicyPanel__Text">What we promise</p>
              <ul className="SecurityPolicyPanel__List">
                <li>We will respond to your report within 3 business days with our evaluation of the report and an expected resolution date;</li>
                <li>If you have followed the instructions above, we will not take any legal action against you in regard to the report;</li>
                <li>We will handle your report with strict confidentiality, and not pass on your personal details to third parties without your permission;</li>
                <li>We will keep you informed of the progress towards resolving the problem;</li>
                <li>
                  In the public information concerning the problem reported, we will give your name as the discoverer of the problem (unless you desire
                  otherwise)
                </li>
                <li>We think we should give credit where credit is due and will mention your name or credentials in one of our channels.</li>
              </ul>
              <p className="SecurityPolicyPanel__Text">
                We strive to resolve all problems as quickly as possible, and we would like to play an active role in the ultimate publication on the problem
                after it is resolved.
              </p>
            </div>
          </div>
        </SmartScrollView>
      </div>
    </div>
  );
};

/**
 * Public exports.
 */
export default component('SecurityPolicy', {}, render);
