import React from 'react';
import { createPortal } from 'react-dom';

import modalManager from '../Modal/ModalManager';

export default class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.container = modalManager.add(this);
  }

  componentWillUnmount() {
    modalManager.remove(this);
    this.container = null;
  }

  render() {
    return createPortal(this.props.children, this.container);
  }
}
