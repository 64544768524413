import { type FC } from 'react';

type Props = {
  className?: string;
};

const HomeIcon: FC<Props> = ({ className, ...rest }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 7.50358H14.1667V5.83691H9.16667V7.50358ZM9.16667 10.8369H14.1667V9.17025H9.16667V10.8369ZM9.16667 14.1702H14.1667V12.5036H9.16667V14.1702ZM10.5893 2.74766C10.433 2.59138 10.221 2.50358 10 2.50358C9.77899 2.50358 9.56702 2.59138 9.41074 2.74766C9.25446 2.90394 9.16667 3.1159 9.16667 3.33691C9.16667 3.55793 9.25446 3.76989 9.41074 3.92617C9.56702 4.08245 9.77899 4.17025 10 4.17025C10.221 4.17025 10.433 4.08245 10.5893 3.92617C10.7455 3.76989 10.8333 3.55793 10.8333 3.33691C10.8333 3.1159 10.7455 2.90394 10.5893 2.74766ZM12.35 2.50358H15.8333C16.2754 2.50358 16.6993 2.67918 17.0118 2.99174C17.3244 3.3043 17.5 3.72822 17.5 4.17025V15.8369C17.5 16.2789 17.3244 16.7029 17.0118 17.0154C16.6993 17.328 16.2754 17.5036 15.8333 17.5036H4.16667C3.72464 17.5036 3.30072 17.328 2.98816 17.0154C2.67559 16.7029 2.5 16.2789 2.5 15.8369V4.17025C2.5 3.72822 2.67559 3.3043 2.98816 2.99174C3.30072 2.67918 3.72464 2.50358 4.16667 2.50358H7.65C8 1.53691 8.91667 0.836914 10 0.836914C11.0833 0.836914 12 1.53691 12.35 2.50358ZM7.5 5.83691H5.83333V7.50358H7.5V5.83691ZM5.83333 9.17025H7.5V10.8369H5.83333V9.17025ZM7.5 12.5036H5.83333V14.1702H7.5V12.5036Z"
        fill="white"
      />
    </svg>
  );
};

export default HomeIcon;
