import React from 'react';
import classNames from 'classnames';

/**
 * Renders a button component
 * @param  {object} props
 * @return {React.Component}
 */
export default class Button extends React.Component {
  render() {
    const { children, className, disabled, ...rest } = this.props;

    const props = {
      type: 'button',
      ...rest,
      className: classNames(
        {
          Button: true,
          'is-disabled': disabled,
        },
        className,
      ),
      disabled,
    };

    return <button {...props}>{children}</button>;
  }
}
