import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import component from 'omniscient';

const /**
   * Component definition
   * @type {Object}
   */
  definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
    },

    getInitialState: () => ({
      loading: true,
      imageRef: createRef(),
    }),

    imageLoadHandler: function () {
      if (this.state.imageRef.current) {
        this.state.imageRef.current.onload = undefined;
      }

      this.setState({
        loading: false,
      });
    },

    componentDidMount: function () {
      if (this.state.imageRef.current) {
        this.loadImage();
      }
    },

    componentWillUnmount: function () {
      if (this.state.imageRef.current) {
        this.state.imageRef.current.onload = undefined;
      }
    },

    componentDidUpdate: function (prevProps) {
      if (prevProps.politicianId !== this.props.politicianId) {
        this.loadImage();
      }
    },

    generateImageUrl: function () {
      const size = this.props.size,
        politicianId = this.props.politicianId,
        actualSize = Math.round(size * (window.devicePixelRatio || 1)),
        baseUrl = this.context.getCursor(['ui', 'settings', 'api']).get('cdnBaseUrl');

      return `${baseUrl}/media/actors/politicians/${actualSize}/${actualSize}/${politicianId}.jpg`;
    },

    loadImage: function () {
      if (this.state.imageRef.current) {
        this.state.imageRef.current.onload = this.imageLoadHandler;
        this.state.imageRef.current.src = this.generateImageUrl();
      }
    },
  },
  /**
   * The PoliticianImage render function
   * @param  {Object} options               Options
   * @param  {Object} options.alt           Image alt attribute
   * @param  {String} [options.className]   DOM object's className
   * @return {React.DOM}                    The PoliticianImage dom
   */
  render = function ({ className, alt }) {
    const imgClassName = classNames('PoliticianImage', className, {
      'u-opaque': this.state.loading,
    });
    const src = this.state.loading ? '' : this.generateImageUrl();

    if (!alt) {
      // When image has an adjacent text alternative, the alt attribute should be empty.
      // This prevents screen readers from reading the same text twice while also preventing
      // the screen reader from reading the image's filename.
      return <img className={imgClassName} src={src} alt="" ref={this.state.imageRef} />;
    } else {
      return <img className={imgClassName} src={src} alt={alt} ref={this.state.imageRef} />;
    }
  };

export default component('PoliticianImage', definition, render);
