import { parseISO, isWithinInterval } from 'date-fns';
import type { AgendaDebate, DebateResponse, Recess, AppResponse, DebateLocation } from '@debatdirect/typings';
import type { AppAgendaDebate, AppDebate } from '@debatdirect/core-ts/types/debate';
import type { App, AppRecess, AppLocationsDict } from '@debatdirect/core-ts/types/app';

const determineDebateStatus = ({ startedAt, endedAt }: DebateResponse | AgendaDebate) => {
  if (!startedAt) return 'planned';
  if (!endedAt) return 'live';

  return 'vod';
};

const createLocationDictionary = (locations: DebateLocation[] = []): AppLocationsDict => {
  return Object.fromEntries(Object.entries(locations).map(([_, location]) => [location.id, location]));
};

export const fetchJson = async (url: string) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const response = await fetch(url, { headers });
  return response.json();
};

export const transformAppData = (app?: AppResponse): App | null => {
  return app
    ? ({
        locations: createLocationDictionary(app.locations),
        activeRecess: transformRecessData(app.recesses),
      } as App)
    : null;
};

export const transformDebateData = <T extends DebateResponse | AgendaDebate, R = T extends DebateResponse ? AppDebate : AppAgendaDebate>(debate: T) => {
  return {
    ...debate,
    debateDateStr: debate.debateDate,
    debateDate: parseISO(debate.debateDate),
    startsAt: parseISO(debate.startsAt),
    endsAt: debate.endsAt ? parseISO(debate.endsAt) : null,
    startedAt: debate.startedAt ? parseISO(debate.startedAt) : null,
    endedAt: debate.endedAt ? parseISO(debate.endedAt) : null,
    status: determineDebateStatus(debate),
  } as R;
};

const transformRecessData = (recesses: Recess[]) => {
  return recesses?.reduce<AppRecess | undefined>((prev, recess) => {
    const now = new Date();
    const startDate = parseISO(recess.startDate);
    const endDate = parseISO(recess.endDate);

    if (isWithinInterval(now, { start: startDate, end: endDate })) {
      return {
        description: recess.description,
        startDate,
        endDate,
      };
    }
    return prev;
  }, undefined);
};
