export default (func, limit) => {
  let lastFunc;
  let lastRan;

  const invokeFunc = function () {
    const context = this;
    const args = arguments;

    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(
        function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan),
      );
    }
  };

  invokeFunc.cancel = () => {
    clearTimeout(lastFunc);
  };

  return invokeFunc;
};
