import React from 'react';

const entries = (object) => Object.keys(object).map((key) => [key, object[key]]);

export default (providing) => (Component) => {
  class Provider extends React.Component {
    static childContextTypes = entries(providing).reduce((acc, [key, { type }]) => ({ ...acc, [key]: type }), {});

    getChildContext = () => entries(providing).reduce((acc, [key, { value }]) => ({ ...acc, [key]: value }), {});

    render() {
      return <Component {...this.props} />;
    }
  }

  return Provider;
};
