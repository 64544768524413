/**
 * Add start and end parameter to given media source.
 * @param {String} source
 * @param {String} start
 * @param {String} [end]
 * @returns {String}
 */
const addSourceParameters = (source, start, end) => {
  source += (source.indexOf('?') === -1 ? '?' : '&') + 'start=' + encodeURIComponent(start);

  if (end) {
    source += '&end=' + encodeURIComponent(end);
  }

  return source;
};

export default addSourceParameters;
