import React, { type FC } from 'react';
import classNames from 'classnames';

import styles from './ChevronButton.module.scss';

type Props = {
  title: string;
  className?: string;
  icon: React.ReactNode;
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ChevronButton: FC<Props> = ({ title, disabled, icon, className, onClick }) => {
  return (
    <button className={classNames(styles.chevron, className)} aria-label={title} onClick={onClick} aria-disabled={disabled}>
      {icon}
    </button>
  );
};

export default ChevronButton;
