import { type FC, createContext, useContext, useMemo } from 'react';
import { type Config } from '@debatdirect/core-ts/types/app';

interface FactoryInstance {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (...args: unknown[]) => any;
}

type SupportedServices = 'api' | 'user-preferences' | 'continue-watching' | 'router';

export type AppContextType = {
  config: Config | null;
  getService: (name: SupportedServices) => FactoryInstance; // TODO define typings for each service instead of using generic FactoryInstance
};

type Props = {
  children: React.ReactNode;
} & AppContextType;

const AppContext = createContext<AppContextType>({
  getService: (name) => {
    throw new Error(`Service '${name}' is not available.`);
  },
  config: null,
});

export const AppProvider: FC<Props> = ({ children, config, getService }) => {
  const value = useMemo(() => ({ getService, config }), [config, getService]);
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppProvider = () => {
  return useContext(AppContext) as { [T in keyof AppContextType]: NonNullable<AppContextType[T]> };
};
