/**
 * Create a lookup table out of an array.
 * @param {Array} list - An array of objects
 * @param {String} key
 */
const createLookupTable = (list, key) => {
  return list.reduce((object, item) => {
    if (!item?.[key]) {
      return object;
    }

    object[item[key]] = item;

    return object;
  }, {});
};

/**
 * Public exports.
 */
export default createLookupTable;
