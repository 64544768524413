import React from 'react';
import component from 'omniscient';

import { eq, getBackgroundClass, toggleFill } from './logic';

const /**
   * Renders the infographic
   * @param  {Component}       props The props
   * @return {React.Component}       The react component
   */
  render = function (props) {
    const { highlight, variant, politicians } = props;
    const fillColor = variant === 'dark' ? 'White' : '';
    const className = (props.className || 'u-fill').concat(' Infographic');

    const politiciansList = politicians?.join(', ');
    const title = `Visualisatie van de Wttewaall van Stoetwegenzaal ${politiciansList ? ' en de plaats van ' + politiciansList : ''}`;

    /* eslint-disable no-extra-parens */
    return (
      <svg viewBox="170 -130 300 705" className={className} role="img" aria-labelledby="infographic-title">
        <title id="infographic-title">{title}</title>
        <g className={getBackgroundClass(variant === 'light' && !!highlight)}>
          <path d="M390.3-38.1c1.1,6.1,1.6,12.2,1.3,18l0.5,218.8l-49.9-0.3l-1.6-218.8c0-3.2-0.3-6.3-0.8-9.5c-4.8-30.7-33.8-51.5-64.7-46.5 c-30.7,5.3-51.5,34.4-46.5,65c0.5,3.2,1.3,6.3,2.4,9.2l70.8,206.4l-48.1,16.7L182.8,15.2l-0.3-0.5c-1.8-5.5-3.4-11.4-4.5-17.4 c-9.8-58.4,29.9-113.9,88.5-123.4S380.5-96.6,390.3-38.1" />
          <rect x="430.2" y="207.6" width="37.8" height="88" />
          <rect x="339.3" y="266.3" width="56" height="53.9" />
          <line fill="none" x1="451.1" y1="295.4" x2="451.1" y2="207.6" />
          <rect x="172" y="266.3" width="158.8" height="29.3" />
          <polygon points="415.4,572.9 236.8,572.9 209.5,362 442.6,362    " />
        </g>
        <rect className={toggleFill(eq(highlight, 'pers'), fillColor)} x="172" y="266.3" width="158.8" height="29.3" />
        <polygon className={toggleFill(eq(highlight, 'publiek'), fillColor)} points="415.4,572.9 236.8,572.9 209.5,362 442.6,362 " />
        <rect className={toggleFill(eq(highlight, 'bode'), fillColor)} x="430.2" y="207.6" width="37.8" height="88" />
        <rect className={toggleFill(eq(highlight, 'verslaglegger'), fillColor)} x="339.3" y="266.3" width="56" height="53.9" />
        <path
          className={toggleFill(eq(highlight, 'kamerleden'), fillColor)}
          d="M319.2-122.2c35.9,12.4,64.2,43.9,70.8,84c0,0,1.6,12.2,1.3,18l0.5,218.8l-49.9-0.3l-1.6-218.8c0-3.2-0.3-6.3-0.8-9.5 c-3.4-20.6-17.4-36.7-35.4-43.6L319.2-122.2z"
        />
        <path
          className={toggleFill(eq(highlight, 'kabinet-en-ambtenaren'), fillColor)}
          d="M264.8-73.6c-25.1,9.2-40.7,35.1-36.2,62.4c0.5,3.2,1.3,6.3,2.4,9.2l70.8,206.4l-48.1,16.7L182.8,15.5l-0.3-0.5 c-1.8-5.5-3.4-11.4-4.5-17.4c-8.7-52.9,22.7-103.1,72.2-119.5L264.8-73.6z"
        />
        <path
          className={toggleFill(eq(highlight, 'griffier'), fillColor)}
          d="M284.6-128l0.5,50.7c7.4,0.3,12.4,1.3,19.6,3.7l14.8-48.6C308.6-125.9,296.7-128,284.6-128z"
        />
        <path
          className={toggleFill(eq(highlight, 'voorzitter'), fillColor)}
          d="M266.9-126.4c-5.8,1.1-11.4,2.4-16.6,4.2l14.5,48.9c3.2-1.3,6.6-2.1,10.3-2.6c3.7-0.5,6.3-0.8,10-0.8l-0.3-50.7 C278.8-128,273-127.5,266.9-126.4z"
        />
      </svg>
    );
    /* eslint-enable no-extra-parens */
  };

export default component('InfographicStoetwegen', render);
