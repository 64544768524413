// Test via a getter in the options object to see if the passive property is accessed
let supportsPassive = false;

try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function () {
      supportsPassive = true;
      return this.passive;
    },
  });

  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {
  // noop
}

export default (passive = true) => (supportsPassive ? { passive: passive } : false);
