/**
 * SplashScreen handler.
 */
const SplashScreenHandler = {
  show: function (staticUrl) {
    let img = document.createElement('img');

    img.onload = function () {
      // show splash screen when background is loaded
      const bgElement = document.querySelector('.SplashScreen-background');
      const bodyElement = document.querySelector('.SplashScreen-body');

      if (bgElement) {
        bgElement.style.backgroundImage = `url(${img.src})`;
      }

      if (bodyElement) {
        bodyElement.classList.add('is-visible');
      }

      // unset img
      img = null;
    };

    img.src = `${staticUrl}/img/blauwe-stoelen.jpg`;
  },

  hide: function () {
    setTimeout(() => {
      const splashScreen = document.querySelector('.SplashScreen');
      if (!splashScreen) return;
      splashScreen.classList.add('u-opaque');
      setTimeout(() => splashScreen.parentNode.removeChild(splashScreen), 500);
    }, 250);
  },
};

/**
 * Public exports.
 */
export default SplashScreenHandler;
