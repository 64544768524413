import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import Snackbar from '../../components/Snackbar/Snackbar';
import DebateNotificationButton from '../../components/DebateNotificationButton/DebateNotificationButton';

import IncidentPrompt from './prompts/IncidentPrompt';

class NotificationsProvider extends Component {
  static propTypes = {
    notifications: PropTypes.object,
    debate: PropTypes.object,
  };

  static contextTypes = {
    getService: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.context.getService('notifications').init();
  }

  onNotificationClose = (notification) => () => {
    notification.set('visible', false);

    this.context.getService('notifications').markAsSeen(notification.get('id'));
  };

  renderNotification = (notification) => {
    const { debate } = this.props,
      id = notification.get('id'),
      message = notification.get('message', ''),
      type = notification.get('type'),
      debateId = notification.get('debateId');

    if (type === 'Incident') {
      return <IncidentPrompt key={id} notification={notification} />;
    }

    // Schorsing & Melding
    return (
      <Snackbar
        onClose={this.onNotificationClose(notification)}
        key={id}
        open={notification.get('visible')}
        aria-live="polite"
        autoCloseDuration={60000}
        autoClose
        wide
      >
        {message}
        {!!window.cordova && debate && debateId === debate.get('id') && type === 'Schorsing' ? (
          <DebateNotificationButton className="Button--adjourn" debate={debate} debateId={debateId} suspended />
        ) : null}
      </Snackbar>
    );
  };

  render() {
    const { notifications } = this.props;
    const visibleNotifications = notifications.filter((datum) => datum.get('visible'));

    return (
      <div className="ViewStack-layer Layer Layer--overlay">
        {visibleNotifications.slice(0, Math.min(3, visibleNotifications.count())).map(this.renderNotification)}
      </div>
    );
  }
}

export default observer(
  structure,
  {
    notifications: ['ui', 'notifications'],
  },
  (props) => <NotificationsProvider {...props} />,
);
