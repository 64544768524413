import type { IOAgendaUpdateDeleteEvent, IOAgendaUpdateEvent, IOLiveNotificationEvent } from '@debatdirect/typings';

import type { IOEvent } from '../../types/debate';

type SocketServiceConfig = {
  eventTimeout: number;
};

export interface SocketObserver {
  onAgendaUpdateEvent: (eventData: IOAgendaUpdateEvent['agenda']['debates']) => void;
  onAgendaDeleteEvent: (eventData: IOAgendaUpdateDeleteEvent['agenda']['debates']) => void;
  onNotificationUpdateEvent: (eventData: IOLiveNotificationEvent) => void;
}

class SocketService {
  connected = false;
  debateId: string | null = null;
  observer?: SocketObserver;
  config: SocketServiceConfig;
  listener: (event: CustomEvent<IOEvent>) => void;

  constructor(config: SocketServiceConfig) {
    this.config = config;
    this.listener = (event) => this.handleEvent(event.detail);
  }

  /**
   * Starts listening to events
   */
  start() {
    document.addEventListener('socketEvent', this.listener as EventListener);
  }

  stop() {
    document.removeEventListener('socketEvent', this.listener as EventListener);
  }

  setObserver(observer: SocketObserver) {
    this.observer = observer;
  }

  /**
   * Handles incoming data
   */
  handleEvent(eventData: IOEvent) {
    if (!this.observer) throw new Error('Updater not set. Cannot handle incoming live data.');

    // trigger observer with a minor delay, to compensate for +/- 1 sec. that the Websocket can be ahead of the API
    setTimeout(() => {
      try {
        if (!this.observer) return;

        switch (eventData.eventType) {
          case 'agenda_update':
            return this.observer.onAgendaUpdateEvent(eventData.agenda.debates);
          case 'agenda_delete':
            return this.observer.onAgendaDeleteEvent(eventData.agenda.debates);
          case 'notification':
            return this.observer.onNotificationUpdateEvent(eventData);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        if (import.meta.env.DEV) console.error(error);
      }
    }, this.config.eventTimeout);
  }
}

export default SocketService;
