import { type FC } from 'react';

type Props = {
  className?: string;
};

const AgendaIcon: FC<Props> = ({ className, ...rest }) => {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...rest}>
      <path
        d="M17 2.46777H18C19.1046 2.46777 20 3.34814 20 4.41375V21.0103C20 22.085 19.1091 22.9562 18.0026 22.9562H2.99742C1.89428 22.9562 1 22.0759 1 21.0103V4.41375C1 3.33902 1.90129 2.46777 3.00923 2.46777H4"
        stroke="white"
      />
      <path d="M14.5 1.00391V3.93083" stroke="white" strokeLinecap="square" />
      <path d="M6.5 8.80916H14.5623" stroke="white" strokeLinecap="square" />
      <path d="M6.5 11.7359H14.5623" stroke="white" strokeLinecap="square" />
      <path d="M6.5 14.6627H14.5623" stroke="white" strokeLinecap="square" />
      <path d="M6.5 1.00391V3.93083" stroke="white" strokeLinecap="square" />
    </svg>
  );
};

export default AgendaIcon;
