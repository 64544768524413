/**
 * Create element.
 * @param {String} nodeName
 * @param {String} [className]
 * @param {Object} [attributes]
 * @param {Array} [children]
 * @returns {HTMLElement}
 */
const createElement = (nodeName, className = '', attributes = null, children = null) => {
  const element = document.createElement(nodeName);

  element.className = className || '';

  if (attributes) {
    Object.entries(attributes).forEach((entry) => element.setAttribute(entry[0], entry[1]));
  }

  return appendChildren(element, children);
};

/**
 * Append children to element.
 * @param {HTMLElement} element
 * @param {Array} children
 * @returns {HTMLElement}
 */
const appendChildren = (element, children) => {
  if (!children) {
    return element;
  }

  children.forEach((child) => element.appendChild(child));

  return element;
};

/**
 * Public exports.
 */
export { createElement, appendChildren };
