import React, { createRef } from 'react';
import { CSSTransition } from 'react-transition-group';

const isDescendant = (child, parentSelector) => {
  const parentElement = document.querySelector(parentSelector);
  let currentNode = child.parentNode;

  while (currentNode !== null) {
    if (currentNode === parentElement) {
      return true;
    }
    currentNode = currentNode.parentNode;
  }

  return false;
};

export default class MenuListTransition extends React.Component {
  slideRef = createRef();

  handleExit() {
    const slide = this.slideRef.current;

    if (slide) {
      slide.style.width = slide.clientWidth + 'px';
      slide.style.height = slide.clientHeight + 'px';
    }
  }

  handleEntered = (element) => {
    const candidates = element.querySelectorAll('[role="button"], input, a, button');
    const focusable = Array.from(candidates)
      .filter((element) => element.getAttribute('tabindex') !== '-1')
      .filter((element) => !isDescendant(element, '.MenuListHeader'));
    const focusItem = focusable[0];

    focusItem?.focus({ preventScroll: true });
  };

  render() {
    const { className, timeout, appear } = this.props;

    return (
      <CSSTransition
        timeout={typeof timeout === 'number' ? timeout : 300}
        classNames={className}
        onExit={() => this.handleExit()}
        in={this.props.in}
        appear={appear}
        onEntered={this.handleEntered}
        unmountOnExit
      >
        <div className={this.props.className} ref={this.slideRef}>
          {this.props.children}
        </div>
      </CSSTransition>
    );
  }
}
