const services = {};

/**
 * A service container for dependency injection
 * @type {Object}
 */
export default {
  /**
   * Returns a registered service
   * @param  {String} name
   * @return {*}
   * @throws {Error} If the requested service is not set
   */
  get: function (name) {
    const service = services[name];

    if (!service) {
      throw new Error(`The service with name ${name} is not registered with the ServiceContainer`);
    }

    return service;
  },

  /**
   * Registers a service with the ServiceContainer
   * @param {String} name
   * @param {*} service
   */
  set: function (name, service) {
    services[name] = service;

    return this;
  },
};
