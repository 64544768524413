import React, { type FC, type SVGProps } from 'react';
import classNames from 'classnames';
import InternalLink from '@debatdirect/core-ts/components/InternalLink/InternalLink';

import styles from './Button.module.scss';

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  label: string;
  href?: string;
  iconLeft?: FC<SVGProps<SVGSVGElement>>;
  iconRight?: FC<SVGProps<SVGSVGElement>>;
  className?: string;
  disabled?: boolean;
  variant?: 'light';
  target?: string;
};

const Button: FC<Props> = ({ onClick, label, href, iconLeft: IconLeft, iconRight: IconRight, className, disabled, target, variant = 'default', ...rest }) => {
  const isExternal = href?.startsWith('http');
  const buttonClasses = classNames(styles.button, className, {
    [styles.light]: variant === 'light',
  });
  const content = (
    <>
      {IconLeft && <IconLeft className={`${styles.icon} ${styles.iconLeft}`} aria-hidden={true} />}
      {label}
      {IconRight && <IconRight className={`${styles.icon} ${styles.iconRight}`} aria-hidden={true} />}
    </>
  );

  // Detect if internal or external link
  if (href) {
    return isExternal ? (
      <a href={href} className={buttonClasses} aria-disabled={disabled} onClick={disabled ? undefined : onClick} target={target} {...rest}>
        {content}
      </a>
    ) : (
      <InternalLink href={href} className={buttonClasses} aria-disabled={disabled} onClick={disabled ? undefined : onClick} target={target} {...rest}>
        {content}
      </InternalLink>
    );
  }

  return (
    <button onClick={disabled ? undefined : onClick} className={buttonClasses} aria-disabled={disabled} {...rest}>
      {content}
    </button>
  );
};

export default Button;
