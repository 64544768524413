import moment from 'moment';

/**
 * Parse ISO 8601 dateString to moment object
 * @param dateString
 * @returns {moment.Moment}
 */
const fromISO = (dateString) => moment(dateString, 'YYYY-MM-DDTHH:mm:ssZ');

/**
 * Format a date string or object to time for example, 17:45
 * @param ts
 * @returns {string}
 */
const timeFormatter = (ts) => moment(ts, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm');

export default {
  fromISO,
  timeFormatter,
};
