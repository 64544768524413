import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import type { AgendaResponse } from '@debatdirect/typings';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import { transformDebateData } from '@debatdirect/core-ts/utils/apiTransformers';
import { isBefore, endOfDay } from 'date-fns';

import useApiData from './useApiData';

const startDateCompareFn = (debateA: AppAgendaDebate, debateB: AppAgendaDebate) => {
  return (debateA.startedAt || debateA.startsAt).getTime() - (debateB.startedAt || debateB.startsAt).getTime();
};

const useAgendaWeeks = () => {
  const { getService } = useAppProvider();
  const api = getService('api');

  return useApiData({
    key: ['agendaWeeks'],
    queryFn: async () => {
      const response: AgendaResponse = await api.getAgendaWeeks();
      const pastDebates = response.debates.filter((debate) => isBefore(debate.startsAt, endOfDay(new Date())));

      // Look further back in time if there are no past debates
      if (pastDebates.length === 0) {
        return api.getAgendaWeeks(3, 1);
      }

      return response;
    },
    transformFn: (agenda: AgendaResponse): AppAgendaDebate[] => {
      return agenda?.debates.map(transformDebateData).sort(startDateCompareFn) ?? [];
    },
  });
};

export default useAgendaWeeks;
