import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

const /**
   * Create the HTML for a single politician.
   * @param {Object} politician
   * @param {String} baseUrl
   * @returns {String}
   */
  createPoliticianListItem = function (politician, baseUrl, onClick) {
    const size = 50,
      politicianId = politician.id || '',
      firstName = politician.firstName || '',
      lastName = politician.lastName || '',
      name = firstName + ' ' + lastName,
      actualSize = Math.round(size * (window.devicePixelRatio || 1)),
      url = `${baseUrl}/media/actors/politicians/${actualSize}/${actualSize}/${politicianId}.jpg`;

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick(politicianId);
      }
    };

    return (
      <li className="List-item Politician" data-politician-id={politicianId} key={politicianId}>
        <div
          role="button"
          tabIndex="0"
          className="u-flex u-grow u-row u-pointer Link--infoPanel"
          onClick={() => onClick(politicianId)}
          onKeyDown={handleKeyDown}
        >
          <img className="PoliticianImage Politician-photo u-flex" src={url} alt="" />
          <div className="u-flex u-col u-centerSelf u-grow u-windowsInline">
            <div className="Politician-name">
              <span className="Politician-name__Label">{name}</span>
              <svg
                className="Icon Politician-name__Arrow u-inline"
                name="arrow"
                width="8.57"
                height="12"
                preserveAspectRatio="xMidYMid meet"
                aria-hidden="true"
              >
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </li>
    );
  },
  definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
    },

    propTypes: {
      party: PropTypes.object.isRequired,
    },

    shouldComponentUpdate: () => false,

    /**
     * Handle clicks.
     * @param {Event} event
     */
    handleClick: function (id) {
      this.context.getService('info-panel').show('PoliticianPanel', {
        politicianId: id,
        initialHeadingLevel: 3,
      });
    },
  },
  /**
   * ParliamentSection render function.
   * @param {Object} props
   * @returns {React.Component}
   */
  render = function ({ party }) {
    const id = party.id,
      name = party.name || 'Onbekend',
      shorthand = party.shorthand || '',
      members = party.members;

    this.members = members;

    const baseUrl = this.context.getCursor(['ui', 'settings', 'api']).get('cdnBaseUrl');

    return (
      <section key={id} className="Politicians-Section" aria-label={name}>
        <h2 className="Politicians-Section__Header">
          <span className="Politicians-Section__HeaderShorthand">{shorthand}</span>
          <span className="Politicians-Section__HeaderFull">({name})</span>
        </h2>
        <ul className="Politicians-Section__List">
          {this.members.map((member) => createPoliticianListItem(member, baseUrl, this.handleClick))}
        </ul>
      </section>
    );
  };

export default component('ParliamentSection', definition, render);
