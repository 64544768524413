import Button from '@debatdirect/core/components/Button/Button';
import React, { Component } from 'react';
import { debounce } from 'throttle-debounce';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';

import Close from '../../icons/Close';
import Search from '../../icons/Search';
import { ChevronRight } from '../../icons';

class SearchBar extends Component {
  searchRef = React.createRef();

  renderInput = (inputProps) => {
    return (
      <form className="SearchBar SearchBar--close" role="search" onSubmit={this.handleSubmit}>
        <label className="srOnly" htmlFor="searchBarInput">
          Zoek op debat titel en beschrijving
        </label>
        <input
          {...inputProps}
          id="searchBarInput"
          data-testid="searchBarInput"
          type="search"
          autoComplete="off"
          value={this.props.query}
          className={this.props.suggestions.length === 0 ? 'SearchBar-input' : 'SearchBar-input SearchBar-input--suggestions-below'}
          placeholder="Zoek op debat titel en beschrijving"
          ref={this.searchRef}
          autoFocus={this.props.query === ''}
        />
        <Search className="SearchBar-icon SearchBar-search" />
        {this.props.query !== '' ? (
          <Button className="IconButton SearchBar-close" onClick={this.props.onReset} aria-label="Wis zoekopdracht">
            <Close />
          </Button>
        ) : null}
      </form>
    );
  };

  renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <div className={classNames('SearchBar-menuItem', { selected: isHighlighted })} key={suggestion.value}>
        <span className="SearchBar-label">{suggestion}</span>
        <span className="SearchBar-suggestionIcon">
          <ChevronRight />
        </span>
      </div>
    );
  };

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;

    // don't render container when there are no suggestions
    if (!children) {
      return null;
    }

    // prevent screen reader from reading out role="listbox" twice
    const { role, ...rest } = containerProps;

    return (
      <div {...rest} className="SearchBar-suggestionsContainer Main-content">
        {children}
      </div>
    );
  };

  getSuggestionsDebounced = debounce(500, (value) => this.props.getSuggestions(value));

  handleSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestionsDebounced(value);
  };

  handleSuggestionsClearRequested = () => {
    this.props.clearSuggestions();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.searchRef.current.blur();
  };

  render() {
    const { suggestions, query } = this.props;

    return (
      <Autosuggest
        suggestions={suggestions}
        renderInputComponent={this.renderInput}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={(suggestion) => {
          return suggestion;
        }}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          onChange: this.props.onChange,
          value: query,
        }}
        theme={{
          suggestionsList: 'SearchBar-list',
        }}
      />
    );
  }
}

export default SearchBar;
