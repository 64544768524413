import React from 'react';
import { CSSTransition } from 'react-transition-group';

export default class Backdrop extends React.Component {
  render() {
    return (
      <CSSTransition classNames="Backdrop--animation" timeout={300} in={this.props.open} mountOnEnter unmountOnExit appear>
        <div className="Backdrop">
          <div className="Backdrop-click" onClick={this.props.onClick} />
          {this.props.children}
        </div>
      </CSSTransition>
    );
  }
}
