import React, { useState } from 'react';

import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import TextPanel from '../TextPanel/TextPanel';

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{12,}$/;

const UpdatePasswordForm = ({ onSubmit, hasError, isSubmitting }) => {
  const [values, setValues] = useState({
    password: '',
  });
  const [passwordError, setPasswordError] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;

    // re-check password error when there is a password error and the user updates the password field
    if (name === 'password' && passwordError) {
      setPasswordError(!PASSWORD_REGEX.test(value));
    }

    setValues((current) => ({ ...current, [name]: value }));
  };

  const handleBlur = () => {
    setPasswordError(!PASSWORD_REGEX.test(values.password));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="Heading">Nieuw wachtwoord instellen</h2>
      {hasError ? (
        <TextPanel variant="error" className="u-mb20 u-mt20">
          Het is niet gelukt om uw wachtwoord aan te passen. Probeer het opnieuw.
        </TextPanel>
      ) : null}
      <p>Om verder te kunnen gaan moet u uw wachtwoord aanpassen. Vul hieronder een nieuw wachtwoord in die voldoet aan de volgende vereisten:</p>
      <ul>
        <li>Minimaal 12 karakters</li>
        <li>Minimaal 1 hoofdletter</li>
        <li>Minimaal 1 kleine letter</li>
        <li>Minimaal 1 cijfer</li>
        <li>Minimaal 1 symbool</li>
      </ul>
      <TextField
        className="u-mb20"
        name="password"
        type="password"
        value={values.password}
        label="Nieuw wachtwoord"
        onChange={handleChange}
        onBlur={handleBlur}
        error={hasError || passwordError}
        required
      />
      {passwordError ? (
        <TextPanel variant="error" className="u-mb20">
          Gekozen wachtwoord is niet geschikt. Kies een nieuw wachtwoord dat voldoet aan de vereisten.
        </TextPanel>
      ) : null}
      <Button className="Button--medium Button--block Button--primary u-mb20" type="submit" disabled={isSubmitting}>
        <span className="Button-label">Versturen</span>
      </Button>
    </form>
  );
};

export default UpdatePasswordForm;
