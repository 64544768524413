import { useQuery } from '@tanstack/react-query';

import useDataTransformer from './useDataTransformer';

type Options<ApiData, TransformedData> = {
  key: (string | undefined)[];
  queryFn: () => Promise<ApiData>;
  transformFn: (data: ApiData) => TransformedData;
  enabled?: boolean;
};

// Wrapper around useQuery to transform and memoize api data
const useApiData = <ApiData, TransformedData>({ key, queryFn, transformFn, enabled = true }: Options<ApiData, TransformedData>) => {
  const { data: rawData, isLoading, isFetching, isError, refetch } = useQuery({ queryKey: key, queryFn, enabled });
  const transformedData = useDataTransformer({
    key,
    data: rawData,
    transformFn,
  });

  return { data: transformedData, isLoading, isFetching, isError, refetch };
};

export default useApiData;
