import type { QueryClient } from '@tanstack/query-core';
import type { AgendaDebate, AgendaResponse } from '@debatdirect/typings';

import type { IOAgendaUpdateDeleteEvent, IOAgendaUpdateEvent } from '../../types/debate';
import type { SocketObserver } from '../SocketService/SocketService';

type WithId = {
  id: string;
};

// Merges two arrays of objects based on their id
export const mergeArraysById = <T extends WithId>(array1: T[], array2: T[]): T[] => {
  const mergedObj: Record<string, T> = [...array1, ...array2].reduce((acc, obj) => {
    return Object.assign(acc, { [obj.id]: obj });
  }, {});

  return Object.values(mergedObj);
};

export class ReactQueryUpdater implements SocketObserver {
  constructor(private readonly client: QueryClient) {}

  /**
   * Handle an agenda debate delete event
   */
  onAgendaDeleteEvent = (deletedDebates: IOAgendaUpdateDeleteEvent['agenda']['debates']) => {
    return this.client.setQueryData(['agendaWeeks'], (data: AgendaResponse | undefined) => {
      if (!data) return undefined;

      const debateIds = deletedDebates.map((debate) => debate.id);
      const filteredDebates = data.debates.filter((debate: AgendaDebate) => debateIds.indexOf(debate.id) === -1);

      return { ...data, debates: filteredDebates };
    });
  };

  /**
   * Handle agenda debate update event
   */
  onAgendaUpdateEvent = (updatedDebates: IOAgendaUpdateEvent['agenda']['debates']) => {
    return this.client.setQueryData(['agendaWeeks'], (agenda: AgendaResponse | undefined) => {
      if (!agenda) return undefined;

      return { ...agenda, debates: mergeArraysById(agenda.debates, updatedDebates) };
    });
  };

  onNotificationUpdateEvent = () => {
    // Notifications are handled from LiveDataService
  };
}
