import moment from 'moment';

/**
 * Parse the given seconds to an hours, minutes and seconds object
 * @param {number} input
 * @returns {{hours: number, seconds: number, minutes: number}}
 */
const parseTime = (input) => {
  const hours = Math.floor(input / 3600);
  const minutes = Math.floor((input - hours * 3600) / 60);
  const seconds = Math.floor(input - (hours * 3600 + minutes * 60));

  return {
    hours,
    minutes,
    seconds,
  };
};

/**
 * Pad the given number with a leading zero when lower than 10
 * @param {number} time
 * @returns {string|*}
 */
const formatTime = (time) => (time < 10 ? `0${time.toString()}` : time);

/**
 * Format the given duration in seconds to a duration string
 * @param {number} duration
 * @returns {string}
 */
const formatDuration = (duration) => {
  // input is not valid
  if (!duration || !isFinite(duration) || isNaN(duration)) {
    return '00:00';
  }

  const { hours, minutes, seconds } = parseTime(duration);

  if (hours > 0) {
    return `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
  }

  return `${formatTime(minutes)}:${formatTime(seconds)}`;
};

/**
 * Format file size to human-readable string
 * @param {number} bytes
 * @returns {string}
 */
const formatSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) return '0 Byte';

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

/**
 * Format the given duration to string
 * @param duration
 * @returns {string}
 */
const formatTimeLong = (duration) => {
  const { hours, minutes, seconds } = parseTime(duration);

  return [hours > 0 ? `${hours}u` : '', minutes > 0 ? `${minutes}min` : '', seconds > 0 ? `${seconds}s` : ''].join(' ');
};

/**
 * Format the title for the navbar based on the current route
 * @param route
 * @param params
 * @returns {string}
 */
const formatNavBarTitle = (route, params) => {
  const date = moment(params?.date);

  if (!date.isSame(moment(), 'week')) {
    const fromDate = date.clone().startOf('week');
    const toDate = date.clone().endOf('week');
    const fromDay = fromDate.format('D');
    const fromMonth = fromDate.format('MMMM');
    const toDay = toDate.format('D');
    const toMonth = toDate.format('MMMM');

    const fromString = fromMonth !== toMonth ? `${fromDay} ${fromMonth}` : fromDay;
    const toString = `${toDay} ${toMonth}`;

    return `${fromString} t/m ${toString} in de Tweede Kamer`;
  }

  return 'Deze week in de Tweede Kamer';
};

export default {
  formatTime,
  formatTimeLong,
  formatDuration,
  parseTime,
  formatSize,
  formatNavBarTitle,
};
