import { Helmet } from 'react-helmet';

import licensesWeb from '../../../web/LICENSES.json';
import licensesApp from '../../../app/LICENSES.json';
import licensesCore from '../../LICENSES.json';
import SmartScrollView from '../ScrollView/SmartScrollView';

const licenses = [...licensesWeb, ...licensesApp, ...licensesCore];

const LicenseList = () => {
  return (
    <div className="Layer ViewStack-layer Layer--white AboutContainer">
      <Helmet>
        <title>Debat Direct Licenties</title>
        <meta name="description" content="Een overzicht van de software en bijbehorende licenties die voor Debat Direct gebruikt worden" />
      </Helmet>
      <div className="Main-wrapper is-expanded">
        <SmartScrollView externalWheelHandling>
          <div className="Main-content Main-content--full Content About">
            <div className="AboutPanel">
              <h2 className="AboutPanel__Title">Licenties</h2>
              <ul>
                {licenses.map(({ name, licenseType }) => (
                  <li key={name}>
                    <h3 className="About__LicenseTitle">{name}</h3>
                    <p>Licensietype: {licenseType}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </SmartScrollView>
      </div>
    </div>
  );
};

export default LicenseList;
