/**
 * Cache for HTTP responses.
 */
class Cache {
  /**
   * Constructor.
   * @param {Number} cacheMinutes
   */
  constructor(cacheMinutes) {
    this._cacheMilliseconds = cacheMinutes * 60 * 10000;
    this._table = {};
  }

  /**
   * Add item to cache.
   * @param {String} key
   * @param {*} value
   */
  add(key, value) {
    if (!key || typeof key !== 'string' || !value) {
      return;
    }

    this._table[key] = {
      time: Date.now(),
      value: value,
    };
  }

  /**
   * Get item from cache.
   * @param {String} key
   * @returns {Object|null}
   */
  get(key) {
    const table = this._table;

    // Invalid call or cache entry not found.
    if (!key || typeof key !== 'string' || !Object.prototype.hasOwnProperty.call(table, key)) {
      return null;
    }

    const record = table[key];

    // Record is stale.
    if (Date.now() - record.time > this._cacheMilliseconds) {
      delete table[key];
      return null;
    }

    return record.value;
  }
}

/**
 * Public exports.
 */
export const politicians = new Cache(30);
