import io from 'socket.io-client';

/**
 * Replace table.
 */
const replaceTable = (function () {
  const source = {
    A: [192, 197],
    C: [199, 199],
    E: [200, 203],
    I: [204, 207],
    O: [210, 214],
    U: [217, 220],
    a: [224, 229],
    c: [231, 231],
    e: [232, 235],
    i: [236, 239],
    o: [242, 246],
    u: [249, 252],
  };

  const table = Object.entries(source).reduce((object, entry) => {
    const toChar = entry[0];
    const charCodes = entry[1];

    for (let i = charCodes[0]; i <= charCodes[1]; i++) {
      object[String.fromCharCode(i)] = toChar;
    }

    return object;
  }, {});

  return table;
})();

/**
 * Make name filename safe.
 * @param {String} input
 * @returns {String}
 */
const makeFileNameSafe = (input) => {
  return input
    .trim() // No trailing spaces allowed in Windows.
    .replace(/\s+/g, '-') // Change spaces to hyphens.
    .replace(/[^a-zA-Z0-9-]/g, (character) => {
      return replaceTable[character] || ''; // Replace to ASCII lookalike or remove.
    });
};

/**
 * Handler for socket traffic in case of a fragment request.
 */
class CreateFragmentConnection {
  /**
   * Constructor.
   * @param {Object} fragment - Object containing properties needed for MP4 creation.
   * @param {Object} config - Configuration properties.
   */
  constructor(fragment, config) {
    this._fragment = fragment;
    this._config = config;

    this._socket = null;

    this._listeners = {
      success: [],
      error: [],
    };
  }

  /**
   * Initialize.
   */
  init() {
    this._socket = io(this._config.url, { transports: ['websocket'] });

    this._socket
      .on('connect', this._handleConnect.bind(this))
      .on('connect_error', this._handleConnectError.bind(this))
      .on('fragment_error', this._handleFragmentError.bind(this))
      .on('fragment_request_received', this._handleFragmentRequestReceived.bind(this))
      .on('fragment_status', this._handleFragmentStatus.bind(this))
      .on('fragment_ready', this._handleFragmentReady.bind(this));
  }

  /**
   * Dispose -> close connection.
   */
  dispose() {
    if (this._socket) {
      this._socket.disconnect();
      this._socket = null;
    }

    this._listeners = {
      success: [],
      error: [],
    };
  }

  /**
   * Allow clients to listen to events.
   * @param {String} event
   * @param {Function} listener
   */
  addEventListener(event, listener) {
    if (Object.prototype.hasOwnProperty.call(this._listeners, event)) {
      this._listeners[event].push(listener);
    }
  }

  /**
   * Handle connect event.
   * @private
   */
  _handleConnect() {
    const name = this._fragment.name,
      fragment = {
        ...this._fragment,
        name: makeFileNameSafe(name),
      };

    this._log('Fragment request for: ', fragment);
    this._socket.emit('fragment_request', fragment);
  }

  /**
   * Handle connect error.
   * @param {Object} event
   * @private
   */
  _handleConnectError(event) {
    this._log('connect_error: ', event);
    this._dispatchEvent('error', { type: 'Connect Error' });
  }

  /**
   *
   * @param event
   * @private
   */
  _handleFragmentError(event) {
    this._log('fragment_error: ', JSON.stringify(event));
    this._dispatchEvent('error', { type: 'Fragment Error' });
  }

  /**
   * Handle fragment request received.
   * @param {Object} event
   * @private
   */
  _handleFragmentRequestReceived(event) {
    this._log('fragment_request_received: ', event);
  }

  /**
   * Handle fragment status.
   * @param {Object} event
   * @private
   */
  _handleFragmentStatus(event) {
    this._log('fragment_status: ', event);
  }

  /**
   * Handle fragment ready.
   * @param {Object} event
   * @private
   */
  _handleFragmentReady(event) {
    this._log('fragment_ready: ', event);
    this._socket.emit('fragment_received', event);
    this._dispatchEvent('success', { url: event.url, expiresAt: event.expiresAt });
  }

  /**
   * Dispatch event to clients.
   * @param {String} eventName
   * @param {Object} data
   * @private
   */
  _dispatchEvent(eventName, data) {
    const event = {
      ...data,
      target: this,
    };

    this._listeners[eventName].forEach((listener) => listener(event));
  }

  /**
   * Log to console.
   * @param {Array} params
   * @private
   */
  _log(...params) {
    if (import.meta.env.DEV) {
      console.log(...params);
    }
  }
}

/**
 * A helper function which generates a name for a debate
 *
 * @param {String} name
 * @param {String} start
 * @param {String} end
 * @param {String} resolution
 * @param {String} type
 * @returns {String}
 */
export const generateFragmentName = (name, start, end, resolution, type) => {
  const startPart = start ? `-${start}` : '';
  const endPart = end ? `-${end}` : '';
  const typePart = type ? `-${type}` : '';
  const resolutionPart = resolution ? `-${resolution}` : '';
  const namePart = name || 'debatdirect-video';

  const partsLength = startPart.length + endPart.length + typePart.length + resolutionPart.length;

  return `${namePart.slice(0, 254 - partsLength)}${startPart}${endPart}${resolutionPart}${typePart}`;
};

/**
 * Public exports.
 * @type {CreateFragmentConnection}
 */
export default CreateFragmentConnection;
