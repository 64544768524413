import { service, util } from '../../core';

const { ServiceContainer } = service;
const { getIn } = util;

/**
 * Initializes state and services
 *
 * @param  {Object}    config  A configuration object with services and initialState
 */
function App(config) {
  const { structure, services } = config;

  // Initialize services
  services.forEach(function (service) {
    let args, extraArgs, instance, reference;

    const { name, keyPath, factory, props } = service;

    if (keyPath) {
      reference = structure.reference(keyPath);
    }

    if (props) {
      extraArgs = props.map(function (prop) {
        const { keyPath, source } = prop;

        if ('config' === source) {
          return getIn(config, keyPath);
        }

        return structure.reference(keyPath);
      });
    }

    if (reference) {
      args = props ? [reference].concat(extraArgs) : [reference];
    } else {
      args = extraArgs;
    }

    instance = factory.apply(createContext(), args);

    ServiceContainer.set(name, instance);
  });

  return structure;
}

function createContext() {
  return {
    getService: function (name) {
      return ServiceContainer.get(name);
    },
  };
}

export default App;
