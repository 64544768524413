import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import observer from 'omnipotent/decorator/observer';
import component from 'omniscient';
import moment from 'moment';
import immutable from 'immutable';

import { structure } from '../../../core';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import { date } from '../../../common';
import createLookupTable from '../../../utils/lib/createLookupTable';
import { createElement } from '../../../utils/lib/DOM';
import ScrollView from '../../../components/ScrollView/SmartScrollView';
import LiveDataDisclaimer from '../../../components/LiveDataDisclaimer/LiveDataDisclaimer';
import SpeakerEventPopover from '../../../components/Popovers/SpeakerEventPopover';

import DebateEventItem from './DebateEventItem';
import ScrollPage from './utils/ScrollPage';
import ScrollManager from './utils/ScrollManager';

/**
 * The component definition
 * @type {Object}
 */
const definition = {
  wasLive: false,
  lastActiveEvent: null,

  mainRef: createRef(),
  hoursMainRef: createRef(),
  hoursSubRef: createRef(),
  hoursRef: createRef(),
  eventsContainerRef: createRef(),

  getInitialState: () => ({
    settingsOpen: false,
    settingsAnchor: null,
  }),

  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    pathWith: PropTypes.func.isRequired,
  },

  componentDidMount: function () {
    this._init();
  },

  componentDidUpdate: function (prevProps) {
    // reset wasLive when debate changes (by clicking the "go to next debate" button)
    if (this.props.debate.get('id') !== prevProps.debate.get('id')) {
      this.wasLive = false;
    }

    // prevent the event list from filling when the debate ends.
    if (this.props.debate.has('endedAt') && !prevProps.debate.has('endedAt')) {
      this.wasLive = true;
    }

    // Check if events have been added.
    const currentEvents = this._lastEvents,
      nextEvents = this._getEventsList(this.props);

    if (nextEvents.length > currentEvents.length) {
      this._addUiItems(currentEvents, nextEvents);
    }

    // less events... clear and re-add
    if (nextEvents.length < currentEvents.length) {
      this._removeUiItems(currentEvents, nextEvents);
    }

    this._lastEvents = nextEvents;

    // Check if active event has changed.
    const nextActiveEvent = this._getActiveEvent(this.props);

    if (this.lastActiveEvent !== nextActiveEvent) {
      this._setActiveUiEvent(this.props);
      this.lastActiveEvent = nextActiveEvent;
    }

    // Check if width has changed.
    const currentWidth = this.props.widthReference,
      nextWidth = this.props.widthReference;

    if (currentWidth !== nextWidth) {
      this._handleResize();
    }
  },

  // shouldComponentUpdate: function (nextProps) {
  //
  //   return nextProps.settingsOpen !== this.props.settingsOpen;
  // },

  componentWillUnmount: function () {
    if (this._scrollManager) {
      this._scrollManager.dispose();
    }

    if (this._containers.pagesContainer) {
      this._containers.pagesContainer.removeEventListener('click', this._handleEventClick);
    }
  },

  /**
   * Initialize.
   * @private
   */
  _init: function () {
    const events = this._getEventsList(this.props);

    this._lastEvents = events;

    this._lastUserSelectedEvent = null;
    this._lastUserSelectedPage = null;

    this._lookup = this._createLookup();

    const uiItems = this._createUiItems(events, this._lookup);

    this._uiEventsTable = uiItems.uiEventsTable;

    this._containers = this._findContainers();
    this._containers.pagesContainer.addEventListener('click', this._handleEventClick);
    this._containers.pagesContainer.addEventListener('keypress', this._handleEventKeyPressClick);

    this._scrollManager = new ScrollManager(this._containers, [...uiItems.uiPages]);
    this._scrollManager.init();

    this._activeUiEvent = this._getActiveUiEvent(this.props);

    if (this._activeUiEvent) {
      this._activeUiEvent.setActive(true);
      this._scrollManager.setActivePage(this._activeUiEvent.page);
    } else {
      this._scrollManager.setActivePage(this._scrollManager.lastPage);
    }

    this._hours = {
      main: this.hoursMainRef.current,
      sub: this.hoursSubRef.current,
      hours: this.hoursRef.current,
      left: this.hoursMainRef.current.querySelector('.DebateEvents-hourButton.ScrollLeft'),
      right: this.hoursMainRef.current.querySelector('.DebateEvents-hourButton.ScrollRight'),
    };

    this._createHourLabels();
    this._setActiveHourLabel();
  },

  /**
   * Create lookup tables for actors and parties for quick lookups.
   * @returns {{actors, parties}}
   * @private
   */
  _createLookup: function () {
    const { getCursor } = this.context,
      politicians = getCursor(['data', 'actors', 'politicians']),
      parties = getCursor(['data', 'actors', 'parties']);

    return {
      actors: createLookupTable(politicians.toJS(), 'id'),
      parties: createLookupTable(parties.toJS(), 'id'),
    };
  },

  /**
   * Create UI items.
   * @param {Array} events
   * @param {Object} lookup
   * @returns {{uiEventsTable: {}, uiEvents: Array, uiPages: Array}}
   * @private
   */
  _createUiItems: function (events, lookup) {
    let uiEvents = [],
      uiEventsTable = {},
      debate = this.props.debate.toJS(),
      pages = {};

    for (let event of events) {
      if (!DebateEventItem.canRender(event)) {
        continue;
      }

      const newItem = new DebateEventItem(debate, event, lookup),
        fullDate = newItem.fullDate;

      if (!fullDate) {
        continue;
      }

      if (!Object.prototype.hasOwnProperty.call(pages, fullDate)) {
        pages[fullDate] = [newItem];
      } else {
        pages[fullDate].push(newItem);
      }

      uiEvents.push(newItem);
      uiEventsTable[newItem.id] = newItem;
    }

    const uiPages = Object.keys(pages)
      .sort((a, b) => a.localeCompare(b))
      .map((key) => {
        const page = new ScrollPage(createElement('ul', 'DebateEvents-list List'), key, pages[key]);

        page.init();

        return page;
      });

    return {
      uiEventsTable,
      uiEvents,
      uiPages,
    };
  },

  /**
   * Add UI items.
   * @param {Array} currentEvents
   * @param {Array} nextEvents
   * @private
   */
  _addUiItems(currentEvents, nextEvents) {
    const uiEventsTable = this._uiEventsTable,
      lookup = this._lookup,
      debate = this.props.debate.toJS();

    for (let event of nextEvents) {
      // Ignore events we can not render.
      if (!DebateEventItem.canRender(event)) {
        continue;
      }

      const eventId = `${event.eventType}${event.eventStart}`;

      // Event is already in our list.
      if (Object.prototype.hasOwnProperty.call(uiEventsTable, eventId)) {
        continue;
      }

      const newUiEvent = new DebateEventItem(debate, event, lookup);

      if (!newUiEvent.fullDate) {
        continue;
      }

      const matchingUiPages = this._scrollManager.pages.filter((page) => page.key === newUiEvent.fullDate),
        targetUiPage = matchingUiPages.length === 1 ? matchingUiPages[0] : null;

      if (targetUiPage) {
        newUiEvent.setPage(targetUiPage);
        targetUiPage.insertItems([newUiEvent]);
      } else {
        const page = new ScrollPage(createElement('ul', 'DebateEvents-list List'), newUiEvent.fullDate, [newUiEvent]);

        page.init();

        this._scrollManager.insertPage(page);
        this._addHourLabel(page);
      }

      uiEventsTable[newUiEvent.id] = newUiEvent;
    }
  },

  _removeUiItems: function (currentEvents, nextEvents) {
    const uiEventsTable = this._uiEventsTable;

    for (let event of currentEvents) {
      const id = event.eventType + event.eventStart;
      const remove = !nextEvents.find((current) => current.eventType + current.eventStart === id);

      if (!remove || !uiEventsTable[id]) {
        continue;
      }

      const matchingUiPages = this._scrollManager.pages.filter((page) => page.key === uiEventsTable[id].fullDate),
        targetUiPage = matchingUiPages.length === 1 ? matchingUiPages[0] : null;

      if (targetUiPage) {
        targetUiPage.removeItem(uiEventsTable[id]);
        delete this._uiEventsTable[id];

        // page is empty
        if (!targetUiPage.items.length) {
          this._removeHourLabel(targetUiPage);
          this._scrollManager.removePage(targetUiPage);
        }
      }
    }
  },

  /**
   * Handle resize.
   * @private
   */
  _handleResize: function () {
    if (import.meta.env.DEV) {
      console.warn('handle resize');
    }

    this._scrollHourLabelIntoView(this._hours.hours.querySelector('.HourSelected'));
    this._setHourScrollButtons();
  },

  /**
   * Handle clicks on hour scroll buttons.
   * @param {Event} event
   * @private
   */
  _handleHourScrollButtonClick: function (event) {
    const { sub, hours } = this._hours;

    if (!sub || !hours) return;

    const direction = parseInt(event.target.getAttribute('data-direction'), 10),
      subWidth = sub.offsetWidth,
      hoursWidth = hours.offsetWidth,
      leftPos = parseInt(hours.style.left, 0) || 0,
      minLeftPos = subWidth - hoursWidth;

    let newLeftPos = Math.max(leftPos - direction * subWidth, minLeftPos);

    newLeftPos = Math.min(newLeftPos, 0);

    hours.style.left = newLeftPos + 'px';

    this._setHourScrollButtons();
  },

  /**
   * Handle click on an hour label.
   * @param {Event} event
   * @private
   */
  _handleHourClick: function (event) {
    const key = event.target.getAttribute('data-key'),
      pages = this._scrollManager.pages;

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      if (page.key === key) {
        this._scrollManager.setActivePage(page);

        this._lastUserSelectedPage = {
          time: Date.now(),
          page: page,
        };

        break;
      }
    }

    this._setActiveHourLabel();
  },

  /**
   * Handle click on an hour label.
   * @param {Event} event
   * @private
   */
  _handleHourKeyPress: function (event) {
    const key = event.target.getAttribute('data-key'),
      pages = this._scrollManager.pages;
    const enterOrSpace = event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar' || event.which === 13 || event.which === 32;

    if (enterOrSpace) {
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];

        if (page.key === key) {
          this._scrollManager.setActivePage(page);

          this._lastUserSelectedPage = {
            time: Date.now(),
            page: page,
          };

          break;
        }
      }
    }

    this._setActiveHourLabel();
  },

  /**
   * Handle click on events.
   * @param {Event} event
   * @private
   */
  _handleEventClick: function (event) {
    const action = this._getClickAction(event);

    if (!action?.id) {
      return;
    }

    switch (action.action) {
      case 'set-active':
        this._setActive(action);
        break;

      case 'share':
        this._shareEvent(action);
        break;

      case 'settings':
        this._openSettings(action);
        break;

      case 'open-panel':
        this._openPanel(action);

        return;

      default:
        return;
    }
  },

  /**
   * Handle click on events.
   * @param {Event} event
   * @private
   */
  _handleEventKeyPressClick: function (event) {
    const action = this._getClickAction(event);
    const enterOrSpace = event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar' || event.which === 13 || event.which === 32;

    if (enterOrSpace) {
      event.preventDefault();

      if (!action?.id) {
        return;
      }

      switch (action.action) {
        case 'set-active':
          this._setActive(action);
          break;

        case 'share':
          this._shareEvent(action);
          break;

        case 'settings':
          this._openSettings(action);
          break;

        case 'open-panel':
          this._openPanel(action);

          return;

        default:
          return;
      }
    }
  },

  /**
   * Set active event as a result of a click on an event.
   * @param {Object} action
   * @private
   */
  _setActive: function (action) {
    const uiEvent = this._uiEventsTable[action.id] || null;

    // If event is already active, we ignore the click.
    if (!uiEvent) {
      return;
    }

    this._lastUserSelectedEvent = {
      time: Date.now(),
      event: uiEvent,
    };

    const debateEvent = uiEvent.event,
      eventType = debateEvent.eventType,
      eventStart = debateEvent.eventStart,
      { getService } = this.context,
      videoService = getService('video-sync');

    this._setSeekEvent(eventType + eventStart);
    videoService.seekToMoment(date.fromISO(eventStart), true);
  },

  /**
   * Handle click on event share button.
   * @param {Object} action
   * @private
   */
  _shareEvent(action) {
    const uiEvent = this._uiEventsTable[action.id] || null;

    if (!uiEvent) {
      return;
    }

    const speakerEvent = uiEvent.event,
      eventType = speakerEvent.eventType,
      eventStart = speakerEvent.eventStart,
      rect = action.element.getBoundingClientRect(),
      result = {
        type: 'speaker_event',
        left: rect.left,
        top: rect.top,
        eventData: {
          type: eventType,
          start: eventStart,
        },
      };

    structure.cursor(['ui']).update('share', () => immutable.fromJS(result));
  },

  /**
   * Handle click on politician link inside event.
   * @param {Object} action
   * @private
   */
  _openPanel: function (action) {
    action.event.preventDefault();

    this.context.getService('info-panel').show('PoliticianPanel', {
      politicianId: action.id,
      initialHeadingLevel: 2,
    });
  },

  /**
   * Open the settings menu
   * @param action
   * @private
   */
  _openSettings: function (action) {
    const uiEvent = this._uiEventsTable[action.id] || null;

    if (!uiEvent) {
      return;
    }

    this.setState({
      settingsOpen: true,
      settingsAnchor: action.element,
      settingsFragmentTitle: action.element.getAttribute('data-fragment-title'),
      settingsEvent: uiEvent.event,
      settingsEventId: action.id,
    });
  },

  /**
   * Get click action.
   * @param {Event} event
   * @returns {Object|null}
   * @private
   */
  _getClickAction: function (event) {
    let element = event.target.nodeType === 3 ? event.target.parentNode : event.target;

    while (element && element.nodeType === 1) {
      const action = element.getAttribute('data-action') || null;

      if (action) {
        const id = element.getAttribute('data-id') || null;

        return {
          action,
          id,
          element,
          event,
        };
      }

      element = element.parentNode;
    }

    return null;
  },

  /**
   * Set seek event.
   * @param {Object} event
   * @private
   */
  _setSeekEvent: function (event) {
    const update = {
      disableTime: Date.now() + 5000,
      event: event,
    };

    this.context.getCursor(['ui', 'seekEvent']).update(() => update);
  },

  /**
   * Get events list from current or next props.
   * @param {Object} props
   * @returns {Array}
   * @private
   */
  _getEventsList: function (props) {
    const video = this.context.getService('video'),
      player = video.getPlayer(),
      pdt = props.pdt.deref(),
      ended = props.debate.has('endedAt') && !this.wasLive,
      events = props.debate.has('events') ? props.debate.get('events').toJS() : [],
      isStarted = player && player.seekable.length > 0,
      seekEvent = props.seekEvent.deref();

    if (ended || !isStarted || !pdt) {
      return events;
    }

    if (seekEvent && seekEvent.disableTime > Date.now()) {
      const targetEvent = events.findIndex((event) => seekEvent.event === `${event.eventType}${event.eventStart}`);

      return events.slice(targetEvent);
    }

    return events.filter((event) => moment(pdt).diff(event.eventStart) >= 0);
  },

  /**
   * Get currently active event.
   * @param props
   * @returns {String|null}
   * @private
   */
  _getActiveEvent: function (props) {
    const seekEvent = props.seekEvent.deref(),
      activeEvent = props.activeEvent.deref();

    if (this.state.settingsOpen) {
      return this.state.settingsEventId;
    }

    if (seekEvent && seekEvent.disableTime > Date.now()) {
      return seekEvent.event;
    }

    return activeEvent || null;
  },

  /**
   * Get active UI event.
   * @param props
   * @returns {null}
   * @private
   */
  _getActiveUiEvent: function (props) {
    const activeEvent = this._getActiveEvent(props);

    if (!activeEvent || typeof activeEvent !== 'string') {
      return null;
    }

    return Object.prototype.hasOwnProperty.call(this._uiEventsTable, activeEvent) ? this._uiEventsTable[activeEvent] : null;
  },

  /**
   * Set active UI event.
   * @param {Object} props
   * @private
   */
  _setActiveUiEvent: function (props) {
    const previousActiveEvent = this._activeUiEvent;

    // De-activate former active event.
    if (this._activeUiEvent) {
      this._activeUiEvent.setActive(false);
    }

    this._activeUiEvent = this._getActiveUiEvent(props);

    if (import.meta.env.DEV) {
      console.warn('new active ui event: ', this._activeUiEvent);
    }

    if (!this._activeUiEvent) {
      this._setActiveHourLabel();

      return;
    }

    // Activate new active event.
    this._activeUiEvent.setActive(true);

    // Scroll to item if page of active item is currently activated.
    if (this._scrollManager.activePage === this._activeUiEvent.page) {
      if (this._canAutoScrollToEvent(this._activeUiEvent)) {
        this._scrollManager.scrollTo(this._activeUiEvent, true);
      }

      // Otherwise switch page.
    } else if (this._canAutoSwitchPage(previousActiveEvent, this._activeUiEvent)) {
      this._scrollManager.setActivePage(this._activeUiEvent.page);
    }

    // Set active hour.
    this._setActiveHourLabel();
  },

  /**
   * Check if we can auto scroll to new active event.
   * @returns {Boolean}
   * @private
   */
  _canAutoScrollToEvent: function () {
    // If user is currently scrolling we do not want to disturb him or her ..
    if (this._scrollManager.isUserScrolling()) {
      return false;
    }

    // If user did not click on any item in the list yet, we can safely scroll.
    if (!this._lastUserSelectedEvent) {
      return true;
    }

    // If user clicked on an event in the list within the last second, the new active event is the one that has been selected by the user.
    // It is most likely already in the view port.
    return Date.now() - this._lastUserSelectedEvent.time > 1000;
  },

  /**
   * Check if we can automatically change the page.
   * @returns {Boolean}
   * @private
   */
  _canAutoSwitchPage: function () {
    // If user is currently scrolling we do not want to disturb him or her ..
    if (this._scrollManager.isUserScrolling()) {
      return false;
    }

    if (!this._lastUserSelectedPage) {
      return true;
    }

    // If user has selected a different page in the last 5 seconds, auto switching pages would probably be disturbing.
    return Date.now() - this._lastUserSelectedPage.time > 5000;
  },

  /**
   * Create hour labels.
   * @private
   */
  _createHourLabels: function () {
    const hoursContainer = this.hoursRef.current,
      pages = this._scrollManager.pages;

    for (let page of pages) {
      const key = page.key,
        hour = parseInt(key.split('T')[1], 10) + ':00',
        label = `${hour}`,
        element = createElement('div', 'DebateEvents-hour', { 'data-key': key, tabIndex: 0 }, [document.createTextNode(label)]);

      hoursContainer.appendChild(element);
      element.addEventListener('click', this._handleHourClick);
      element.addEventListener('keypress', this._handleHourKeyPress);
    }
  },

  /**
   * Add a new hour label (possible during live events).
   * @param {ScrollPage} page
   * @private
   */
  _addHourLabel: function (page) {
    const hoursContainer = this.hoursRef.current,
      key = page.key,
      hour = parseInt(key.split('T')[1], 10) + ':00',
      label = `${hour}`,
      element = createElement('div', 'DebateEvents-hour', { 'data-key': key }, [document.createTextNode(label)]);

    hoursContainer.appendChild(element);
    element.addEventListener('click', this._handleHourClick);
    element.addEventListener('keypress', this._handleHourKeyPress);

    this._setActiveHourLabel();
  },

  /**
   * Remove hour label
   * @param page
   * @private
   */
  _removeHourLabel: function (page) {
    const container = this.hoursRef.current,
      element = container.querySelector(`[data-key="${page.key}"]`);

    if (container && element) {
      container.removeChild(element);
    }

    this._setActiveHourLabel();
  },

  /**
   * Set active hour label.
   * @private
   */
  _setActiveHourLabel: function () {
    const hoursContainer = this.hoursRef.current,
      activePage = this._scrollManager.activePage,
      activeHourLabel = activePage ? activePage.key : null,
      activeItemHourLabel = this._activeUiEvent ? this._activeUiEvent.page.key : null,
      labels = hoursContainer.querySelectorAll('.DebateEvents-hour');

    let selectedLabel = null;

    for (let i = 0; i < labels.length; i++) {
      const label = labels[i],
        key = label.getAttribute('data-key');

      if (activeHourLabel && activeHourLabel === key) {
        label.classList.add('HourSelected');
        selectedLabel = label;
      } else {
        label.classList.remove('HourSelected');
      }

      if (activeItemHourLabel && activeItemHourLabel === key) {
        label.classList.add('HourActiveItem');
      } else {
        label.classList.remove('HourActiveItem');
      }
    }

    this._hours.main.style.visibility = labels.length > 1 ? 'inherit' : 'hidden';

    this._scrollHourLabelIntoView(selectedLabel);
    this._setHourScrollButtons();
  },

  /**
   * Scroll active hour label into view.
   * @param {HTMLElement} hourLabel
   * @private
   */
  _scrollHourLabelIntoView: function (hourLabel) {
    const { sub, hours } = this._hours;

    if (!sub || !hours) return;

    if (!hourLabel) {
      hours.style.left = '0px';

      return;
    }

    const subWidth = sub.offsetWidth,
      hoursWidth = hours.offsetWidth;

    // Hours fit in container.
    if (hoursWidth < subWidth) {
      hours.style.left = '0px';
      hours.classList.add('DebateEvents-hours--fits');

      return;
    }

    const minLeftPos = subWidth - hoursWidth,
      targetLeftPos = -(hourLabel.offsetLeft + hourLabel.offsetWidth / 2 - subWidth / 2);

    let newLeftPos = Math.max(targetLeftPos, minLeftPos);

    newLeftPos = Math.min(newLeftPos, 0);

    hours.classList.remove('DebateEvents-hours--fits');
    hours.style.left = newLeftPos + 'px';
  },

  /**
   * Set hour scroll buttons.
   * @private
   */
  _setHourScrollButtons() {
    const { sub, hours, left, right } = this._hours;

    if (!sub || !hours) return;

    const subWidth = sub.offsetWidth,
      hoursWidth = hours.offsetWidth,
      leftPos = parseInt(hours.style.left, 0) || 0,
      minLeftPos = subWidth - hoursWidth;

    left.style.display = leftPos < 0 ? 'block' : 'none';
    right.style.display = leftPos > minLeftPos ? 'block' : 'none';
  },

  /**
   * Find containers for events.
   * @returns {Object}
   * @private
   */
  _findContainers: function () {
    const mainContainer = this.mainRef.current,
      // Scroll container is main container on mobile and nested container on desktop (see SmartScrollView.js).
      scrollContainer = mainContainer.hasAttribute('data-scroll-body') ? mainContainer : mainContainer.querySelector('[data-scroll-body]'),
      pagesContainer = this.eventsContainerRef.current;

    return {
      scrollContainer,
      pagesContainer,
    };
  },
};

/**
 * Render method
 * @returns {React.Component}
 */
const render = function () {
  const debate = this.props.debate,
    dataQuality = debate.get('dataQuality', 'live'),
    style = { left: '0px' };

  return (
    <main role="main" className="DebateEvents-main" ref={this.mainRef}>
      <div className="DebateEvents-hoursMain" ref={this.hoursMainRef}>
        <div className="DebateEvents-hoursSub" ref={this.hoursSubRef}>
          <div className="DebateEvents-hours" style={style} ref={this.hoursRef} />
        </div>
        <Button className="DebateEvents-hourButton ScrollLeft" data-direction="-1" onClick={this._handleHourScrollButtonClick}>
          <Icon name="caretLeft" className="Button-icon" width="40" height="40" />
        </Button>
        <Button className="DebateEvents-hourButton ScrollRight" data-direction="1" onClick={this._handleHourScrollButtonClick}>
          <Icon name="caretLeft" className="Button-icon" width="40" height="40" />
        </Button>
      </div>
      <ScrollView externalWheelHandling={true}>
        <div className="Main-content Content Main-content--full">
          <section className="Content-section Section">
            <div className="LiveDataDisclaimer-container">
              <div className="u-width600 u-horizontalCenter">{dataQuality === 'live' ? <LiveDataDisclaimer /> : null}</div>
            </div>
            <div ref={this.eventsContainerRef} />
          </section>
        </div>
      </ScrollView>
      <SpeakerEventPopover
        open={this.state.settingsOpen}
        anchor={this.state.settingsAnchor}
        initiator={this.state.settingsAnchor}
        transformOrigin={{ horizontal: 'auto', vertical: 'auto' }}
        event={this.state.settingsEvent}
        debate={this.props.debate}
        fragmentTitle={this.state.settingsFragmentTitle}
        onClose={() => this.setState({ settingsOpen: false })}
      />
    </main>
  );
};

const DebateSpeakersComponent = component('DebateSpeakersComponent', definition, render);

export default observer(
  structure,
  {
    video: ['ui', 'video'],
    seekEvent: ['ui', 'seekEvent'],
    activeEvent: ['ui', 'sync', 'current', 'activeEvent'],
    pdt: ['ui', 'sync', 'pdt'],
    widthReference: ['ui', 'width'],
  },
  DebateSpeakersComponent,
);
