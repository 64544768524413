import React from 'react';

import createIcon from './createIcon';

export default createIcon(
  '0 0 24 24',
  <g>
    <path d="M21,3H3C1.9,3,1,3.9,1,5v3h2V5h18v14h-7v2h7c1.1,0,2-0.9,2-2V5C23,3.9,22.1,3,21,3z" />
    <path className="bar3" d="M1,10v2c5,0,9,4,9,9h2C12,14.9,7.1,10,1,10z" />
    <path className="bar2" d="M1,14v2c2.8,0,5,2.2,5,5h2C8,17.1,4.9,14,1,14z" />
    <path className="bar1" d="M1,18v3h3C4,19.3,2.7,18,1,18z" />
  </g>,
);
