const /**
   * Replaces data at keyPath
   * @param  {Array}  keyPath The keyPath to the value to update
   * @return {Function}       The replacer function
   */
  replace = (keyPath) => (eventData) => [{ keyPath, operation: 'set', value: eventData }],
  /**
   * Builds the actual updater function
   * @param  {Array}    updates Other updates to add to the diff
   * @return {Function}         The updater function
   */
  update = (updates) => (eventData) => [...updates, { keyPath: ['events'], operation: 'prepend', value: [eventData] }],
  /**
   * Updates value at keyPath
   * @param  {Array}    keyPath The keyPath to the value to update
   * @param  {String}   key     The key of the property on eventData to feed to update
   * @return {Function}         The updater function
   */
  updateProp = (keyPath, key) => (eventData) => update([{ keyPath, operation: 'updateIn', value: eventData[key] }])(eventData),
  resolvers = {
    /**
     * Resolves the "chairman_change" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveChairmanChange: update([]),

    /**
     * Resolves the "chairman" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveChairman: update([]),

    /**
     * Resolves the "chairman_selection" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveChairmanSelection: update([]),

    /**
     * Resolves the "debate_end" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveDebateEnd: updateProp(['endedAt'], 'eventStart'),

    /**
     * Resolves the "debate_start" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveDebateStart: updateProp(['startedAt'], 'eventStart'),

    /**
     * Resolves the "interrupter" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveInterrupter: update([]),

    /**
     * Resolves the "refresh" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveRefresh: replace([]),

    /**
     * Resolves the "speaker" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveSpeaker: update([]),

    /**
     * Resolves the "speaker_motion_change" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveSpeakerMotionChange: update([]),

    /**
     * Resolves the "speaker_motion_present" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveSpeakerMotionPresent: update([]),

    /**
     * Resolves the "speaker_motion_withdraw" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveSpeakerMotionWithdraw: update([]),

    /**
     * Resolves the "speaker_nonverbal" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveSpeakerNonverbal: update([]),

    /**
     * Resolves the "suspended" event
     * @param  {Object} eventData The received live data
     * @return {Array}            The resulting diff
     */
    resolveSuspended: update([]),
  };

export default resolvers;
