import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';

const definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
    },
  },
  render = function (props) {
    return <img alt="" {...props} />;
  };

export default component('Image', definition, render);
