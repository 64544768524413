import React from 'react';
import classNames from 'classnames';
import { withDefaults } from 'omniscient';

import ExternalLink from '../../components/ExternalLink/ExternalLink';
import Icon from '../../components/Icon/Icon';

const component = withDefaults({ cursorField: 'cursor' }),
  /**
   * Capitalizes the first letter of a string
   * @param  {String} string Input
   * @return {String}        The output string
   */
  ucfirst = ([c, ...cs]) => [c.toUpperCase(), ...cs].join(''),
  /**
   * Renders the DebateDocumentListItem
   * @param  {Cursor}          cursor The document cursor
   * @return {React.Component}        An instance of DebateDocumentListItem
   */
  render = function (cursor) {
    const title = cursor.get('name', 'Geen titel beschikbaar'),
      description = cursor.get('description', 'Geen omschrijving beschikbaar'),
      downloadLink = cursor.get('url'),
      linkClassName = classNames('Button Button--share u-flex', {
        'u-invisible': !downloadLink,
      });

    return (
      <li className="List-item Document u-flex u-row">
        <div className="Document-info">
          <header className="Document-header">
            <h2 className="Document-title">{ucfirst(title)}</h2>
          </header>
          <p className="Document-description">{ucfirst(description)}</p>
        </div>
        <ExternalLink className={linkClassName} href={downloadLink} aria-label={`Download ${title}`}>
          <Icon name="download" aria-hidden="true" />
        </ExternalLink>
      </li>
    );
  };

export default component('DebateDocumentsListItem', render);
