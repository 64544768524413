export const DEBATE_TYPE_SUBSCRIPTIONS = [
  'Stemmingen',
  'Regeling van werkzaamheden',
  'Vragenuur',
  'Hamerstukken',
  'Beëdiging',
  'Herdenking',
  'Mededelingen',
  'Hoorzitting',
  'Gesprek',
];

export const SUBSCRIPTION_TYPE = {
  DEBATE: 'debate',
  DEBATE_TYPE: 'debateType',
  POLITICIAN: 'politician',
  SUBJECT: 'subject',
};
