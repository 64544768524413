import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import component from 'omniscient';

/**
 * Get coords object from native touch event.
 *
 * @param {Event} event
 * @returns {{x: (number), y: (number)}}
 */
const getCoords = function (event) {
  const originalEvent = event.originalEvent || event;
  const touches = originalEvent.touches?.length ? originalEvent.touches : [originalEvent];
  const e = originalEvent.changedTouches?.[0] || touches[0];

  // mouse event
  if (event.type.indexOf('touch') === -1) {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }

  // touch event
  return {
    x: e.clientX,
    y: e.clientY,
  };
};

const componentDefinition = {
  volumeSliderRef: createRef(),

  getInitialState: () => ({
    dragging: false,
    railWidth: 0,
    volume: 1,
    startCoords: null,
  }),

  componentDidMount: function () {
    const volumeSlider = this.volumeSliderRef.current;
    const { getService } = this.context;
    const videoService = getService('video');

    volumeSlider?.addEventListener('mousedown', this.handleStartInteraction);
    volumeSlider?.addEventListener('touchstart', this.handleStartInteraction);
    volumeSlider?.addEventListener('mouseleave', this.handleEndInteraction);

    window.addEventListener('resize', this.handleResize);

    videoService.on('volumechange', this.handleVolumeChange);
    videoService.on('readystatechange', this.handleReadyStateChange);

    this.state.volume = videoService.getMuted() ? 0 : videoService.getVolume() || 1;

    if (this.props.onVolume) {
      this.props.onVolume(this.state.volume);
    }

    this.handleResize();
  },

  componentWillUnmount: function () {
    const volumeSlider = this.volumeSliderRef.current,
      { getService } = this.context,
      videoService = getService('video');

    videoService.off('volumechange', this.handleVolumeChange);
    videoService.off('readystatechange', this.handleReadyStateChange);

    volumeSlider?.removeEventListener('mousedown', this.handleStartInteraction);
    volumeSlider?.removeEventListener('touchstart', this.handleStartInteraction);
    volumeSlider?.removeEventListener('mouseleave', this.handleEndInteraction);

    window.removeEventListener('resize', this.handleResize);
  },

  handleResize: function () {
    const volumeSlider = this.volumeSliderRef.current;
    const rail = volumeSlider.querySelector('.VolumeSlider-rail');

    if (!rail) {
      return;
    }

    const offsetWidth = rail.offsetWidth;

    if (this.state.railWidth !== offsetWidth) {
      this.setState({ railWidth: offsetWidth });
    }
  },

  handleVolumeChange: function (event) {
    const { getService } = this.context,
      videoService = getService('video');

    if (this.state.dragging) {
      return;
    }

    this.setState(
      {
        volume: videoService.getMuted() ? 0 : event.volume,
      },
      () => {
        if (this.props.onVolume) {
          this.props.onVolume(this.state.volume);
        }
      },
    );
  },

  handleReadyStateChange: function () {
    const { getService } = this.context,
      videoService = getService('video');

    this.setState(
      {
        volume: videoService.getMuted() ? 0 : videoService.getVolume(),
      },
      () => {
        if (this.props.onVolume) {
          this.props.onVolume(this.state.volume);
        }
      },
    );
  },

  handleStartInteraction: function (event) {
    const volumeSlider = this.volumeSliderRef.current;

    if (!volumeSlider) return;

    const coords = getCoords(event),
      leftOffset = Math.min(this.state.railWidth, Math.max(0, coords.x - volumeSlider.offsetLeft)),
      progress = leftOffset / volumeSlider.offsetWidth;

    event.preventDefault();

    this.setState({
      cursorVisible: false,
      dragging: true,
      thumbProgress: progress,
      startCoords: coords,
    });

    if (typeof this.props.onDragStart === 'function') {
      this.props.onDragStart(event, progress);
    }

    if (event.type === 'touchstart') {
      volumeSlider.addEventListener('touchmove', this.handleMoveInteraction);
      volumeSlider.addEventListener('touchend', this.handleEndInteraction);
    } else {
      volumeSlider.addEventListener('mousemove', this.handleMoveInteraction);
      volumeSlider.addEventListener('mouseup', this.handleEndInteraction);
    }
  },

  handleMoveInteraction: function (event) {
    const volumeSlider = this.volumeSliderRef.current;

    if (!volumeSlider) return;

    const { railWidth } = this.state,
      coords = getCoords(event),
      leftOffset = Math.min(railWidth, Math.max(0, coords.x - volumeSlider.offsetLeft)),
      progress = leftOffset / railWidth;

    if (this.state.dragging) {
      this.setState({ volume: progress });
      this.props.onVolume?.(progress);

      const video = this.context.getService('video');

      if (video.getMuted()) {
        video.setMuted(false);
      }

      this.context.getService('video').setVolume(progress);

      if (typeof this.props.onDragMove === 'function') {
        this.props.onDragMove(event, progress);
      }
    }
  },

  handleEndInteraction: function (event) {
    const volumeSlider = this.volumeSliderRef.current;

    if (!volumeSlider) return;

    const { dragging } = this.state,
      { getService } = this.context,
      coords = getCoords(event),
      volumeSliderWidth = volumeSlider.offsetWidth,
      videoService = getService('video'),
      leftOffset = Math.min(volumeSliderWidth, Math.max(0, coords.x - volumeSlider.offsetLeft)),
      progress = leftOffset / volumeSliderWidth;

    if (dragging) {
      if (event.type.indexOf('touch') === 0) {
        volumeSlider.removeEventListener('touchmove', this.handleMoveInteraction);
        volumeSlider.removeEventListener('touchend', this.handleEndInteraction);
      } else {
        volumeSlider.removeEventListener('mouseup', this.handleEndInteraction);
        volumeSlider.removeEventListener('mousemove', this.handleMoveInteraction);
      }

      // when the user clicks the rail, we don't receive a drag/move event
      this.setState({ volume: progress });

      videoService.setVolume(progress);

      this.props.onVolume?.(progress);

      if (videoService.getMuted()) {
        videoService.setMuted(false);
      }

      if (typeof this.props.onDragEnd === 'function') {
        this.props.onDragEnd(event, progress);
      }
    }

    this.setState({ dragging: false });
  },

  shouldComponentUpdate: function () {
    return true;
  },

  getThumbStyles() {
    const { volume, railWidth } = this.state;
    const left = volume * railWidth;

    return {
      left: `${left}px`,
    };
  },

  getRailStyles() {
    const { volume, railWidth } = this.state;
    const width = volume * railWidth;

    return {
      width: `${width}px`,
    };
  },

  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  },
};

export default component('VolumeSlider', componentDefinition, function ({ className, style }) {
  const rootClassName = classNames('VolumeSlider', className, {
    'VolumeSlider--disabled': !this.state.played,
  });

  return (
    <div className={rootClassName} style={style} ref={this.volumeSliderRef}>
      <div className="VolumeSlider-rail">
        <div className="VolumeSlider-progress" style={this.getRailStyles()} />
        <div className="VolumeSlider-thumb" style={this.getThumbStyles()} />
      </div>
    </div>
  );
});
