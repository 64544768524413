import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import { warning } from '../../utils/logger';
import passive from '../../utils/passive';
import Portal from '../Modal/Portal';

function getOffsetTop(rect, vertical) {
  let offset = 0;

  if (typeof vertical === 'number') {
    offset = vertical;
  } else if (vertical === 'center') {
    offset = rect.height / 2;
  } else if (vertical === 'bottom') {
    offset = rect.height;
  }

  return offset;
}

function getOffsetLeft(rect, horizontal) {
  let offset = 0;

  if (typeof horizontal === 'number') {
    offset = horizontal;
  } else if (horizontal === 'center') {
    offset = rect.width / 2;
  } else if (horizontal === 'right') {
    offset = rect.width;
  }

  return offset;
}

export default class Tooltip extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    anchorOffset: PropTypes.shape({
      horizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    transformOrigin: PropTypes.shape({
      horizontal: PropTypes.oneOf(['auto', 'left', 'center', 'right']),
      vertical: PropTypes.oneOf(['auto', 'top', 'center', 'bottom']),
    }),
  };

  static defaultProps = {
    disabled: false,
    anchorOffset: {
      horizontal: 'center',
      vertical: 'center',
    },
    transformOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
  };

  anchorRef = createRef();
  tooltipRef = createRef();

  state = {
    visible: false,
  };

  componentDidMount() {
    this.bindEventListeners();
  }

  componentWillUnmount() {
    const domElement = this.anchorRef.current;

    if (!domElement) {
      return;
    }

    window.removeEventListener('resize', this.handleResize);

    domElement.removeEventListener('mouseover', this.handleMouseOver);
    domElement.removeEventListener('mouseleave', this.handleMouseLeave);
    domElement.removeEventListener('mouseup', this.handleMouseUp);

    domElement.removeEventListener('touchstart', this.handleTouchStart);
    domElement.removeEventListener('touchend', this.handleTouchEnd);
    domElement.removeEventListener('touchcancel', this.handleTouchEnd);
  }

  componentDidUpdate() {
    this.handleResize();
  }

  getAnchorOffset() {
    const anchor = this.anchorRef.current;
    const { anchorOffset } = this.props;

    if (!anchor) {
      return;
    }

    const anchorRect = anchor.getBoundingClientRect();
    const { horizontal, vertical } = anchorOffset;

    return {
      top: anchorRect.top + getOffsetTop(anchorRect, vertical || 'center'),
      left: anchorRect.left + getOffsetLeft(anchorRect, horizontal || 'center'),
    };
  }

  handleMouseOver = () => {
    if (!this.state.visible && !this.props.disabled && !this.touchTimer) {
      this.setState({
        visible: true,
      });
    }
  };

  handleMouseLeave = () => {
    this.setState({
      visible: false,
    });
  };

  handleMouseUp = () => {
    this.setState({
      visible: false,
    });
  };

  handleTouchStart = () => {
    clearTimeout(this.touchTimer);
    this.touchTimer = setTimeout(() => {
      this.setState({ visible: true });
    }, 500);
  };

  handleTouchEnd = () => {
    clearTimeout(this.touchTimer);
    this.touchTimer = null;

    if (this.state.visible) {
      this.setState({ visible: false });
    }
  };

  handleResize = debounce(() => {
    const tooltip = this.tooltipRef.current;
    const anchorOffset = this.getAnchorOffset();

    if (!anchorOffset) {
      return;
    }

    let { top, left } = anchorOffset;

    const transformOrigin = this.props.transformOrigin;

    let horizontalOffset = 0;
    let verticalOffset = 0;

    if (transformOrigin) {
      const windowWidth = Math.min(window.outerWidth, window.innerWidth);
      const windowHeight = Math.min(window.outerHeight, window.innerHeight);

      let { horizontal, vertical } = transformOrigin;

      if (horizontal === 'auto') {
        horizontal = left <= windowWidth / 2 ? 'right' : 'left';
      }

      if (vertical === 'auto') {
        vertical = top <= windowHeight / 2 ? 'bottom' : 'top';
      }

      if (!tooltip) {
        return;
      }

      const width = tooltip.clientWidth + 10;
      const height = tooltip.clientHeight + 10;

      if (horizontal === 'left') {
        horizontalOffset = '-100%';
        left = Math.max(Math.min(left, windowWidth), width);
      } else if (horizontal === 'center') {
        horizontalOffset = '-50%';
        left = Math.max(Math.min(left, windowWidth - width / 2), width / 2);
      } else {
        left = Math.max(Math.min(left, windowWidth - width), 0);
      }

      if (vertical === 'top') {
        verticalOffset = '-100%';
        top = Math.max(Math.min(top, windowHeight), height);
      } else if (vertical === 'center') {
        verticalOffset = '-50';
        top = Math.max(Math.min(top, windowHeight - height / 2), height / 2);
      } else {
        top = Math.max(Math.min(top, windowHeight - height), 0);
      }
    }

    if (tooltip) {
      tooltip.style.top = `${Math.round(top)}px`;
      tooltip.style.left = `${Math.round(left)}px`;

      if (verticalOffset || horizontalOffset) {
        tooltip.style.transform = `translate(${horizontalOffset}, ${verticalOffset})`;
      }
    }
  }, 44);

  bindEventListeners() {
    const domElement = this.anchorRef.current;

    if (!domElement) {
      return warning('Tooltip could not find the child dom element');
    }

    window.addEventListener('resize', this.handleResize, passive());

    domElement.addEventListener('mouseup', this.handleMouseUp, passive());
    domElement.addEventListener('mouseover', this.handleMouseOver, passive());
    domElement.addEventListener('mouseleave', this.handleMouseLeave, passive());

    domElement.addEventListener('touchstart', this.handleTouchStart, passive());
    domElement.addEventListener('touchend', this.handleTouchEnd, passive());
    domElement.addEventListener('touchcancel', this.handleTouchEnd, passive());
  }

  render() {
    const { visible } = this.state;
    const { disabled } = this.props;
    const hidden = disabled || !visible ? 'true' : 'false';
    const tooltipClassName = classNames('Tooltip', {
      hidden: disabled || !visible,
    });

    return (
      <div style={{ display: 'inline', position: 'relative' }} ref={this.anchorRef}>
        {this.props.children}
        <Portal>
          <div className={tooltipClassName} ref={this.tooltipRef} id={this.props.id} aria-hidden={hidden}>
            {this.props.title}
          </div>
        </Portal>
      </div>
    );
  }
}
