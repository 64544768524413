import classNames from 'classnames';
import moment from 'moment';

import { appendChildren, createElement } from '../../../utils/lib/DOM';

import AbstractScrollItem from './utils/AbstractScrollItem';
import { getEvent, isSupportedEvent } from './DebateEvents';

class DebateEventItem extends AbstractScrollItem {
  /**
   * Check if event can be rendered.
   * @param {Object} event
   * @returns {Boolean}
   */
  static canRender(event) {
    return isSupportedEvent(event);
  }

  /**
   * Constructor.
   * @param {Object} debate
   * @param {Object} event
   * @param {Object} lookup - Key based lookup for actors and parties.
   */
  constructor(debate, event, lookup) {
    super();

    this._debate = debate;
    this._event = event;
    this._lookup = lookup;

    this._date = moment(this._event.eventStart, 'YYYY-MM-DDTHH:mm:ssZ');
    this._fullDate = this._date.isValid() ? this._date.format('YYYY-MM-DDTHH') : '';
    this._hour = this._date.isValid() ? this._date.format('HH') : '';

    this._id = `${event.eventType}${event.eventStart}`;

    this._uiEvent = getEvent({ event, lookup, debate, parentId: this._id });
  }

  /**
   * Id created from event start and event type.
   * @returns {String}
   */
  get id() {
    return this._id;
  }

  /**
   * Event.
   * @returns {Object}
   */
  get event() {
    return this._event;
  }

  /**
   * Event start in YYYY-MM-DDTHH format.
   * @returns {String}
   */
  get fullDate() {
    return this._fullDate;
  }

  /**
   * Event start in HH format.
   * @returns {String}
   */
  get hour() {
    return this._hour;
  }

  /**
   * Is active flag.
   * @returns {Boolean}
   */
  get isActive() {
    return this._active;
  }

  /**
   * Set active or inactive.
   * @param {Boolean} active
   */
  setActive(active) {
    this._active = active;

    if (!this.isRendered) {
      return;
    }

    // Set container class name.
    this._container.classList[active ? 'add' : 'remove']('is-active');

    // Re-render event.
    const description = this._elements.description;

    while (description.hasChildNodes()) {
      description.removeChild(description.lastChild);
    }

    description.appendChild(this._uiEvent.render(this._active));
  }

  /**
   * Render.
   */
  render() {
    this._container = this._createContainer();
    this._elements = this._elements || this._createElements();
  }

  /**
   * Clear.
   */
  clear() {
    super.clear();
    this._uiEvent.clear();
  }

  /**
   * Create container.
   * @returns {HTMLLIElement}
   * @private
   */
  _createContainer() {
    return createElement(
      'li',
      classNames('List-item DebateEventItem', {
        'is-active': this._active,
        'is-playable': true,
      }),
      { 'data-id': this._id, 'data-action': 'set-active', 'aria-labelledby': `label-${this._id}` },
    );
  }

  /**
   * Creat nested elements.
   * @returns {Object}
   * @private
   */
  _createElements() {
    const subContainer = createElement('div', 'List-item-align u-flex u-row');

    const bullet = createElement('div', 'List-bullet u-flex u-center', null, [createElement('div', 'List-bullet-icon u-centerSelf')]);
    bullet.setAttribute('role', 'button');
    bullet.setAttribute('tabindex', 0);
    bullet.setAttribute('aria-label', 'Spreekmoment afspelen');
    bullet.setAttribute('aria-describedby', `label-${this._id}`);

    const time = createElement('time', 'Time Time--time DebateEvents-time u-centerSelf u-flex-child', null, [
      document.createTextNode(this._date.format('HH:mm')),
    ]);

    const description = createElement('span', 'DebateEvents-description u-flex-child u-shrink u-centerSelf');

    appendChildren(description, [this._uiEvent.render(this._active)]);
    appendChildren(subContainer, [bullet, time, description]);
    appendChildren(this._container, [subContainer]);

    return {
      subContainer,
      time,
      description,
    };
  }
}

/**
 * Public exports.
 * @type {DebateEventItem}
 */
export default DebateEventItem;
