import moment from 'moment';

const /**
   * Constructs VideoSyncService
   * @param  {Reference} reference  Reference to the ui.sync cursor
   * @return {Object}               Use to start and stop the service
   */
  VideoSyncServiceFactory = function (reference) {
    let interval,
      player,
      streamSyncMode = 'pdt',
      streamDelay = 0,
      videoStartMoment,
      videoEndMoment,
      lastSeek,
      goToPosition = false,
      debateStartedAt,
      eventListeners = [];

    function pdtFromUrl(url) {
      const handleLoad = function () {
        if (this.readyState === 4) {
          const text = this.responseText;
          const firstPdt = text.match(/#EXT-X-PROGRAM-DATE-TIME:(.+)/);

          if (!firstPdt?.[1]) {
            return;
          }

          videoStartMoment = moment(firstPdt[1]);
          dispatch({ type: 'change', videoStartMoment, videoEndMoment });
        }
      };

      if (url) {
        const request = new XMLHttpRequest();

        request.onload = handleLoad;
        request.open('GET', url);
        request.send(null);
      }
    }

    function updatePdt(pdt) {
      updateStartAndEndMoment();
      reference.cursor(['ui', 'sync']).update('pdt', () => pdt);
    }

    function getCurrentProgramDateTime() {
      const pdtFromDate = moment('2019-09-02'); // We can trust player pdt from this date

      if (!player) return;

      // Fallback for when pdt is unavailable (i.e. debates older than 2019-09-02)
      if (!player.currentProgramDateTime || moment(player.currentProgramDateTime).isBefore(pdtFromDate)) {
        return moment(debateStartedAt).add(player.currentTime, 's').format();
      }

      return player.currentProgramDateTime;
    }

    function handleTimeUpdate() {
      const currentProgramTime = getCurrentProgramDateTime();

      if (goToPosition !== false) {
        player.currentTime = goToPosition;
        goToPosition = false;
      }

      if (!currentProgramTime || player.paused || player.waiting) {
        return;
      }

      // use pdt from video
      if (streamSyncMode === 'pdt') {
        return updatePdt(moment(currentProgramTime).subtract(streamDelay, 'ms').format());
      }

      // use currentTime and fixed stream delay
      updatePdt(moment().subtract(streamDelay, 'ms').format());
    }

    function updateStartAndEndMoment() {
      const currentProgramTime = getCurrentProgramDateTime();

      if (player && currentProgramTime) {
        const currentTime = player.currentTime === Infinity ? 0 : player.currentTime;
        const prevVideoStartMoment = videoStartMoment;

        videoStartMoment = moment(currentProgramTime).subtract(currentTime, 's');

        if (player.seekable.length) {
          videoEndMoment = moment(currentProgramTime).add(player.seekable.end(0) - currentTime, 's');
        }

        if (!prevVideoStartMoment || videoStartMoment.diff(prevVideoStartMoment, 's') > 0) {
          dispatch({ type: 'change', videoStartMoment, videoEndMoment });
        }
      }
    }

    function setInstance(instance) {
      removeInstance();

      player = instance;

      if (player) {
        updateStartAndEndMoment();
        player.addEventListener('playing', updateStartAndEndMoment);
        player.addEventListener('canplay', handleCanPlay);
        player.addEventListener('durationchange', handleDuration);
      }
    }

    function handleCanPlay() {
      player.removeEventListener('canplay', handleCanPlay);

      updateStartAndEndMoment();

      if (lastSeek) {
        seekToMoment(lastSeek.moment, lastSeek.play);
      }
    }

    function handleDuration() {
      updateStartAndEndMoment();

      if (lastSeek) {
        seekToMoment(lastSeek.moment, lastSeek.play);
      }
    }

    function removeInstance() {
      if (player) {
        player.removeEventListener('playing', updateStartAndEndMoment);
        player.removeEventListener('canplay', handleCanPlay);
        player.removeEventListener('durationchange', handleDuration);

        player = null;
        lastSeek = null;
      }
    }

    /**
     * Starts updating delay from player instance
     * @param {String} mode
     * @param {Number} newStreamDelay
     */
    function start(mode, newStreamDelay, debateStartTime) {
      // The param could be pdt or id3 -> now fixed on pdt.

      stop();

      streamDelay = newStreamDelay || 0;
      streamSyncMode = mode;
      debateStartedAt = debateStartTime;

      if (import.meta.env.DEV) {
        console.log('videoSyncService mode ->', mode);
        console.log('videoSyncService delay ->', streamDelay);
      }

      videoStartMoment = undefined;
      videoEndMoment = undefined;

      handleTimeUpdate();

      clearInterval(interval);
      interval = setInterval(handleTimeUpdate, 1000);
    }

    /**
     * Stops updating delay if player is already bound
     */
    function stop() {
      if (interval) {
        clearInterval(interval);
      }

      videoStartMoment = moment();
      videoEndMoment = moment();
    }

    function seekToMoment(aMoment, play) {
      const pdt = getCurrentProgramDateTime();

      if (!pdt) {
        lastSeek = { moment: aMoment, play: play || false };

        // THEOplayer 3.6.0+ doesn't return a currentProgramDateTime when ready. So we need to start it first to get
        // the pdt update.
        if (player && play) player.play();

        return false;
      }

      lastSeek = null;

      const currentTime = player.currentTime === Infinity ? 0 : player.currentTime;

      performSeek(currentTime + Number(aMoment.diff(pdt, 's')), play);
    }

    function performSeek(targetTime, play) {
      if (play) {
        player.play();
        reference.cursor(['ui', 'video']).update('playRequested', () => true);

        // add a small delay, without this delay the player will never start
        setTimeout(() => {
          if (player) player.currentTime = targetTime;
        }, 200);
      } else {
        goToPosition = targetTime;
      }
    }

    function getVideoStartDate() {
      return videoStartMoment;
    }

    function canSeekToMoment(aMoment) {
      return aMoment.isAfter(videoStartMoment) && aMoment.isBefore(videoEndMoment);
    }

    function dispatch(event) {
      eventListeners.filter((listener) => listener.type === event.type).forEach((listener) => listener.callback.call(this, event));
    }

    function addEventListener(evt, fn) {
      if (eventListeners.findIndex((event) => event.type === evt && event.callback === fn) !== -1) {
        if (import.meta.env.DEV) {
          console.log('prevent adding duplicate event listener to video service', evt, fn);
        }

        return;
      }

      eventListeners.push({
        type: evt,
        callback: fn,
      });
    }

    function removeEventListener(evt, fn) {
      const index = eventListeners.findIndex((event) => event.type === evt && event.callback === fn);

      if (index >= 0) {
        eventListeners.splice(index, 1);
      }
    }

    return {
      setInstance,
      removeInstance,
      start,
      stop,
      seekToMoment,
      getVideoStartDate,
      canSeekToMoment,
      addEventListener,
      removeEventListener,
      pdtFromUrl,
    };
  };

export default VideoSyncServiceFactory;
