import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import { withDefaults } from 'omniscient';

import { getDeeplinkFromNotification, getPathnameFromDeeplink } from '../../utils/push';
import DebateNotification from '../../components/DebateNotification/DebateNotification';
import SpeakerNotification from '../../components/SpeakerNotification/SpeakerNotification';

const component = withDefaults({ cursorField: 'cursor' });

const createDismissAction = (getCursor) => {
    return function () {
      // This function needs to bindable, so it is not an arrow function.
      getCursor(['ui', 'pushNotification', 'notification']).update(() => Immutable.fromJS({}));
    };
  },
  composeProps = (notificationType, cursor, onDismiss) => {
    const title = cursor.has('message') ? cursor.get('title') : '',
      description = cursor.has('message') ? cursor.get('message') : cursor.get('title'),
      deeplink = getDeeplinkFromNotification(cursor.toJS()),
      href = getPathnameFromDeeplink(deeplink);

    return {
      notification: cursor.toJS(),
      title,
      description,
      href,
      onDismiss,
    };
  },
  getComponent = (notificationType) => {
    if ('speaker' === notificationType) {
      return SpeakerNotification;
    }

    return DebateNotification;
  },
  getNotificationType = () => {
    // We currently don't know if the notification is sent for a speaker
    // So for now we only return 'debate' notifications (without politician image)

    return 'debate';
  },
  definition = {
    /**
     * Component contextTypes
     * @type {Object}
     */
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      pathTo: PropTypes.func.isRequired,
    },

    /**
     * Component propTypes
     * @type {Object}
     */
    propTypes: {
      cursor: PropTypes.object.isRequired,
    },
  },
  /**
   * Easy setup for Notifications
   *
   * @param {Object}          cursor  The route cursor
   *
   * @returns {React.Element}         The NotificationFactory element
   */
  render = function (cursor) {
    const { getCursor } = this.context,
      notificationType = getNotificationType(),
      Notification = getComponent(notificationType),
      onDismiss = createDismissAction(getCursor),
      props = composeProps(notificationType, cursor, onDismiss);

    return <Notification {...props} />;
  };

export default component('NotificationFactory', definition, render);
