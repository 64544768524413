import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { structure } from '../../core';
import PoliticianPanel from '../PoliticianPanel/PoliticianPanel';
import TerminologyPanel from '../TerminologyPanel/TerminologyPanel';
import VotingExplanationPanel from '../VotingExplanationPanel/VotingExplanationPanel';

const slideIn = (component, key) => (
  <CSSTransition classNames="slide" timeout={{ exit: 440, enter: 440 }} key={key} appear>
    {component}
  </CSSTransition>
);

const definition = {
    layerRef: createRef(),

    contextTypes: {
      getService: PropTypes.func.isRequired,
    },

    componentDidUpdate(prevProps) {
      const isVisible = this.props.infoPanel.get('isVisible', false);
      const wasVisible = prevProps.infoPanel.get('isVisible', false);

      if (isVisible && !wasVisible) {
        this.lastFocusedElement = document.activeElement;
        window.requestAnimationFrame(() => {
          this.layerRef.current?.querySelectorAll('button, a, [tabindex="0"]')[0]?.focus({ preventScroll: true });
        });
      } else if (!isVisible && wasVisible) {
        this.lastFocusedElement?.focus();
      }
    },

    renderPanel: function (infoPanel) {
      const component = infoPanel.get('component');

      switch (component) {
        case 'TerminologyPanel':
          return slideIn(<TerminologyPanel infoPanel={infoPanel} key="terminologyPanel" />, 'terminologyPanel');
        case 'PoliticianPanel':
          return slideIn(<PoliticianPanel infoPanel={infoPanel} key="politicianPanel" />, 'politicianPanel');
        case 'VotingExplanationPanel':
          return slideIn(<VotingExplanationPanel infoPanel={infoPanel} key="votingExplanationPanel" />, 'votingExplanationPanel');
      }

      return null;
    },

    handleKeyUp: function (event) {
      if (event.key === 'Escape' && this.props.infoPanel.get('isVisible', false)) {
        this.context.getService('info-panel').hide();
      }
    },
  },
  /**
   * Easy setup for InfoPanels
   *
   * @example
   *
   * ```
   * var infoPanels = [{
   *  route:         'debate-subject-location-info',
   *  propsComposer: propsComposer,
   *  component:     TerminologyPanel
   * }];
   *
   * return <InfoPanelFactory cursor={ routeCursor } infoPanels={ infoPanels } />;
   * ```
   *
   * @returns {React.Component}               The InfoPanelFactory component
   */
  render = function ({ infoPanel }) {
    const isVisible = infoPanel.get('isVisible', false),
      className = classNames('Layer ViewStack-layer Layer-infoPanel', {
        'is-visible': isVisible,
        'is-hidden': !isVisible,
      });

    return (
      <div className={className} onKeyUp={this.handleKeyUp} ref={this.layerRef}>
        <TransitionGroup>{isVisible && this.renderPanel(infoPanel)}</TransitionGroup>
      </div>
    );
  },
  InfoPanelFactory = component('InfoPanelFactory', definition, render);

export default observer(
  structure,
  {
    infoPanel: ['ui', 'infoPanel'],
  },
  InfoPanelFactory,
);
