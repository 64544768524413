import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import component from 'omniscient';

import PoliticianListItem from './PoliticanListItem';

const definition = {
    contextTypes: {
      getCursor: PropTypes.func.isRequired,
      getService: PropTypes.func.isRequired,
    },

    propTypes: {
      members: PropTypes.object.isRequired,
    },
  },
  /**
   * Cabinet render function.
   * @param {Object} props
   * @returns {React.Component}
   */
  render = function ({ members }) {
    return (
      <section className="Politicians-Section">
        <Helmet>
          <title>Kabinetsleden | Debat Direct</title>
          <meta name="description" content="Wie zitten er in het kabinet?" />
        </Helmet>
        <ul className="Politicians-Section__List">
          {members.map((politician) => (
            <PoliticianListItem politician={politician} key={politician.get('id')} />
          ))}
        </ul>
      </section>
    );
  };

export default component('Cabinet', definition, render);
