import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';

import { structure } from '../../core';
import NotificationButton from '../NotificationButton/NotificationButton';

const LABEL_TOGGLED = (
    <span className="Button-label u-centerSelf">
      Stuur geen <br />
      notificaties meer
    </span>
  ),
  LABEL = (
    <span className="Button-label u-centerSelf">
      Stuur notificaties
      <br />
      over dit debat
    </span>
  ),
  LABEL_REOPENING = (
    <span className="Button-label u-centerSelf">
      Stuur notificatie
      <br />
      als dit debat wordt hervat
    </span>
  ),
  getLabel = (isToggled, isSuspended) => (isToggled ? LABEL_TOGGLED : isSuspended ? LABEL_REOPENING : LABEL),
  definition = {
    contextTypes: {
      getService: PropTypes.func.isRequired,
    },

    getInitialState: () => ({
      openRequest: false,
      error: false,
    }),

    getNotification: function () {
      const notificationService = this.context.getService('push-notification');

      return notificationService.hasDebateSubscription(this.props.debate);
    },

    handleClick: function () {
      if (this.state.openRequest) {
        return;
      }

      const notification = this.getNotification(),
        subscribed = !!notification,
        request = subscribed ? this.unsubscribeNotification : this.subscribeNotification;

      request().then(
        () => {
          this.setState({ openRequest: false });
        },
        () => {
          this.setState({ openRequest: false, error: true });
        },
      );

      this.setState({
        openRequest: true,
        error: false,
      });
    },

    subscribeNotification: function () {
      const notificationService = this.context.getService('push-notification');

      return notificationService.addDebateSubscription(this.props.debate);
    },

    unsubscribeNotification: function () {
      const notificationService = this.context.getService('push-notification');

      return notificationService.removeDebateSubscription(this.props.debate);
    },
  },
  /**
   * Renders the notification toggle
   * @param  {object} props
   * @return {React.Component}
   */
  render = function ({ notifications, debateId, suspended, debate, ...rest }) {
    const notification = this.getNotification(),
      subscribed = !!notification,
      props = {
        label: getLabel(subscribed, suspended),
        toggled: subscribed,
        pending: this.state.openRequest,
        error: this.state.error,
        onClick: this.handleClick,
        ...rest,
      };

    if (true === subscribed) {
      props.disabled = false;
    }

    return <NotificationButton {...props} />;
  },
  DebateNotificationButton = component('DebateNotificationButton', definition, render);

export default observer(
  structure,
  {
    notifications: ['data', 'notifications'],
  },
  DebateNotificationButton,
);
