import PropTypes from 'prop-types';
import React from 'react';
import component from 'omniscient';
import moment from 'moment';

import ScrollView from '../../components/ScrollView/SmartScrollView';
import InternalLink from '../../components/InternalLink/InternalLink';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import Icon from '../../components/Icon/Icon';

/**
 * Get header text.
 * @param {Object} recessInfo
 * @returns {String}
 */
const getHeaderText = (recessInfo) => {
  const defaultHeaderText = 'De Tweede Kamer vergadert deze week niet';

  if (!recessInfo?.endDate) {
    return defaultHeaderText;
  }

  const endDate = moment(recessInfo.endDate);

  return 'De Tweede Kamer is met reces ' + (endDate.isValid() ? 'tot en met ' + endDate.format('dddd D MMMM') : '');
};

/**
 * RecessComponent definition.
 */
const definition = {
  contextTypes: {
    pathTo: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
  },
};

/**
 * RecessComponent render method
 * @param {Object} props
 * @returns {React.Component}
 */
const render = function (props) {
  const { pathTo, getService } = this.context,
    recessInfo = getService('date').getRecessInfoForDate(props.agendaDate),
    headerText = getHeaderText(recessInfo),
    plenaryPagePath = pathTo('location-plenary');

  return (
    <div className="Layer ViewStack-layer Layer--white">
      <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
        <ScrollView>
          <section className="Main-content Content NoDebates">
            <h2 className="NoDebates-heading Heading">{headerText}</h2>
            <div className="u-flex u-col u-mb95">
              <ExternalLink className="NoDebates-link u-centerSelf" href="https://www.tweedekamer.nl/vergaderingen/plenaire_vergaderingen">
                <span className="Button-label">NAAR DE KALENDER</span>
                <Icon className="Button-icon u-inline u-fillBlue" name="external-link" width="13" height="13" />
              </ExternalLink>
              <ExternalLink className="NoDebates-link u-centerSelf" href="https://debatgemist.tweedekamer.nl">
                <span className="Button-label">NAAR DEBAT GEMIST</span>
                <Icon className="Button-icon u-inline u-fillBlue" name="external-link" width="13" height="13" />
              </ExternalLink>
            </div>

            <h2 className="NoDebates-heading Heading">Informatie over</h2>

            <div className="u-flex u-col">
              <InternalLink className="Button Button--primary NoDebates-button u-flex u-centerSelf" href={plenaryPagePath}>
                <span className="Button-label u-grow u-centerSelf">de plenaire zaal</span>
                <Icon className="Button-icon u-inline" name="arrow" width="13" height="13" aria-hidden="true" />
              </InternalLink>
            </div>
          </section>
        </ScrollView>
      </div>
    </div>
  );
};

/**
 * Public exports.
 */
export default component('RecessComponent', definition, render);
